import { ListSubheader } from "@mui/material";
import React from "react";
import '../styles/styles.css'
export default function Subheader(props) {
    return <>

        <div className='row'>
            <div className='col-md-12 col-lg-12 col-sx-12 col-sm-12'>
                <div className='fieldHeader'>
                    <label>{props.headerName}</label>
                </div>

            </div>
        </div>

        <div style={{ height: 8 }}></div>
    </>

}