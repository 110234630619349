import React, { useContext, useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import "../styles/styles.css"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Paper } from '@mui/material'
import formData from '../lib/formsData.json'
import CustomDivComponent from "../components/divComponent";
import RowComponent from "../components/rowComponent";
import { validateSSNArray, postRequest, getUser, setVisitId, setSRData, getMandatoryData, validateEmail, getCompanyId, getSRData, getCompanyName, getBranchName, setSsnNumber, setFirstName, setLastName, getADIds, getiPrevDocumentId, getBranchId, getSsnNumber, getFirstName, getLastName } from "../Services/DataMode";
import { postRequestSR } from "../Services/DataMode";
import UserContext from '../components/context';
import { useNavigate } from "react-router-dom";
import ReactLoading from 'react-loading';

function AddDocumentsCreateUpdate(props) {


    const userInfo = useContext(UserContext).user;
    const context = useContext(UserContext);
    const user = useContext(UserContext);
    let navigate = useNavigate();
    const [loading, setloading] = useState(false);
    const [values, setValues] = useState({});
    const [documentsVisitID, setdocumentsVisitID] = useState('');
    const [isNew, setisNew] = useState(true);


    function handleFieldChange(event) {
        var name = event.target.name;
        var value = event.target.value;
        console.log(event.target.name + " --> " + event.target.value)

        if (name == 'SSNumber') {
            setSsnNumber(value)
        }

        if (name == 'FirstName') {
            setFirstName(value)
        }

        if (name == 'LastName') {
            setLastName(value)
        }
        setValues({ ...values, [name]: value });
        console.log(name, value);
    }

    useEffect(() => {

        props.updatePage();

        if (getiPrevDocumentId() == '' || getiPrevDocumentId() == undefined) {
            setValues({
                "Branch": getBranchName(),
                "SSNumber": "",
                "FirstName": "",
                "LastName": "",
                "Company": getCompanyName(),
                "VisitID": 0,
                "Test_Result_File_Ids": "",
                "sKey": getUser()
            })
            setisNew(true)
        } else {
            setValues({
                "Branch": getBranchName(),
                "SSNumber": getSsnNumber().replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3"),
                "FirstName": getFirstName(),
                "LastName": getLastName(),
                "Company": getCompanyName(),
                "VisitID": 0,
                "Test_Result_File_Ids": "",
                "sKey": getUser()
            })
            setisNew(false)
            setdocumentsVisitID(getiPrevDocumentId())
        }

    }, [])



    const submit = () => {

        if (values.SSNumber == '') {
            user.setUser(0, 'Error', getMandatoryData('add_documents_protocol', 'ssn'), 'OK')
        } else if (values.SSNumber.length !== 11) {
            user.setUser(0, 'Error', getMandatoryData('add_documents_protocol', 'ssn_valid'), 'OK')
        } else if (getMandatoryData('add_documents_protocol', 'ssn_invalid_message') != "" && values.SSNumber.length == 11 && !validateSSNArray(values.SSNumber.split("-").join(""), getMandatoryData('add_documents_protocol', 'ssn_invalid'))) {
            context.setUser(0, 'Error', getMandatoryData('add_documents_protocol', 'ssn_invalid_message'), 'OK')
        } else if (values.FirstName == '') {
            user.setUser(0, 'Error', getMandatoryData('add_documents_protocol', 'first_name'), 'OK')
        } else if (values.LastName == '') {
            user.setUser(0, 'Error', getMandatoryData('add_documents_protocol', 'last_name'), 'OK')
        } else if (getADIds() == '' || getADIds() == undefined) {
            user.setUser(0, 'Error', getMandatoryData('add_documents_protocol', 'upload_doc_mandatory'), 'OK')
        } else {
            setloading(true);
            postRequest('ws_webrtc/telemed.asmx/CreateFinalPatientnUploadDocument',
                {
                    sKey: getUser(),
                    sFirstName: values.FirstName,
                    sLastName: values.LastName,
                    sSSN: parseInt(values.SSNumber.split("-").join("")),
                    sPrevDocumentDetailsIds: getADIds(),
                    iPrevDocumentId: parseInt(getiPrevDocumentId()),
                    iCompanyId: parseInt(getCompanyId()),
                    iCompanybranchId: parseInt(getBranchId())
                },
                (response) => {
                    console.log(response);
                    setloading(false);
                    context.setUser(2, "Success", isNew ? getMandatoryData('add_documents_protocol', 'document_create_success') : getMandatoryData('add_documents_protocol', 'document_update_success'), 'OK', false, '', () => { console.log(false) }, () => { navigate('/App/AddDocumentsDashboard'); })

                }, context)
        }
    }



    return (
        <div className="h-100 col-12 p-2">
            <div className="row">
                <div className='col-md-12 col-lg-12 col-sx-12 col-sm-12'>

                    <div >
                        <div className='row'>
                            <div className='col-md-12 col-lg-12 col-sx-12 col-sm-12'>
                                <div>
                                    <CustomDivComponent data={formData.AddDocuments}
                                        onFieldChange={handleFieldChange}
                                        hideControl={false}
                                        hideClaim={true}
                                        hideMQR={false}
                                        hideJC={true}
                                        loadValues={values}
                                        documentsVisitID={documentsVisitID} />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-12 col-lg-12 col-sx-12 col-sm-12 text-center'>
                                <div style={{ padding: 10 }}>
                                    <button className={"btn"} onClick={submit} style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center' }} variant="primary" type="submit">{isNew ? 'Submit' : 'Update'}</button>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
            {loading && (
                <div style={{
                    position: 'fixed', left: '50%', top: '50%',
                    transform: 'translate(-50%, -50%)', height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                }}>
                    <ReactLoading type={'spin'} color={'#ff0000'} height={'5%'} width={'5%'} />
                </div>
            )}
        </div>
    )


}
export default AddDocumentsCreateUpdate;