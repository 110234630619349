import React, { useContext, useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import "../styles/styles.css"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Paper } from '@mui/material'
import formData from '../lib/formsData.json'
import CustomDivComponent from "../components/divComponent";
import RowComponent from "../components/rowComponent";
import { validateSSNArray, postRequest, getUser, setVisitId, setSRData, getMandatoryData, validateEmail, getCompanyId, getSRData, getCompanyName, getBranchName, setSsnNumber, setFirstName, setLastName, getADIds, getiPrevDocumentId, getBranchId, getSsnNumber, getFirstName, getLastName, setiPrevDocumentId, getTempCompanyName, setTempCompanyId, getTempCompanyId } from "../Services/DataMode";
import { postRequestSR } from "../Services/DataMode";
import UserContext from '../components/context';
import { useNavigate } from "react-router-dom";
import ReactLoading from 'react-loading';
import {
    TextField,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Checkbox,
    TextareaAutosize,
    Box,
    OutlinedInput,
    InputAdornment,
} from "@mui/material";
import SearchIcon from "../Images/search_icon.png";
import Parser from 'html-react-parser';
import UploadPreview from "../components/UploadPreview";
import SubHeader from "../components/subHeader";
import SearchList from "../components/SearchList";
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';


function CompanyProtocolCreateUpdate(props) {


    const userInfo = useContext(UserContext).user;
    const context = useContext(UserContext);
    const user = useContext(UserContext);
    let navigate = useNavigate();
    const [loading, setloading] = useState(false);
    const [values, setValues] = useState({});
    const [documentsVisitID, setdocumentsVisitID] = useState('');
    const [isNew, setisNew] = useState(true);


    const [searchedText, setsearchedText] = useState("");
    const [isShow, setShow] = useState(false);
    const [noCompaniesFound, setNoCompaniesFound] = useState(false);
    const [visits, setVisits] = useState([]);
    const timeout = React.useRef(null);
    const [selectedCompanyId, setSelectedCompanyId] = useState(0);
    const [companyName, setCompanyName] = useState("");
    const [enteredText, setEnteredText] = useState("");
    const [imgClear, setImgClear] = useState(false);

    function onSearchFieldChange(event) {
        setNoCompaniesFound(false)
        var value = event.target.value;
        console.log(event.target.value)
        setsearchedText(event.target.value)
        clearTimeout(timeout.current);
        if (value.length > 2) {
            console.log(value)
            timeout.current = setTimeout(() => {
                setVisits([]);
                setShow(false);
                setloading(true);
                postRequest("ws_webrtc/telemed.asmx/GetEMRTelemedCompanies",
                    {
                        sKey: getUser(),
                        sCompanyName: event.target.value
                    },
                    (response) => {

                        setloading(false);
                        setShow(true);
                        if (response.d.length == 0) {
                            setNoCompaniesFound(true)
                        } else {
                            setVisits(response.d);
                        }

                    }, context)
            }, 2000);

        } else {
            setloading(false);
            setVisits([]);
            setShow(false);
        }

    }
    const onCompanySelect = (item) => {
        setVisits([]);
        setShow(false);
        setloading(false);
        console.log(item)

        setsearchedText(item.CompanyName)
        setCompanyName(item.CompanyName)
        setSelectedCompanyId(item.CompanyId)


        if (item.CompanyId != 0) {
            postRequest('ws_webrtc/Telemed.asmx/GetCompanyProtocolDocumentsDashboardList',
                { sKey: getUser(), iCompanyId: item.CompanyId },
                (response) => {
                    console.log(response.d);
                    console.log(response.d.length);

                    if (response.d.length == 0) {

                    } else {
                        response.d.map((itemRes) => {
                            console.log(itemRes.CompanyProtocol_Document_Id)
                            console.log(itemRes.CompanyProtocol_Document_Id)
                            setTempCompanyId(item.CompanyId)
                            GetCompanyProtocolDocumentsUploaded(itemRes.CompanyProtocol_Document_Id)
                            setiPrevDocumentId(itemRes.CompanyProtocol_Document_Id)
                            setdocumentsVisitID(itemRes.CompanyProtocol_Document_Id)
                        }
                        );

                    }
                }, [context])
        }
        setImgClear(true);
    }
    function GetCompanyProtocolDocumentsUploaded(iCompanyProtocolDocumentId) {
        postRequest('ws_webrtc/Telemed.asmx/GetCompanyProtocolDocumentsUploaded',
            { sKey: getUser(), iCompanyProtocolDocumentId: iCompanyProtocolDocumentId },
            (response) => {
                console.log(response.d);
                response.d.map((item) => {
                    setEnteredText(item.CompanyProtocol)
                })
            }, [context])
    }

    useEffect(() => {

        props.updatePage();

        if (getiPrevDocumentId() == '' || getiPrevDocumentId() == undefined) {
            setisNew(true)
        } else {
            setsearchedText(getTempCompanyName())
            setImgClear(true)
            GetCompanyProtocolDocumentsUploaded(getiPrevDocumentId())
            setisNew(false)
            setdocumentsVisitID(getiPrevDocumentId())
        }

    }, [])
    function onFieldChange(event) {
        setEnteredText(event.target.value)
    }


    const submit = () => {

        var condition = getMandatoryData('add_documents_protocol', 'mandatory_condition')
        if (getTempCompanyId() == '') {
            user.setUser(0, 'Error', getMandatoryData('add_documents_protocol', 'company_mandatory'), 'OK')
        } else if (condition == 0) {
            finalSubmit()
        } else if (condition == 1) {
            if (getADIds() == '' && enteredText == '') {
                user.setUser(0, 'Error', getMandatoryData('add_documents_protocol', 'edit_companyprotocol_mandatory'), 'OK')
            } else {
                finalSubmit()
            }
        } else if (condition == 2) {
            if (getADIds() == '') {
                user.setUser(0, 'Error', getMandatoryData('add_documents_protocol', 'upload_doc_mandatory'), 'OK')
            } else if (enteredText == '') {
                user.setUser(0, 'Error', getMandatoryData('add_documents_protocol', 'company_protocol_mandatory'), 'OK')

            } else {
                finalSubmit()
            }
        } else if (condition == 3) {
            if (enteredText == '') {
                user.setUser(0, 'Error', getMandatoryData('add_documents_protocol', 'company_protocol_mandatory'), 'OK')

            } else {
                finalSubmit()
            }
        } else if (condition == 4) {
            if (getADIds() == '') {
                user.setUser(0, 'Error', getMandatoryData('add_documents_protocol', 'upload_doc_mandatory'), 'OK')
            } else {
                finalSubmit()
            }
        }

    }
    function finalSubmit() {
        setloading(true);
        postRequest('ws_webrtc/telemed.asmx/CreateFinalCompanyProtocolUploadDocument',
            {
                sKey: getUser(),
                sCompanyProtocolDocumentDetailsIds: getADIds(),
                iCompanyProtocolId: parseInt(getiPrevDocumentId()),
                iCompanyId: parseInt(getTempCompanyId()),
                sCompanyProtocol: enteredText
            },
            (response) => {
                console.log(response);
                setloading(false);
                context.setUser(2, "Success", isNew ? getMandatoryData('add_documents_protocol', 'add_company_protocol_msg') : getMandatoryData('add_documents_protocol', 'update_company_protocol_msg'), 'OK', false, '', () => { console.log(false) }, () => { navigate('/App/CompanyProtocolDashboard'); })

            }, context)
    }
    const onImgClear = () => {
        setImgClear(false);
        setsearchedText('')
        setEnteredText('')
        setdocumentsVisitID(0)
    }


    return (
        <div className="h-100 col-12 p-2">
            <div className="row">
                <div className='col-md-12 col-lg-12 col-sx-12 col-sm-12'>

                    <div >
                        <div className='row'>
                            <div className='col-md-12 col-lg-12 col-sx-12 col-sm-12'>
                                <div>
                                    <div className="sticky-top bg-white" style={{ zIndex: 99 }}>

                                        {isNew == false && (<>
                                            <label for="exampleSelect1" style={{ paddingLeft: 5, fontSize: '16px', fontWeight: 'bold', color: 'black' }}>Company Name:</label>
                                            <label for="exampleSelect1" style={{ paddingLeft: 5, fontSize: '16px', fontWeight: 'bold', color: 'lightBlue' }}>{getTempCompanyName()}</label>
                                        </>)}

                                        {isNew && (<>
                                            <div style={{ padding: 10 }}>

                                                <>
                                                    <TextField
                                                        variant="standard"
                                                        placeholder="Search Company"
                                                        id="outlined"
                                                        size="small"
                                                        value={searchedText}
                                                        disabled={imgClear}
                                                        style={{
                                                            fontSize: 9,
                                                            backgroundColor: "#E9E9EC",
                                                            paddingLeft: 0,
                                                            paddingRight: 0,
                                                            paddingTop: 10,
                                                            paddingBottom: 10,
                                                            borderStyle: "none",
                                                            borderRadius: 10,
                                                            color: "black",
                                                        }}
                                                        className={"col-12 required"}
                                                        onChange={onSearchFieldChange}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            color: "black",
                                                            sx: {
                                                                color: "black",
                                                            },
                                                            endAdornment: (
                                                                <div style={{ marginRight: 10 }} onClick={onImgClear}>
                                                                    {
                                                                        imgClear && <HighlightOffOutlinedIcon style={{ cursor: "pointer" }} />
                                                                    }
                                                                </div>
                                                            ),
                                                            startAdornment: (
                                                                <InputAdornment position="end">
                                                                    <img
                                                                        src={SearchIcon}
                                                                        style={{ width: "30px", height: "30px" }}
                                                                    ></img>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />

                                                </>
                                                {isShow && <div style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: 500 }} >
                                                    <SearchList data={visits} isCompany={true} handleClick={onCompanySelect} />
                                                </div>
                                                }
                                                {noCompaniesFound &&
                                                    <text style={{ padding: 10, justifyContent: 'center', alignItems: 'center', alignContent: 'center', display: 'flex' }}>No Companies found</text>
                                                }
                                            </div>
                                        </>)}
                                        <>

                                            <div style={{ height: 10 }}></div>
                                            <div className="contentbody">
                                                <SubHeader headerName={'Documents'} />
                                                <UploadPreview
                                                    documentsVisitID={documentsVisitID}
                                                    etext='Do you want to delete scanned document?'
                                                    data={Parser("Select documents to upload<span style='color: red'> * </span>:")}
                                                    id={'CP'}
                                                />
                                                <div style={{ height: 10 }}></div>
                                            </div>
                                            <div style={{ height: 10 }}></div>
                                            <>
                                                <Form.Label
                                                    style={{
                                                        paddingLeft: 5,
                                                        color: "black",
                                                        fontSize: "16px",
                                                        fontWeight: "bold",
                                                        color: "grey",
                                                    }}
                                                >
                                                    {Parser("Company Protocol<span style='color: red'> * </span>:")}
                                                </Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    disabled={false}
                                                    placeholder={''}
                                                    defaultValue={enteredText}
                                                    onChange={onFieldChange}
                                                    style={{ height: "100px" }}
                                                //   name={props.tagid}
                                                />
                                            </>

                                        </>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-12 col-lg-12 col-sx-12 col-sm-12 text-center'>
                                <div style={{ padding: 10 }}>
                                    <button className={"btn"} onClick={submit} style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center' }} variant="primary" type="submit">{isNew ? 'Submit' : 'Update'}</button>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
            {loading && (
                <div style={{
                    position: 'fixed', left: '50%', top: '50%',
                    transform: 'translate(-50%, -50%)', height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                }}>
                    <ReactLoading type={'spin'} color={'#ff0000'} height={'5%'} width={'5%'} />
                </div>
            )}
        </div>
    )


}
export default CompanyProtocolCreateUpdate;