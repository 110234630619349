import React from "react";
import { useRef, useEffect } from "react";
import FormControls from "../components/formControls";
import {
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Checkbox,
  TextareaAutosize,
  Box,
  OutlinedInput,
  InputAdornment,
  Paper,
} from "@mui/material";
import SubHeader from "./subHeader";
import { postRequest, getUser } from "../Services/DataMode";
import CancelIcon from "@mui/icons-material/Cancel";
import UpdateIcon from "@mui/icons-material/Update";
import Icon from "@mui/material/Icon";

export default function SearchList(props) {
  console.log("search data", props.data);
  const visits = props.data;
  function handleClick(item) {
    props.handleClick(item);
  }
  const visit_li = visits.map((visit) => (
    <li key={visit.first_name}>
      <div
        className="row p-3 border-bottom"
        onClick={() => {
          handleClick(visit);
        }}
        style={{ cursor: "pointer" }}
      >
        <div className="col">
          <div class="row">
            <text>
              {visit.first_name == undefined
                ? visit.Patient_Name
                : visit.first_name + " " + visit.last_name}
              {props.isCompany == true
                ? visit.company_name == undefined
                  ? visit.CompanyName
                  : visit.company_name
                : ""}
              {props.isCompanyProtocol == true ? visit.Company_Name : ""}
              {props.showssn == true ? "" + visit.display_DOB + "" : ""}
            </text>
          </div>
        </div>
      </div>
    </li>
  ));
  return <ul style={{ margin: 0, padding: 0 }}>{visit_li}</ul>;
}
