import React, { useState, useEffect, useContext } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import "../styles/styles.css"
import Button from 'react-bootstrap/Button';
import { Paper } from '@mui/material'
import formData from '../lib/formsData.json'
import RowComponent from "../components/rowComponent";
import { postRequest, getUser, getVisitId, getMandatoryData, getExamId, setDoctorId, setDoctorName } from "../Services/DataMode";
import UserContext from '../components/context';
import { useNavigate } from "react-router-dom";
import ReactLoading from 'react-loading';
import Select from 'react-select'


function selectProvider(props) {

    const [doctors, setDoctors] = useState([{ Doc_Name: '', User_ID: '0', Color: "#000000" }]);
    const context = useContext(UserContext);
    const [provider, setDocs] = useState([])
    const [providers, setproviders] = useState(null);
    const [providersYN, setprovidersYN] = useState([]);
    const [providerIDs, setproviderIDs] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [values, setValues] = useState({});

    const [providerID, setProviderID] = useState(0);
    const [providerYN, setProviderYN] = useState(false);
    const [selectedOption, setSelectedOption] = useState([{ Doc_Name: '', User_ID: '0', Color: "#000000", isEngaged: false }]);

    var providerFinalID = 0
    var providerFinalYN = false

    const handleFieldChange = (fieldId) => {
        console.log(fieldId)
        // let index = providers.indexOf(fieldId.target.value)
        // providerFinalYN = providersYN[index]
        // setProviderYN(providerFinalYN)
        // providerFinalID = providerIDs[index]
        // setProviderID(providerFinalID)
        setSelectedOption(fieldId)
        //setValues({ ...values, [fieldId]: value });
    };
    let navigate = useNavigate();
    
    const colorStyles = {
        option: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: 'white',
                color: data.color
            };
        }
    };

    function navigateDoc() {
        // if (selectedOption.isEngaged == false) {
        //     insertDoc()
        // } else {
        //     context.setUser(1, "Info", getMandatoryData('select_provider', 'engaged_message'), 'Cancel', true, getMandatoryData('select_provider', 'go_to_dashboard'), () => { navigate('/App/Dashboard') });
        // }

        if (selectedOption.isEngaged != undefined && selectedOption.isEngaged == true){
            context.setUser(1, "Info", getMandatoryData('select_provider', 'engaged_message'), 'Cancel', true, getMandatoryData('select_provider', 'go_to_dashboard'), () => { navigate('/App/Dashboard') });
        }else{
            insertDoc()
        }
    }
    function insertDoc() {
        setLoading(true)
        postRequest('ws_webrtc/Telemed.asmx/InsertProviderForVisitLog',
            { sKey: getUser(), iVisitId: getVisitId(), iDocId: selectedOption.value == undefined ? 0 : selectedOption.value },
            (response) => {
                setDoctorId(selectedOption.value == undefined ? 0 : selectedOption.value)
                setDoctorName(selectedOption.label == undefined ? 'Next Available' : selectedOption.label)
                setLoading(false)
                navigate('/App/SMCallRoom')
            }, context)
    }
    useEffect(() => {

        var array = [{
            label: 'Next Available', value: '0', color: "black", isEngaged: false
        }]
        setDocs(array)

        props.updatePage();
        postRequest('ws_webrtc/telemed.asmx/GetTelemedProvidersPerVisitType',
            { sKey: getUser(), iVisitId: getVisitId() },
            (response) => {
            //    setDoctors(response.d);
                console.log(response.d)
                setDocs([])
                response.d.map((visit) => {
                  //  providers.push(visit.Doc_Name)
                    // providersYN.push(visit.Provider_Engaged_YN)
                    // providerIDs.push(visit.User_ID)
                  //  setproviders(providers)
                    // setprovidersYN(providersYN)
                    // setproviderIDs(providerIDs)
                    provider.push({
                        label: visit.Doc_Name, value: visit.User_ID, color: (visit.Provider_Engaged_YN ? "grey" : "black"), isEngaged: visit.Provider_Engaged_YN
                    })
                    setDocs(provider)
                }
                )

            }, context)

    }, [])

    //console.log(formData.PatientInfo)
    return (
        <div className="container" style={{ paddingTop: 10 }}>
            <div className="row">
                <div className='col-md-12 col-lg-12 col-sx-12 col-sm-12'>

                    {/* <div className="contentbody">
                        <div className="row">
                            <div className='col-md-12 col-lg-12 col-sx-12 col-sm-12'>
                                <Paper elevation={3} style={{ height: 40, borderRadius: 0 }}>
                                    <header className='pageHeader' style={{ paddingLeft: 10, padding: 10, color: '#333', fontSize: 15, fontWeight: 'bold' }}>Select a Provider</header>
                                </Paper>
                            </div>
                        </div> */}

                    {/* <div className='row'>
                        <div className='col-md-12 col-lg-12 col-sx-12 col-sm-12'>
                            <div style={{ padding: 10 }}>
                                <RowComponent data={formData.SelectProvider}
                                    doctorsList={doctors}
                                    onFieldChange={handleFieldChange} />
                            </div>
                        </div>
                    </div> */}

                    <div className='row'>
                        <div className='col-md-12 col-lg-12 col-sx-12 col-sm-12'>
                            <div style={{ padding: 10 }}>
                                {/* <div className="col-lg-11 col-md-11 col-sm-11 col-xs-11"> */}
                                    <label for="exampleSelect1" style={{ paddingLeft: 5, fontSize: '16px', fontWeight: 'bold', color: 'grey' }}>{getExamId() == '' ? "Select a Provider:" : getMandatoryData('select_provider',getExamId())}</label>
                                    {/* <select onChange={handleFieldChange} className="col-12 required"
                                        style={{ background: '#E2E2E2 !important', height: 30 }} styles={colorStyles}>
                                        {
                                            providers != undefined && providers.map((item, i) => (
                                                <option>{item}</option>
                                            ))
                                        }
                                    </select> */}

                                    <div>
                                        <Select
                                            defaultValue={{ label: "Next Available", value: 0, isEngaged: false }}
                                            onChange={handleFieldChange}
                                            options={provider}
                                            styles={colorStyles}
                                            isSearchable={false}
                                            clearable={false}
                                            maxMenuHeight="auto"
                                        />
                                    </div>

                                {/* </div> */}
                                <div style={{ height: 10 }}></div>

                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-12 col-lg-12 col-sx-12 col-sm-12 text-center'>
                            <div>
                                <button className={"btn"} onClick={() => { navigateDoc() }} style={{ borderRadius: 25, paddingTop: 5, paddingBottom: 5, paddingRight: 50, paddingLeft: 50 }} variant="primary" type="submit">OK</button>
                            </div>
                        </div>
                    </div>

                    {isLoading && (
                        <div style={{
                            position: 'absolute', left: '50%', top: '50%',
                            transform: 'translate(-50%, -50%)', height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                        }}>
                            <ReactLoading type={'spin'} color={'#ff0000'} height={'5%'} width={'5%'} />
                        </div>
                    )}

                    {/* </div> */}

                </div>
            </div>

        </div>
    )


}
export default selectProvider