import React, { useState, useContext,useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import "../styles/styles.css"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Paper } from '@mui/material'
import formData from '../lib/formsData.json'
import CustomDivComponent from "../components/divComponent";
import RowComponent from "../components/rowComponent";
import { postRequest, getSRData, getUser, setVisitId, getMandatoryData } from "../Services/DataMode";
import { useNavigate } from "react-router-dom";
import UserContext from '../components/context';
import ReactLoading from 'react-loading';

function ServiceRequestPageTwo(props) {

    let navigate = useNavigate();
    const context = useContext(UserContext);
    const [loading, setloading] = useState(false);

    const [values, setValues] = useState(JSON.parse(getSRData()));
    //console.log("SRPageOne Data Values " + JSON.stringify(values));
    function handleFieldChange(event) {
        var name = event.target.name;
        var value = event.target.value;
        console.log(event.target.name + " --> " + event.target.value)


        setValues({ ...values, [name]: value });
        console.log(name, value);
    }

    const user = useContext(UserContext);

    useEffect(() => {
        props.updatePage();
    }, []);

    const createSRVisit = () => {

        if(values.Address1.trim()==''){
            user.setUser(0,'Error',getMandatoryData('create_sr','address1'),'OK')
        } else if(values.City.trim()==''){
            user.setUser(0,'Error',getMandatoryData('create_sr','city'),'OK')
        } else if(values.zip.trim()==''){
            user.setUser(0,'Error',getMandatoryData('create_sr','zip'),'OK')
        } else{
            console.log("Create SR Visit" + JSON.stringify(values));
            var NRPhoneNo = values.SR_Country_Code +'-'+ values.NR_PhoneNumber;
            var data = values;
            data.NR_PhoneNumber = NRPhoneNo;
            setloading(true);
            postRequest('TelemedWebAPI2/ServiceRequest/CreateServiceRequest',
            data,
                (response) => {
                    //console.log(response.d);
                    setloading(false);
                    setVisitId(response.VisitID);
                    context.setUser(1, "Info", getMandatoryData('create_sr','sr_created'), 'OK', 
                        false, '', null, () => { navigate('/App/SRDashboard')} );
                }, user)
        }

    }

    function cancelSRVisit(){
        context.setUser(1, "Info", getMandatoryData('create_sr','request_cancel'), 'Yes', 
            true, 'No', ()=>{}, () => { navigate('/App/SRDashboard')} );
    }

    
    return (
        <div className="h-100 col-12 p-2">
            <div className="row">
                <div className='col-md-12 col-lg-12 col-sx-12 col-sm-12'>

                    <div className='row'>
                        <div className='col-md-12 col-lg-12 col-sx-12 col-sm-12'>
                            <div >
                                <CustomDivComponent
                                    data={formData.ServiceRequestPageTwo}
                                    loadValues={values}
                                    onFieldChange={handleFieldChange} />
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-md-6 col-lg-6 col-sx-6 col-sm-6 text-center'>
                            <div style={{ padding: 10 , justifyContent: 'right', alignItems: 'right', alignContent: 'right', display:'flex' }}>
                                <button className={"btn"} onClick={createSRVisit} style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center' }} variant="primary" type="submit">Submit</button>
                            </div>
                        </div>
                        <div className='col-md-6 col-lg-6 col-sx-6 col-sm-6 text-center'>
                            <div style={{ padding: 10 , justifyContent: 'left', alignItems: 'left', alignContent: 'left', display:'flex' }}>
                                <button className={"btn"} onClick={cancelSRVisit} style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center' }} variant="primary" type="submit">Cancel</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {loading && (
                    <div style={{
                        position: 'fixed', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)', height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                    }}>
                        <ReactLoading type={'spin'} color={'#ff0000'} height={'5%'} width={'5%'} />
                    </div>
                )}

        </div>
    )


}
export default ServiceRequestPageTwo;