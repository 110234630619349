import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from "react";
import $ from 'jquery';
import { getMandatoryData } from '../Services/DataMode';


function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            backdrop="static"
            keyboard={false}
            size="md"   
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="modal-width"
        >
            <div className='p-2'>

                <div className='pb-2 d-flex align-items-center border-bottom border-danger ' >
                    <img
                        src={props.icon}
                        style={{ width: '40px', height: '40px' }}
                    />
                    <label style={{ display: 'flex', fontSize: "25px", paddingLeft: 5, color: 'black', fontWeight: 'Bold' }}>{props.headerName}</label>
                </div>

                <div className='m-3'>{$.isArray(props.data) &&
              
                   <label style={{fontSize: "20px", color: 'grey', flexDirection: 'row' }}>
                        {props.data[0]}
                       
                        <label style={{opacity: 0.7, color: 'black', fontWeight: 'bold', fontStyle: 'italic'}}>
                            {props.data[1]}
                            </label>
                       
                        {props.data[2]}
                    </label>
                   
                }
                { $.isArray(props.data) == false &&
                    <label style={{ display: 'flex', fontSize: "20px", color: 'grey', fontWeight: 'normal' }}> {props.data} </label>
                }
                    </div>
 
                 <div className='row'>
                    <div className='col-md-12 col-lg-12 col-xs-12 col-sm-12' style={{ padding: 10,  justifyContent: 'center', alignItems: 'center', alignContent:'center', display:'flex' }}>
                                      <button className={getMandatoryData('sm_room', 'abort_popup_message') == props.data ? "btnpop" : "btn"} onClick={props.onHide} style={{ borderRadius: 25, paddingTop: 5, paddingBottom: 5, }} >{props.okText}</button> &nbsp;&nbsp;
                     {props.cancel &&
                            <button className={getMandatoryData('sm_room', 'abort_popup_message') == props.data ? "btnpop" : "btn"} onClick={props.onCancel} style={{ borderRadius: 25, paddingTop: 5, paddingBottom: 5,  justifyContent: 'center', alignItems: 'center', alignContent: 'center' }} variant="primary" type="submit">{props.cancelText}</button>
                       
                    }   
                                </div>
                   {/* <div className='col d-flex justify-content-center' >
                        <button className={"btn"} onClick={props.onHide} style={{ borderRadius: 25, paddingTop: 5, paddingBottom: 5, paddingRight: 50, paddingLeft: 50 }} >{props.okText}</button>
                    </div>
                    {props.cancel &&
                        <div className='col d-flex justify-content-center'>
                            <button className={"btn"} onClick={props.onCancel} style={{ borderRadius: 25, paddingTop: 5, paddingBottom: 5, paddingRight: 50, paddingLeft: 50, justifyContent: 'center', alignItems: 'center', alignContent: 'center' }} variant="primary" type="submit">{props.cancelText}</button>
                        </div>
                    }*/}
                </div> 
            </div>

        </Modal>
    );
}

export default MyVerticallyCenteredModal