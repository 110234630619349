import { Paper } from "@mui/material";
import { useEffect, useState, useContext, useRef } from "react";
import UserContext from "../components/context";
import {
  postRequest,
  getUser,
  getCompanyId,
  setVisitId,
  setVisitType,
  getMandatoryData,
  setExamId,
  getVisitId,
  getVisitType,
  setDoctorId,
} from "../Services/DataMode";
import CancelIcon from "@mui/icons-material/Cancel";
import UpdateIcon from "@mui/icons-material/Update";
import Icon from "@mui/material/Icon";
import React from "react";
import VisitsList from "../components/VisitsList";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import SearchView from "../components/SearchView";

function ScheduledSeriveRequest(props) {
  console.log("hello cheduledSeriveRequest", props);
  const [loading, setloading] = useState(false);
  const [visits, setVisits] = useState([]);
  const userInfo = useContext(UserContext).user;
  const context = useContext(UserContext);
  let navigate = useNavigate();
  const ref = useRef();
  const [getData, setGetData] = useState(false);
  const searchRef = useRef();

  useEffect(() => {
    props.updatePage();
    loadDashboard();
    setVisitId(0);
    setVisitType("");
    setExamId("");

    // handleResize();

    // window.addEventListener("resize", handleResize);
    // return () => {
    //   window.removeEventListener("resize", handleResize);
    // }
  }, []);

  function loadDashboard() {
    setloading(true);
    postRequest(
      "/TelemedWebAPI2/ServiceRequest/viewSRDetailsList",
      {
        sKey: getUser(),
        first_name: "",
        last_name: "",
        ssn: "",
        company_id: getCompanyId(),
      },
      (response) => {
        setloading(false);
        console.log(response);
        setVisits(response);
      },
      context
    );
  }

  function patientSelected(selectedValue) {
    setVisits([]);
    setloading(true);
    postRequest(
      "TelemedWebAPI2/ServiceRequest/viewSRDetailsList",
      {
        sKey: getUser(),
        ssn: selectedValue.ssn,
        first_name: selectedValue.first_name,
        last_name: selectedValue.last_name,
        company_id: getCompanyId(),
      },
      (response) => {
        //console.log(response);
        setloading(false);
        setVisits(response);
      },
      context
    );
  }

  function clearVisitsWhileLoading(item) {
    console.log(item.clear_visit);
    if (item.clear_visit >= 1) {
      setGetData(true);
      setVisits([]);
    } else {
      if (getData) {
        setGetData(false);
        loadDashboard();
      }
    }
  }

  function visitInitiated(item) {
    if (
      item.examsList[0].current_exam == true &&
      item.examsList[0].exam_id == 2 &&
      item.has_documents == false
    ) {
      context.setUser(
        0,
        "Info",
        getMandatoryData("service_request", "link_not_submitted"),
        "OK"
      );
    } else {
      setExamId(item.examsList[0].exam_id);
      setVisitId(item.visitID);
      if (getMandatoryData("visitPage", "isSelectaProviderScreenVisible")) {
        isproviderEngaged(item);
        setVisitType("SR");
      } else {
        navigate("/App/SelectProvider");
        setVisitType("SR");
      }
      //  navigate('/App/SelectProvider')
      setVisitType("SR");
    }
  }
  function isproviderEngaged(item) {
    postRequest(
      "ws_webrtc/telemed.asmx/IsAllTelemedProvidersEngaged",
      { sKey: getUser(), iVisitId: item.visitID },
      (response) => {
        console.log(response.d.status, "get Doc status");

        if (response.d.status) {
          context.setUser(
            1,
            "Info",
            getMandatoryData("select_provider", "all_engaged_message"),
            getMandatoryData("select_provider", "all_engaged_btn"),
            notifyEmail()
          );
        } else {
          getStateSpecific(item);
        }
      },
      context
    );
  }
  function getStateSpecific(item) {
    postRequest(
      "ws_webrtc/Telemed.asmx/getStateSpecificProvider",
      { sKey: getUser(), iVisitId: item.visitID },
      (response) => {
        console.log(response.d.Doc_ID, "get Doc ID");
        if (response.d.Doc_ID) {
          insertDoc(response.d.Doc_ID);
          setDoctorId((response.d.Doc_ID))
        } else {
          insertDoc(response.d.Doc_ID);
          setDoctorId((response.d.Doc_ID))
        }
      },
      context
    );
  }
  function insertDoc(item) {
    //setLoading(true)
    console.log(item);
    postRequest(
      "ws_webrtc/Telemed.asmx/InsertProviderForVisitLog",
      { sKey: getUser(), iVisitId: getVisitId(), iDocId: item },
      (response) => {
        //     setDoctorId(selectedOption.value == undefined ? 0 : selectedOption.value)
        //    setDoctorName(selectedOption.label == undefined ? 'Next Available' : selectedOption.label)
        // setLoading(false)
        if (response.d) {
          navigate("/App/SMCallRoom");
        }
      },
      context
    );
  }
  const handleResize = () => {
    const top = ref.current;
    const search = searchRef.current;
    if (window.innerWidth <= 960) {
      top.className = "col-lg-12 col-md-12 col-xs-12 col-sm-12  paddingLR";
      search.className = "marginRight10";
    } else {
      top.className = "col-lg-7 col-md-7 col-sm-7 col-sm-7 container";
      search.className = "marginRight35";
    }
  };

  function notifyEmail() {
    postRequest(
      "ws_webrtc/Telemed.asmx/SendEmailToTelemedProviders",
      {
        sKey: getUser(),
        iVisitId: getVisitId(),
      },
      (response) => {
        console.log(response);
      },
      context
    );
  }

  return (
    <div className="h-100 col-12 p-2">
      {loading && (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactLoading
            type={"spin"}
            color={"#ff0000"}
            height={"5%"}
            width={"5%"}
          />
        </div>
      )}
      <div className="sticky-top bg-white" style={{ zIndex: 99 }}>
        <SearchView
          isDisable={loading}
          heading="Patient Search:"
          placeholder={"Search Patient"}
          url={"TelemedWebAPI2/ServiceRequest/ViewServiceRequestListSearch"}
          handleClick={patientSelected}
          clearVisits={clearVisitsWhileLoading}
        ></SearchView>
      </div>
      <div className="px-1">
        <VisitsList
          deleteIcon={false}
          data={visits}
          handleClick={(event) => {
            visitInitiated(event);
          }}
        />
      </div>
    </div>
  );
}

export default ScheduledSeriveRequest;
