//dev
// export const baseUrl = "http://40.67.186.36/";
// export const jsonFileURL = "http://40.67.186.36/mandatoryData.json";
// export const pdfUrl = "http://40.67.186.36";
// export const forgotPassword = "http://40.67.186.36/EMR5WebAPI/telemed/";

// move to dev
// export const baseUrl = "https://40.67.186.36/";
// export const jsonFileURL = "https://40.67.186.36/mandatoryData.json";
// export const pdfUrl = "https://40.67.186.36";
// export const forgotPassword = "https://40.67.186.36/EMR5WebAPI/telemed/";

// move to stage
//  export const baseUrl = "http://40.67.186.36:90/";
//  export const jsonFileURL = "http://40.67.186.36:90/mandatoryData.json";
//  export const pdfUrl = "http://40.67.186.36:90";
//  export const forgotPassword = "http://40.67.186.36:90/EMR5WebAPI/telemed/";

//live
 export const baseUrl = "https://telemed2.caduceususa.com/";
 export const jsonFileURL = "https://telemed2.caduceususa.com/mandatoryData.json"
 export const pdfUrl = "https://telemed2.caduceususa.com"
export const forgotPassword ="https://emr.caduceusoccmed.com/EMR5WebAPI/telemed/";

export function getBaseUrl() {
  return pdfUrl;
}

export function setUser(sKey) {
  sessionStorage.setItem("sessionKey", sKey);
}
export function getUser() {
  return sessionStorage.getItem("sessionKey");
}

export function setCompanyId(CompanyId) {
  sessionStorage.setItem("CompanyId", CompanyId);
}
export function getCompanyId() {
  return sessionStorage.getItem("CompanyId");
}

export function setCompanyName(CompanyName) {
  sessionStorage.setItem("CompanyName", CompanyName);
}
export function getCompanyName() {
  return sessionStorage.getItem("CompanyName");
}

export function setBranchId(BranchId) {
  sessionStorage.setItem("BranchId", BranchId);
}
export function getBranchId() {
  return sessionStorage.getItem("BranchId");
}

export function setBranchName(BranchName) {
  sessionStorage.setItem("BranchName", BranchName);
}
export function getBranchName() {
  return sessionStorage.getItem("BranchName");
}

export function setUserId(UserId) {
  sessionStorage.setItem("UserId", UserId);
}
export function getUserId() {
  return sessionStorage.getItem("UserId");
}

export function setGlobalEmail(GlobalEmail) {
  sessionStorage.setItem("GlobalEmail", GlobalEmail);
}
export function getGlobalEmail() {
  return sessionStorage.getItem("GlobalEmail");
}

export function setisGlobal(isGlobal) {
  sessionStorage.setItem("isGlobal", isGlobal);
}
export function getisGlobal() {
  return sessionStorage.getItem("isGlobal");
}
export function setisFollowupYN(isFollowupYN) {
  sessionStorage.setItem("isFollowupYN", isFollowupYN);
}
export function getisFollowupYN() {
  return sessionStorage.getItem("isFollowupYN");
}

export function setVisitTypeID(VisitTypeID) {
  sessionStorage.setItem("VisitTypeID", VisitTypeID);
}
export function getVisitTypeID() {
  return sessionStorage.getItem("VisitTypeID");
}

export function setVisitTypeName(VisitTypeName) {
  sessionStorage.setItem("VisitTypeName", VisitTypeName);
}
export function getVisitTypeName() {
  return sessionStorage.getItem("VisitTypeName");
}

export function setPurposeOfVistID(PurposeOfVistID) {
  sessionStorage.setItem("PurposeOfVistID", PurposeOfVistID);
}
export function getPurposeOfVistID() {
  return sessionStorage.getItem("PurposeOfVistID");
}

export function setsherwinText(sherwinText) {
  sessionStorage.setItem("sherwinText", sherwinText);
}
export function getsherwinText() {
  return sessionStorage.getItem("sherwinText");
}
export function setSRData(data) {
  sessionStorage.setItem("sr_data", data);
}
export function getSRData() {
  return sessionStorage.getItem("sr_data");
}
export function setVisitId(visit_id) {
  sessionStorage.setItem("visit_id", visit_id);
}
export function getVisitId() {
  return sessionStorage.getItem("visit_id");
}
export function setVisitHistoryId(visit_history_id) {
  sessionStorage.setItem("visit_history_id", visit_history_id);
}
export function getVisitHistoryId() {
  return sessionStorage.getItem("visit_history_id");
}
export function setVisitHistoryType(visit_history_type) {
  sessionStorage.setItem("visit_history_type", visit_history_type);
}
export function getVisitHistoryType() {
  return sessionStorage.getItem("visit_history_type");
}
export function setFollowupYN(data) {
  sessionStorage.setItem("followup", data);
}
export function getFollowupYN() {
  return sessionStorage.getItem("followup");
}
export function setVisitType(visitType) {
  sessionStorage.setItem("visitType", visitType);
}
export function getVisitType() {
  return sessionStorage.getItem("visitType");
}
export function setExamId(ExamId) {
  sessionStorage.setItem("ExamId", ExamId);
}
export function getExamId() {
  return sessionStorage.getItem("ExamId");
}
export function setLanguageId(LanguageId) {
  sessionStorage.setItem("LanguageId", LanguageId);
}
export function getLanguageId() {
  return sessionStorage.getItem("LanguageId");
}

export function setVideoIndexId(VideoIndexId) {
  sessionStorage.setItem("VideoIndexId", VideoIndexId);
}
export function getVideoIndexId() {
  return sessionStorage.getItem("VideoIndexId");
}

export function setDoctorId(DoctorId) {
  sessionStorage.setItem("DoctorId", DoctorId);
}
export function getDoctorId() {
  return sessionStorage.getItem("DoctorId");
}
export function setDoctorName(DoctorName) {
  sessionStorage.setItem("DoctorName", DoctorName);
}
export function getDoctorName() {
  return sessionStorage.getItem("DoctorName");
}
export function setFutureVisitFromSMDashboard(futureVisit) {
  sessionStorage.setItem("futureVisitFromSMDashboard", futureVisit);
}
export function getFutureVisitFromSMDashboard() {
  return sessionStorage.getItem("futureVisitFromSMDashboard");
}
// Add documents
export function setSsnNumber(SsnNumber) {
  sessionStorage.setItem("SsnNumber", SsnNumber);
}
export function getSsnNumber() {
  return sessionStorage.getItem("SsnNumber");
}

export function setFirstName(FirstName) {
  sessionStorage.setItem("FirstName", FirstName);
}
export function getFirstName() {
  return sessionStorage.getItem("FirstName");
}

export function setLastName(LastName) {
  sessionStorage.setItem("LastName", LastName);
}
export function getLastName() {
  return sessionStorage.getItem("LastName");
}

export function setADIds(ADIds) {
  sessionStorage.setItem("ADIds", ADIds);
}
export function getADIds() {
  return sessionStorage.getItem("ADIds");
}

export function setiPrevDocumentId(iPrevDocumentId) {
  sessionStorage.setItem("iPrevDocumentId", iPrevDocumentId);
}
export function getiPrevDocumentId() {
  return sessionStorage.getItem("iPrevDocumentId");
}
// company protocol

export function setTempCompanyId(TempCompanyId) {
  sessionStorage.setItem("TempCompanyId", TempCompanyId);
}
export function getTempCompanyId() {
  return sessionStorage.getItem("TempCompanyId");
}

export function setTempCompanyName(TempCompanyName) {
  sessionStorage.setItem("TempCompanyName", TempCompanyName);
}
export function getTempCompanyName() {
  return sessionStorage.getItem("TempCompanyName");
}

export function setMandatoryData(Mandatory) {
  sessionStorage.setItem("mandatoryData", JSON.stringify(Mandatory));
}
export function getMandatoryData(Base, Key) {
  var value = "";
  if (sessionStorage.getItem("mandatoryData") != null) {
    var dic = JSON.parse(sessionStorage.getItem("mandatoryData"));
    value = dic[Base][Key];
  }
  return value;
}

export function validateEmail(email) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
}
// export function validateSSN(SSN) {
//     var ssnnumber = SSN.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
//     console.log(ssnnumber);
//     return /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/i.test(ssnnumber);
// }
// export function validateSSN(SSN,Reg) {
//     var ssnnumber = SSN.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
//     let array = ['/',Reg,'/']
//     let textJoin = array.join("");
//    // let regex = new RegExp(/^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/);
//     let regex = new RegExp(eval(textJoin));
//     return regex.test(ssnnumber)
// }
var ssnNumber = 0;
export function validateSSNArray(SSN, Reg) {
  console.log(SSN);
  ssnNumber = SSN;
  var array = Reg.map(joinFunction);
  return !array.includes(false);
}
function joinFunction(Regular) {
  var reg = "/" + Regular + "/";
  let regex = new RegExp(eval(reg));
  return regex.test(ssnNumber);
}
export function validateAdditionalEmail(email) {
  if (email.includes(",")) {
    var stringArray = email.split(",");
    const sampleArr = [];
    for (var i = 0; i < stringArray.length; i++) {
      var bool = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        stringArray[i]
      );
      sampleArr.push(bool);
      if (stringArray.length - 1 == i) {
        return !sampleArr.includes(false);
      }
    }
  } else {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  }
}
export function storeEmail(email) {
  sessionStorage.setItem("Email", email);
}
export function getEmail() {
  return sessionStorage.getItem("Email");
}
export function setTestResultFileIds(testResultFileIds) {
  sessionStorage.setItem("TestResultFileIds", testResultFileIds);
}
export function getTestResultFileIds() {
  return sessionStorage.getItem("TestResultFileIds");
}
export function setMQRIds(MQRIds) {
  sessionStorage.setItem("MQRIds", MQRIds);
}
export function getMQRIds() {
  return sessionStorage.getItem("MQRIds");
}
export function setUploadIds(UploadIds) {
  sessionStorage.setItem("UploadIds", UploadIds);
}
export function getUploadIds() {
  return sessionStorage.getItem("UploadIds");
}
export function storePassword(password) {
  sessionStorage.setItem("Password", password);
}
export function getPassword() {
  return sessionStorage.getItem("Password");
}

export function mandatoryDataCalling() {
  fetch(jsonFileURL)
    .then((response) => response.json())
    .then((responseJson) => {
      console.log(responseJson);
      setMandatoryData(responseJson);
    })
    .catch((error) => {
      console.error(error);
    });
}
export function postRequest(url, params, callBack, context) {
  console.log(baseUrl + url + "   " + JSON.stringify(params));
  var res;
  fetch(baseUrl + url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  })
    .then((response) => {
      res = response;
      return response.json();
    })
    .then((json) => {
      if (res.ok) {
        callBack(json);
      } else {
        console.log(json);
        if (json["Message"].includes("Error Message:")) {
          var str = json["Message"];
          var new_str = str.replace(/Error Message: /g, "");
          context.setUser(10, "Error", new_str, "OK");
        } else {
          context.setUser(10, "Error", json["Message"], "OK");
        }
      }
    })
    // .catch((error)=>context.setError({show:true,tittle:'Error',message:'Internal server error'})
    .catch((error) => {
      console.log(error);
    });
}
export function getRequest(url, callBack, context) {
  console.log(forgotPassword + url);
  // fetch(forgotPassword + url,{
  //     method: 'GET',
  //     headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'text/plain'
  //     },
  // })
  // .then(response => response)
  // .then((response) => { callBack(response) })
  // .catch((error) => { console.log('Error'); console.log(error); }
  // );

  fetch(forgotPassword + url)
    .then((response) => response.json())
    .then((responseJson) => {
      console.log(responseJson);
      callBack(responseJson);
    })
    .catch((error) => {
      console.error(error);
    });
}
export function searchRequest(url, params, callBack, context, signal) {
  //console.log(baseUrl+url+"   "+JSON.stringify(params));
  // fetch(baseUrl + url, {
  //     method: 'POST',
  //     headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify(params)
  // })
  //     .then((response) => response.json())
  //     .then((json) => { callBack(json) })
  //     // .catch((error)=>context.setError({show:true,tittle:'Error',message:'Internal server error'})
  //     .catch((error) => { context.setUser(0, 'Error', 'You already logged in another device.', 'OK');  console.log(error); }
  //     );

  console.log(baseUrl + url + "   " + JSON.stringify(params));
  fetch(baseUrl + url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
    signal,
  })
    .then((response) => response.json())
    .then((json) => {
      callBack(json);
    })
    .catch((e) => {
      console.warn(`Fetch 1 error: ${e.message}`);
      if (e.name === "AbortError") {
        // We know it's been canceled!
        console.warn(`aborted`);
      }
    });
}
