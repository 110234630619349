import React, { Component } from 'react';
import { Paper, TextField } from '@mui/material'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal';
import CancelIcon from '@mui/icons-material/Cancel';


function PreviousVisitDetails(props) {
    return (
    <Modal
        {...props}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-width450">

        <div className="row">
            <div className='col-md-10 col-lg-10 col-xs-10 col-sm-10'> 
               <header className='pageHeader' style={{ paddingLeft: 10,paddingTop:10,color: '#333', fontWeight: 'bold', justifyContent: 'center', alignItems: 'center', alignContent:'center', display:'flex' }}>Previous visit details</header>
            </div>
            <div className='col-md-2 col-lg-2 col-xs-2 col-sm-2' style={{ paddingTop:10, justifyContent: 'center', alignItems: 'center', alignContent:'center', display:'flex'}}>
                <CancelIcon onClick={props.onHideMain} style={{color:'black'}}/>   
            </div>
       </div>
       <div className="container" style={{ paddingTop: 10 }}>
           <table className="contentbody">
               <tr>
                   <td className='col-6'>   
                       <div className="schedulePopupLbl">
                           <Form.Label>Patient Name</Form.Label>
                       </div>
                   </td>
                   <td className='col-6'> 
                       <div className="schedulePopupLblvalue">
                           <Form.Label>:{props.data.first_name + props.data.last_name}</Form.Label>
                       </div>
                   </td>
               </tr>
               <tr>
                   <td className='col-6'>   
                       <div className="schedulePopupLbl">
                           <Form.Label>Company Name</Form.Label>
                       </div>
                   </td>
                   <td className='col-6'>
                       <div className="schedulePopupLblvalue">
                           <Form.Label>:{props.data.company_name}</Form.Label>
                       </div>
                   </td>
               </tr>
               <tr>
                   <td className='col-6'>  
                       <div className="schedulePopupLbl">
                           <Form.Label>Branch Name</Form.Label>
                       </div>
                   </td>
                   <td className='col-6'>   
                       <div className="schedulePopupLblvalue">
                           <Form.Label>:{props.data.branch_name == '' ? 'None' : props.data.branch_name}</Form.Label>
                       </div>
                   </td>
               </tr>
               <tr>
                   <td className='col-6'>    
                       <div className="schedulePopupLbl">
                           <Form.Label>Is Previous visit a follow up</Form.Label>
                       </div>
                   </td>
                   <td className='col-6'>  
                       <div className="schedulePopupLblvalue">
                               <Form.Label>:{props.data.PrevVisit_Followup}</Form.Label>
                       </div>
                   </td>
               </tr>
                {props.data.Followup_Count >=1  && <tr>
                   <td className='col-6'>    
                       <div className="schedulePopupLbl">
                           <Form.Label>Follow up visit number</Form.Label>
                       </div>
                   </td>
                   <td className='col-6'>  
                       <div className="schedulePopupLblvalue">
                               <Form.Label>:{props.data.Followup_Count}</Form.Label>
                       </div>
                   </td>
               </tr>}
               <tr>
                   <td className='col-6'>  
                       <div className="schedulePopupLbl">
                           <Form.Label>Visit completed on</Form.Label>
                       </div>
                   </td>
                   <td className='col-6'>  
                       <div className="schedulePopupLblvalue">
                               <Form.Label>:{props.data.Visit_Completed}</Form.Label>
                       </div>
                   </td>
               </tr>
               <tr>
                   <td className='col-6'>  
                       <div className="schedulePopupLbl">
                           <Form.Label>Work status</Form.Label>
                       </div>
                   </td>
                   <td className='col-6'>  
                       <div className="schedulePopupLblvalue">
                               <Form.Label>:{props.data.workstatus}</Form.Label>
                       </div>
                   </td>
               </tr>
 
           </table>
       </div>
       <div style={{height:10}}></div>
       <div className='row'>         
           <div className='col-md-12 col-lg-12 col-xs-12 col-sm-12' style={{ padding: 10,  justifyContent: 'center', alignItems: 'center', alignContent:'center', display:'flex' }}>
               <button className={"btn"} onClick={props.onHide}  style={{  justifyContent: 'center', alignItems: 'center', alignContent: 'center'}} variant="primary" type="submit">Ok</button>
           </div>
                      
       </div>
   </Modal>
    );
}

export default PreviousVisitDetails;