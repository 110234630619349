import { useNavigate } from "react-router-dom";
import {
  useMemo,
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import {
  setGlobalEmail,
  setVideoIndexId,
  getEmail,
  postRequest,
  getRequest,
  searchRequest,
  setUser,
  mandatoryDataCalling,
  getMandatoryData,
  storeEmail,
  storePassword,
  getUser,
  setCompanyId,
  getCompanyId,
  baseUrl,
  pdfUrl,
  setisGlobal,
  getBaseUrl,
} from "../Services/DataMode";
import { browserName, browserVersion, osName } from "react-device-detect";
import logo from "../Images/CaduceusLogoRed.png";
import forgot_key from "../Images/forgot_key.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import UserContext from "../components/context";
import TopMenu from "../components/TopMenu";
import context from "react-bootstrap/esm/AccordionContext";
import ReactLoading from "react-loading";
import { FormControlLabel, Switch, colors } from "@mui/material";
import Form from "react-bootstrap/Form";
import Parser from "html-react-parser";

import CaduceusLogin from "../Images/CaduceusLoginBg.jpg";
import Loading from "react-loading";
import { TroubleshootSharp } from "@mui/icons-material";

function Login(props) {
  // <script src="https://static.opentok.com/v2/js/opentok.min.js" type="text/javascript" />
  const [timer, setTimer] = useState(
    getMandatoryData("loginPage", "resendCode_Timer_Value_android")
  );
  const [timerInterval, setTimerInterval] = useState(null);

  const [showPasswordField, setShowPasswordField] = useState(true);
  const [showOTPField, setShowOTPField] = useState(false);
  const [showPhoneField, setShowPhoneField] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("+1");
  const [OTP, setOtp] = useState("");
  const [btnText, setbtnText] = useState("LOG IN");
  const [isLoading, setLoading] = useState(false);
  const [activePage, setActivepage] = useState("Caduceus TeleMed");
  const [message, setMessage] = useState("");
  const [terms, setTerms] = useState(false);
  const [termsPath, setTermsPath] = useState("");
  const [termsData, setTermsData] = useState({});
  const [showOTP, setShowOTP] = useState(false);

  const [showPassword, setShowpassword] = useState(true);
  const [forgotPassword, setForgotpassword] = useState("Forgot Password");
  const [countrieslist, setCountrieslist] = useState("");

  const [emailDisabled, setEmailDisabled] = useState(false);
  // const [pbnumber, setPbnumber] = useState(false);
  const [displayOtp, setDisplayOtp] = useState(false);

  const [PhoneNumber, setPhonenumber] = useState("");

  const [cancelText, setCancelText] = useState("Cancel");
  const [disabledCancelText, setDisabledCancelText] = useState(false);

  const [isLoggedin, setIsLoggedin] = useState(false);
  const [isSentOTP, setIsSentOTP] = useState(false);
  const [disabledResend, setDisabledResend] = useState(true);

  const ref = useRef(null);
  const Ref = useRef(null);
  const contentRef = useRef(null);
  let navigate = useNavigate();
  const user = useContext(UserContext);
  const topMenu = useRef();
  useEffect(() => {
    //  setshowPhoneNumber(true)
    setUser("");
    mandatoryDataCalling();
    handleResize();
    if (getEmail() !== "") {
      setEmail(getEmail());
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    setVideoIndexId(0);
  }, []);

  const handleResize = () => {
    const top = ref.current;
    const content = contentRef.current;
    const menu = topMenu.current;
    if (window.innerWidth <= 960) {
      // alert(side)
      // top.className = "col-lg-12 col-md-12 col-xs-12 col-sm-12 paddingmargin0 container"
      // content.className = "col-lg-12 col-md-12 col-xs-12 col-sm-12 vh-100 h-100 text-center justify-content-center paddingmargin0"
      // menu.className = "topmenumargin"
    } else {
      // top.className = "col-lg-7 col-md-7 col-sm-7 col-sm-7 container"
      // content.className = "col-lg-7 col-md-7 col-xs-7 col-sm-7 container vh-100 h-100 text-center justify-content-center "
      // menu.className="topmenumarginresize"
    }
  };
  function handleDropdownChange(event) {
    var value = event.target.value;
    console.log(value);
    setCode(value);
  }
  function handleFieldChanges(event) {
    event.preventDefault();
    var name = event.target.name;
    var value = event.target.value;

    // setCode(value);

    const re = /^[0-9\b]+$/;

    if (name === "Phonenumber") {
      if (value === "" || re.test(value)) {
        console.log(re.test(value));

        setPhonenumber(value);
      } else {
        console.log("key pressed", value);
        return false;
      }
    } else if (name == "otp") {
      if (value === "" || re.test(value)) {
        console.log(re.test(value));

        setOtp(value);
      } else if (name == "CountryCode") {
        console.log(value);
        setCode(value);
      } else {
        console.log("key pressed", value);
        return false;
      }
    }
    return false;
  }

  const successLoginTracking = {
    device_name: browserName + " " + browserVersion,
    device_os: "Web - " + osName,
    app_version: "1.0",
    user_name: email,
    password: password,
    login_status: "Success",
    wifi_name: "",
    reason: "Credentials Matched",
  };

  const failLoginTracking = {
    device_name: browserName + " " + browserVersion,
    device_os: "Web - " + osName,
    app_version: "webapp 1.0",
    user_name: email,
    password: password,
    login_status: "Failed",
    wifi_name: "",
    reason: message,
  };

  const LoginTracking = (success) => {
    setLoading(true);

    if (success) {
      postRequest(
        "ws_webrtc/Telemed.asmx/createLoginTracking",
        { oLTrack: successLoginTracking },
        (response) => {
          console.log("login track", response.d);
          setLoading(false);
        },
        context
      );
    } else {
      postRequest(
        "ws_webrtc/Telemed.asmx/createLoginTracking",
        { oLTrack: failLoginTracking },
        (response) => {
          //console.log(response.d);
          setLoading(false);
          setPassword("");
        },
        context
      );
    }
  };

  const returnObject = () => {
    postRequest(
      "ws_webrtc/Util.asmx/returnObject",
      { sKey: getUser(), sType: "contact", iObjectId: 0 },
      (response) => {
        setCompanyId(response.d.CompanyId);
        console.log(getCompanyId());
        postRequest(
          "ws_webrtc/telemed.asmx/getUser",
          { sKey: getUser() },
          (response) => {
            if (response.d.userlevel_id == 3) {
              setLoading(true);
              showTermsOfService();
              moveToDashBoard();
            } else {
              setLoading(false);
              user.setUser(
                0,
                "Error",
                "Your role is not enabled to login to website",
                "OK"
              );
              setLoading(false);
              //showTermsOfService();
            }
          },
          context
        );
        //   showTermsOfService();
      },
      context
    );
  };

  function showTermsOfService() {
    postRequest(
      "ws_webrtc/telemed.asmx/getTosInfo",
      { sKey: getUser() },
      (response) => {
        // console.log(JSON.stringify(response.d));
        if (response.d.tos_load) {
          setTerms(true);
          setTermsPath(baseUrl + response.d.tos_filepath);
          setTermsData(response.d);
          setLoading(true);
        } else {
          setTerms(false);

          // if (showPasswordField) {
          //   getPhonenumber(false);
          // // } else if (showOTPField) {
          //   if (OTP == "") {
          //     user.setUser(
          //       0,
          //       "Error",
          //       getMandatoryData("loginPage", "authentication_code"),
          //       "OK"
          //     );
          //   } else {
          //     validateOTP();
          //   }
          // }
        }
      },
      context
    );
  }

  function GetCountries() {
    postRequest(
      "ws_webrtc/Telemed.asmx/GetCountries",
      { sKey: getUser() },
      (response) => {
        // console.log("countries", response.d);
        const countryArray = [];
        for (var i = 0; i < response.d.length; i++) {
          countryArray.push({
            id: response.d[i].Country_Id,
            name: response.d[i].Country_Name,
            abbr: response.d[i].Country_Abbr,
            code: response.d[i].Country_Code,
          });
        }
        // console.log("countryArray", countryArray);
        setCountrieslist(countryArray);
        // console.log("countrieslist", countrieslist);
      },
      context
    );
  }

  function clearFields() {
    showPasswordField(true);
    showPhoneField(false);
    setPassword("");
    setCode("");
  }

  function getPhonenumber(boolean) {
    postRequest(
      "ws_webrtc/telemed.asmx/SendSMSNotificationFor2FA",
      {
        sKey: getUser(),
        isResendCode: boolean,
        DeviceName: browserName + " " + browserVersion,
      },

      (response) => {
        console.log("response of phone number", response.d);
        if (!response.d.Enable2Fa_Yn) {
          returnObject();
        } else {
          if (response.d.status) {
            setDisabledResend(true);
            setTimer(timer);
            setTimeout(() => {
              setDisabledResend(false);
            }, getMandatoryData("loginPage", "resendCode_Timer_Value_android"));
            setShowOTPField(true);
            setShowPhoneField(false);
            setLoading(false);
            setbtnText("LOG IN");
            setShowOTP(true);
            setShowpassword(false);
            setIsSentOTP(true);
            setEmailDisabled(true);
            setForgotpassword("Resend Code");
            setDisabledCancelText(true);
          } else {
            // setPbnumber(true);
            setIsSentOTP(true);
            setShowOTP(false);
            setShowpassword(false);
            GetCountries();
            setEmailDisabled(true);
            setCancelText("Cancel");
            setbtnText("SUBMIT");
            setLoading(false);
            // else {
            //   setPassword("");
            //   setLoading(false);
            //   user.setUser(1, "Error", response.d.message, "Ok", false, "");
            //   showPasswordField(true);
            //   showPhoneField(false);
            // }

            if (!showOTPField) {
              setShowPhoneField(true);
            }
          }
          setShowPasswordField(false);
        }
      }
    );
    // setLoading(false);
  }

  function updatePhoneNumber() {
    console.log("hello update Phone ", PhoneNumber, code);
    if (!PhoneNumber) {
      user.setUser(
        0,
        "Error",
        getMandatoryData("loginPage", "phoneNumber"),
        "OK"
      );
      setLoading(false);
    } else {
      postRequest(
        "ws_webrtc/Telemed.asmx/UpdateUserPhoneNumber",
        { sKey: getUser(), PhoneNumber: code + "-" + PhoneNumber },
        (response) => {
          console.log("PhoneNumber", PhoneNumber);
          console.log("updated", JSON.stringify(response.d));
          console.log("status", response.d.status);

          if (response.d.status) {
            setLoading(false);
            // setPbnumber(false);
            setShowOTP(true);
            setIsSentOTP(true);
            setOtp("");

            setbtnText("LOG IN");
            setForgotpassword("Resend Code");
            setShowPhoneField(false);
            setShowOTPField(true);
            setDisabledResend(true);
            getPhonenumber(false); //SendSMS
            setTimer(timer);
            setTimeout(() => {
              setDisabledResend(false);
            }, getMandatoryData("loginPage", "resendCode_Timer_Value_android"));
          } else {
            user.setUser(0, "Error", response.d.message, "OK");
          }
          setLoading(false);
        },
        context
      );
    }
  }

  function validateOTP() {
    // if (!isLoading) {
    //   setLoading(true);
    // }

    postRequest(
      "ws_webrtc/Telemed.asmx/ValidateOTP",
      { sKey: getUser(), otp: OTP },
      (response) => {
        console.log(JSON.stringify(response.d));
        console.log("otp configuration", response.d.status);
        if (response.d.status) {
          returnObject();
          setLoading(true);
        } else {
          user.setUser(
            0,
            "Error",
            getMandatoryData("loginPage", "ValidOTP"),
            "OK"
          );

          setIsSentOTP(true);
          setShowOTP(true);
          setLoading(false);
        }
      },
      context
    );
  }

  function moveToDashBoard() {
    // if (!isLoading) {
    //   setLoading(true);
    // }

    postRequest(
      "ws_webrtc/Telemed.asmx/IsUserGODUser",
      { sKey: getUser() },
      (response) => {
        console.log(JSON.stringify(response.d));
        if (response.d.status) {
          setLoading(false);
          user.setUser(
            0,
            "Error",
            "Your role is not enabled to login to website",
            "OK"
          );
        } else {
          postRequest(
            "ws_webrtc/util.asmx/HasMultipleParentCompaniesPerSM",
            { sKey: getUser() },
            (response) => {
              console.log(JSON.stringify(response.d));
              setLoading(false);
              if (response.d) {
                setisGlobal("SM");
                navigate("App/SelectCompany");
              } else {
                setisGlobal("SM");
                navigate("App/Dashboard");
              }
            },
            context
          );
        }
      },
      context
    );
  }

  const navigateToApp = (e) => {
    const params = { sUser: email, sPass: password };
    if (e !== undefined) e.preventDefault();

    if (email == "" || email == null) {
      user.setUser(0, "Error", getMandatoryData("loginPage", "email"), "OK");
    } else if (showPasswordField && password == "") {
      user.setUser(0, "Error", getMandatoryData("loginPage", "password"), "OK");
    } else if (showPhoneField) {
      updatePhoneNumber();
    } else if (showOTPField) {
      if (OTP == "") {
        user.setUser(
          0,
          "Error",
          getMandatoryData("loginPage", "authentication_code"),
          "OK"
        );
      } else {
        validateOTP();
      }
    } else {
      setLoading(true);
      storeEmail(email);
      storePassword(password);
      postRequest(
        "ws_webrtc/telemed.asmx/telemedLogin",
        { sUser: email, sPass: password },
        (response) => {
          if (response.d.status) {
            setLoading(true);
            const data = sessionStorage.getItem("mandatoryData", "loginPage");
            const mandatoryDataOf2fa = JSON.parse(data);
            const is2FASMVisiblevalue =
              mandatoryDataOf2fa.loginPage.is2FASMVisible;
            if (response.d.key != "") {
              setUser(response.d.key);
              setIsLoggedin(true);
              LoginTracking(response.d.status);

              //Session Key

              if (
                response.d.UserLevelId === 3 &&
                response.d.Global_User === false
              ) {
                if (mandatoryDataOf2fa && is2FASMVisiblevalue === true) {
                  getPhonenumber(false);
                } else {
                  setLoading(true);
                  returnObject();
                }
              } else {
                setLoading(false);

                user.setUser(
                  0,
                  "Error",
                  "Your role is not enabled to login to website",
                  "OK"
                );
              }
            }
          } else {
            setMessage(response.d.message);
            LoginTracking(response.d.status);
            user.setUser(0, "Error", response.d.message, "OK");
            setLoading(false);
            setIsLoggedin(false);
          }
        },
        user
      );
    }
  };

  const validate = (text) => {
    let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
      setEmail({ email: text });
      return false;
    } else {
      setEmail({ email: text });
    }
  };

  const inputParameters = {
    __type: termsData.__type,
    ContactId: termsData.ContactId,
    CompanyId: termsData.CompanyId,
    ContactTypeId: termsData.ContactTypeId,
    tos_load: false,
    tos_datesigned: " ",
    tos_version: termsData.tos_version,
    tos_filepath: termsData.tos_filepath,
  };

  function switchChange(event) {
    if (event.target.checked) {
      setLoading(true);
      postRequest(
        "ws_webrtc/telemed.asmx/setTosInfo",
        { sKey: getUser(), oContact: inputParameters },
        (response) => {
          console.log(JSON.stringify(response.d));
          moveToDashBoard();
        },
        context
      );
    } else {
    }
  }

  function callForCancel() {
    console.log("on cancel click===>", showOTPField, showPhoneField);
    setShowpassword(true);
    setIsSentOTP(false);
    setShowOTP(false);
    setbtnText("LOG IN");
    setLoading(false);
    setForgotpassword("Forgot Password");
    setDisabledCancelText(true);
    setEmail("");
    setPassword("");
    setCode("");
    setOtp("");
    setEmailDisabled(false);
    setShowOTPField(false);
    setShowPhoneField(false);
    setShowPasswordField(true);
    setPhonenumber("");
  }

  function validateUser(){
    postRequest(
      "/ws_webrtc/Telemed.asmx/GetUserLevelActiveStatus",
      { EmailId: email },
      (response) => {
        setLoading(true);


          if(response.d.active){
            callForgotPassword()

          }else{
            user.setUser(
              0,
              "Error",
              response.d.message,
              "OK"
            );
            setLoading(false)

          
          
        }
      },
      context
    );

  }

  function callForgotPassword() {
    if (forgotPassword == "Forgot Password") {
      if (email == "" || email == null) {
        user.setUser(0, "Error", getMandatoryData("loginPage", "email"), "OK");
      } else {
        if (email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)) {
          setLoading(true);

          postRequest(
            "/ws_webrtc/Telemed.asmx/GetUserLevelId",
            { EmailId: email },
            (response) => {
              console.log(JSON.stringify(response.d), "üserlvlID");
              setLoading(true);
              if (response.d.UserLevelId == 3) {

                if(response.d.active){
                  getRequest(
                    "ResetPasswordEmailNotificationTelemed?EmailAddress=" + email,
                    (response) => {
                      setLoading(false);
                      console.log(JSON.stringify(response));
                      console.log(response);
                      if (response == true) {
                        user.setUser(
                          2,
                          "Success",
                          getMandatoryData("loginPage", "forgot_success"),
                          "OK"
                        );
                      } else {
                        
                        user.setUser(
                          0,
                          "Error",
                          getMandatoryData("loginPage", "forgot_unsuccess"),
                          "OK"
                        );
                        setLoading(false);
                      }
                    },
                    context
                  );

                }else{
                  user.setUser(
                    0,
                    "Error",
                    response.d.message,
                    "OK"
                  );
                  setLoading(false)

                }
                
              }else if(response.d.message== getMandatoryData("loginPage", "forgot_unsuccess")){
                user.setUser(
                  0,
                  "Error",
                  response.d.message,
                  "OK"
                );
                setLoading(false)

              } else if(response.d.UserLevelId==0){
                setLoading(false);
                user.setUser(
                  0,
                  "Error",
                  "Your role is not enabled to login to website",
                  "OK"
                );
              
              }else {
                setLoading(false);
                user.setUser(
                  0,
                  "Error",
                  "Your role is not enabled to login to website",
                  "OK"
                );
              }
            },
            context
          );
        }
      }
    } else {
      getPhonenumber(true);
      setDisplayOtp(true);
      startTimer(); // Start the timer when resend button is clicked;
    }
  }

  // Function to start the timer
  const startTimer = () => {
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          clearInterval(intervalId);
          return 0;
        }
      });
    }, 1000);
    setTimerInterval(intervalId);
    console.log("Timer started with interval ID:", intervalId);
  };

  // Function to stop and reset the timer
  const resetTimer = () => {
    clearInterval(timerInterval);
    setTimer(10); // Reset timer to 60 seconds
    setTimerInterval(null);
    setDisplayOtp(false);
  };

  useEffect(() => {
    if (timer === 0) {
      resetTimer();
    }
  }, [timer]);

  return (
    <>
      <div className="row d-flex justify-content-center">
        <div className="col-12 p-0">
          <div
            className="col-12 text-center"
            style={{ backgroundColor: "#D92A28" }}
            ref={topMenu}
          >
            {/* <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 container text-center" ref={topMenu}> */}
            <TopMenu activePage={activePage} />
            {/* </div> */}
          </div>
        </div>
      </div>
      {terms == true ? (
        <div className="row d-flex justify-content-center">
          <div className="col-12 p-0">
            <div className="col-12 vh-100 m-3 overflow-auto" ref={contentRef}>
              {/* <div className='col-md-7 col-lg-7 col-sm-7 col-xs-7 container vh-100 h-100 justify-content-center' style={{ overflow: 'hidden' }} ref={contentRef}> */}
              <iframe
                style={{ width: "99%", height: "85%" }}
                title="A youtube video on React hooks"
                src={termsPath}
              ></iframe>
              <div className="d-flex justify-content-center">
                <FormControlLabel
                  control={<Switch onChange={switchChange} color="success" />}
                  label="By checking the switch, you agree to the terms of service***"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row d-flex justify-content-center">
          <div className="col-12 p-0">
            <div className="col-12 overflow-hidden">
              <div className="col-12 vh-100 p-0 m-0" ref={contentRef}>
                {/* <div className='row paddingmargin0'>
                  <div className='col-lg-7 col-md-7 col-lg-7 col-sm-7 col-xs-7 container vh-100 h-100 justify-content-center paddingmargin0' style={{ overflow: 'hidden' }} ref={contentRef}>*/}
                <img
                  className="col-12 text-center justify-content-center"
                  src={CaduceusLogin}
                  style={{
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    height: "100%",
                    width: "100%",
                    opacity: 0.5,
                    overflow: "hidden",
                    marginLeft: 0,
                    marginRight: 0,
                    paddingRight: 0,
                    paddingLeft: 0,
                    border: "1px solid gray",
                  }}
                ></img>
                <div
                  className="fixed-top vh-100 d-flex align-items-center justify-content-center"
                  style={{ margin: 20 }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      height: "auto",
                      width: "auto",
                      // paddingLeft: 20,
                      padding: 30,
                      borderRadius: "15px",
                      borderColor: "lightgray",
                      borderWidth: 1,
                      boxShadow: "1px 2px 9px #D4AAB9",
                      display: "flex",
                      flexDirection: "column",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={logo} width="150" height="150" />
                    </div>

                    <div
                      style={{
                        textAlign: "center",
                        width: "100%",
                        backgroundColor: "white",
                        borderRadius: 5,
                        alignItems: "center",
                        margin: 10,
                        marginLeft: -2,
                        paddingTop: 20,
                        paddingBottom: 30,
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: "white",
                        boxShadow: "1px 2px 9px #D4AAB9",
                        paddingBottom: 14,
                        paddingLeft: 30,
                        paddingRight: 30,
                        paddingTop: 10,
                        paddingLeft: 10,
                        paddingRight: 19,
                      }}
                    >
                      <div
                        style={{
                          paddingBottom: 10,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <input
                          type="text"
                          id="txtEmail"
                          placeholder="Email"
                          style={{
                            borderRadius: 5,
                            borderWidth: 0.5,
                            borderColor: "lightgray",
                            height: 42,
                            backgroundColor: emailDisabled
                              ? "lightgray"
                              : "white",
                            color: "#000000",
                            marginLeft: 5,
                            padding: 10,
                            width: "100%",
                          }}
                          disabled={emailDisabled}
                          className="form-controls form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              navigateToApp(event);
                            }
                          }}
                        />
                      </div>

                      {showPasswordField && (
                        <div
                          style={{
                            paddingBottom: 10,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <input
                            id="txtPassword"
                            type="password"
                            placeholder="Password"
                            style={{
                              borderRadius: 5,
                              borderWidth: 0.5,
                              borderColor: "lightgray",
                              height: 42,
                              backgroundColor: "white",
                              color: "#000000",
                              marginLeft: 5,

                              padding: 10,
                              width: "100%",
                            }}
                            className="form-control form-controls"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                navigateToApp(event);
                              }
                            }}
                          />
                        </div>
                      )}

                      {showPhoneField && (
                        <div>
                          <p
                            class="text-start"
                            style={{ marginLeft: 5, marginBottom: "-1px" }}
                          >
                            {Parser(
                              getMandatoryData("loginPage", "2FAPhonenumber")
                            )}
                          </p>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: 5,
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "lightgray",
                                display: "flex",
                                height: 35,
                              }}
                            >
                              <select
                                style={{ background: "#E2E2E2", height: 35 }}
                                name="CountryCode"
                                onChange={(e) => handleDropdownChange(e)}
                              >
                                {countrieslist !== "" &&
                                  countrieslist.map((x, y) => (
                                    <option key={x.code} value={x.code}>
                                      {x.abbr}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div>
                              <input
                                style={{
                                  marginLeft: 10,
                                }}
                                type="text"
                                placeholder="Phone Number"
                                className="form-control form-controls"
                                value={PhoneNumber}
                                name="Phonenumber"
                                onChange={(e) => handleFieldChanges(e)}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {showOTPField && (
                        <div
                          style={{
                            paddingBottom: 10,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <p
                            style={{
                              color: "red",
                              fontSize: "15px",
                              width: "210px",
                              textAlign: "left",
                            }}
                          >
                            Authentication code has been sent to your registered
                            phone number/email address
                          </p>
                          <input
                            id="txtPassword"
                            type="text"
                            placeholder="Authentication Code"
                            value={OTP}
                            name="otp"
                            onChange={(e) => handleFieldChanges(e)}
                            style={{
                              borderRadius: 5,
                              borderWidth: 0.5,
                              borderColor: "lightgray",
                              height: 42,
                              backgroundColor: "white",
                              color: "#000000",
                              marginLeft: 5,
                              padding: 10,
                              width: "100%",
                            }}
                            className="form-control form-controls"
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                navigateToApp(event);
                              }
                            }}
                          />
                        </div>
                      )}

                      <button
                        class={"loginbtn btn"}
                        onClick={navigateToApp}
                        value="Login"
                        style={{
                          marginBottom: -50,
                        }}
                      >
                        {btnText}
                      </button>
                    </div>

                    {/* for forgotpassword button */}

                    {(showPasswordField || showOTPField) && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: 20,
                        }}
                      >
                        {showPasswordField && !showOTPField && (
                          <img
                            onClick={validateUser}
                            src={forgot_key}
                            style={{ width: 20, height: 20 }}
                          />
                        )}
                        <label
                          onClick={validateUser}
                          style={{
                            paddingLeft: 5,
                            color: displayOtp ? "grey" : "red",
                            fontWeight: "bold",
                            cursor: displayOtp
                              ? "not-allowed"
                              : forgotPassword === "Resend Code"
                              ? "pointer"
                              : "",
                          }}
                        >
                          {forgotPassword}
                        </label>
                        {/* {displayOtp && showOTPField && (
                          <div style={{ marginLeft: 10 }}>
                            <p
                              style={{
                                color: displayOtp ? "grey" : "red",
                                marginTop: 18,
                                fontWeight: "bold",
                              }}
                            >
                              <p>00:{timer}</p>
                            </p>
                          </div>
                        )} */}
                      </div>
                    )}

                    {/* for cancel button */}
                    {(showOTPField || showPhoneField) && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: showPhoneField ? 20 : 10,
                          cursor: "pointer",
                        }}
                      >
                        <label
                          onClick={callForCancel}
                          style={{
                            paddingLeft: 5,
                            color: "red",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          {cancelText}
                        </label>
                      </div>
                    )}
                    {/* end cancel button */}
                  </div>

                  {isLoading && (
                    <div
                      style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ReactLoading
                        type={"spin"}
                        color={"#ff0000"}
                        height={"5%"}
                        width={"5%"}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default Login;
