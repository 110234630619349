import React from 'react';
import {useContext,useState,useEffect} from 'react';
import App from './NewApp';
import { BrowserRouter } from 'react-router-dom';
import Login from './pages/LoginLatest';
import AlertDialog from './components/AlertDailog';
import {
  Routes,
  Route,
} from "react-router-dom";
import UserContext from './components/context';
import PageContext from './components/context';
import MyVerticallyCenteredModal from './components/MyVerticallyCenteredModal';
import ErrorIcon from './Images/error-icon.png';
import SuccessIcon from './Images/success1.png';
import InfoIcon from './Images/information-icon.png';
import { Cancel } from '@mui/icons-material';
import {postRequest, getUser, setVisitId } from "./Services/DataMode";
import { useNavigate } from "react-router-dom";
import { navData } from './lib/leftMenuData';


export default function MainPage(){
     const [modalShow, setModalShow] = useState({headerName:'tittle',icon:null,data:'message',okText:'OK',show:false,cancel:false,cancelText:'Cancel',cancelAction:null}); //React.useState(false);
     const [loading, setloading] = useState(true);
     const [alert, showAlert] = useState({show:false,tittle:'tittle',message:'message'});
     const [user, setUser] = useState('');
     const context = useContext(UserContext);
     const [error, setError] = useState(false);
     let navigate = useNavigate();
     function popCancelClick(){
      //console.log("cancelclick");

      if(modalShow.cancelAction===undefined || modalShow.cancelAction===null)
        setModalShow(false);
      else{
        modalShow.cancelAction();
        setModalShow(false);

      }

     }
  function popOkClick() {
    if (error == true) {
      setError(false)
      navigate('/')
      setModalShow(false)
    }
    else if (modalShow.okAction === undefined || modalShow.okAction === null)
      setModalShow(false)
    else {
       setModalShow(false);
      modalShow.okAction();
     
    }

  }

     function updateUser(check,headerName,message,okText,cancel,cancelText,cancelAction,okAction){

      // console.log("modal",headerName);
      // console.log("Message",message);
      //  if (message == 'You already logged in another device.') {
      //    setError(true)
      //  }
       let icon = null 
       if(check == 0){
        icon = ErrorIcon
         }else if(check == 1){
          icon = InfoIcon
        }else if(check == 2){
          icon = SuccessIcon
        }else if(check == 10){
          icon = ErrorIcon
          setError(true)
        }
      setModalShow({headerName:headerName,icon:icon,data:message,okText:okText,show:true,cancel:cancel,cancelText:cancelText,cancelAction:cancelAction,okAction:okAction})
    
     }
     function updateError(errorData){
      //console.log('error updated  '+JSON.stringify(errorData));
      showAlert(errorData);
     }
  return (
  
      
      <UserContext.Provider value={{user:{user},setUser:updateUser,error:{alert},setError:updateError}}>  
      <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/App/*" element={<App />} /> 
      </Routes>
      <AlertDialog show={alert.show} tittle={alert.tittle} message={alert.message} handleClose={()=>showAlert({show:false,tittle:'',message:''})}></AlertDialog>
      <MyVerticallyCenteredModal
          okText={modalShow.okText}
          cancelText={modalShow.cancelText}
          headerName={modalShow.headerName}
          icon={modalShow.icon}
          data={modalShow.data}
          show={modalShow.show}
          cancel={modalShow.cancel}
          onHide={popOkClick}
          onCancel={popCancelClick}
          />
      </UserContext.Provider>
     
  );
}

