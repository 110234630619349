import React, { useRef, useState, useEffect } from "react";
import { useSpeechSynthesis } from "react-speech-kit";
import { TextField, InputAdornment } from "@mui/material";

import SendButton from "../Images/send_button.png";

function NewSpeechRecognition(props) {
  const [value, setValue] = useState("");
  const [lang, setLang] = useState("");

  const { speak, voices } = useSpeechSynthesis();

  useEffect(() => {
    speak({ text: props.data, voice: voices[8] });
  }, [props.data]);

  return (
    <div></div>
    // <div className="microphone-wrapper">
    //   <div className="mircophone-container">
    //     <div>
    //       <TextField
    //         autoComplete="off"
    //         variant="standard"
    //         value={value}
    //         data={props.data}
    //         onChange={(e) => setValue(e.target.value)}
    //         className="col-12 MuiInputBase-input-search"
    //         style={{
    //           fontSize: 9,
    //           backgroundColor: "#d3d3d3",
    //           paddingTop: 10,
    //           paddingBottom: 10,
    //           borderRadius: 10,
    //           cursor: "default",
    //         }}
    //         InputProps={{
    //           disableUnderline: true,

    //           endAdornment: (
    //             <InputAdornment position="end">
    //               <img
    //                 src={SendButton}
    //                 style={{ width: "30px", height: "30px", marginRight: 15 }}
    //                 onClick={() =>
    //                   speak({ text: props.value, voice: voices[8] })
    //                 }
    //               ></img>
    //             </InputAdornment>
    //           ),
    //         }}
    //       />
    //     </div>
    //   </div>
    // </div>
  );
}
export default NewSpeechRecognition;
