import { useNavigate } from "react-router-dom";
import { Paper } from "@mui/material";
import { useEffect, useState, useContext, useRef, useCallback } from "react";
import UserContext from "../components/context";
import {
  postRequest,
  getUser,
  setVisitId,
  getVisitId,
  getCompanyId,
  getBranchId,
} from "../Services/DataMode";
import CancelIcon from "@mui/icons-material/Cancel";
import UpdateIcon from "@mui/icons-material/Update";
import Icon from "@mui/material/Icon";
import ReactLoading from "react-loading";
import React from "react";
import VisitsList from "../components/VisitsList";
import { PropaneSharp } from "@mui/icons-material";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "../Images/search_icon.png";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import SearchList from "./SearchList";

function SearchView(props) {
  console.log("hello come on the values", props);
  const [divHeight, setHeight] = useState();
  const [isShow, setShow] = useState(false);
  const [visits, setVisits] = useState([]);
  const [value, setValue] = useState("");
  const [imgClear, setImgClear] = useState(false);
  const [noPatientsFound, setNoPatientsFound] = useState(false);
  const userInfo = useContext(UserContext).user;
  const context = useContext(UserContext);
  let navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [valueMain, setValueMain] = React.useState("");
  const timeout = React.useRef(null);
  var search = new AbortController();

  const onChange = (event) => {
    setNoPatientsFound(false);
    setValue(event.target.value);
    // console.log("logg", event.target.value);
    setVisits([]);
    clearTimeout(timeout.current);
    setValueMain(event.target.value);
    if (event.target.value !== "") {
      setLoading(true);
      props.clearVisits({ clear_visit: event.target.value.length });
      if (event.target.value.length > 2) {
        timeout.current = setTimeout(() => {
          setLoading(true);
          setVisits([]);
          setShow(false);
          // console.log("res", getCompanyId(), event.target.value);

          if (props.isCompanyProtocol == true) {
            postRequest(
              props.url,
              // console.log("hello", getUser(), event.target.value),
              { sKey: getUser(), sCompanyName: event.target.value },
              (response) => {
                console.log(
                  "res follow up yes",
                  response
                );
                if (value !== "") {
                
                  setLoading(false);
                  setShow(true);
                  if (response.d.length == 0) {
                    setNoPatientsFound(true);
                  } else {
                    setVisits(response.d);
                  }
                }
              },
              context
            );
          } else {
            postRequest(
              props.url,
              {
                sKey: getUser(),
                iCompanyId: getCompanyId(),
                sPatientName: event.target.value,
                search_key: event.target.value,
                company_id: getCompanyId(),
                iCompanyBranchId:
                  getBranchId() == undefined ||
                  getBranchId() == null ||
                  getBranchId() == ""
                    ? 0
                    : getBranchId(),
                iBranchId:
                  getBranchId() == undefined ||
                  getBranchId() == null ||
                  getBranchId() == ""
                    ? 0
                    : getBranchId(),
              },
              (response) => {
                if (value !== "") {
                  // console.log(
                  //   "res",
                  //   getCompanyId(),
                  //   event.target.value,
                  //   response
                  // );
                  setLoading(false);
                  setShow(true);
                  if (response.d.length == 0) {
                    setNoPatientsFound(true);
                  } else {
                    setVisits(response.d);
                  }
                }
              },
              context
            );
          }
        }, 2000);
      } else {
        setLoading(false);
        setVisits([]);
        setShow(false);
        props.clearVisits({ clear_visit: event.target.value.length });
      }
    } else {
      setVisits([]);
      setShow(false);
      setLoading(false);
      props.handleClick({ first_name: "", ssn: "", Patient_Name: "" });
    }
  };

  const onPatientSelect = (item) => {
    setVisits([]);
    setShow(false);
    setLoading(false);
     console.log("selected patient->" + item);
     setVisitId(item.visit_id);
    if (props.isCompanyProtocol == true) {
      setValue(item.Company_Name);
    } else {
      setValue(
        (item.first_name == undefined
          ? item.Patient_Name
          : item.first_name + " " + item.last_name) +
          (props.showssn == true ? item.display_DOB : "")
      );
      
    }
    setImgClear(true);
    props.handleClick(item);

  };

  const onImgClear = () => {
    if (props.fromCreateVisit == true) {
      console.log("live test");
      context.setUser(
        1,
        "Info",
        "Do you want to clear the below data?",
        "Yes",
        true,
        "No",
        () => {},
        () => {
          setImgClear(false);
          setLoading(false);
          setValue("");
          props.handleClick({ ssn: "" });
        }
      );
    } else {
      setImgClear(false);
      setLoading(false);
      setValue("");
      if (props.isCompanyProtocol == true) {
        // console.log("Company protocol");
        props.clearVisits({ clear_visit: 0 });
      } else {
        props.handleClick({
          first_name: "",
          ssn: "",
          last_name: "",
          Patient_Name: "",
        });
      }
    }
  };


  useEffect(() => {
    if(props.noCLick){
         setImgClear(false);
          setLoading(false);
          setValue("");
          setVisits([])

          props.handleClick({ ssn: "" });
          if(noPatientsFound){
            setNoPatientsFound(false)

          }
     

     }

  }, [props.noCLick]);

  const ref = useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);

  return (
    <>
      <div className="col-12" style={{ paddingLeft: 1, marginLeft: 1 }}>
        <label
          style={{
            margin: 0,
            padding: 0,
            marginBottom: 10,
            marginTop: 10,
            fontSize: 16,
            fontWeight: "bold",
            marginLeft: 2,
          }}
        >
          {props.heading}
        </label>
        <TextField
          autoComplete="off"
          value={value}
          disabled={imgClear}
          disabled={props.isDisable}
          onChange={onChange}
          variant="standard"
          placeholder={props.placeholder}
          className="col-12 MuiInputBase-input-search"
          style={{
            fontSize: 9,
            backgroundColor: "#E9E9EC",
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 10,
            paddingBottom: 10,
            borderStyle: "none",
            borderRadius: 10,
            cursor: "default",
          }}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <div style={{ marginRight: 10 }} onClick={onImgClear}>
                {imgClear && (
                  <HighlightOffOutlinedIcon style={{ cursor: "pointer" }} />
                )}
              </div>
            ),
            startAdornment: (
              <InputAdornment position="end">
                <img
                  src={SearchIcon}
                  style={{ width: "30px", height: "30px" }}
                ></img>
              </InputAdornment>
            ),
          }}
        />
        {isLoading && (
          <div
            style={{
              // height: '100%', width: '100%',
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <ReactLoading
              type={"spin"}
              color={"#ff0000"}
              height={"7.5%"}
              width={"7.5%"}
            />
          </div>
        )}

        {isShow && (
          <div
            style={{ overflowY: "auto", overflowX: "hidden", maxHeight: 500 }}
            ref={ref}
          >
            <SearchList
              isCompanyProtocol={
                props.isCompanyProtocol == undefined
                  ? false
                  : props.isCompanyProtocol
              }
              data={visits}
              handleClick={onPatientSelect}
              showssn={props.showssn}
            />
          </div>
        )}
        {noPatientsFound && (
          <text
            style={{
              padding: 10,
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              display: "flex",
            }}
          >
            {props.isCompanyProtocol
              ? "No Companies found"
              : "No patient(s) found"}
          </text>
        )}
      </div>
    </>
  );
}

export default SearchView;
