import { useEffect, useState, useContext, useRef } from "react";
import ReactLoading from "react-loading";
import React from "react";
import informationIcon from "../Images/information-icon.png";
import CustomDivComponent from "../components/divComponent";
import formData from "../lib/formsData.json";
import { useNavigate } from "react-router-dom";
import {
  postRequest,
  getUser,
  getMandatoryData,
  setCompanyId,
} from "../Services/DataMode";
import context from "react-bootstrap/esm/AccordionContext";
import UserContext from "../components/context";
import Parser from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

function ParentCompanySelect(props) {
  const [loading, setloading] = useState(false);
  let navigate = useNavigate();
  const [companies, setCompanies] = useState([{ name: "Select", id: "0" }]);
  const [branches, setbranches] = useState([{ name: "Select", id: "0" }]);
  const context = useContext(UserContext);

  const [selectedCompany, setSelectedCompany] = useState(0);
  const [selectedBranch, setSelectedBranch] = useState(0);
  const [isDiabled, setisDisabled] = useState(false);

  useEffect(() => {
    props.updatePage();

    postRequest(
      "ws_webrtc/Util.asmx/ParentCompaniesPerSMGet",
      { sKey: getUser() },
      (response) => {
        console.log(response.d);
        const companiesArray = [];
        companiesArray.push({ id: 0, name: "Select" });
        for (var i = 0; i < response.d.length; i++) {
          companiesArray.push({
            id: response.d[i].CompanyId,
            name: response.d[i].CompanyName,
          });
        }
        setCompanies(companiesArray);
      },
      [context]
    );
  }, []);

  const onOKClick = (e) => {
    console.log("onOKClick");
    if (selectedCompany == 0) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("global_user", "company_mandatory"),
        "OK"
      );
    } else if (
      (branches.length > 1 && selectedBranch == 0) ||
      isDiabled == true
    ) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("global_user", "branch_mandatory"),
        "OK"
      );
    } else {
      setCompanyId(selectedCompany);
      navigate("/App/Dashboard");
    }
  };

  const onCancelClick = (e) => {
    console.log("onCancelClick");
    navigate("/");
  };
  function handleFieldChangeCompany(event) {
    var name = event.target.name;
    var value = event.target.value;
    console.log(event.target.name + " --> " + event.target.value);
    setSelectedCompany(event.target.value);
    setSelectedBranch(0);
    const branchesArray = [];
    branchesArray.push({ id: 0, name: "Select" });
    setbranches(branchesArray);
    setisDisabled(true);
    if (event.target.value != 0) {
      postRequest(
        "ws_webrtc/Telemed.asmx/getTelemedCompanyBranchesPerCompany",
        { sKey: getUser(), iCompanyId: event.target.value },
        (response) => {
          console.log(response.d);
          const branchesArray = [];
          // branchesArray.push({ id: 0, name: "Select" })
          for (var i = 0; i < response.d.length; i++) {
            branchesArray.push({
              id: response.d[i].CompanyId,
              name: response.d[i].CompanyName,
            });
          }
          setbranches(branchesArray);
          setisDisabled(false);
        },
        [context]
      );
    }
  }
  function handleFieldChangeBranch(event) {
    var name = event.target.name;
    var value = event.target.value;
    console.log(event.target.name + " --> " + event.target.value);
    setSelectedBranch(event.target.value);
  }

  return (
    <>
      {loading && (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactLoading
            type={"spin"}
            color={"#ff0000"}
            height={"5%"}
            width={"5%"}
          />
        </div>
      )}

      <div
        style={{ padding: 30, height: "100%" }}
        className="d-flex align-items-center justify-content-center"
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            borderRadius: 5,
            alignItems: "center",
            margin: 10,
            marginLeft: -2,
            paddingTop: 20,
            paddingBottom: 30,
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: "grey",
            paddingBottom: 14,
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 10,
            paddingLeft: 10,
            paddingRight: 19,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: -30,
            }}
          >
            <img src={informationIcon} width="35" height="35" />
          </div>

          <div style={{ marginTop: 15, textAlign: "center" }} className="col">
            <label
              style={{ fontSize: "23px", fontWeight: "bold", color: "Grey" }}
            >
              {" "}
              Select Company{" "}
            </label>
          </div>

          <div className="row">
            <div className="col-md-12 col-lg-12 col-sx-12 col-sm-12">
              <div style={{ padding: 10 }}>
                <label
                  htmlFor="companySelect"
                  style={{
                    paddingLeft: 5,
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "grey",
                  }}
                >
                  {Parser(getMandatoryData("global_user", "drpLblCompany"))}
                </label>
                <div style={{ height: 5 }}></div>
                <div style={{ position: "relative" }}>
                  <select
                    id="companySelect"
                    onChange={handleFieldChangeCompany}
                    className="col-12 required form-select"
                    style={{
                      background: "#E2E2E2",
                      height: 40,
                      paddingRight: 40,
                    }}
                  >
                    {companies != undefined &&
                      companies.map((item, i) => (
                        <option key={item.id} value={item.id}>
                          {" "}
                          {item.name}
                        </option>
                      ))}
                  </select>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: 10,
                      transform: "translateY(-50%)",
                    }}
                  />
                </div>
              </div>

              <div style={{ padding: 10 }}>
                <label
                  htmlFor="branchSelect"
                  style={{
                    paddingLeft: 5,
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "grey",
                  }}
                >
                  {Parser(getMandatoryData("global_user", "drpLblbranch"))}
                </label>
                <div style={{ height: 5 }}></div>
                <div style={{ position: "relative" }}>
                  <select
                    id="branchSelect"
                    onChange={handleFieldChangeBranch}
                    disabled={branches.length === 1}
                    className="col-12 required form-select"
                    style={{
                      background: "#E2E2E2",
                      height: 40,
                      paddingRight: 40,
                    }}
                  >
                    {branches != undefined &&
                      branches.map((item, i) => (
                        <option key={item.id} value={item.id}>
                          {" "}
                          {item.name}
                        </option>
                      ))}
                  </select>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: 10,
                      transform: "translateY(-50%)",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-12 col-lg-12 col-xs-12 col-sm-12"
              style={{
                padding: 10,
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                display: "flex",
              }}
            >
              <button
                className={"btnNoRadius"}
                onClick={onOKClick}
                style={{ paddingTop: 5, paddingBottom: 5 }}
              >
                OK
              </button>{" "}
              &nbsp;&nbsp;
              <div className="col-md-1 col-lg-1 col-xs-1 col-sm-1" />
              <button
                className={"btnNoRadius"}
                onClick={onCancelClick}
                style={{
                  paddingTop: 5,
                  paddingBottom: 5,
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                }}
                variant="primary"
                type="submit"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ParentCompanySelect;
