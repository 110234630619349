import { Paper } from "@mui/material";
import { useEffect, useState, useContext, useRef } from "react";
import UserContext from '../components/context';
import { postRequest, getUser, getCompanyId, getMandatoryData, setVisitId, setVisitType, setFutureVisitFromSMDashboard, setiPrevDocumentId, setADIds, setTempCompanyName, setTempCompanyId } from "../Services/DataMode";
import CancelIcon from '@mui/icons-material/Cancel';
import UpdateIcon from '@mui/icons-material/Update';
import Icon from '@mui/material/Icon';
import React from "react";
import VisitsList from "../components/VisitsList";
import ReactLoading from 'react-loading';
import { useNavigate } from "react-router-dom";
import SearchView from "../components/SearchView";


function CompanyProtocolDashboard(props) {
  const [loading, setloading] = useState(true);
  const [visits, setVisits] = useState([]);
  const userInfo = useContext(UserContext).user;
  const context = useContext(UserContext);
  const [reloading, setreloading] = useState(false);
  const ref = useRef();
  let navigate = useNavigate();
  const [getData, setGetData] = useState(false);

  const searchRef = useRef();
  const data = [
    { id: '1', title: 'First item' },
    { id: '2', title: 'Second item' },
    { id: '3', title: 'Third item' },
    { id: '4', title: 'Fourth item' }
  ];

  useEffect(() => {
    props.updatePage();
    loadDashboard();

    setiPrevDocumentId('')
    setADIds('')
    setTempCompanyId('')
    setTempCompanyName('')
  }, [reloading])

  function loadDashboard() {
    setloading(true);
    postRequest('ws_webrtc/telemed.asmx/GetCompanyProtocolDocumentsDashboardList',
      { sKey: getUser(), iCompanyId: 0 },
      (response) => {
        //console.log(response);
        setloading(false);
        setVisits(response.d);
      }, context)
  }

  

  function patientSelected(item) {
    setVisits([]);
    setloading(true);
    postRequest('ws_webrtc/telemed.asmx/GetCompanyProtocolDocumentsDashboardList',
      { sKey: getUser(), iCompanyId: item.Company_Id },
      (response) => {
        //console.log(response);
        setloading(false);
        setVisits(response.d);
      }, context)
  }

  function clearVisitsWhileLoading(item) {
    console.log(item.clear_visit)
    if (item.clear_visit >= 1) {
      setGetData(true);
      setVisits([]);
    } else {
      if (getData) {
        setGetData(false);
        loadDashboard();
      }
    }
  }

  function navigateToCreateVisit(item) {
    // setVisitId(item.visit_id);
    // setVisitType('Future');
    // setFutureVisitFromSMDashboard('False');
    navigate('/App/CompanyProtocolCreateUpdate');
    setiPrevDocumentId(item.CompanyProtocol_Document_Id)
    console.log(item)
    setTempCompanyName(item.Company_Name)
    setTempCompanyId(item.Company_Id)
  }
  // useEffect(() => {


  //   handleResize();
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   }

  // }, []);
  const handleResize = () => {

    const top = ref.current;
    const search = searchRef.current;
    if (window.innerWidth <= 960) {
      top.className = "col-lg-12 col-md-12 col-xs-12 col-sm-12 paddingLR"
      search.className = "marginRight10"
    } else {
      top.className = "col-lg-7 col-md-7 col-sm-7 col-sm-7 container"
      search.className = "marginRight35"
    }
  }
  return (
    <div className="h-100 col-12 p-2">
      {loading && (
        <div style={{
          position: 'absolute', left: '50%', top: '50%',
          transform: 'translate(-50%, -50%)', height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
        }}>
          <ReactLoading type={'spin'} color={'#ff0000'} height={'5%'} width={'5%'} />
        </div>
      )}
      <div className="sticky-top bg-white" style={{ zIndex: 99 }}>
        <SearchView isDisable={loading} isCompanyProtocol={true} heading="Company Search" placeholder={"Search Company"} url={"ws_webrtc/telemed.asmx/GetCompanyProtocolDocumentsDashboardSearch"} handleClick={patientSelected} clearVisits={clearVisitsWhileLoading}></SearchView>
      </div>
      <div className="px-1">
        <VisitsList isCompanyProtocol={true} deleteIcon={false} data={visits} handleClick={navigateToCreateVisit} />
      </div>
    </div >
  );
}

export default CompanyProtocolDashboard;