import React, { useContext, useState, useEffect, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import "../styles/styles.css"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Paper } from '@mui/material'
import formData from '../lib/formsData.json'
import CustomDivComponent from "../components/divComponent";
import AlertDailog from "../components/AlertDailog"
import { setVisitTypeID, setPurposeOfVistID, setUserId, getGlobalEmail, setisFollowupYN, setisGlobal, getsherwinText, setsherwinText, validateAdditionalEmail, setUploadIds, setMQRIds, setTestResultFileIds, getMQRIds, getUploadIds, getTestResultFileIds, postRequest, getUser, setVisitId, getMandatoryData, validateEmail, getCompanyId, getVisitId, getVisitType, getFutureVisitFromSMDashboard, setFollowupYN, setVisitHistoryId, setVisitHistoryType, setDoctorName, setDoctorId, validateSSNArray, setLanguageId, setUser, getisGlobal, setVisitTypeName, setCompanyName } from "../Services/DataMode";
import { browserName, browserVersion, osName } from "react-device-detect";
import UserContext from '../components/context';
import { useNavigate } from "react-router-dom";
import Reactloading from 'react-loading';
import SearchView from '../components/SearchView'
import SubHeader from "../components/subHeader"
import VisitHistory from "../components/VisitHistory";
import Modal from 'react-bootstrap/Modal';
import SearchIcon from "../Images/search_icon.png";
import SearchList from "../components/SearchList";

import {
    TextField,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Checkbox,
    TextareaAutosize,
    Box,
    OutlinedInput,
    InputAdornment,
} from "@mui/material";
function GODuser(props) {

    const [loading, setloading] = useState(false);

    let navigate = useNavigate();
    const [visitTypes, setvisitTypes] = useState([{ name: 'Select', id: '0' }]);
    const [purposeOfVisits, setpurposeOfVisits] = useState([{ name: 'Select', id: '0' }]);
    const [companyDropdown, setcompanyDropdown] = useState([{ name: 'Select', id: '0' }]);
    const context = useContext(UserContext);
    const [noCompaniesFound, setNoCompaniesFound] = useState(false);


    const [selectedVisitTypeID, setSelectedVisitTypeID] = useState(0);
    const [selectedVisitTypeName, setSelectedVisitTypeName] = useState("");

    const [selectedPurposeOfVisitID, setSelectePurposeOfVisitdID] = useState(0);
    const [selectedPurposeOfVisitName, setSelectedPurposeOfVisitName] = useState("");

    const [selectedCompanyID, setSelectedCompanyID] = useState(0);
    const [selectedCompanyName, setSelectedCompanyName] = useState("");


    const [selectedYN, setSelectedYN] = useState(true);
    const [isShow, setShow] = useState(false);

    const [selectedFollowYN, setSelectedFollowYN] = useState(false);
    const [visits, setVisits] = useState([]);
    const [value, setValue] = useState("");

    const [showFollowYN, setShowFollowYN] = useState(false);

    const [selectedcompanyDropdownID, setSelectedcompanyDropdownID] = useState(0);
    const timeout = React.useRef(null);

    const [showOKBtn, setShowOKBtn] = useState(false);
    const [searchedText, setsearchedText] = useState("");
    useEffect(() => {

        props.updatePage();

        postRequest('ws_webrtc/telemed.asmx/GetVisitTypes',
            { sKey: getUser() },
            (response) => {
                console.log(response.d);
                const visitTypesArray = []
                visitTypesArray.push({ id: 0, name: "Select" })
                for (var i = 0; i < response.d.length; i++) {
                    visitTypesArray.push({ id: response.d[i].VisitType_Id, name: response.d[i].VisitType })
                }
                setvisitTypes(visitTypesArray);
            }, [context])

        setShowOKBtn(false)
        setsearchedText("")
    }, [])

    const successLoginTracking = {

        "device_name": browserName + ' ' + browserVersion,
        "device_os": "Web - " + osName,
        "app_version": "1.0",
        "user_name": getGlobalEmail(),
        "password": "",
        "login_status": "Success",
        "wifi_name": "",
        "reason": "Global User OK Click"
    }

    const validate = () => {
        if (selectedYN == false) {
            console.log("SR")
        } else {
            console.log("Normal")
            if (selectedVisitTypeID == 0) {
                context.setUser(0, 'Error', getMandatoryData('global_user', 'visittype'), 'OK')

            } else if (selectedPurposeOfVisitID == 0) {
                context.setUser(0, 'Error', getMandatoryData('global_user', 'purposeofvisit'), 'OK')

            } else if (selectedCompanyID == 0) {
                context.setUser(0, 'Error', getMandatoryData('global_user', 'company_mandatory'), 'OK')

            } else {
                console.log("done")
                console.log(getUser())
                console.log(selectedCompanyID)
                console.log(selectedCompanyName)
                console.log(selectedVisitTypeID)
                console.log(selectedVisitTypeName)
                console.log(selectedPurposeOfVisitID)
                console.log(selectedPurposeOfVisitName)
                console.log(selectedFollowYN)

                setloading(true);
                postRequest("ws_webrtc/telemed.asmx/InsertCompleteImpersonationDetails",
                    {
                        sKey: getUser(),
                        iCompanyId: selectedCompanyID,
                        iPurposeOfVisit: selectedPurposeOfVisitID,
                        bFollowup: selectedFollowYN
                    },
                    (response) => {



                        setisFollowupYN(selectedFollowYN)
                        setisGlobal("GSM")
                        setVisitTypeID(selectedVisitTypeID)
                        setPurposeOfVistID(selectedPurposeOfVisitID)
                        setVisitTypeName(selectedVisitTypeName)
                        setCompanyName(selectedCompanyName)
                        console.log(response)
                        setUser(response.d.key)
                        if (response.d.status == true) {
                            if (response.d.key == "") {

                            } else {
                                console.log(getUser())
                                timeout.current = setTimeout(() => {

                                    postRequest('ws_webrtc/telemed.asmx/getUser',
                                        { sKey: response.d.key },
                                        (response) => {
                                            console.log(response)
                                            setUserId(response.d.user_id)
                                            timeout.current = setTimeout(() => {
                                                postRequest('ws_webrtc/Telemed.asmx/createLoginTracking',
                                                    { oLTrack: successLoginTracking },
                                                    (response) => {
                                                        console.log(response.d);
                                                        setloading(false);
                                                        console.log(getisGlobal())
                                                        console.log(getUser())
                                                        navigate('/App/Dashboard');
                                                    }, context)
                                            }, 2000);

                                        }, context)
                                }, 2000);
                            }
                        } else {

                        }

                    }, context)


            }
        }

    }

    function getNameByID(array, ID) {
        let obj = array.find(o => { return o.id === ID });
        return obj.name
    }
    function handleFieldChangeVisitType(event) {

        var name = event.target.name;
        var value = event.target.value;
        console.log(event.target.name + " --> " + event.target.value)
        setSelectedVisitTypeID(event.target.value)
        setSelectedVisitTypeName(getNameByID(visitTypes, parseInt(event.target.value)));
        setSelectePurposeOfVisitdID(0)
        setSelectedFollowYN(false)
        setShowFollowYN(false)
        const purposeOfVisitsArray = []
        purposeOfVisitsArray.push({ id: 0, name: "Select" })
        setpurposeOfVisits(purposeOfVisitsArray);

        if (event.target.value != 0) {
            postRequest('ws_webrtc/Util.asmx/TelemedGlobalUserVisitsPurposesGet',
                { sKey: getUser(), iVisitTypeID: event.target.value },
                (response) => {
                    console.log(response.d);
                    const purposeOfVisitsArray = []
                    purposeOfVisitsArray.push({ id: 0, name: "Select" })
                    for (var i = 0; i < response.d.length; i++) {
                        purposeOfVisitsArray.push({ id: response.d[i].PurposeOfVisit_Id, name: response.d[i].PurposeOfVisit })
                    }
                    setpurposeOfVisits(purposeOfVisitsArray);
                }, [context])
        }
        setShowOKBtn(false)
        setsearchedText("")
    }
    function handleFieldChangePurposeOfVisit(event) {
        setSelectedFollowYN(false)
        var name = event.target.name;
        var value = event.target.value;
        console.log(event.target.name + " --> " + event.target.value)
        setSelectePurposeOfVisitdID(event.target.value)
        setSelectedPurposeOfVisitName(getNameByID(purposeOfVisits, parseInt(event.target.value)));
        if (event.target.value == 1 || event.target.value == 5 || event.target.value == 6 || event.target.value == 7 || event.target.value == 8 || event.target.value == 11) {
            setShowFollowYN(true)
        } else {
            setShowFollowYN(false)
        }
        if (event.target.value !== 0) {
            postRequest('ws_webrtc/telemed.asmx/GetEMRTelemedFollowupCompaniesPerVisitType',
                { sKey: getUser(), iPurposeOfVisit: event.target.value, iVisitTypeID: selectedVisitTypeID },
                (response) => {
                    console.log(response.d);
                    const companyArray = []
                    companyArray.push({ id: 0, name: "Select" })
                    for (var i = 0; i < response.d.length; i++) {
                        companyArray.push({ id: response.d[i].CompanyId, name: response.d[i].CompanyName })
                    }
                    setcompanyDropdown(companyArray);
                }, [context])
        }
        setShowOKBtn(false)
        setsearchedText("")
    }

    function handleFieldChangecompanyDropdown(event) {
        var name = event.target.name;
        var value = event.target.value;
        console.log(event.target.name + " --> " + event.target.value)
        setSelectedcompanyDropdownID(event.target.value)
        setSelectedCompanyName(getNameByID(companyDropdown, event.target.value));
        setSelectedCompanyID(event.target.value)
        if (event.target.value !== 0) {
            setShowOKBtn(true)
        }
        setsearchedText("")
    }



    var data = [
        {
            "label": "Create New Visit",
            "value": 0,
            "default": "Create New Visit"

        },
        {
            "label": "Create Service Request",
            "value": 1,
            "default": ""

        }
    ]
    var options = [
        {
            "label": "Yes",
            "value": 1,
            "default": ""

        },
        {
            "label": "No",
            "value": 0,
            "default": "No"

        }
    ]
    function handleFieldChange(event) {
        var value = event.target.value;
        console.log(value)
        setSelectedYN(!selectedYN)
        console.log(selectedYN)
        setSelectedFollowYN(false)
        setShowFollowYN(false)
        setShowOKBtn(false)
        setsearchedText("")
    }
    function onFieldChangeFollowUp(event) {

        var name = event.target.name;
        var value = event.target.value;
        console.log(event.target.name + " --> " + event.target.value)
        setSelectedFollowYN(!selectedFollowYN)
        console.log(selectedFollowYN)
        //true(0) --- followup - no
        //false(1) --- followup - yes
        setShowOKBtn(false)
        setsearchedText("")
    }

    function onSearchFieldChange(event) {
        setNoCompaniesFound(false)
        var value = event.target.value;
        console.log(event.target.value)
        setsearchedText(event.target.value)
        clearTimeout(timeout.current);
        if (value.length > 2) {
            console.log(value)
            console.log(selectedYN)
            if (selectedYN == true) { //create new visit

                timeout.current = setTimeout(() => {
                    setVisits([]);
                    setShow(false);
                    setloading(true);
                    postRequest("ws_webrtc/telemed.asmx/GetEMRTelemedCompaniesPerVisitType",
                        {
                            sKey: getUser(),
                            sCompanyName: event.target.value,
                            iVisitTypeID: selectedVisitTypeID
                        },
                        (response) => {
                            //   if (value !== '') {

                            setloading(false);
                            setShow(true);
                            if (response.d.length == 0) {
                                setNoCompaniesFound(true)
                            } else {
                                setVisits(response.d);
                            }

                            //   }
                        }, context)
                }, 2000);

            } else {// create service request
                timeout.current = setTimeout(() => {
                    setVisits([]);
                    setShow(false);
                    setloading(true);
                    postRequest("TelemedWebAPI2/ServiceRequest/EmployersGetSearch",
                        {
                            sKey: getUser(),
                            company_name: event.target.value,
                        },
                        (response) => {
                            //   if (value !== '') {

                            setloading(false);
                            setShow(true);
                            if (response.d.length == 0) {
                                setNoCompaniesFound(true)
                            } else {
                                setVisits(response.d);
                            }

                            //   }
                        }, context)
                }, 2000);

            }
        } else {
            setloading(false);
            setVisits([]);
            setShow(false);
        }
    }

    const onCompanySelect = (item) => {
        setVisits([]);
        setShow(false);
        setloading(false);
        console.log(item)
        if (selectedYN == false) {
            console.log("selected->" + item.company_id);
            setsearchedText(item.company_name);
            setSelectedCompanyID(item.company_id)
            setSelectedCompanyName(item.company_name)
        } else {
            console.log("selected->" + item.CompanyId);
            setsearchedText(item.CompanyName);

            setSelectedCompanyID(item.CompanyId)
            setSelectedCompanyName(item.CompanyName)

        }
        setNoCompaniesFound(false)
        setShowOKBtn(true)
        // setImgClear(true);
        // props.handleClick(item);
    }


    return (
        <>
            <div className="h-100 col-12 p-2">
                <div className="row" >
                    <div className='col-md-12 col-lg-12 col-xs-12 col-sm-12'>
                        <div className='row'>
                            <div className='col-md-12 col-lg-12 col-xs-12 col-sm-12'>

                                <div className='m-3'>

                                    {data.map(choice => (
                                        <label style={{ display: 'flex', fontSize: "20px", color: 'grey', fontWeight: 'normal' }}>
                                            <input type="radio"
                                                value={choice.value}
                                                defaultChecked={choice.default}
                                                selected={props.remotePatientYN}
                                                checked={(choice.label == "Create New Visit" && selectedYN) || (choice.label == "Create Service Request" && !selectedYN)}
                                                onChange={handleFieldChange}
                                                style={{ padding: 5 }}
                                            />
                                            {choice.label}
                                        </label>

                                    ))
                                    }

                                </div>
                                <div className='row px-3'>
                                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                        {selectedYN && (<>
                                            <label for="exampleSelect1" style={{ paddingLeft: 5, fontSize: '16px', fontWeight: 'bold', color: 'grey' }}>Visit Type:</label>
                                            <div style={{ height: 5 }}></div>
                                            <div>
                                                <select onChange={handleFieldChangeVisitType} className="col-12 required" class="form-select" style={{ background: '#E2E2E2', height: 40 }} >
                                                    {

                                                        visitTypes != undefined && visitTypes.map((item, i) => (
                                                            <option value={item.id}> {item.name}</option>

                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div style={{ height: 5 }}></div>

                                            <label for="exampleSelect1" style={{ paddingLeft: 5, fontSize: '16px', fontWeight: 'bold', color: 'grey' }}>Purpose of Visit:</label>
                                            <div style={{ height: 5 }}></div>
                                            <div>
                                                <select onChange={handleFieldChangePurposeOfVisit} className="col-12 required" class="form-select" style={{ background: '#E2E2E2', height: 40 }} >
                                                    {purposeOfVisits != undefined && purposeOfVisits.map((item, i) => (
                                                        <option value={item.id}> {item.name}</option>

                                                    ))
                                                    }
                                                </select>
                                            </div>
                                            {showFollowYN && (<>
                                                <label style={{ paddingLeft: 5, fontSize: '16px', fontWeight: 'bold', color: 'grey' }}>
                                                    Follow-up:
                                                </label>
                                                {options.map((choice) => (
                                                    <label style={{ padding: 10 }}>
                                                        <input
                                                            type="radio"
                                                            value={choice.value}
                                                            defaultChecked={choice.default}
                                                            checked={
                                                                (choice.label == "Yes" && selectedFollowYN) ||
                                                                (choice.label == "No" && !selectedFollowYN)
                                                            }
                                                            onChange={onFieldChangeFollowUp}
                                                            style={{ padding: 5 }}
                                                        />
                                                        {choice.label}
                                                    </label>
                                                ))}
                                            </>)}


                                            <div style={{ height: 5 }}></div>
                                        </>
                                        )}
                                        <label for="exampleSelect1" style={{ paddingLeft: 5, fontSize: '16px', fontWeight: 'bold', color: 'grey' }}>{selectedFollowYN ? "Select Company:" : "Company Search:"}</label>
                                        <div style={{ height: 5 }}></div>
                                        {selectedFollowYN == true ? <>
                                            <div>
                                                <select onChange={handleFieldChangecompanyDropdown} className="col-12 required" class="form-select" style={{ background: '#E2E2E2', height: 40 }} >
                                                    {companyDropdown != undefined && companyDropdown.map((item, i) => (
                                                        <option value={item.id}> {item.name}</option>

                                                    ))
                                                    }
                                                </select>
                                            </div>
                                        </>
                                            :
                                            <>
                                                <TextField
                                                    variant="standard"
                                                    placeholder="Search Company"
                                                    id="outlined"
                                                    size="small"
                                                    value={searchedText}
                                                    //   disabled={props.isDisable}
                                                    //   defaultValue={props.tag_id}
                                                    style={{
                                                        fontSize: 9,
                                                        backgroundColor: "#E9E9EC",
                                                        paddingLeft: 0,
                                                        paddingRight: 0,
                                                        paddingTop: 10,
                                                        paddingBottom: 10,
                                                        borderStyle: "none",
                                                        borderRadius: 10,
                                                        color: "black",
                                                    }}
                                                    className={"col-12 required"}
                                                    onChange={onSearchFieldChange}
                                                    //   name={props.tagid}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        color: "black",
                                                        sx: {
                                                            color: "black",
                                                        },
                                                        startAdornment: (
                                                            <InputAdornment position="end">
                                                                <img
                                                                    src={SearchIcon}
                                                                    style={{ width: "30px", height: "30px" }}
                                                                ></img>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />

                                            </>
                                        }
                                        {isShow && <div style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: 500 }} >
                                            <SearchList data={visits} isCompany={true} handleClick={onCompanySelect} />
                                        </div>
                                        }
                                        {noCompaniesFound &&
                                            <text style={{ padding: 10, justifyContent: 'center', alignItems: 'center', alignContent: 'center', display: 'flex' }}>No Companies found</text>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showOKBtn && (
                            <div className='row'>
                                <div className='col-md-12 col-lg-12 col-xs-12 col-sm-12 text-center'>
                                    <div style={{ padding: 10 }}>
                                        <button onClick={validate}
                                            style={{
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                alignContent: 'center',
                                                fontSize: 16,
                                                borderRadius: 9,
                                                paddingRight: 30,
                                                paddingLeft: 30,
                                                paddingTop: 10,
                                                paddingBottom: 10,
                                                fontWeight: 'bold',
                                                color: '#ffffff',
                                                backgroundColor: '#D92A28',
                                                border: '#ffffff'
                                            }}

                                            variant="primary" type="submit">OK</button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>


                </div>
                {loading && (
                    <div style={{
                        position: 'fixed', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)', height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                    }}>
                        <Reactloading type={'spin'} color={'#ff0000'} height={'5%'} width={'5%'} />
                    </div>
                )}
            </div>


        </>
    )

}
export default GODuser