import React from "react";
import { useRef, useEffect, useState,useLayoutEffect,useCallback } from "react";
import FormControls from '../components/formControls'
import { TextField, FormControl, MenuItem, Select, InputLabel, Checkbox, TextareaAutosize, Box, OutlinedInput, InputAdornment, Paper } from '@mui/material';
import SubHeader from "./subHeader";
import { postRequest, getUser, getMandatoryData } from "../Services/DataMode";
import CancelIcon from '@mui/icons-material/Cancel';
import UpdateIcon from '@mui/icons-material/Update';
import Icon from '@mui/material/Icon';
import editDocuments from '../Images/editDocuments.png'

export default function VisitsList(props) {
  const [divHeight, setHeight] = useState('7.2vh');
  const currentExamColor = getMandatoryData('service_request', 'current_exam_color')
  const completedExamColor = getMandatoryData('service_request', 'completed_exam_color')
  const pendingExamColor = getMandatoryData('service_request', 'pending_exam_color')
  const [isloading,setLoading] = useState()
 // const ref = useRef(null);
  const listRef = useRef();
  const contentRef = useRef(null)
  const visits = props.data;
  function handleClick(item) {
    props.handleClick(item);
  }
const [listItems, setListItems] = useState([props.data]);
 const getListSize = () => {
   
  };
  useEffect(() => {
  //  /getListSize();
  //handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [])
  const handleResize = () => {
 //   const side = ref.current.clientHeight;
    console.log(window.innerWidth)
    if (window.innerWidth <= 760) {
      if(ref.current != null){
    //    setHeight(ref.current.clientHeight);
      }
    } else {
    //   setHeight(ref.current.clientHeight);
    }
  }

  function handleRescheduleClick(item) {
    props.handleRescheduleClick(item);
  }
  function handleDeleteClick(item) {
    props.handleDeleteClick(item);
  }
   const ref = useCallback(node => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);
  const visit_li = visits.map((visit) =>
  <>

    <li>
      <Paper elevation={3} style={{ borderRadius: 0, marginTop: 10, paddingLeft: 8 }}>
        <div className="container-fluid  align-self-center" >
          <div className="row py-3  align-items-baseline align-self-center" >
            <div className="col-11 align-self-center" style={{ cursor: "pointer"}} ref={ref} onClick={() => { handleClick(visit) }} >
                 <div className="row paddingmargin0">
                <div className="col-10">
              {visit.followup_YN && <div className="align-self-center" style={{ width: 5, backgroundColor: '#D92A28', float: 'left', display: 'flex', marginRight: 15, marginTop: 5, marginBottom: 5 }}>
                <div style={{ height: divHeight }} ></div>
              </div>
              }
              {
                props.isCompanyProtocol && 
                <> 
              <text style={{ fontWeight: 'bold', wordWrap: "break-word" }}>{visit.Company_Name}</text><br />
              <text>{ visit.Date_Updated == '' ? 'Created on :' + visit.Date_Created : 'Updated on :' + visit.Date_Updated}</text>
                 </>
              }
              { visit.first_name == undefined ? <>
              <text style={{ fontWeight: 'bold', wordWrap: "break-word" }}>{visit.First_Name + " " + visit.Last_Name}</text><br />
              { visit.confCount !== undefined && visit.confCount !== 0 ?
              <text>{visit.confCount} documents(s) added</text>
             : null
              }
              </>
               : 
               <>
              <text style={{ fontWeight: 'bold', wordWrap: "break-word" }}>{visit.first_name + " " + visit.last_name}</text><br />
              </>
              }               
              {
                visit.followup_YN ?
                  <span>
                    <text style={{ fontStyle: 'italic', fontWeight: 'bold', }}> Follow Up: </text>
                    <text> {visit.date_of_service} </text>
                  </span>
                  :
                  <text>{visit.date_of_service}</text>
              }

              {visit.examsList != undefined && visit.examsList.map((Name) => {
                var examColor = null
                if (Name.current_exam == true && Name.status_id == 0) {
                  examColor = currentExamColor
                }
                if (Name.status_id == 2 || Name.status_id == 3) {
                  examColor = completedExamColor
                }
                if (Name.current_exam == false && Name.status_id == 0) {
                  examColor = pendingExamColor
                }
                return (
                  <div>
                    &nbsp;
                    &nbsp;
                    <text style={{ fontWeight: 'bold', color: examColor }}>{Name.Exam_name}</text>
                  </div>
                )
              })}
              {
                visit.reason != null &&
                <text>{visit.reason}</text>
              }
             <br/>
                 <label style={{ wordWrap: "break-word"}}>{visit.company_name}</label>
              {visit.branch_name &&
                <>
                  <text> {"-"} </text>
                  <text>{visit.branch_name}</text>
                </>
              }
              <br />
              {
                visit.visit_date != null &&
                <div>
                  <text>{visit.visit_date}</text>
                </div>
              }
               </div>
                </div>
            </div>
            {props.deleteDocumentIcon && <>
              <div className="col-1 align-self-center text-right" style={{ textAlign: 'right', alignItems: 'right', justifyContent: 'right', alignContent: 'right', display: 'flex' }}>
                            <img src={editDocuments}  onClick={() => { handleClick(visit) }} style={{ color: '#D92A28', width: 30, height: 30, cursor: "pointer" }} />
                            <CancelIcon onClick={() => { handleDeleteClick(visit) }} style={{ color: '#D92A28', width: 30, height: 30, cursor: "pointer" }} />
                            </div>
                            </>
            }
            {props.deleteIcon && visit.visit_id!=0 &&
              <div className="col-1 align-self-center text-right" style={{ textAlign: 'right', alignItems: 'right', justifyContent: 'right', alignContent: 'right', display: 'flex' }}>
                {visit.followup_YN && <UpdateIcon style={{ color: '#D92A28', width: 30, height: 30, cursor: "pointer" }} onClick={() => { handleRescheduleClick(visit) }} />} &nbsp;
                <CancelIcon onClick={() => { handleDeleteClick(visit) }} style={{ color: '#D92A28', width: 30, height: 30, cursor: "pointer" }} />
              </div>
            }
          </div>
        </div >
      </Paper >
      
    </li >
  
    </>
  );
  return (
    <ul style={{ margin: 0, padding: 0,paddingBottom:10 }}>{visit_li}</ul>
  );
}