
import React, { Component, useState, useEffect, useContext } from 'react';
import CustomDivComponent from "../components/divComponent";
import formData from '../lib/formsData.json'
import { postRequest, getUser, setVisitId, getVisitHistoryType, getVisitId, getVisitHistoryId } from "../Services/DataMode";
import UserContext from '../components/context';
import Form from 'react-bootstrap/Form'


function VisitHistory(){

    const context = useContext(UserContext);
    const [values, setValues] = useState({});
    const [patientName, setPatientName] = useState('');
    
    useEffect(() => {

        postRequest('ws_webrtc/Telemed.asmx/getVisitHistory',
            { sKey: getUser(), iVisitId: getVisitHistoryId() },
            (response) => {
                console.log(JSON.stringify(response.d));
                setValues(response.d);
                setPatientName(response.d.first_name +' '+ response.d.last_name);
            }, context)


    }, [])

    return (
        <>
            <div className="container-fluid" style={{ overflowX: 'hidden', width: '100 %', marginTop: 10, position: 'relative' }}>
                <div className="row" >
                    <div className='col-md-12 col-lg-12 col-xs-12 col-sm-12'>

                        <div className='row'>
                            <div className='col-12'>
                                <div>
                                    <Form.Label style={{color:'#ff0000'}} >VISIT HISTORY: </Form.Label>
                                    <Form.Label style={{color:'#ff0000', fontWeight:'bold', marginLeft:4}} >{values.visit_Date}</Form.Label>
                                </div>
                            </div>
                            
                        </div>

                        <div className='row'>
                             <div className='col-12'>
                                <div>
                                    <Form.Label style={{color:'grey'}} >Physician Name: </Form.Label>
                                    <Form.Label style={{color:'grey', fontWeight:'bold', marginLeft:4}} >{values.doc_name}</Form.Label>
                                </div>
                            </div>
                        </div>

                        <div>
                            <CustomDivComponent
                                data={ getVisitHistoryType()=='MA' ? formData.ShowMAVisitHistory : formData.ShowVisitHistory}
                                loadValues={values}
                                patientName={patientName}
                                />
                        </div>                        
                    </div>
                </div>
                
            </div>
        </>
    )
}

export default VisitHistory