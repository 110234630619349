import React, { useState, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../styles/styles.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Paper } from "@mui/material";
import formData from "../lib/formsData.json";
import CustomDivComponent from "../components/divComponent";
import RowComponent from "../components/rowComponent";
import {
  postRequest,
  getSRData,
  getUser,
  setVisitId,
  getMandatoryData,
  getCompanyId,
  getVisitId,
  validateSSNArray,
  validateEmail,
} from "../Services/DataMode";
import { useNavigate } from "react-router-dom";
import UserContext from "../components/context";
import ReactLoading from "react-loading";
import { format } from "date-fns";

function EditVisitPage(props) {
  let navigate = useNavigate();
  const context = useContext(UserContext);
  const [loading, setloading] = useState(false);
  const [branches, setBranches] = useState([{ name: "Select", id: "0" }]);
  const [values, setValues] = useState({});
  const [SSNValidation, setSSNValidation] = useState(false);
  const [nrCountryCode, setnrCountryCode] = useState("+1");
  const [remoteCountryCode, setRemoteCountryCode] = useState("+1");
  const [showPatientField, setShowPatientField] = useState(true);

  function handleFieldChange(event) {
    var name = event.target.name;
    var value = event.target.value;
    console.log(event.target.name + " --> " + event.target.value);

    if (name == "ssn" && value.length === 9) {
      getSSNValidation(value);
    }

    setValues({ ...values, [name]: value });
    console.log(name, value);
  }
  const handleDateChange = (date, name) => {
    setValues({ ...values, [name]: date });
  };
  const user = useContext(UserContext);

  useEffect(() => {
    props.updatePage();

    postRequest(
      "ws_webrtc/Telemed.asmx/getTelemedCompanyBranchesPerCompany",
      { sKey: getUser(), iCompanyId: getCompanyId() },
      (response) => {
        console.log("Branches", response.d);
        const branchArray = [];
        for (var i = 0; i < response.d.length; i++) {
          branchArray.push({
            id: response.d[i].CompanyId,
            name: response.d[i].CompanyName,
          });
        }
        setBranches(branchArray);
      },
      context
    );

    postRequest(
      "ws_webrtc/Util.asmx/GetCompanySpecificFields_YN",
      { sKey: getUser(), icompanyId: getCompanyId() },
      (response) => {
        // debugger;
        console.log(
          "hello 1111111111 GetCompanySpecificFields_YN",
          response.d.status
        );

        if (response.d.status) {
          setShowPatientField(false);
          setloading(false);
        } else {
          setShowPatientField(true);
          setloading(false);
        }
      },
      context
    );

    getVisitDetails();
  }, []);

  function getVisitDetails() {
    postRequest(
      "ws_webrtc/util.asmx/GetGlobalSMVisitDetailsToEdit",
      { sKey: getUser(), iVisitId: getVisitId() },
      (response) => {
        setloading(false);
        console.log("Get Visit Details " + JSON.stringify(response.d));

        console.log(response.d, "branch name");

        setValues({
          first_name: response.d.first_name,
          last_name: response.d.last_name,
          Address1: response.d.Address1,
          Address2: response.d.Address2,
          City: response.d.City,
          company_id: response.d.company_id,
          patient_email: response.d.patient_email,
          ssn: response.d.ssn.replaceAll("-", ""),
          RemotePatient_YN: response.d.RemotePatient_YN ? true : false,
          Zip_Code: response.d.Zip_Code,
          Claim_Number: response.d.Claim_Number,
          PhoneNumber: response.d.RemotePatient_YN
            ? response.d.PhoneNumber.split("-")[1]
            : response.d.PhoneNumber,
          NR_PhoneNumber: response.d.NR_PhoneNumber.split("-")[1],
          DrugType_Id: response.d.DrugType_Id,
          branch_id: response.d.branch_id,
          Visit_Category_Type_Id: response.d.Visit_Category_Type_Id,
          PurposeOfVisit_Id: response.d.PurposeOfVisit_Id,
          date_of_birth: new Date(response.d.date_of_birth),
          visit_id: getVisitId(),
          state_id: response.d.state_id,
          company_name: response.d.company_name,
        });

        setnrCountryCode(response.d.NR_PhoneNumber.split("-")[0]);
        setRemoteCountryCode(
          response.d.RemotePatient_YN
            ? response.d.PhoneNumber.split("-")[0]
            : ""
        );
        if (response.d.ssn.replaceAll("-", "").length == 9) {
          getSSNValidation(response.d.ssn.replaceAll("-", ""));
        }
      },
      context
    );
  }

  const validate = () => {
    if (values.first_name.trim() == "") {
      console.log("first name");
      user.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "firstname"),
        "OK"
      );
    } else if (values.last_name.trim() == "") {
      console.log("last name");
      user.setUser(0, "Error", getMandatoryData("visitPage", "lastname"), "OK");
    } else if (values.ssn.trim() == "") {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "ssn_textfieldmsg"),
        "OK"
      );
    } else if (values.ssn.length != 9) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "ssn_validation"),
        "OK"
      );
    } else if (values.ssn.length == 9 && SSNValidation) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "ssn_invalid_message"),
        "OK"
      );
    } else if (
      getMandatoryData("visitPage", "ssn_invalid_message") != "" &&
      values.ssn.length == 9 &&
      !validateSSNArray(
        values.ssn,
        getMandatoryData("visitPage", "ssn_invalid")
      )
    ) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "ssn_invalid_message"),
        "OK"
      );
    } else if (values.NR_PhoneNumber.trim() == "") {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "phonenumber"),
        "OK"
      );
    } else if (
      values.patient_email !== "" &&
      !validateEmail(values.patient_email)
    ) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "patient_email_validation"),
        "OK"
      );
    } else if (
      getMandatoryData("visitPage", "claim_number") != "" &&
      values.Claim_Number.trim() == ""
    ) {
      //Claim_Number
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "claim_number"),
        "OK"
      );
    } else if (
      getMandatoryData("visitPage", "Address1") != "" &&
      values.Address1.trim() == ""
    ) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "Address1"),
        "OK"
      );
    } else if (
      getMandatoryData("visitPage", "Address2") != "" &&
      values.Address2.trim() == ""
    ) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "Address2"),
        "OK"
      );
    } else if (
      getMandatoryData("visitPage", "City") != "" &&
      values.City.trim() == ""
    ) {
      context.setUser(0, "Error", getMandatoryData("visitPage", "City"), "OK");
    } else if (values.Zip_Code.trim() == "") {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "zip_code"),
        "OK"
      );
    } else if (branches.length > 1 && values.branch_id == 0) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "branch"),
        "OK"
      );
    } else {
      setloading(true);
      var data = values;
      var ssnnumber = values.ssn.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
      data.date_of_birth = format(values.date_of_birth, "MM/dd/yyyy");
      data.NR_PhoneNumber = nrCountryCode + "-" + values.NR_PhoneNumber;
      data.phonenumber = values.remotePatientYN
        ? remoteCountryCode + "-" + values.phonenumber
        : "";
      data.ssn = ssnnumber;
      console.log(JSON.stringify(data));

      postRequest(
        "ws_webrtc/util.asmx/UpdateGlobalSMVisitDetailsEdited",
        { sKey: getUser(), oNFF: data },
        (response) => {
          console.log(response.d.key);
          setloading(false);

          var array = [
            "Visit edited successfully for ",
            data.first_name + " " + data.last_name,
            " ",
          ];

          context.setUser(2, "Success", array, "OK", false, "OK", null, () => {
            navigate("/App/CompletedVisitsDashboard");
          });
        },
        context
      );
    }
  };

  function cancelVisit() {
    navigate("/App/CompletedVisitsDashboard");
    setSSNValidation(false);
  }

  function getSSNValidation(value) {
    setloading(true);
    // alert(purpose_id)
    console.log("Purpsoe", SSNValidation);
    postRequest(
      "ws_webrtc/Util.asmx/GetSSNValidation_YN",
      { sKey: getUser(), sSSN: value },
      (response) => {
        setSSNValidation(response.d.status);
        console.log("Purpsoe", SSNValidation);
        if (response.d.status) {
          context.setUser(0, "Error", response.d.message, "OK");
          setloading(false);
        } else {
          setloading(false);
        }
      },
      context
    );
  }
  ///error

  return (
    <div className="h-100 col-12 p-2">
      <div className="row">
        <div className="col-md-12 col-lg-12 col-sx-12 col-sm-12">
          <div className="row">
            <div className="col-md-12 col-lg-12 col-sx-12 col-sm-12">
              <div style={{ marginBottom: 100 }}>
                <CustomDivComponent
                  data={formData.EditVisit}
                  loadValues={values}
                  branchValue={branches}
                  isClaimEnabled={true}
                  onFieldChange={handleFieldChange}
                  documentsVisitID={getVisitId()}
                  hideControl={false}
                  hideClaim={true}
                  hideMQR={false}
                  hideJC={true}
                  datePickerChange={handleDateChange}
                  isShowPatientEmail={showPatientField}
                />
              </div>
            </div>
          </div>

          <div className="fixed-bottom">
            <div className="col-md-12 col-lg-12 col-sx-12 col-sm-12 bg-white d-flex justify-content-center">
              <div
                style={{
                  padding: 10,
                  justifyContent: "right",
                  alignItems: "right",
                  alignContent: "right",
                  display: "flex",
                }}
              >
                <button
                  className={"btn"}
                  onClick={validate}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    margin: 5,
                  }}
                  variant="primary"
                  type="submit"
                >
                  Save
                </button>

                <button
                  className={"btn"}
                  onClick={cancelVisit}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    margin: 5,
                  }}
                  variant="primary"
                  type="submit"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div
          style={{
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactLoading
            type={"spin"}
            color={"#ff0000"}
            height={"5%"}
            width={"5%"}
          />
        </div>
      )}
    </div>
  );
}
export default EditVisitPage;
