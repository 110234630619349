import React, { useState, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../styles/styles.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Paper } from "@mui/material";
import formData from "../lib/formsData.json";
import CustomDivComponent from "../components/divComponent";
import RowComponent from "../components/rowComponent";
import {
  postRequest,
  getSRData,
  getUser,
  setVisitId,
  getMandatoryData,
  getCompanyId,
  getVisitId,
  validateSSNArray,
  validateEmail
} from "../Services/DataMode";
import { useNavigate } from "react-router-dom";
import UserContext from "../components/context";
import ReactLoading from "react-loading";
import { format } from 'date-fns';

function EmailSummaryEdit(props) {
  let navigate = useNavigate();
  const context = useContext(UserContext);
  const [loading, setloading] = useState(false);
  const [branches, setBranches] = useState([{ name: "Select", id: "0" }]);
  const [values, setValues] = useState({});


const [nrCountryCode, setnrCountryCode] = useState('+1');
const [remoteCountryCode, setRemoteCountryCode] = useState('+1');

  function handleFieldChange(event) {
    var name = event.target.name;
    var value = event.target.value;
    console.log(event.target.name + " --> " + event.target.value);

    setValues({ ...values, [name]: value });
    console.log(name, value);
  }
  const handleDateChange = (date, name) => {
    setValues({ ...values, [name]: date });
};
  const user = useContext(UserContext);

  useEffect(() => {
    props.updatePage();

    postRequest(
      "ws_webrtc/Telemed.asmx/getTelemedCompanyBranchesPerCompany",
      { sKey: getUser(), iCompanyId: getCompanyId() },
      (response) => {
        console.log("Branches", response.d);
        const branchArray = [];
        for (var i = 0; i < response.d.length; i++) {
          branchArray.push({
            id: response.d[i].CompanyId,
            name: response.d[i].CompanyName,
          });
        }
        setBranches(branchArray);
      },
      context
    );

    // getVisitDetails();

    setValues({
        date_of_injury: "7/24/2023",
        date_of_service: "10/4/2023",
        first_name: "WORTHINGTON",
        last_name: "JAMES",
        claim_number:876787656,
        reason:  "Workers Comp/ Telemedicine/ Follow-up visit/ Office Visit",
        company_name: "ABC company",
        department_or_branchname: "N/A",
        physician_name: "Dr.Stephen Dawkins",
        subjective: "Follow-up ",
        objective: "As per recorded video",
        assessment: "SPRAIN OF LIGAMENTS OF LUMBER SPINE",
        call_paperwork_auditDuration: "40 Minutes",
        plan: "Follow Up Date -10/10/2023",
        work_status: "Restricted Work"
    });

  }, []);

  function getVisitDetails() {
    postRequest(
      "ws_webrtc/util.asmx/GetGlobalSMVisitDetailsToEdit",
      { sKey: getUser(), iVisitId: getVisitId() },
      (response) => {
        setloading(false);
        console.log("Get Visit Details " + JSON.stringify(response.d));

        console.log(response.d, "branch name");

        setValues({
            date_of_injury: response.d.date_of_injury,
            date_of_service: response.d.date_of_service,
            first_name: response.d.first_name,
            last_name: response.d.last_name,
            claim_number: response.d.claim_number,
            reason: response.d.reason,
            company_name: response.d.company_name,
            department_or_branchname: response.d.department_or_branchname,
            physician_name: response.d.physician_name,
            subjective: response.d.subjective,
            objective: response.d.objective,
            assessment: response.d.assessment,
            call_paperwork_auditDuration: response.d.call_paperwork_auditDuration,
            plan: response.d.plan,
            work_status: response.d.work_status
        });
    
      setnrCountryCode(response.d.NR_PhoneNumber.split("-")[0])
      setRemoteCountryCode(response.d.RemotePatient_YN  ? response.d.PhoneNumber.split("-")[0]  : "")
      
      },
      context
    );
  }

  const validate = () => {
    if (values.first_name.trim() == "") {
      console.log("first name");
      user.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "firstname"),
        "OK"
      );
    } else if (values.last_name.trim() == "") {
      console.log("last name");
      user.setUser(0, "Error", getMandatoryData("visitPage", "lastname"), "OK");
    } else if (values.ssn.trim() == "") {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "ssn_textfieldmsg"),
        "OK"
      );
    } else if (values.ssn.length != 9) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "ssn_validation"),
        "OK"
      );
    } else if (
      getMandatoryData("visitPage", "ssn_invalid_message") != "" &&
      values.ssn.length == 9 &&
      !validateSSNArray(
        values.ssn,
        getMandatoryData("visitPage", "ssn_invalid")
      )
    ) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "ssn_invalid_message"),
        "OK"
      );
    } else if (values.NR_PhoneNumber.trim() == "") {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "phonenumber"),
        "OK"
      );
    }else if (values.patient_email !== "" && !validateEmail(values.patient_email)) {
      context.setUser(
        0,
        'Error', 
        getMandatoryData('visitPage', 'patient_email_validation'), 
        'OK')
    }else if (getMandatoryData('visitPage', 'claim_number') != "" && values.Claim_Number.trim() == "" ) { //Claim_Number
      context.setUser(0, 'Error', getMandatoryData('visitPage', 'claim_number'), 'OK')
    }else if (getMandatoryData('visitPage', 'Address1') != "" && values.Address1.trim() == "" ) {
      context.setUser(0, 'Error', getMandatoryData('visitPage', 'Address1'), 'OK')
    }else if (getMandatoryData('visitPage', 'Address2') != "" && values.Address2.trim() == "" ) {
      context.setUser(0, 'Error', getMandatoryData('visitPage', 'Address2'), 'OK')
    }else if (getMandatoryData('visitPage', 'City') != "" && values.City.trim() == "" ) {
      context.setUser(0, 'Error', getMandatoryData('visitPage', 'City'), 'OK')
    }else if (values.Zip_Code.trim() == "") {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "zip_code"),
        "OK"
      );
    } else if (branches.length > 1 && values.branch_id == 0) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "branch"),
        "OK"
      );
    }else{
      setloading(true);
      var data = values;
      var ssnnumber = values.ssn.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
      data.date_of_birth = format(values.date_of_birth, 'MM/dd/yyyy')
      data.NR_PhoneNumber = nrCountryCode+'-'+values.NR_PhoneNumber
      data.phonenumber = values.remotePatientYN ? remoteCountryCode+'-'+values.phonenumber : ''
      data.ssn = ssnnumber;
      console.log(JSON.stringify(data))

      postRequest('ws_webrtc/util.asmx/UpdateGlobalSMVisitDetailsEdited',
      { sKey: getUser(), oNFF: data },
      (response) => {
          console.log(response.d.key);
          setloading(false);
          
               var array = ['Visit edited successfully for ',data.first_name + " " + data.last_name," "]
        
          context.setUser(2, "Success", array, 'OK', 
              false, 'OK', null, () => { navigate('/App/CompletedVisitsDashboard'); });

      }, context)


    }
  };

  function cancelVisit() {
    navigate("/App/CompletedVisitsDashboard");
  }

  return (
    <div className="h-100 col-12 p-2">
      <div className="row">
        <div className="col-md-12 col-lg-12 col-sx-12 col-sm-12">
          <div className="row">
            <div className="col-md-12 col-lg-12 col-sx-12 col-sm-12">
              <div style={{marginBottom:100}}>
                <CustomDivComponent 
                  data={formData.EmailSummaryVisit}
                  loadValues={values}
                  branchValue={branches}
                  isClaimEnabled={true}
                  onFieldChange={handleFieldChange}
                  documentsVisitID={getVisitId()}
                  hideControl={false}
                  hideClaim={true}
                  hideMQR={false}
                  hideJC={true}
                  datePickerChange={handleDateChange}
                />
              </div>
            </div>
          </div>

          <div className="fixed-bottom">
            <div className="col-md-12 col-lg-12 col-sx-12 col-sm-12 bg-white d-flex justify-content-center">
              <div
                style={{
                  padding: 10,
                  justifyContent: "right",
                  alignItems: "right",
                  alignContent: "right",
                  display: "flex",
                }}
              >
                <button
                  className={"btn"}
                  onClick={validate}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    margin:5
                  }}
                  variant="primary"
                  type="submit"
                >
                  Submit
                </button>

                <button
                  className={"btn"}
                  onClick={cancelVisit}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    margin:5
                  }}
                  variant="primary"
                  type="submit"
                >
                  Cancel
                </button>
              </div>
            </div>
          
          </div>
        </div>
      </div>
      {loading && (
        <div
          style={{
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactLoading
            type={"spin"}
            color={"#ff0000"}
            height={"5%"}
            width={"5%"}
          />
        </div>
      )}
    </div>
  );
}
export default EmailSummaryEdit;
