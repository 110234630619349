import React, { useContext, useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../styles/styles.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Paper } from "@mui/material";
import formData from "../lib/formsData.json";
import CustomDivComponent from "../components/divComponent";
import AlertDailog from "../components/AlertDailog";
import {
  getsherwinText,
  setsherwinText,
  validateAdditionalEmail,
  setUploadIds,
  setMQRIds,
  setTestResultFileIds,
  getMQRIds,
  getUploadIds,
  getTestResultFileIds,
  postRequest,
  getUser,
  setVisitId,
  getMandatoryData,
  validateEmail,
  getCompanyId,
  getVisitId,
  getVisitType,
  getFutureVisitFromSMDashboard,
  setFollowupYN,
  setVisitHistoryId,
  setVisitHistoryType,
  setDoctorName,
  setDoctorId,
  validateSSNArray,
  setLanguageId,
  getDoctorId,
  searchRequest,
} from "../Services/DataMode";
import UserContext from "../components/context";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import SearchView from "../components/SearchView";
import SubHeader from "../components/subHeader";
import VisitHistory from "../components/VisitHistory";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { format } from "date-fns";
import { tr } from "date-fns/locale";
import { faL } from "@fortawesome/free-solid-svg-icons";

function CreateVisit(props) {
  const [loading, setloading] = useState(false);

  // const [visitTypes, setVisitTypes] = useState([{ VisitType: 'Select', Visit_Category_Type_Id: '0' }]);
  // const [purposeTypes, setPurposeTypes] = useState([{ PurposeOfVisit: 'Select', PurposeOfVisit_Id: '0' }]);
  // const [drugTypes, setDrugTypes] = useState([{ DrugType_Id: 'Select', DrugType: '0' }]);
  // const [countries, setCountries] = useState([{ Country_Name: 'Select', Country_Id: '0' }]);
  // const [branches, setBranches] = useState([{ CompanyName: 'Select', CompanyId: '0', selectedValue: 0 }]);
  // const [states, setStates] = useState([{ name: 'Select', id: '0' }]);
  // const [languages, setLanguages] = useState([{ name: 'Select', id: '0' }]);
  const [isChecked, setChecked] = useState("No");
  const [visitTypes, setVisitTypes] = useState([{ name: "Select", id: "0" }]);
  const [purposeTypes, setPurposeTypes] = useState([
    { name: "Select", id: "0" },
  ]);
  const [drugTypes, setDrugTypes] = useState([{ name: "Select", id: "0" }]);
  const [countries, setCountries] = useState([
    { name: "Select", id: "0", abbr: "", code: "" },
  ]);
  const [branches, setBranches] = useState([{ name: "Select", id: "0" }]);
  const [states, setStates] = useState([{ name: "Select", id: "0" }]);

  // for job and region title
  const [jobTitle, setJobTitle] = useState([{ name: "Select", id: "0" }]);
  const [region, setRegion] = useState([{ name: "Select", id: "0" }]);
  const [unitedRentals, setUnitedRentals] = useState(false);
  const [hideRegion, setHideRegion] = useState("Job_region_Id");
  const [hideRegionValue, setHideRegionValue] = useState(false);
  const [searchURL, setSearchURL] = useState(false);
  const [showAdditionalEmail, setShowAdditionalEmail] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // State for disabling the button
  const [buttonBgColor, setButtonBgColor] = useState(true); // State to track button background color
  //

  const [languages, setLanguages] = useState([{ name: "Select", id: "0" }]);
  const [jobCategoryArray, setJobCategoryArray] = useState([
    { name: "Select", id: "0" },
  ]);
  const [time, setTime] = useState([{ name: "Select", id: "0" }]);
  const [hideControl, setHidecontrol] = useState("DrugType_Id");
  const [hideClaim, setHideClaim] = useState("");
  const [hideMQR, setHideMQR] = useState(true);
  const [hideJC, setHideJC] = useState(true);
  const [loadSSNDocuemnts, setSSNForDocuments] = useState("");
  const [visitCategory, setVisitCategory] = useState();
  const [files, setFiles] = useState();
  const userInfo = useContext(UserContext).user;
  const context = useContext(UserContext);
  const [createVisitText, setCreateVisitText] = useState("Start New Visit");

  const [isRemotePatient, setRemotePatient] = useState(false);
  const [PhoneNumber, setPhoneNumber] = useState("");
  //patient's email
  const [showPatientField, setShowPatientField] = useState(false);
  const [createParentvisit_id, setCreateParentvisit_id] = useState(0);

  const [date, setDate] = useState(0);
  // const [ssn, setSSN] = useState();
  const [abbr, setAbbr] = useState("US");
  const [code, setCode] = useState("+1");
  const [isbranchEnable, setbranchEnable] = useState(true);
  const [isStateEnable, setStateEnable] = useState(true);
  const [showVisitHistory, setShowVisitHistory] = useState(false);
  const [isUploadID_Mandatory_Field, setisUploadID_Mandatory_Field] = useState(
    false
  );
  const [sherwinText, setSherwinText] = useState("");
  const [claimEnable, setClaimEnable] = useState(true);

  const [isFromDashborad,setIsFromDashboard]=useState(false)

  /// for date
  const [newDOB, setNewDOB] = useState("");
  const [SSNValidation, setSSNValidation] = useState(false);

  let navigate = useNavigate();

  const [Branch_YN, setBranch_YN] = useState(true);
  const [State_YN, setState_YN] = useState(true);

  const [isOpen, setisOpen] = useState(false);

  const handleShowDialog = (event) => {
    setisOpen(!isOpen);
  };
  const [documentsVisitID, setdocumentsVisitID] = useState("");

  const [values, setValues] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [branchesCopy, setBranchesCopy] = useState([
    { name: "Select", id: "0" },
  ]);
  const [statesCopy, setStatesCopy] = useState([{ name: "Select", id: "0" }]);
  var tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [followupvisit_CompletedYN, setfollowupvisit_CompletedYN] = useState(
    false
  );
  const [iscreateVisit,isFromCreateVisit]=useState(
    true
  );

  const [clearData,setClearData] =useState(false)
  const [labelValue,setlabelValue] =useState("")


  const handleFieldChangeSelectRadio = (value) => {
    console.log("Selected value:", value);
    setlabelValue(value)
    // debugger;
    if (value === "Yes" && !searchURL) {
      context.setUser(
        1,
        " Info",
        getMandatoryData("visitPage", "Create_FollowUp"),
        "Yes",
        true,
        "No",
        setSearchURL(false),
        () => {
          followUpValue();
        }
      );
      setClearData(false)
    } else {

      if(!values.Visit_Category_Type_Id == 0){
        context.setUser(
          1,
          " Info",
          getMandatoryData("visitPage", "Clear_Data"),
          "Yes",
          true,
          "No", ()=>{
            setSearchURL(true)

          }
          ,
          () => {
            followUpCancel();
         
          }
        );
        setClearData(false)

      }else{
           followUpCancel();
        setSearchURL(false)
        setSSNForDocuments("")
        setButtonBgColor(true)

      }
 
    }
  };

  const followUpValue = () => {
    // console.log("Executing additional logic after 'Yes' is clicked");
    // Add any other code you want to run here
    // debugger;
    setSearchURL(true);
    setClearData(true)
    setCreateVisitText("Continue")
    setButtonBgColor(false)
    setShowModal(true);
    setIsButtonDisabled(true);
    setSSNForDocuments("")
    setValues({
      branch_id: 0,
      first_name: "",
      last_name: "",
      Job_title_Id: 0,
      Job_region_Id: 0,
      ssn: "",
      state_id: 0,
      date_of_birth: subtractYears(new Date(), 13),
      date_of_injury: new Date(),
      patient_email: "",
      rfv: "Telemedicine",
      company_name: "",
      contact_name: "",
      creditcard_id: 0,
      visit_id: 0,
      Visit_Category_Type_Id: 0,
      MQR_File_Ids: "",
      IDCard_File_IDs: "",
      company_id: getCompanyId(),
      Test_Result_File_Ids: "",
      Number_Of_Sessions: 0,
      HR_Email: "",
      followup_YN: false,
      Visit_Type: "Regular",
      NR_PhoneNumber: "",
      RemotePatient_YN: false,
      PhoneNumber: "",
      Country_Id: 1,
      Job_Category_Id: 0,
      PurposeOfVisit_Id: 0,
      DrugType_Id: 0,
      Language_Id: 1,
      Address1: "",
      Address2: "",
      City: "",
      Zip_Code: "",
      Claim_Number: "",
      Future_Date: getMandatoryData(
        "visitPage",
        "create_future_visit_for_today"
      )
        ? new Date()
        : tomorrow,
      Time_Id: 0,
    });
  };

  const followUpCancel = () => {
    setSearchURL(false);
    setShowModal(false);
    setIsButtonDisabled(false);
    setButtonBgColor(true);
    setCreateVisitText("Start New Visit");
    setClearData(true)
    setClaimEnable(true)
    setSSNForDocuments("")
    setValues({
      branch_id: 0,
      first_name: "",
      last_name: "",
      Job_title_Id: 0,
      Job_region_Id: 0,
      ssn: "",
      state_id: 0,
      date_of_birth: subtractYears(new Date(), 13),
      date_of_injury: new Date(),
      patient_email: "",
      rfv: "Telemedicine",
      company_name: "",
      contact_name: "",
      creditcard_id: 0,
      visit_id: 0,
      Visit_Category_Type_Id: 0,
      MQR_File_Ids: "",
      IDCard_File_IDs: "",
      company_id: getCompanyId(),
      Test_Result_File_Ids: "",
      Number_Of_Sessions: 0,
      HR_Email: "",
      followup_YN: false,
      Visit_Type: "Regular",
      NR_PhoneNumber: "",
      RemotePatient_YN: false,
      PhoneNumber: "",
      Country_Id: 1,
      Job_Category_Id: 0,
      PurposeOfVisit_Id: 0,
      DrugType_Id: 0,
      Language_Id: 1,
      Address1: "",
      Address2: "",
      City: "",
      Zip_Code: "",
      Claim_Number: "",
      Future_Date: getMandatoryData(
        "visitPage",
        "create_future_visit_for_today"
      )
        ? new Date()
        : tomorrow,
      Time_Id: 0,
    });
    setSSNForDocuments("")

  };

  const handleDateChange = (date, name) => {
    setDate("");
    setValues({ ...values, [name]: date });

    // setValues((name == "date_of_birth") ? { "date_of_birth": date } : { "date_of_injury": date })
  };
  // const handleFieldChange = (fieldId, value) => {
  //     if (fieldId == 'NR_PhoneNumber' || fieldId == 'PhoneNumber') {
  //         if (value != "")
  //             value = '+91-' + value;
  //         console.log("Number" + value);
  //     }

  //     if (fieldId == 'ssn' && value.length === 9) {

  //         setSSNForDocuments(value);
  //     }

  //     if (fieldId == 'PurposeOfVisit_Id') {
  //         if (value == 2) {
  //             setHidecontrol('')
  //         } else {
  //             setHidecontrol('DrugType_Id')
  //         }

  //         if (value == 11) {
  //             setHideMQR(false);
  //         } else {
  //             setHideMQR(true);
  //         }
  //     }

  //     if (fieldId == 'RemotePatient_YN' && value == true) {
  //         context.setUser(1, "Info", "Are you sure that patient is 'Remote'. Do you wish to continue?", 'Yes', true, 'No', null, () => { confirmRemotePatient() });
  //     }

  //     setValues({ ...values, [fieldId]: value });
  //     if (fieldId == 'Visit_Category_Type_Id') {
  //         //  handleFieldChange('PurposeOfVisit_Id', 0);
  //         //  setPurposeTypes([{ PurposeOfVisit: 'Select', PurposeOfVisit_Id: '0' }]);
  //         getPurposes(value);
  //     }

  // };

  function handleFieldChange(event) {
    var name = event.target.name;
    var value = event.target.value;
    console.log(event.target.name + " --> " + event.target.value);

    // console.log("hello document ", values);
    if (name === "IDCard_File_IDs" && value) {
      setloading(true);

      setValues({ ...values, IDCard_File_IDs: "" });
      setloading(false);
    }

    // if (name == "Job_title_Id") {
    //   // setJobTitlevalueRegion(value);

    //   setHideRegionValue(true);
    //   setValues({ ...values, Job_title_Id: value });
    //   getRegionArea(value);

    //   ///
    // }
    // if (name == "Job_region_Id") {
    //   setValues({ ...values, Job_region_Id: value });
    //   setHideRegion("Job_region_Id");
    // }

    if (name == "Visit_Category_Type_Id") {
      getPurposes(value);
      setValues({ ...values, PurposeOfVisit_Id: 0 });
      values.PurposeOfVisit_Id = 0;
      setValues({ ...values, DrugType_Id: 0 });
      values.DrugType_Id = 0;
      setHidecontrol("DrugType_Id");
      setHideMQR(true);
    }

    if (name == "NR_PhoneNumber") {
      setValues({ ...values, PhoneNumber: value });
      values.PhoneNumber = value;
    }
    if (name == "ssn" && value.length === 9) {
      setloading(true);
      console.log("SSN validation", value);
      console.log("1234567890", "validation");

      getSSNValidation(value);
    } else if (name == "ssn" && value.length !== 9) {
      setSSNForDocuments("");
    }
    if (name == "PurposeOfVisit_Id") {
      setHidecontrol(value == 2 ? "" : "DrugType_Id");
      setHideMQR(value == 11 ? false : true);
    }
    if (name == "RemotePatient_YN") {
      // (value == 0) ? setRemotePatient(false) : setRemotePatient(true);

      value == 0 ? (value = false) : (value = true);
    }
    if (name == "Country_Id") {
      countries.map((item) => {
        if (item.id == value) {
          setAbbr(item.abbr);
          setCode(item.code);
        }
      });
      branchStateEnable(value);
    }
    if (name == "date_of_birth") {
      setDate(value);
    }
    if (name == "date_of_injury") {
      setDate(value);
    }
    if (name == "RemotePatient_YN") {
      if (value == true) {
        context.setUser(
          1,
          "Info",
          "Are you sure that patient is 'Remote'. Do you wish to continue?",
          "Yes",
          true,
          "No",
          () => {
            confirmRemotePatient(false);
          },
          () => {
            confirmRemotePatient(true);
          }
        );
      } else {
        confirmRemotePatient(false);
      }
    }

    setValues({ ...values, [name]: value });
    console.log(name, value);
  }

  function branchStateEnable(countryId) {
    postRequest(
      "ws_webrtc/Telemed.asmx/GetCountryCode",
      { sKey: getUser(), iCountryId: countryId },
      (response) => {
        console.log("Branch_YN " + response.d.Branch_YN);
        console.log("State_YN " + response.d.State_YN);
        setBranch_YN(response.d.Branch_YN);
        setState_YN(response.d.State_YN);
        if (response.d.Branch_YN === true) {
          if (branchesCopy.length == 1) {
            setbranchEnable(false);
          } else {
            setbranchEnable(true);
          }
          setBranches(branchesCopy);

          // setValues({ ...values, branch_id: 0 });
          // values.branch_id = 0;
        } else {
          setbranchEnable(false);
          const branchArray = [];
          branchArray.push({ id: 0, name: "None" });
          setBranches(branchArray);
          // setValues({ ...values, 'branch_id': 0 })
          // values.branch_id = 0
        }

        if (response.d.State_YN === true) {
          setStateEnable(true);
          setStates(statesCopy);
          // setValues({ ...values, 'state_id': 0 })
          // values.state_id = 0
        } else {
          setStateEnable(false);
          const stateArray = [];
          stateArray.push({ id: 0, name: "None" });
          setStates(stateArray);
          // setValues({ ...values, 'state_id': 0 })
          // values.state_id = 0
        }
      },
      context
    );
  }
  function branchStateEnable2(countryId) {
    postRequest(
      "ws_webrtc/Telemed.asmx/GetCountryCode",
      { sKey: getUser(), iCountryId: countryId },
      (response) => {
        console.log("Branch_YN " + response.d.Branch_YN);
        console.log("State_YN " + response.d.State_YN);
        setBranch_YN(response.d.Branch_YN);
        setState_YN(response.d.State_YN);
        if (response.d.Branch_YN === true) {
          if (branchesCopy.length == 1) {
            setbranchEnable(false);
          } else {
            setbranchEnable(true);
          }
        } else {
          setbranchEnable(false);
          const branchArray = [];
          branchArray.push({ id: 0, name: "None" });
          setBranches(branchArray);
        }

        if (response.d.State_YN === true) {
          setStateEnable(true);
        } else {
          setStateEnable(false);
          const stateArray = [];
          stateArray.push({ id: 0, name: "None" });
          setStates(stateArray);
        }
      },
      context
    );
  }

  useEffect(() => {
   
    setTestResultFileIds("");
    setMQRIds("");
    setUploadIds("");
    props.updatePage();

    if (getVisitType() == "Future") {
      setCreateVisitText("Create Future Visit");
      postRequest(
        "ws_webrtc/Util.asmx/GetCompanySpecificFields_YN",
        { sKey: getUser(), icompanyId: getCompanyId() },
        (response) => {
          // debugger;
          console.log(
            "hello 1111111111 GetCompanySpecificFields_YN",
            response.d.status
          );

          if (response.d.status) {
            setShowAdditionalEmail(false);
            setShowPatientField(false);
            setloading(false);
          } else {
            setShowAdditionalEmail(true);
            setShowPatientField(true);
            setloading(false);
          }
        },
        context
      );
      setShowPatientField(true);
    }
    if (getVisitType() != "Future") {
      setloading(true);
      postRequest(
        "ws_webrtc/Util.asmx/GetCompanySpecificFields_YN",
        { sKey: getUser(), icompanyId: getCompanyId() },
        (response) => {
          // debugger;
          console.log(
            "hello 1111111111 GetCompanySpecificFields_YN",
            response.d.status
          );

          if (response.d.status) {
            setShowPatientField(false);
            setShowAdditionalEmail(false);
            setloading(false);
          } else {
            setShowPatientField(true);
            setShowAdditionalEmail(true);
            setloading(false);
          }
        },
        context
      );
    }

    postRequest(
      "ws_webrtc/Telemed.asmx/GetVisitTypes",
      { sKey: getUser() },
      (response) => {
        //console.log("category", response.d);
        const newArray = [];
        console.log("category", response.d);
        newArray.push({ id: 0, name: "Select" });
        for (var i = 0; i < response.d.length; i++) {
          newArray.push({
            id: response.d[i].VisitType_Id,
            name: response.d[i].VisitType,
          });
        }

        setVisitTypes(newArray);
      },
      context
    );

    postRequest(
      "ws_webrtc/Telemed.asmx/GetCountries",
      { sKey: getUser() },
      (response) => {
        console.log(response.d);
        const countryArray = [];
        for (var i = 0; i < response.d.length; i++) {
          countryArray.push({
            id: response.d[i].Country_Id,
            name: response.d[i].Country_Name,
            abbr: response.d[i].Country_Abbr,
            code: response.d[i].Country_Code,
          });
        }
        setCountries(countryArray);
      },
      context
    );

    postRequest(
      "ws_webrtc/Util.asmx/TelemedDrugTypesGet",
      { sKey: getUser(), iCountryId: 0 },
      (response) => {
        //console.log(response.d);
        const drugArray = [];
        drugArray.push({ id: 0, name: "Select" });
        for (var i = 0; i < response.d.length; i++) {
          drugArray.push({
            id: response.d[i].DrugType_Id,
            name: response.d[i].DrugType,
          });
        }
        setDrugTypes(drugArray);
      },
      context
    );

    postRequest(
      "ws_webrtc/Telemed.asmx/getTelemedCompanyBranchesPerCompany",
      { sKey: getUser(), iCompanyId: getCompanyId() },
      (response) => {
        // console.log(response.d);
        console.log("Branches", response.d);
        const branchArray = [];
        for (var i = 0; i < response.d.length; i++) {
          branchArray.push({
            id: response.d[i].CompanyId,
            name: response.d[i].CompanyName,
          });
        }
        setBranches(branchArray);
        setBranchesCopy(branchArray);

        if (branchArray.length == 1) {
          setbranchEnable(false);
        } else {
          setbranchEnable(true);
        }
      },
      context
    );

    postRequest(
      "ws_webrtc/Util.asmx/GetClientSpecific_MandatoryFields",
      { sKey: getUser(), iCompanyId: getCompanyId() },
      (response) => {
        setisUploadID_Mandatory_Field(response.d.isUploadID_Mandatory_Field);
        //  callMethod();
      },

      context
    );

    postRequest(
      "ws_webrtc/Telemed.asmx/IsSMUserWithSherlianWilliams",
      { sKey: getUser(), iCompanyId: getCompanyId() },
      (response) => {
        console.log(response.d);
        if (response.d.domain != "") {
          setSherwinText(response.d.domain);
          setsherwinText(response.d.domain);
        } else {
          setsherwinText("");
        }
      },
      context
    );

    postRequest(
      "ws_webrtc/Util.asmx/populateDDL",
      { sKey: getUser(), sTableName: "tblStates" },
      (response) => {
        //console.log(response.d);
        const stateArray = [];
        console.log("States ", response.d);
        stateArray.push({ id: 0, name: "Select" });
        for (var i = 0; i < response.d.length; i++) {
          stateArray.push({ id: response.d[i].id, name: response.d[i].name });
        }
        setStates(stateArray);
        setStatesCopy(stateArray);
      },
      context
    );

    postRequest(
      "ws_webrtc/Util.asmx/populateDDL",
      { sKey: getUser(), sTableName: "tbltelemed_languages" },
      (response) => {
        //console.log(response.d);
        const langArray = [];
        console.log("Languages ", response.d);
        for (var i = 0; i < response.d.length; i++) {
          langArray.push({ id: response.d[i].id, name: response.d[i].name });
        }
        setLanguages(langArray);
      },
      context
    );

    postRequest(
      "ws_webrtc/Telemed.asmx/GetTimeSlots",
      { sKey: getUser() },
      (response) => {
        //console.log(response.d);
        const timeArray = [];
        console.log("Time Slots ", response.d);
        for (var i = 0; i < response.d.length; i++) {
          timeArray.push({
            id: response.d[i].Time_Id,
            name: response.d[i].Time,
          });
        }
        setTime(timeArray);
      },
      context
    );

    postRequest(
      "ws_webrtc/Telemed.asmx/IsCompanyWithJobCategory",
      { sKey: getUser(), iCompanyId: getCompanyId() },
      (response) => {
        console.log(response.d);

        if (response.d == true) {
          postRequest(
            "ws_webrtc/SouthWestAirlinesForm.asmx/SouthWestAirlines_JobCategory_List",
            { sKey: getUser() },
            (response) => {
              //console.log(response.d);
              const jobArray = [];
              console.log("Job Category ", response.d);
              jobArray.push({ id: 0, name: "Select" });
              for (var i = 0; i < response.d.length; i++) {
                jobArray.push({
                  id: response.d[i].Job_Category_Id,
                  name: response.d[i].Job_Category,
                });
              }
              setJobCategoryArray(jobArray);
            },
            context
          );
          setHideJC(false);
        } else {
          //  setHidecontrol('Job_Category_Id')
          setHideJC(true);
        }
      },
      context
    );

    //hello united rentials
    // postRequest(
    //   "/ws_webrtc/UnitedRentalsForm.asmx/GetSpecificFields_UnitedRentals_YN",
    //   { sKey: getUser(), icompanyId: getCompanyId() },
    //   (response) => {
    //     console.log("hello response united renrial ", response.d.status);
    //     setUnitedRentals(response.d.status);
    //     if (response.d.status) {
    //       console.log("hello response of uniteAgrement", response.d.status);
    //       getJobTitle();
    //      // callMethod();
    //     } else {
    //    //   callMethod();
    //     }
    //   },
    //   context
    // );
    //hello united rentials

    callMethod();

    return setTestResultFileIds(""), setMQRIds(""), setUploadIds("");
  }, []);

  function getJobTitle() {
    postRequest(
      "/ws_webrtc/UnitedRentalsForm.asmx/UnitedRentals_JobTitles_Get",
      { sKey: getUser(), iVisitId: getVisitId() },
      (response) => {
        //console.log("category", response.d);
        const jobTitleArr = [];

        console.log("job title response", response.d);
        jobTitleArr.push({ id: 0, name: "Select" });
        for (var i = 0; i < response.d.length; i++) {
          jobTitleArr.push({
            id: response.d[i].JobTitleId,
            name: response.d[i].JobTitleName,
          });
        }
        setJobTitle(jobTitleArr);
      },
      context
    );
  }

  function getRegionArea(value) {
    postRequest(
      "ws_webrtc/UnitedRentalsForm.asmx/GetRegionPerJobTitle",
      { sKey: getUser(), iJobTitleId: +value },
      (response) => {
        //console.log("category", response.d);
        const RegionTitleArr = [];
        console.log("job title response", response.d);
        RegionTitleArr.push({ id: 0, name: "Select" });
        for (var i = 0; i < response.d.length; i++) {
          RegionTitleArr.push({
            id: response.d[i].iRegionId,
            name: response.d[i].sRegionName,
          });
        }
        setRegion(RegionTitleArr);
      },
      context
    );
  }

  function callGetVisit() {
    console.log("visit_id " + getVisitId());

    getVisitDetails();
    if (getVisitType() == "Future") {
      if (getFutureVisitFromSMDashboard() == "True") {
        setCreateVisitText("Continue");
      } else {
        setCreateVisitText("Update Future Visit");
      }
    } else {
      setCreateVisitText("Continue");
    }
  }

  function callMethod() {
    if (getVisitId() > 0) {
      setloading(true);
      setValues({
        ...values,
        Future_Date: getMandatoryData(
          "visitPage",
          "create_future_visit_for_today"
        )
          ? new Date()
          : tomorrow,
      });
      isFromCreateVisit(false)
      setTimeout(() => {
        callGetVisit();
        console.log("CallMethod");
      }, 2000);
    } else {
      setValues({
        branch_id: 0,
        first_name: "",
        last_name: "",
        Job_title_Id: 0,
        Job_region_Id: 0,
        ssn: "",
        state_id: 0,
        date_of_birth: subtractYears(new Date(), 13),
        date_of_injury: new Date(),
        patient_email: "",
        rfv: "Telemedicine",
        company_name: "",
        contact_name: "",
        creditcard_id: 0,
        visit_id: 0,
        Visit_Category_Type_Id: 0,
        MQR_File_Ids: "",
        IDCard_File_IDs: "",
        company_id: getCompanyId(),
        Test_Result_File_Ids: "",
        Number_Of_Sessions: 0,
        HR_Email: "",
        followup_YN: false,
        Visit_Type: "Regular",
        NR_PhoneNumber: "",
        RemotePatient_YN: false,
        PhoneNumber: "",
        Country_Id: 1,
        Job_Category_Id: 0,
        PurposeOfVisit_Id: 0,
        DrugType_Id: 0,
        Language_Id: 1,
        Address1: "",
        Address2: "",
        City: "",
        Zip_Code: "",
        Claim_Number: "",
        Future_Date: getMandatoryData(
          "visitPage",
          "create_future_visit_for_today"
        )
          ? new Date()
          : tomorrow,
        Time_Id: 0,
      });
    }
  }

  function subtractYears(date, years) {
    date.setFullYear(date.getFullYear() - years);
    return date;
  }

  function getVisitDetails() {
  
    postRequest(
      "ws_webrtc/Telemed.asmx/getVisitDetails",
      { sKey: getUser(), iVisitId: getVisitId() },
      (response) => {
        setloading(false);
              

        console.log("loine number 905", response.d.IDCard_File_IDs);
        console.log("Get visit details", response.d);

        getSSNValidation(response.d.ssn.replaceAll("-", ""));
        setNewDOB(response.d.date_of_birth);
        setCreateParentvisit_id(response.d.parentvisit_id);
        console.log("123456789", "validation");

        // console.log(
        //   "Get Visit Details  ==========> " +
        //     JSON.stringify(response.d, response.d.IDCard_File_IDs)
        // );
        visitTypes.selectedValue = response.d.Visit_Category_Type_Id;
        //console.log("Get Visit Details " + visitTypes);
        if (response.d.RemotePatient_YN) {
          setRemotePatient(true);
        } else {
          setRemotePatient(false);
        }
        response.d.parentvisit_id > 0
          ? setClaimEnable(false)
          : setClaimEnable(true);
        getPurposes(response.d.Visit_Category_Type_Id);
        setHidecontrol(response.d.PurposeOfVisit_Id == 2 ? "" : "DrugType_Id");
        setHideMQR(response.d.PurposeOfVisit_Id == 11 ? false : true);
        if (response.d.Claim_Number == "" && response.d.parentvisit_id > 0) {
          setHideClaim("Claim_Number");
        }

        // if (response.d.Job_title_Id) {
        //   setHideRegionValue(true);
        //   getRegionArea(response.d.Job_title_Id);
        // }
        //

        console.log("hello hoe  +++++>>>+++ ajgfjgfk ");

        setValues({
          branch_id: response.d.branch_id,
          first_name: response.d.first_name,
          last_name: response.d.last_name,
          Job_title_Id: response.d.Job_title_Id,
          Job_region_Id: response.d.Job_region_Id,
          ssn: response.d.ssn.replaceAll("-", ""),
          state_id: response.d.state_id,
          date_of_birth: new Date(response.d.date_of_birth),
          date_of_injury: new Date(response.d.date_of_injury),
          patient_email: response.d.patient_email,
          rfv: "Telemedicine",
          company_name: "",
          contact_name: "",
          creditcard_id: 0,
          visit_id:getVisitId(),
          parentvisit_id: response.d.parentvisit_id,
          //followup_visitId
          Visit_Category_Type_Id: response.d.Visit_Category_Type_Id,
          MQR_File_Ids: "",
          IDCard_File_IDs: response.d.IDCard_File_IDs,
          company_id: 0,
          Test_Result_File_Ids: "",
          Number_Of_Sessions: 0,
          HR_Email:
            getsherwinText() != ""
              ? response.d.HR_Email.split("@")[0]
              : response.d.HR_Email,
          followup_YN: response.d.parentvisit_id == 0 ? false : true,
          Visit_Type: response.d.parentvisit_id == 0 ? "Regular" : "Follow-up",
          NR_PhoneNumber: response.d.NR_PhoneNumber.split("-")[1],
          RemotePatient_YN: response.d.RemotePatient_YN ? true : false,
          PhoneNumber: response.d.RemotePatient_YN
            ? response.d.PhoneNumber.split("-")[1]
            : response.d.PhoneNumber,
          Country_Id: response.d.Country_Id,
          Job_Category_Id: response.d.Job_Category_Id,
          PurposeOfVisit_Id: response.d.PurposeOfVisit_Id,
          DrugType_Id: response.d.DrugType_Id,
          Language_Id: response.d.Language_Id,
          Address1: response.d.Address1,
          Address2: response.d.Address2,
          City: response.d.City,
          Zip_Code: response.d.Zip_Code,
          Claim_Number: response.d.Claim_Number,
          Future_Date:
            response.d.Future_Date == ""
              ? getMandatoryData("visitPage", "create_future_visit_for_today")
                ? new Date()
                : tomorrow
              : response.d.Future_Date,
          Time_Id: response.d.Time_Id,
        });

        setdocumentsVisitID(getVisitId());
        setSSNForDocuments(response.d.IDCard_File_IDs);
        setCode(response.d.NR_PhoneNumber.split("-")[0]);

        response.d.parentvisit_id == 0
          ? setFollowupYN("false")
          : setFollowupYN("true");
        branchStateEnable2(response.d.Country_Id);
        setfollowupvisit_CompletedYN(response.d.followupvisit_completedYN);
      },
      context
    );
  }

  ///trail

  ///error

  function getPurposes(purpose_id) {
    // alert(purpose_id)
    postRequest(
      "ws_webrtc/Util.asmx/TelemedVisitsPurposesGet",
      { sKey: getUser(), iVisitTypeID: purpose_id },
      (response) => {
        var tempArray = [];
        console.log("Purpsoe", response.d);
        tempArray.push({ id: 0, name: "Select" });
        for (var i = 0; i < response.d.length; i++) {
          tempArray.push({
            id: response.d[i].PurposeOfVisit_Id,
            name: response.d[i].PurposeOfVisit,
          });
        }
        setPurposeTypes(tempArray);
      },
      context
    );
  }

  function patientSelected(selectedValue) {

   
    if (selectedValue.ssn == "") {
      setValues({
        branch_id: 0,
        first_name: "",
        last_name: "",
        //
        Job_title_Id: 0,
        Job_region_Id: 0,
        //
        ssn: "",
        state_id: 0,
        date_of_birth: subtractYears(new Date(), 13),
        date_of_injury: new Date(),
        patient_email: "",
        rfv: "Telemedicine",
        company_name: "",
        contact_name: "",
        creditcard_id: 0,
        visit_id: 0,
        Visit_Category_Type_Id: 0,
        MQR_File_Ids: "",
        IDCard_File_IDs: "",
        company_id: 0,
        Test_Result_File_Ids: "",
        Number_Of_Sessions: 0,
        HR_Email: "",
        followup_YN: false,
        Visit_Type: "Regular",
        NR_PhoneNumber: "",
        RemotePatient_YN: false,
        PhoneNumber: "",
        Country_Id: 0,
        Job_Category_Id: 0,
        PurposeOfVisit_Id: 0,
        DrugType_Id: 0,
        Language_Id: 1,
        Address1: "",
        Address2: "",
        City: "",
        Zip_Code: "",
        Claim_Number: "",
        Future_Date: getMandatoryData(
          "visitPage",
          "create_future_visit_for_today"
        )
          ? new Date()
          : tomorrow,
        Time_Id: 0,
      });
      setHidecontrol("DrugType_Id");
      setHideMQR(true);
      setShowVisitHistory(false);
      setRemotePatient(false);
      setdocumentsVisitID("");
      setSSNForDocuments("");
      setSSNValidation(false);
      setHideRegionValue(false);
    } else {
      setloading(true);
      setHideRegionValue(selectedValue.Job_title_Id ? true : false);
      // postRequest(
      //   "ws_webrtc/util.asmx/GetVisitDetailsPerSSN",
      //   { sKey: getUser(), sSSN: selectedValue.ssn },
      //   (response) => {
      //     //console.log(response);
      //     setloading(false);
      //     setShowVisitHistory(true);
      //     console.log(
      //       "Get Visit Details Per SSn " + JSON.stringify(response.d)
      //     );
      //     //console.log("Get Visit Details " + visitTypes);
      //     visitTypes.selectedValue = response.d.Visit_Category_Type_Id;
      //     //console.log("Get Visit Details " + visitTypes);
      //     if (response.d.RemotePatient_YN) {
      //       setRemotePatient(true);
      //     } else {
      //       setRemotePatient(false);
      //     }
      //     setHidecontrol(
      //       response.d.PurposeOfVisit_Id == 2 ? "" : "DrugType_Id"
      //     );
      //     setHideMQR(response.d.PurposeOfVisit_Id == 11 ? false : true);
      //     getPurposes(response.d.Visit_Category_Type_Id);
      //     setVisitHistoryId(response.d.visit_id);
      //     if (response.d.PurposeOfVisit_Id == 2) {
      //       setVisitHistoryType("MA");
      //     } else {
      //       setVisitHistoryType("Normal");
      //     }
      //     setValues({
      //       branch_id: response.d.branch_id,
      //       first_name: response.d.first_name,
      //       last_name: response.d.last_name,
      //       ssn: response.d.ssn.replaceAll("-", ""),
      //       state_id: response.d.state_id,
      //       date_of_birth: new Date(response.d.date_of_birth),
      //       date_of_injury:
      //         response.d.date_of_injury == ""
      //           ? new Date()
      //           : new Date(response.d.date_of_injury),
      //       patient_email: response.d.patient_email,
      //       rfv: "Telemedicine",
      //       company_name: "",
      //       contact_name: "",
      //       creditcard_id: 0,
      //       visit_id: getVisitId(),
      //       parentvisit_id: response.d.parentvisit_id,
      //       //followup_visitId
      //       Visit_Category_Type_Id: response.d.Visit_Category_Type_Id,
      //       MQR_File_Ids: "",
      //       IDCard_File_IDs: "",
      //       company_id: 0,
      //       Test_Result_File_Ids: "",
      //       Number_Of_Sessions: 0,
      //       HR_Email:
      //         getsherwinText() != ""
      //           ? response.d.HR_Email.split("@")[0]
      //           : response.d.HR_Email,
      //       //followupvisit_completedYN
      //       followup_YN: false,
      //       Visit_Type: "Regular",
      //       NR_PhoneNumber: response.d.NR_PhoneNumber.split("-")[1],
      //       RemotePatient_YN: response.d.RemotePatient_YN ? true : false,
      //       PhoneNumber: response.d.PhoneNumber.split("-")[1],
      //       Country_Id: response.d.Country_Id,
      //       Job_Category_Id: response.d.Job_Category_Id,
      //       PurposeOfVisit_Id: response.d.PurposeOfVisit_Id,
      //       DrugType_Id: response.d.DrugType_Id,
      //       Language_Id: response.d.Language_Id,
      //       Address1: response.d.Address1,
      //       Address2: response.d.Address2,
      //       City: response.d.City,
      //       Zip_Code: response.d.Zip_Code,
      //       Claim_Number: response.d.Claim_Number,
      //       Future_Date: getMandatoryData(
      //         "visitPage",
      //         "create_future_visit_for_today"
      //       )
      //         ? new Date()
      //         : tomorrow,
      //       Time_Id: 0,
      //     });
      //     setdocumentsVisitID(response.d.visit_id);
      //     setSSNForDocuments(response.d.ssn.replaceAll("-", ""));
      //     setCode(response.d.NR_PhoneNumber.split("-")[0]);
      //     setfollowupvisit_CompletedYN(response.followupvisit_completedYN);
      //   },
      //   context
      // );


      if(searchURL){
        getVisitDetails();
        setShowVisitHistory(true);
        setVisitHistoryId(getVisitId());
        setIsButtonDisabled(false)
        setButtonBgColor(true)
  
      }else{
              postRequest(
        "ws_webrtc/util.asmx/GetVisitDetailsPerSSN_DOB",
        {
          sKey: getUser(),
          sSSN: selectedValue.ssn,
          CompanyId: getCompanyId(),
          BranchId: "0",
          DOB: selectedValue.date_of_birth,
          sPatientName:
            selectedValue.first_name + " " + selectedValue.last_name,
        },
        (response) => {
          //console.log(response);
          setloading(false);
          getSSNValidation(response.d.ssn.replaceAll("-", ""));
          console.log(
            "Get Visit Details Per SSn visit Id ====>:",
            response.d.visit_id,
            response.d.date_of_birth,
            response.d.Job_region_Id
          );
          console.log(
            "Get Visit Details Per SSn " + JSON.stringify(response.d)
          );
          //console.log("Get Visit Details " + visitTypes);
          visitTypes.selectedValue = response.d.Visit_Category_Type_Id;
          //console.log("Get Visit Details " + visitTypes);
          if (response.d.RemotePatient_YN) {
            setRemotePatient(true);
          } else {
            setRemotePatient(false);
          }
          setHidecontrol(
            response.d.PurposeOfVisit_Id == 2 ? "" : "DrugType_Id"
          );
          setHideMQR(response.d.PurposeOfVisit_Id == 11 ? false : true);
          getPurposes(response.d.Visit_Category_Type_Id);
          setVisitHistoryId(response.d.visit_id);
          if (response.d.PurposeOfVisit_Id == 2) {
            setVisitHistoryType("MA");
          } else {
            setVisitHistoryType("Normal");
          }

          if (response.d.Job_title_Id) {
            getRegionArea(response.d.Job_title_Id);
          }

          console.log("hello ++++++ ====> before checking", response.d.ssn);

          setValues({
            branch_id: response.d.branch_id,
            first_name: response.d.first_name,
            last_name: response.d.last_name,
            Job_title_Id: response.d.Job_title_Id,
            Job_region_Id: response.d.Job_region_Id,
            ssn: response.d.ssn.replaceAll("-", ""),
            state_id: response.d.state_id,
            date_of_birth: new Date(response.d.date_of_birth)
              .toLocaleDateString("en-US")
              .replace(/\b(\d)\b/g, "0$1"),
            date_of_injury:
              response.d.date_of_injury == ""
                ? new Date()
                : new Date(response.d.date_of_injury),
            patient_email: response.d.patient_email,
            rfv: "Telemedicine",
            company_name: "",
            contact_name: "",
            creditcard_id: 0,
            visit_id: getVisitId(),
            parentvisit_id: response.d.parentvisit_id,
            //followup_visitId
            Visit_Category_Type_Id: response.d.Visit_Category_Type_Id,
            MQR_File_Ids: "",
            IDCard_File_IDs: "",
            company_id: 0,
            Test_Result_File_Ids: "",
            Number_Of_Sessions: 0,
            HR_Email:
              getsherwinText() != ""
                ? response.d.HR_Email.split("@")[0]
                : response.d.HR_Email,
            //followupvisit_completedYN
            followup_YN: false,
            Visit_Type: "Regular",
            NR_PhoneNumber: response.d.NR_PhoneNumber.split("-")[1],
            RemotePatient_YN: response.d.RemotePatient_YN ? true : false,
            PhoneNumber: response.d.PhoneNumber.split("-")[1],
            Country_Id: response.d.Country_Id,
            Job_Category_Id: response.d.Job_Category_Id,
            PurposeOfVisit_Id: response.d.PurposeOfVisit_Id,
            DrugType_Id: response.d.DrugType_Id,
            Language_Id: response.d.Language_Id,
            Address1: response.d.Address1,
            Address2: response.d.Address2,
            City: response.d.City,
            Zip_Code: response.d.Zip_Code,
            Claim_Number: response.d.Claim_Number,
            Future_Date: getMandatoryData(
              "visitPage",
              "create_future_visit_for_today"
            )
              ? new Date()
              : tomorrow,
            Time_Id: 0,
          });

          console.log(
            "hello sir how are you doing +++++++++++++>>",
            values.ssn
          );
          setdocumentsVisitID(response.d.visit_id);
          setCode(response.d.NR_PhoneNumber.split("-")[0]);
          setfollowupvisit_CompletedYN(response.followupvisit_completedYN);
          console.log("hello last boy", values.date_of_birth, values.ssn);
          setShowVisitHistory(true);
                  setVisitHistoryId(response.d.visit_id);


        },

        context
          );


      }


      console.log("hello last boy", values.date_of_birth, values.ssn);
    }
  }

  function showVisitHistoryData() {
    setisOpen(true);
  }

  const inputParameters = {
    sKey: getUser(),
    oFormFS: values,
  };

  function confirmRemotePatient(remotePatientyesno) {
    setRemotePatient(false);
    remotePatientEnable(false);
    if (remotePatientyesno) {
      context.setUser(
        1,
        "Info",
        "Is remote patient phone number available with you?",
        "Yes",
        true,
        "No",
        () => {
          remotePatientEnable(false);
        },
        () => {
          remotePatientEnable(true);
        }
      );
    } else {
      setValues({ ...values, RemotePatient_YN: 0 });
    }
  }
  function remotePatientEnable(isRemote) {
    setRemotePatient(isRemote);
  }

  const validate = () => {
    if (values.Visit_Category_Type_Id == 0) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "visittype"),
        "OK"
      );
    } else if (values.PurposeOfVisit_Id == 0) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "purposeofvisit"),
        "OK"
      );
    } else if (
      hideMQR == false &&
      getMandatoryData("visitPage", "mqr_mandatory_message") != "" &&
      getMQRIds() == ""
    ) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "mqr_mandatory_message"),
        "OK"
      );
    } else if (values.DrugType_Id == 0 && values.PurposeOfVisit_Id == 2) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "drugtype"),
        "OK"
      );
    } else if (values.branch_id == 0 && isbranchEnable) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "branch"),
        "OK"
      );
    } else if (jobCategoryArray.length > 1 && values.Job_Category_Id == 0) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "jobcategory"),
        "OK"
      );
    } else if (
      unitedRentals &&
      // !values.Job_title_Id &&
      values.Job_title_Id == 0
    ) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("create_sr", "JobTitle"),
        "OK"
      );
    } else if (
      hideRegionValue &&
      // !values.Job_region_Id &&
      values.Job_region_Id == 0
    ) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("create_sr", "Region"),
        "OK"
      );
    }
    //
    else if (values.first_name.trim() == "") {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "firstname"),
        "OK"
      );
    } else if (values.last_name.trim() == "") {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "lastname"),
        "OK"
      );
    } else if (
      isUploadID_Mandatory_Field &&
      getMandatoryData("visitPage", "upload_id_card") != "" &&
      getUploadIds() == ""
    ) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "upload_id_card"),
        "OK"
      );
    } else if (values.ssn && values.ssn.trim() == "") {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "ssn_textfieldmsg"),
        "OK"
      );
    } else if (values.ssn.trim().length == 0) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "ssn_textfieldmsg"),
        "OK"
      );
    } else if (values.ssn && values.ssn.length != 9) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "ssn_validation"),
        "OK"
      );
    } else if (SSNValidation) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "ssn_invalid_message"),
        "OK"
      );
    } else if (values.ssn.length == 9 && SSNValidation) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "ssn_invalid_message"),
        "OK"
      );
    } else if (
      values.ssn &&
      getMandatoryData("visitPage", "ssn_invalid_message") != "" &&
      values.ssn.length == 9 &&
      !validateSSNArray(
        values.ssn,
        getMandatoryData("visitPage", "ssn_invalid")
      )
    ) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "ssn_invalid_message"),
        "OK"
      );
    } else if (values.NR_PhoneNumber.trim() == "") {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "phonenumber"),
        "OK"
      );
    } else if (values.date_of_birth == "" || values.date_of_birth == null) {
      context.setUser(0, "Error", "Please select Date of Birth", "OK");
    } else if (values.date_of_injury == "" || values.date_of_injury == null) {
      context.setUser(0, "Error", "Please select Date of Injury", "OK");
    } else if (values.date_of_birth > subtractYears(new Date(), 13)) {
      console.log(
        "hello testing for dob",
        values.date_of_birth,
        subtractYears(new Date(), 13)
      );
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "dob_validation"),
        "OK"
      );
    } else if (values.date_of_birth > values.date_of_injury) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "dateofinjury_validation"),
        "OK"
      );
    } else if (
      getMandatoryData("visitPage", "claim_number") != "" &&
      values.Claim_Number.trim() == ""
    ) {
      //Claim_Number
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "claim_number"),
        "OK"
      );
    } else if (
      values.patient_email !== "" &&
      !validateEmail(values.patient_email)
    ) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "patient_email_validation"),
        "OK"
      );
    } else if (values.HR_Email == "" && sherwinText != "") {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "hr_email"),
        "OK"
      );
    } else if (values.HR_Email.includes("@") && sherwinText != "") {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "hr_email_validation"),
        "OK"
      );
    } else if (
      sherwinText != "" &&
      !validateEmail(values.HR_Email + sherwinText)
    ) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "hr_email_valid"),
        "OK"
      );
    } else if (
      sherwinText == "" &&
      values.HR_Email != "" &&
      !validateAdditionalEmail(values.HR_Email)
    ) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "additonal_email"),
        "OK"
      );
    } else if (
      getMandatoryData("visitPage", "Address1") != "" &&
      values.Address1.trim() == ""
    ) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "Address1"),
        "OK"
      );
    } else if (
      getMandatoryData("visitPage", "Address2") != "" &&
      values.Address2.trim() == ""
    ) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "Address2"),
        "OK"
      );
    } else if (
      getMandatoryData("visitPage", "City") != "" &&
      values.City.trim() == ""
    ) {
      context.setUser(0, "Error", getMandatoryData("visitPage", "City"), "OK");
    } else if (values.state_id == 0 && isStateEnable) {
      context.setUser(0, "Error", getMandatoryData("visitPage", "state"), "OK");
    } else if (values.Zip_Code.trim() == "") {
      context.setUser(
        0,
        "Error",
        getMandatoryData("visitPage", "zip_code"),
        "OK"
      );
    } else if (
      values.RemotePatient_YN == true &&
      values.PhoneNumber.trim() == ""
    ) {
      context.setUser(
        0,
        "Error",
        "Please enter Remote Patient Phone Number",
        "OK"
      );
    } else {
      // if (values.Test_Result_File_Ids == "") {
      if (getTestResultFileIds() == "") {
        context.setUser(
          1,
          "Document's Info",
          "Scanned copy of all Test results are not uploaded. Do you want to continue to next screen?",
          "OK",
          true,
          "Cancel",
          () => {},
          () => {
            createVisit();
          }
        );
      } else {
        {
          createVisit();
        }
      }
    }

    function createVisit() {
      setloading(true);
      setLanguageId(values.Language_Id);
      var ssnnumber = values.ssn.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3");
      var NRPhoneNo = code + "-" + values.NR_PhoneNumber;
      var data = values;
      data.date_of_birth = new Date(values.date_of_birth);
      data.ssn = ssnnumber;
      data.NR_PhoneNumber = NRPhoneNo;
      data.Test_Result_File_Ids = getTestResultFileIds();
      data.IDCard_File_IDs = getUploadIds();
      data.MQR_File_Ids = getMQRIds();

      data.HR_Email =
        sherwinText == "" ? values.HR_Email : values.HR_Email + sherwinText;
      if (values.RemotePatient_YN == true && !values.PhoneNumber == "") {
        var phoneNo = code + "-" + values.PhoneNumber;
        data.PhoneNumber = phoneNo;
      }
      if (Branch_YN == false) {
        data.branch_id = 0;
      }
      if (State_YN == false) {
        data.state_id = 0;
      }
      if (values.parentvisit_id > 0) {
        if (values.parentvisit_id == values.visit_id) {

          if(searchURL){
            data.followup_parentvisitId = values.visit_id;
          }     
        } else {
          console.log("1","2");

          if (followupvisit_CompletedYN == true) {
            console.log("1","3");

            data.followup_parentvisitId = values.visit_id;
          } else {
            console.log("1","4");

            data.followup_visitId = values.visit_id;
          }
        }
      }

      if (
        getVisitType() == "Future" &&
        getFutureVisitFromSMDashboard() == "False"
      ) {
        console.log("Future " + JSON.stringify(data));
        postRequest(
          "ws_webrtc/Telemed.asmx/CreateFutureVisit",
          { sKey: getUser(), oFormFS: data },
          (response) => {
            console.log(response.d.key);
            setloading(false);
            if (createVisitText == "Update Future Visit") {
              var array = [
                "Future Visit updated successfully for",
                data.first_name + " " + data.last_name,
                " ",
              ];
            } else {
              var array = [
                "Future Visit created successfully for",
                data.first_name + " " + data.last_name,
                " ",
              ];
            }
            context.setUser(
              2,
              "Success",
              array,
              "OK",
              false,
              "OK",
              null,
              () => {
                navigate("/App/FutureDashboard");
              }
            );
          },
          context
        );
      } else {
        const paramas = data;
        if (createParentvisit_id > 0) {
          paramas.date_of_birth = moment(
            new Date(paramas.date_of_birth)
          ).format("DD/MM/yyyy");
        } else {
          setValues({ date_of_birth: new Date(data.date_of_birth) });
        }

        console.log("Hello ++++++++++++++++>>>", paramas);

        postRequest(
          "ws_webrtc/Telemed.asmx/createNewVisit",
          { sKey: getUser(), oFormFS: paramas },
          (response) => {
            //console.log(response.d);
            console.log(response.d.key);
            console.log("testing for date of birth =====>", data.date_of_birth);
            updateCreateVisitLog("Visit created with VisitID" + response.d.key);
            if (getVisitId() > 0) {
              getPreviousDoctor(response.d.key, data);
              setVisitId(response.d.key);

            } else {
              setloading(false);
              setVisitId(response.d.key);

              //  navigate('/App/SelectProvider');
              console.log(data.PurposeOfVisit_Id);
              if (data.PurposeOfVisit_Id == 2) {
                //setLoading(true)
                postRequest(
                  "ws_webrtc/Telemed.asmx/InsertProviderForVisitLog",
                  { sKey: getUser(), iVisitId: response.d.key, iDocId: 0 },
                  (response) => {
                    setDoctorId(0);
                    setDoctorName("Next Available");
                    //      setLoading(false)
                    navigate("/App/SMCallRoom");
                  },
                  context
                );
              } else {
                if (
                  getMandatoryData(
                    "visitPage",
                    "isSelectaProviderScreenVisible"
                  )
                ) {
                  isproviderEngaged();
                } else {
                  navigate("/App/SelectProvider");
                }
              }
            }
          },
          context
        );
      }
      setTestResultFileIds("");
      setMQRIds("");
      setUploadIds("");
    }
  };

  function getSSNValidation(value) {
    setloading(false);
    console.log("1393i848", getUser(), value);
    postRequest(
      "ws_webrtc/Util.asmx/GetSSNValidation_YN",
      { sKey: getUser(), sSSN: value },
      (response) => {
        // debugger;
        setloading(true);

        if (response.d.status) {
          context.setUser(0, "Error", response.d.message, "OK");
          setSSNValidation(response.d.status);
          setloading(false);
        } else {
          setSSNValidation(false);
        }
        setloading(false);
      },
      context
    );
  }

  function getPreviousDoctor(new_visit_id, data) {
    postRequest(
      "ws_webrtc/telemed.asmx/getProviderForPrevVisit_New",
      { sKey: getUser(), iVisitId: getVisitId() },
      (response) => {
        console.log("Previous Visit ", JSON.stringify(response.d));
        var docId = response.d.doc_id;
        var docName = response.d.doc_name;
        var inConference = response.d.IsInConference;
        var inCheckout = response.d.IsInCheckout;
        setloading(false);
        if (docId == "0") {
          setVisitId(new_visit_id);
          console.log("provider1");
          // navigate('/App/SelectProvider');
          console.log(data.PurposeOfVisit_Id);
          if (data.PurposeOfVisit_Id == 2) {
            //setLoading(true)
            postRequest(
              "ws_webrtc/Telemed.asmx/InsertProviderForVisitLog",
              { sKey: getUser(), iVisitId: getVisitId(), iDocId: 0 },
              (response) => {
                setDoctorId(0);
                setDoctorName("Next Available");
                //      setLoading(false)
                navigate("/App/SMCallRoom");
              },
              context
            );
          } else {
            if (
              getMandatoryData("visitPage", "isSelectaProviderScreenVisible")
            ) {
              isproviderEngaged();
            } else {
              navigate("/App/SelectProvider");
            }
          }
        } else {
          showPreviousDocALert(
            docName,
            docId,
            new_visit_id,
            inConference,
            inCheckout
          );
        }
      },
      context
    );
  }

  function showPreviousDocALert(
    doc_name,
    doc_id,
    new_visit_id,
    in_conference,
    in_checkout
  ) {
    var array = [
      "Initial visit was attended by ",
      doc_name,
      " Do you want the same provider for this call?",
    ];
    context.setUser(
      1,
      "Info",
      array,
      "Yes",
      true,
      "No",
      () => {
        updateCreateVisitLog(
          "Initial visit was attended by " +
            doc_name +
            " Do you want the same provider for this call?- No"
        );
        setVisitId(new_visit_id);
        console.log("provider2");
        //navigate('/App/SelectProvider');
        if (getMandatoryData("visitPage", "isSelectaProviderScreenVisible")) {
          isproviderEngaged();
        } else {
          navigate("/App/SelectProvider");
        }
      },
      () => {
        //    selectedPreviousDoctor(in_conference, in_checkout, new_visit_id, doc_id, doc_name)
        //  context.setUser(1, "Info", getMandatoryData("select_provider","all_engaged_message"), getMandatoryData("select_provider","all_engaged_btn"), false, "", "", () => { navigate('/App/Dashboard') });
        if (getMandatoryData("visitPage", "isSelectaProviderScreenVisible")) {
          isproviderEngaged();
        } else {
          navigate("/App/SelectProvider");
        }
      }
    );
  }

  function getdocCheckoutStatus() {
    postRequest(
      "ws_webrtc/Telemed.asmx/IsProviderCheckoutState",
      { sKey: getUser(), iVisitId: getVisitId() },

      (response) => {
        console.log(
          "get Doc check oout status",
          response.d.IsInCheckout + "" + response.d.IsInConference
        );

        if (response.d.IsInCheckout || response.d.IsInConference) {
          context.setUser(
            1,
            "Info",
            getMandatoryData("select_provider", "all_engaged_message"),
            getMandatoryData("select_provider", "all_engaged_btn"),
            false,
            "",
            "",
            () => {
              navigate("/App/Dashboard");
              notifyEmail();
            }
          );

          //      context.setUser(1, "Info", getMandatoryData("select_provider","all_engaged_message"), getMandatoryData("select_provider","all_engaged_btn"),navigate('/App/Dashboard'));
        } else if (!response.d.IsInCheckout && !response.d.IsInConference) {
          getStateSpecific();
        }
      },
      context
    );
  }
  function isproviderEngaged() {
    console.log("hello for region counter", getVisitId(), getUser());
    postRequest(
      "ws_webrtc/telemed.asmx/IsAllTelemedProvidersEngaged",
      { sKey: getUser(), iVisitId: getVisitId() },
      (response) => {
        console.log("get Doc status", response.d);

        if (response.d) {
          context.setUser(
            1,
            "Info",
            getMandatoryData("select_provider", "all_engaged_message"),
            getMandatoryData("select_provider", "all_engaged_btn"),
            false,
            "",
            "",
            () => {
              notifyEmail();
              navigate("/App/Dashboard");
            }
          );

          //      context.setUser(1, "Info", getMandatoryData("select_provider","all_engaged_message"), getMandatoryData("select_provider","all_engaged_btn"),navigate('/App/Dashboard'));
        } else {
          getdocCheckoutStatus();
        }
      },
      context
    );
  }
  function getStateSpecific() {
    postRequest(
      "ws_webrtc/Telemed.asmx/getStateSpecificProvider",
      { sKey: getUser(), iVisitId: getVisitId() },
      (response) => {
        console.log(response.d.Doc_ID, "get Doc ID");
        insertDoc(response.d);
        setDoctorId(response.d.Doc_ID);
      },
      context
    );
  }
  function insertDoc(item) {
    //setLoading(true)
    console.log(item);
    postRequest(
      "ws_webrtc/Telemed.asmx/InsertProviderForVisitLog",
      { sKey: getUser(), iVisitId: getVisitId(), iDocId: item.Doc_ID },
      (response) => {
        setDoctorId(item.Doc_ID);

        console.log("docID", getDoctorId());

        //     setDoctorId(selectedOption.value == undefined ? 0 : selectedOption.value)
        //    setDoctorName(selectedOption.label == undefined ? 'Next Available' : selectedOption.label)
        // setLoading(false)
        if (response.d) {
          navigate("/App/SMCallRoom");
        }
      },
      context
    );
  }

  function selectedPreviousDoctor(
    IsInConference,
    IsInCheckout,
    new_visit_id,
    doc_id,
    doc_name
  ) {
    updateCreateVisitLog(
      "Initial visit was attended by " +
        doc_name +
        " Do you want the same provider for this call?- Yes"
    );
    if (IsInConference) {
      var array = [
        "Provider ",
        doc_name,
        " is not available. Please select OK for the next available provider",
      ];
      context.setUser(
        1,
        "Info",
        array,
        "Cancel",
        true,
        "OK",
        () => {
          updateCreateVisitLog(
            doc_name + "Provider in conference , OK Clicked"
          );
          setVisitId(new_visit_id);
          navigate("/App/SMCallRoom");
        },
        () => {
          updateCreateVisitLog(
            doc_name + "Provider in conference , Cancel Clicked"
          );
          setVisitId(new_visit_id);
          abortFollowUpVisit();
        }
      );
    } else if (IsInCheckout) {
      var array = [
        "Provider ",
        doc_name,
        " is in check out. Please select OK for the next available provider",
      ];
      context.setUser(
        1,
        "Info",
        array,
        "Cancel",
        true,
        "OK",
        () => {
          updateCreateVisitLog(doc_name + "Provider in checkout , OK Clicked");
          setVisitId(new_visit_id);
          navigate("/App/SMCallRoom");
        },
        () => {
          updateCreateVisitLog(
            doc_name + "Provider in checkout , Cancel Clicked"
          );
          setVisitId(new_visit_id);
          abortFollowUpVisit();
        }
      );
    } else {
      setVisitId(new_visit_id);
      insertProviderForVisit(doc_id);
      setDoctorName(doc_name);
      setDoctorId(doc_id);
    }
  }

  function insertProviderForVisit(doc_id) {
    setloading(true);
    postRequest(
      "ws_webrtc/telemed.asmx/InsertProviderForVisitLog",
      { sKey: getUser(), iVisitId: getVisitId(), iDocId: doc_id },
      (response) => {
        setloading(false);
        console.log("Previous Visit ", JSON.stringify(response));
        if (response.d) {
          navigate("/App/SMCallRoom");
        }
      },
      context
    );
  }

  function abortFollowUpVisit() {
    setloading(true);
    postRequest(
      "ws_webrtc/WebRTC.asmx/cancelRTCSession",
      { sKey: getUser(), iVisitId: getVisitId() },
      (response) => {
        setloading(false);
        console.log("Abort Visit ", JSON.stringify(response));
        navigate("/App/Dashboard");
      },
      context
    );
  }

  function updateCreateVisitLog(data) {
    console.log("updateCreateVisitLog ===>>>>>>>", data);
    setloading(true);
    postRequest(
      "ws_webrtc/Telemed.asmx/InsertTelemedCreateVisitLog",
      { sKey: getUser(), sLog_Detail: data },
      (response) => {
        setloading(false);
        console.log("Abort Visit ", JSON.stringify(response));
      },
      context
    );
  }

  function notifyEmail(action) {
    postRequest(
      "ws_webrtc/Telemed.asmx/SendEmailToTelemedProviders",
      {
        sKey: getUser(),
        iVisitId: getVisitId(),
      },
      (response) => {
        console.log(response);
        // navigate(
        //   getVisitType() == "SR" ? "/App/SRDashboard" : "/App/Dashboard"
        // );
        // action.endCall();
      },
      context
    );
  }

  // function notifyVisit(action) {
  //   if (action) {
  //     const url = "ws_webrtc/Telemed.asmx/SendEmailToTelemedProviders";
  //     return url;
  //   } else {
  //     const url = "ws_webrtc/Telemed.asmx/SendEmailToTelemedProviders";
  //     return url;
  //   }
  // }

  return (
    <>
      <div className="h-100 col-12 p-2">
        <div className="row">
          <div className="col-md-12 col-lg-12 col-xs-12 col-sm-12">
            <div className="row">
              <div className="col-md-12 col-lg-12 col-xs-12 col-sm-12">
                
                {iscreateVisit&& (
                  <>
                    {getVisitType() !== "Future" && (
                      <div className="contentbody" style={{ marginBottom: 10 }}>
                        <div className="row px-3">
                          <div style={{ height: 10 }}></div>
                          <div style={{ display: "flex" }}>
                            <label
                              style={{
                                marginRight: "10px",
                                fontWeight: "bold",
                              }}
                            >
                              Follow Up Visit:
                            </label>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 20,
                              }}
                            >
                              {["Yes", "No"].map((label) => (
                                <label key={label} className="custom-radio">
                                  <input
                                    style={{
                                      marginRight: "25px",
                                      fontWeight: "bold",
                                      accentColor: "green",
                                      fontSize: 10,
                                      marginRight: 5,
                                    }}
                                    type="radio"
                                    value={label}
                                    defaultChecked={label === "No"}
                                    checked={
                                      (label === "Yes" && searchURL) ||
                                      (label === "No" && !searchURL)
                                    }
                                    onChange={() =>
                                      handleFieldChangeSelectRadio(label)
                                    }
                                    onMouseEnter={(e) =>
                                      (e.target.style.color = "green")
                                    }
                                    onMouseLeave={(e) =>
                                      (e.target.style.color = "green")
                                    }
                                  />
                                  <span
                                    className="custom-radio-mark"
                                    style={{ borderColor: "green" }}
                                  ></span>
                                  {label}
                                </label>
                              ))}
                            </div>
                          </div>
                          <div>
                            <p
                              style={{
                                // fontSize: "20px",
                                margin: "0",
                                fontStyle: "italic",
                              }}
                            >
                              <i>
                                (Please select "Yes" only if creating the visit
                                for Follow Up)
                              </i>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="contentbody">
                      <SubHeader headerName="Patient Search" />
                      <div className="row px-3">
                        <SearchView
                          heading="Search Visit History"
                          placeholder={"Search by Patient Name"}
                          url={
                            searchURL
                              ? "ws_webrtc/Telemed.asmx/GetSMCreateVisitPatientsFollowupVisit"
                              : "ws_webrtc/Util.asmx/GetSMCreateVisitPatientsFutureVisit"
                          }
                          handleClick={patientSelected}
                          showssn={true}
                          fromCreateVisit={true}
                          noCLick={clearData}
                          clearVisits={() => {
                            console.log("Clear Visits called");
                          }}
                        ></SearchView>
                        <div style={{ height: 10 }}></div>
                      </div>
                    </div>
                  </>
                )}
                {showVisitHistory && (
                  <div
                    onClick={() => {
                      showVisitHistoryData();
                    }}
                    style={{
                      color: "#ff0000",
                      fontWeight: "bold",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    VISIT HISTORY
                  </div>
                )}
                <div style={{ marginBottom: 70 }}>
                  <CustomDivComponent
                    data={
                      getVisitType() == "Future"
                        ? formData.PatientInfoFuture
                        : formData.PatientInfo
                    }
                    visitTypes={visitTypes}
                    drugValue={drugTypes}
                    jobCategoryValue={jobCategoryArray}
                    visitCategoryTypeData={visitTypes}
                    purposeValue={purposeTypes}
                    countryValue={countries}
                    branchValue={branches}
                    stateValue={states}
                    languageValue={languages}
                    timeValue={time}
                    hideControl={hideControl}
                    hideClaim={hideClaim}
                    hideMQR={hideMQR}
                    hideJC={hideJC}
                    loadSSNDocuemnts={loadSSNDocuemnts}
                    onFieldChange={handleFieldChange}
                    loadValues={values}
                    datePickerChange={handleDateChange}
                    remotePhoneNo={values.PhoneNumber}
                    remotePatientYN={isRemotePatient}
                    isRadioBtnChecked={isChecked}
                    countryAbbr={abbr}
                    sherwinText={sherwinText}
                    documentsVisitID={documentsVisitID}
                    isClaimEnabled={claimEnable}
                    //for patient email
                    isShowPatientEmail={showPatientField}
                    //trail for job and region
                    //for new case
                    jobTitleValue={jobTitle}
                    regionValue={region}
                    ///for test case
                    hideRegion={hideRegion} // hideRegion = "region_id"
                    hideRegionValue={hideRegionValue}
                    unitedRentals={unitedRentals}
                    // parentId_Region={createParentvisit_id}
                    isShowAdditionalEmail={showAdditionalEmail}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="fixed-bottom">
          <div className="col-md-12 col-lg-12 col-xs-12 col-sm-12 d-flex justify-content-center">
            <div className="col-md-6 col-lg-6 col-xs-6 col-sm-6 bg-white d-flex justify-content-center">
              {/* {console.log("hello angel", isButtonDisabled)} */}
              <button
                onClick={validate}
                disabled={isButtonDisabled}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  fontSize: 16,
                  borderRadius: 9,
                  paddingRight: 30,
                  paddingLeft: 30,
                  paddingTop: 10,
                  paddingBottom: 10,
                  fontWeight: "bold",
                  color: "#ffffff",
                  backgroundColor: buttonBgColor ?"#D92A28" :"#808080", // Use the dynamic background color
                  border: "#ffffff",
                }}
                variant="primary"
                type="submit"
              >
                {createVisitText}
              </button>
            </div>
          </div>
        </div>

        {loading && (
          <div
            style={{
              position: "fixed",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ReactLoading
              type={"spin"}
              color={"#ff0000"}
              height={"5%"}
              width={"5%"}
            />
          </div>
        )}
      </div>
      {isOpen && (
        <Modal
          show={true}
          onHide={() => {
            handleShowDialog();
          }}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <VisitHistory />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
export default CreateVisit;
