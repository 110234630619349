import React, {
  Component,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import UserContext from "../components/context";
import Spinner from "react-spinner";
import classNames from "classnames";
import {
  postRequest,
  getUser,
  getVisitId,
  setVisitId,
  getEmail,
  getPassword,
  getVisitType,
  getLanguageId,
} from "../Services/DataMode";
import toast, { Toaster } from "react-hot-toast";

import AccCore from "opentok-accelerator-core";
import "opentok-solutions-css";
import { browserName, browserVersion, osName } from "react-device-detect";
import demo_1 from "../videos/demo_1.mp4";
import demo_2 from "../videos/demo_2.mp4";
import demo_3 from "../videos/demo_3.mp4";
import demo_4 from "../videos/demo_4.mp4";
import demo_5 from "../videos/demo_5.mp4";
import video_1 from "../videos/1.mp4";
import video_2 from "../videos/2.mp4";
import Mic from "../Images/speaker.png";
import MuteMic from "../Images/forbidden.png";
import cameraFront from "../Images/cameraFront.png";
import cameraBack from "../Images/cameraBack.png";

import AnswerPopup from "../components/AnswerPopup";
import SpeechRecognition from "./speechKit";
import TextSpeech from "./textSpeech";
import VideosLoop from "./videosLoop";

// import logo from './logo.svg';
// import config from './config.json';
import "../App.css";
import song from "../audios/ringtone.mp3";

let otCore;

/**
 * Build classes for container elements based on state
 * @param {Object} state
 */
const containerClasses = (state) => {
  const { active, meta, localAudioEnabled, localVideoEnabled } = state;
  const sharingScreen = meta ? !!meta.publisher.screen : false;
  const viewingSharedScreen = meta ? meta.subscriber.screen : false;
  const activeCameraSubscribers = meta ? meta.subscriber.camera : 0;
  const activeCameraSubscribersGt2 = activeCameraSubscribers > 2;
  const activeCameraSubscribersOdd = activeCameraSubscribers % 2;
  const screenshareActive = viewingSharedScreen || sharingScreen;
  return {
    controlClass: classNames("App-control-container", { hidden: !active }),
    localAudioClass: classNames("ots-video-control circle audio", {
      hidden: !active,
      muted: !localAudioEnabled,
    }),
    localVideoClass: classNames("ots-video-control circle video", {
      hidden: !active,
      muted: !localVideoEnabled,
    }),
    localCallClass: classNames("ots-video-control end-call", {
      hidden: !active,
    }),
    cameraPublisherClass: classNames("video-container, visiblePublisher", {
      hidden: !active,
      small: !!activeCameraSubscribers || screenshareActive,
      left: screenshareActive,
    }),
    screenPublisherClass: classNames("video-container,visiblePublisher", {
      hidden: !active || !sharingScreen,
    }),
    cameraSubscriberClass: classNames(
      "video-container",
      { hidden: false || false },
      { "active-gt2": activeCameraSubscribersGt2 && !screenshareActive },
      { "active-odd": activeCameraSubscribersOdd && !screenshareActive },
      { small: screenshareActive }
    ),
    screenSubscriberClass: classNames("video-container", {
      hidden: !viewingSharedScreen || !active,
    }),
  };
};

const connectingMask = () => (
  <div className="App-mask">
    <Spinner />
    <div className="message with-spinner">Connecting</div>
  </div>
);

const startCallMask = (start) => (
  <div
    style={{
      zIndex: 2,
      width: "100%",
      height: "100%",
      backgroundColor: "black",
    }}
    className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
  >
    <video controls autoPlay loop muted width="100%" height="100%">
      <source src={video_1} type="video/mp4" />
      {/* <source src={demo_1} type="video/mp4" /> 
    <source src={demo_2} type="video/mp4" />
    <source src={demo_3} type="video/mp4" />
    <source src={demo_4} type="video/mp4" />
    <source src={demo_5} type="video/mp4" /> */}
    </video>
  </div>
);

const context = () => useContext(UserContext);

class SMCallRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connected: false,
      active: false,
      publishers: null,
      subscribers: null,
      meta: null,
      localAudioEnabled: true,
      localVideoEnabled: true,
      remoteAudioEnable: true,
      showNotifyRemotePatient: false,
      showConnectToDoctor: false,
      playVideo: true,
      isFollowupVisit: false,
      showAnswerPopUp: false,
      connectionText: "Please wait",
      disableNotifyPatient: false,
      disableconnectTodoctor: false,
      doctorName: "",
      audio: new Audio(song),
      isPlaying: false,
      pleaseWait: false,
      screenShare: false,
      hidePublisher: false,
      showDisconnectFromVisit: false,
      currentExamMH: false,
      isdoctorconnected: false,
      isVisitComplete: false,
      showErrorPopup: true,
      providertransData: "",
      smtransData: "",
      providerLangCode: "",
      SMLangCode: "",
      listen: true,
      cameraFace: true,
      translatorState: false,
      isSubscriberVisible: false,
      events: [
        "subscribeToCamera",
        "unsubscribeFromCamera",
        "subscribeToScreen",
        "unsubscribeFromScreen",
        "startScreenShare",
        "endScreenShare",
      ],
    };

    this.updateParentState = this.updateParentState(this);
    this.startCall = this.startVideoCall.bind(this);
    this.endCall = this.endCall.bind(this);
    this.answerCall = this.answerCall.bind(this);
    this.toggleLocalAudio = this.toggleLocalAudio.bind(this);
    this.toggleLocalVideo = this.toggleLocalVideo.bind(this);
    this.notifyRemotePatient = this.notifyRemotePatient.bind(this);
    this.connectToDoctor = this.connectToDoctor.bind(this);
    this.toggleSubscriberAudioVideo = this.toggleSubscriberAudioVideo.bind(
      this
    );
    // this.navigationHelper = this.navigationHelper.bind(this);
    this.myRef = React.createRef();
    this.muteSession = this.muteSession.bind(this);
    this.flipCamera = this.flipCamera.bind(this);
    this.props.updatePage();
  }

  playPause = () => {
    // Get state of song
    let isPlaying = this.state.isPlaying;

    if (isPlaying) {
      // Pause the song if it is playing
      this.state.audio.pause();
    } else {
      // Play the song if it is paused
      this.state.audio.play();
    }

    // Change the state of song
    this.setState({ isPlaying: !isPlaying });
  };

  componentDidMount() {
    //setVisitId("1838633");
    //console.log("visit_id-->"+getVisitId());
    this.createSession();
  }

  updateParentState(key, value) {
    this.setState({ [key]: value });
  }

  startVideoCall() {
    console.log(otCore, "Ot core At start call");
    otCore
      .startCall()
      .then(({ publishers, subscribers, meta }) => {
        this.setState({ publishers, subscribers, meta, active: true });
      })
      .catch((error) => console.log(error));
  }

  async endCall() {
    console.log(otCore, "Ot core At end call");
    this.props.timeStopHelper("stop");
    if (otCore) {
      try {
        await otCore.endCall();
        console.log("Call ended successfully");
      } catch (error) {
        console.error("Error ending call:", error);
      }
    }
    this.setState({ active: false });
    console.log("after");
    postRequest(
      "ws_webrtc/telemed.asmx/InsertProviderForVisitLog",
      { sKey: getUser(), iVisitId: getVisitId(), iDocId: 0 },
      (response) => {
        if (response.d) {
          postRequest(
            "ws_webrtc/Telemed.asmx/getVisitDetails",
            { sKey: getUser(), iVisitId: getVisitId() },
            (response) => {
              if (response.d.parentvisit_id > 0) {
                this.props.navigationHelper("end_followup_call");
              } else {
                this.props.navigationHelper("end_initial_call");
              }
            },
            context
          );
        }
      },
      context
    );
  }
  muteSession() {
    if (this.state.listen) {
      this.toggleRemoteAudio(false);
      this.setState({ listen: !this.state.listen });
    } else {
      this.toggleRemoteAudio(true);
      this.setState({ listen: true });
    }
  }
  flipCamera() {
    if (this.state.cameraFace) {
      this.setState({ cameraFace: !this.state.cameraFace });
    } else {
      this.setState({ cameraFace: true });
    }
  }

  toggleRemoteAudio(enable) {
    console.log("AUD ID " + this.myRef.current.id);
    var childId = this.myRef.current.children;
    for (var i = 0; i < childId.length; i++) {
      console.log("Child Id " + childId[i].id);
      otCore.toggleRemoteAudio(childId[i].id, enable);
    }
  }

  answerCall() {
    this.setState({ showDisconnectFromVisit: true });
    this.setState({ playVideo: false });
    this.setState({ connectionText: this.state.doctorName });
    this.setState({ showAnswerPopUp: false });
    this.setState({ isdoctorconnected: true });
    this.playPause();

    otCore.session.signal(
      {
        data: "hello",
        type: "ACCEPTED",
      },
      function(error) {
        if (error) {
          console.log("signal error (" + error.name + "): " + error.message);
        } else {
          console.log("signal sent.");
          //  this.setState({ isSubscriberVisible: true })
        }
      }
    );
  }

  toggleLocalAudio() {
    otCore.toggleLocalAudio(!this.state.localAudioEnabled);
    this.setState({ localAudioEnabled: !this.state.localAudioEnabled });
  }
  toggleSubAudio() {
    otCore.toggleRemoteAudio(!this.state.remoteAudioEnable);
    this.setState({ remoteAudioEnable: !this.state.remoteAudioEnable });
  }

  toggleLocalVideo() {
    otCore.toggleLocalVideo(!this.state.localVideoEnabled);
    this.setState({ localVideoEnabled: !this.state.localVideoEnabled });
  }

  toggleSubscriberAudioVideo(enable) {
    console.log("AUD ID " + this.myRef.current.id);
    var childId = this.myRef.current.children;
    for (var i = 0; i < childId.length; i++) {
      console.log("Child Id " + childId[i].id);
      otCore.toggleRemoteAudio(childId[i].id, enable);
      otCore.toggleRemoteVideo(childId[i].id, enable);
    }
  }

  setNotificationTimeout(delay) {
    return new Promise((resolve) => setTimeout(resolve, delay));
  }

  isRemotePatient() {
    postRequest(
      "ws_webrtc/Telemed.asmx/IsVisitForRemotePatient",
      { sKey: getUser(), sVisitID: getVisitId() },
      (response) => {
        //console.log("Remote" + response.d);
        //console.log("Remote Status " + response.d.status);
        if (response.d.status) {
          this.setState({ showNotifyRemotePatient: response.d.status });
          //console.log("VisitForRemote-True " + this.state.showNotifyRemotePatient);
        } else {
          this.setState({ showNotifyRemotePatient: response.d.status });
          this.notifyVisit();
          //console.log("VisitForRemote-False " + this.state.showNotifyRemotePatient);
        }
      },
      context
    );
  }

  notifyRemotePatient() {
    this.setState({ disableNotifyPatient: true });
    postRequest(
      "ws_webrtc/Telemed.asmx/SendNotificationToRemotePatient",
      { sKey: getUser(), sVisitID: getVisitId() },
      (response) => {
        //console.log(response.d);
        if (response.d.status) {
        } else {
          this.props.inValidRemoteNumber(response.d.message);
          // otCore.disconnect();
          otCore.endCall();
          //alert(response.d.message);
        }
      },
      context
    );
  }

  connectToDoctor() {
    this.setState({ disableconnectTodoctor: true });

    postRequest(
      "ws_webrtc/Telemed.asmx/telemed_OutOfRoom_Time_Get",
      { sKey: getUser(), sVisitID: getVisitId() },
      (response) => {
        //console.log(response.d);
      },
      context
    );

    postRequest(
      "ws_webrtc/util.asmx/UpdateConnectDoctorClickedOn",
      { sKey: getUser(), iVisitId: getVisitId() },
      (response) => {
        //console.log(response.d);
      },
      context
    );

    // postRequest('ws_webrtc/Util.asmx/notifyANewVisit',
    //   { firebasetoken: '', sKey: getUser(), iVisitId: getVisitId() },
    //   (response) => {
    //     //console.log(response.d);
    //   }, context)

    this.notifyVisit();
  }

  notifyVisit() {
    postRequest(
      getVisitType() == "SR"
        ? "ws_webrtc/util.asmx/notifyANewSRVisit"
        : "ws_webrtc/Util.asmx/notifyANewVisit",
      { firebasetoken: "", sKey: getUser(), iVisitId: getVisitId() },
      async (response) => {
        this.props.timeStartHelper(otCore);
      },
      context
    );
  }

  createSession() {
    postRequest(
      "ws_webrtc/Telemed.asmx/InsertTelemedCreateVisitLog",
      {
        sLog_Detail: "Emp rep/Global user entered in to room",
        sKey: getUser(),
      },
      (response) => {
        console.log(response.d);
      },
      context
    );

    postRequest(
      "ws_webrtc/Telemed.asmx/getVisitDetails",
      { sKey: getUser(), iVisitId: getVisitId() },
      (response) => {
        console.log(response.d);
        if (response.d.parentvisit_id.length > 0) {
          this.setState({ isFollowupVisit: true });
        } else {
          this.setState({ isFollowupVisit: false });
        }
      },
      context
    );

    postRequest(
      "ws_webrtc/Telemed.asmx/createVisitTracking",
      {
        sKey: getUser(),
        oVTrack: {
          visit_id: getVisitId(),
          device_name: browserName + " " + browserVersion,
          device_os: "Web - " + osName,
          app_version: "1.0",
          user_name: getEmail(),
          password: getPassword(),
          login_status: "Success",
          wifi_name: "",
        },
      },
      (response) => {
        console.log("Visit Tracking " + JSON.stringify(response.d));
      },
      null
    );

    this.isRemotePatient();

    // postRequest('Telemed.asmx/IsVisitForRemotePatient',
    //   { sKey: getUser(),  },
    //   (response) => {
    //     console.log(response.d);
    //     if(response.d.status){

    //     } else{

    //     }
    //   }, null)

    postRequest(
      getVisitType() == "SR"
        ? "ws_webrtc/WebRTC.asmx/createSRRTCSession"
        : "ws_webrtc/WebRTC.asmx/createRTCSession",
      { sKey: getUser(), iVisitId: getVisitId() },
      (response) => {
        //console.log(response.d);

        const otCoreOptions = {
          credentials: {
            apiKey: response.d.ApiKey,
            sessionId: response.d.sessionId,
            token: response.d.Token,
          },
          // A container can either be a query selector or an HTML Element
          streamContainers(pubSub, type, data, stream) {
            return {
              publisher: {
                camera: "#cameraPublisherContainer",
                screen: "#screenPublisherContainer",
              },
              subscriber: {
                camera: "#cameraSubscriberContainer",
                screen: "#screenSubscriberContainer",
              },
            }[pubSub][type];
          },
          controlsContainer: "#controls",
          packages: ["annotation"],
          communication: {
            callProperties: null, // Using default
          },
          textChat: {
            name: ["David", "Paul", "Emma", "George", "Amanda"][
              (Math.random() * 5) | 0
            ], // eslint-disable-line no-bitwise
            waitingMessage:
              "Messages will be delivered when other users arrive",
            container: "#chat",
          },
          // screenSharing: {
          //   extensionID: 'plocfffmbcclpdifaikiikgplfnepkpo',
          //   annotation: true,
          //   externalWindow: false,
          //   dev: true,
          //   screenProperties: {
          //     insertMode: 'append',
          //     width: '50%',
          //     height: '50%',
          //     showControls: false,
          //     style: {
          //       buttonDisplayMode: 'off',
          //     },
          //     videoSource: 'window',
          //     fitMode: 'contain' // Using default
          //   },
          // },
          annotation: {
            absoluteParent: {
              publisher: ".App-video-container",
              subscriber: ".App-video-container",
            },
          },
        };

        otCore = new AccCore(otCoreOptions);
        otCore
          .connect()
          .then(
            () => this.startVideoCall(),
            this.setState({ connected: true })
          );

        otCore.session.on(
          "signal",
          function(event) {
            console.log(event, "at main Session");
            console.log("Signal sent from connection,data " + event.data);
            console.log("Signal sent from connection,type " + event.type);
            // console.log("Signal sent from connection " + event.from.id);
            // Process the event.data property, if there is any data.
            if (event.type == "signal:PING") {
              this.props.timeStopHelper("Stop");
              this.setState({
                connectionText:
                  event.data +
                  " has just logged in. Please accept the connection when he attempts to connect. Thank you.",
              });
              this.setState({ doctorName: event.data });
              otCore.session.signal(
                {
                  data: "hello",
                  type: "ack",
                },
                function(error) {
                  if (error) {
                    console.log(
                      "signal error (" + error.name + "): " + error.message
                    );
                  } else {
                    console.log("signal sent.");
                  }
                }
              );
            } else if (event.type == "signal:CALLING") {
              this.props.timeStopHelper("Stop");
              this.setState({ showAnswerPopUp: true });
              this.setState({ currentExamMH: false });
              this.setState({ playVideo: false });
              this.playPause();
              this.setState({ connectionText: event.data });
            } else if (event.type == "signal:CLOSE") {
              // otCore.disconnect();
              otCore.endCall();
              this.setState({ isVisitComplete: true });
              this.setState({ showErrorPopup: false });
              if (this.state.showErrorPopup) {
                this.props.navigationHelper("close");
              }
              //navigate('/App/Dashboard');
            } else if (event.type == "signal:PROVABORT") {
              // otCore.disconnect();
              otCore.endCall();
              this.setState({ isVisitComplete: true });
              this.setState({ showErrorPopup: false });
              if (this.state.showErrorPopup) {
                this.props.navigationHelper("provider_abort");
              }
            } else if (event.type == "signal:PACK") {
              this.setState({ playVideo: false });
              this.setState({ screenShare: false });
              this.setState({ showConnectToDoctor: true });
              this.setState({ disableconnectTodoctor: false });
              this.setState({ showNotifyRemotePatient: false });
            } else if (event.type == "signal:VIDEOOFF") {
              console.log("video off");
              this.props.navigationHelper("video_off");
              this.toggleLocalVideo();
            } else if (event.type == "signal:VIDEOON") {
              this.props.navigationHelper("video_on");
              this.toggleLocalVideo();
            } else if (event.type == "signal:HOLD_SM" && event.data == "ON") {
              this.setState({ pleaseWait: true });
              this.toggleLocalVideo();
              this.toggleLocalAudio();
              this.toggleSubscriberAudioVideo(false);
            } else if (event.type == "signal:HOLD_SM" && event.data == "OFF") {
              this.setState({ pleaseWait: false });
              this.toggleLocalVideo();
              this.toggleLocalAudio();
              this.toggleSubscriberAudioVideo(true);
            } else if (event.type == "signal:CALL_HOLD" && event.data == "ON") {
              this.setState({ pleaseWait: true });
              this.toggleLocalVideo();
              this.toggleLocalAudio();
            } else if (
              event.type == "signal:CALL_HOLD" &&
              event.data == "OFF"
            ) {
              this.setState({ pleaseWait: false });
              this.toggleLocalVideo();
              this.toggleLocalAudio();
            } else if (event.type == "signal:SHARE") {
              this.setState({ screenShare: true });
              this.setState({ hidePublisher: true });
            } else if (event.type == "signal:STOPSHARE") {
              this.setState({ screenShare: true });
              this.setState({ hidePublisher: false });
            } else if (event.type == "signal:TRANSLATE") {
              if (
                otCore.session.connection.connectionId ==
                event.from.connectionId
              ) {
              } else {
                this.setState({ providertransData: event.data });
                // this.setState({ smtransData: event.data })
                console.log(this.state.providertransData, "tran data");
              }
            } else if (event.type == "signal:DOCLANGCODE") {
              if (event.data == this.state.SMLangCode) {
                this.setState(!this.state.translatorState);
                console.log(event.data, "EVENT DATA for Provider language");
                console.log("event SM Lang", this.state.SMLangCode);
              } else {
                this.setState({ translatorState: true });
                this.setState({ providerLangCode: event.data });
                console.log(event.data, "EVENT DATA for language");
                console.log("event SM Lang", this.state.SMLangCode);
              }
            } else if (event.type == "signal:CURRENTEXAMMH") {
              this.setState({ currentExamMH: true });
              this.setState({
                connectionText: "Call has been transferred to Provider",
              });
              this.props.timeStartHelper(otCore);
            }
          }.bind(this)
        );

        otCore.session.on(
          "streamDestroyed",
          function(event) {
            !this.state.screenShare &&
              !this.state.isVisitComplete &&
              this.state.showErrorPopup &&
              this.props.navigationHelper("stream_destroyed");
            console.log("Stream stopped. Reason: ");
          }.bind(this)
        );

        otCore.session.on(
          "sessionReconnecting",
          function(event) {
            if (this.state.showErrorPopup) {
              this.props.navigationHelper("session_reconnecting");
            }
          }.bind(this)
        );

        otCore.session.on(
          "sessionDisconnected",
          function(event) {
            console.log("session disconnected ");
            if (this.state.showErrorPopup) {
              if (!this.state.isdoctorconnected) {
                this.props.navigationHelper("session_disconnect_bandwidth");
              } else {
                this.props.navigationHelper("session_disconnect_video");
              }
            }
          }.bind(this)
        );

        this.state.events.forEach((event) =>
          otCore.on(event, ({ publishers, subscribers, meta }) => {
            this.setState({ publishers, subscribers, meta });
          })
        );

        //  otCore.on("PROVABORT", function(event) {
        //   console.log("Signal sent from connection " + event.from.id);
        //   // Process the event.data property, if there is any data.
        //  });
      },
      context
    );

    postRequest(
      "ws_webrtc/telemed.asmx/GetLanguaugeDetails",
      { sKey: getUser(), iLanguageId: getLanguageId() },
      (response) => {
        console.log(response.d.Language_Code, "at respnse");

        this.setState({ SMLangCode: response.d.Language_Code });
        console.log(this.state.SMLangCode, "sm lang");
      },
      context
    );
  }

  render() {
    const { connected, active } = this.state;
    const {
      localAudioClass,
      localVideoClass,
      localCallClass,
      controlClass,
      cameraPublisherClass,
      screenPublisherClass,
      cameraSubscriberClass,
      screenSubscriberClass,
    } = containerClasses(this.state);

    return (
      <div className="h-100">
        <div style={{ width: "100%", height: "50%", backgroundColor: "black" }}>
          <div className="App-video-container col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
            {this.state.showAnswerPopUp && (
              <div
                style={{
                  position: "absolute",
                  zIndex: 4,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "black",
                }}
              ></div>
            )}
            {this.state.pleaseWait && (
              <div
                style={{
                  position: "absolute",
                  zIndex: 4,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "black",
                }}
              >
                <text
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    display: "flex",
                    color: "#ffffff",
                    width: "100%",
                    height: "100%",
                    fontSize: 30,
                  }}
                >
                  Please wait in session
                </text>
              </div>
            )}
            {this.state.showConnectToDoctor == false &&
              this.state.currentExamMH && (
                <>
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 4,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "black",
                    }}
                  >
                    <text
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        display: "flex",
                        color: "#ffffff",
                        width: "100%",
                        height: "100%",
                        fontSize: 30,
                      }}
                    >
                      Please wait in session
                    </text>
                  </div>
                </>
              )}
            <div
              id="cameraPublisherContainer"
              className={(cameraPublisherClass, "smallcontainer")}
            />
            <div
              id="screenPublisherContainer"
              className={
                !this.state.hidePublisher
                  ? (screenPublisherClass, "smallcontainer")
                  : "hiddenPublisher"
              }
            />
            {/* {this.state.isdoctorconnected && (
              <div
                style={{
                  position: "absolute",
                  zIndex: 4,
                  backgroundColor: "black",
                  left: 25,
                  bottom: 10,
                 
                }}
              >

                 <img
                  src={this.state.cameraFace ? cameraFront : cameraBack}
                  style={{ width: "25px", height: "30px", display: "flex" }}
                  onClick={this.flipCamera}
                    > 
                    </img>
               
              </div>
            )} */}
            {this.state.isdoctorconnected && (
              <div
                style={{
                  position: "absolute",
                  zIndex: 4,
                  backgroundColor: this.state.cameraFace ? "" : "black",
                  left: 50,
                  bottom: 10,
                }}
              >
                <img
                  src={this.state.cameraFace ? cameraFront : cameraBack}
                  style={{ width: "35px", height: "35px", display: "flex" }}
                  onClick={this.flipCamera}
                ></img>
              </div>
            )}
            {this.state.isdoctorconnected && this.state.translatorState && (
              <div
                style={{
                  position: "absolute",
                  zIndex: 4,
                  backgroundColor: "black",
                  left: 100,
                  bottom: 10,
                }}
              >
                <img
                  src={this.state.listen ? Mic : MuteMic}
                  style={{ width: "35px", height: "35px", display: "flex" }}
                  onClick={this.muteSession}
                ></img>
              </div>
            )}
            <div
              id="cameraSubscriberContainer"
              ref={this.myRef}
              className={
                !this.state.hidePublisher
                  ? cameraSubscriberClass
                  : "borderblack, hiddenPublisher"
              }
            />

            <div
              id="screenSubscriberContainer"
              className={screenSubscriberClass}
            />
            {/* {this.state.playVideo && startCallMask(this.startCall)} */}
            {this.state.playVideo && (
              <div
                style={{
                  zIndex: 2,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "black",
                }}
                className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
              >
                <VideosLoop />
              </div>
            )}
          </div>
        </div>
        <div
          className="d-flex flex-column align-items-center"
          style={{ width: "100%", height: "50%" }}
        >
          {this.state.isdoctorconnected && this.state.translatorState && (
            <div className=" col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mg-10 ">
              {/* <TextSpeech data={this.state.providertransData} /> */}
              <SpeechRecognition
                data={otCore}
                // textvoice={this.state.smtransData}
                transtext={this.state.providertransData}
                providerCode={this.state.providerLangCode}
                SMCode={this.state.SMLangCode}
                placeholder="Enter Text for Translation"
              />
            </div>
          )}

          <div className="mb-auto" style={{ marginTop: 20, marginBottom: 20 }}>
            <p style={{ color: "#808080" }}> {this.state.connectionText}</p>
          </div>

          <br />
          <div id="controls">
            {/* <div className={localAudioClass} onClick={this.toggleLocalAudio} />
            <div className={localVideoClass} onClick={this.toggleLocalVideo} /> */}

            <div>
              {this.state.showConnectToDoctor &&
                this.state.showDisconnectFromVisit && (
                  <>
                    <button
                      className={localCallClass + " btn"}
                      onClick={() => {
                        this.setState({ showErrorPopup: false });
                        this.props.disconnectFromVisit(otCore);
                      }}
                      style={{ marginBottom: "10px", width: 280, height: 45 }}
                    >
                      {" "}
                      Disconnect from Visit?{" "}
                    </button>
                    <br />
                  </>
                )}
              {this.state.showNotifyRemotePatient && (
                <button
                  className={localCallClass + " btn"}
                  disabled={this.state.disableNotifyPatient}
                  onClick={this.notifyRemotePatient}
                  style={{ marginBottom: "10px", width: 280, height: 45 }}
                >
                  Notify Remote Patient
                </button>
              )}
              {this.state.showConnectToDoctor && (
                <button
                  className={localCallClass + " btn"}
                  disabled={this.state.disableconnectTodoctor}
                  onClick={this.connectToDoctor}
                  style={{ marginBottom: "10px", width: 280, height: 45 }}
                >
                  Connect to Doctor
                </button>
              )}
              {/* {this.state.showConnectToDoctor && <button className="btn" style={{marginBottom:"10px", width: '15%',height:45}} onClick={this.connectToDoctor} >Connect to Doctor</button>} */}
              <br />{" "}
              <button
                className={localCallClass + " btn"}
                disabled={!active || !this.state.playVideo}
                onClick={this.endCall}
                style={{ marginBottom: "10px", width: 280, height: 45 }}
              >
                End Call
              </button>{" "}
            </div>
          </div>
        </div>
        <Toaster position="bottom-center" />
        <AnswerPopup
          show={this.state.showAnswerPopUp}
          onHide={this.answerCall}
          okText={"Answer"}
          data={"Pick up the call from Doctor?"}
        />
      </div>

      // <div className="App col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ marginTop: -10, minHeight: 600 }}>
      //   <div className="App-main col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">

      //   </div>

      //   <AnswerPopup show={this.state.showAnswerPopUp} onHide={this.answerCall} okText={'Answer'} data={'Pick up the call from Doctor?'} />
      // </div>
    );
  }
}

export default SMCallRoom;
