import styles from "./sidenav.module.css"
import { NavLink } from "react-router-dom";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { navData, navInitialGSMData, navGSMData } from "../lib/leftMenuData";
import { getisGlobal } from "../Services/DataMode";
import { useEffect, useState } from "react";
import React, { Component } from 'react';
import DashboardIcon from '../Images/dash_board.png'
import CancelIcon from '../Images/cancel_icon.png';

import { TextField, FormControl, MenuItem, Select, InputLabel, Checkbox, TextareaAutosize, Box, OutlinedInput, InputAdornment, Paper } from '@mui/material';
export default function Sidenav(props) {
    const [open, setopen] = useState(true)
    const [isactive, setActive] = useState()

    const [showData, setShowData] = useState([])

    const toggleOpen = () => {
        setopen(!open)
    }

    useEffect(() => {
        // console.log('menu_open->');
        if (getisGlobal() == "SM"){
            setShowData(navData)
        }else if (getisGlobal() == "GSM"){ 
            setShowData(navGSMData)
        }else{
            setShowData(navInitialGSMData) 
        }

    }, []);

    function closeClicked(e) {
        e.preventDefault();
        props.menuClicked(false);
    }

    const handleClick = (id) => {
        //  alert(document.getElementsByClassName(id));
        var element = document.getElementById(id);

        var cls = element.className;
        var items = document.getElementsByClassName(cls);
        for (var i = 0; i < items.length; i++) {
            // items[i].style.backgroundColor = "#303030";
            // items[i].style.color = "#e3c15d";

        }
        // setActive(element.style.backgroundColor = "#e3c15d");
        //  setActive(element.style.color = "green");transparent
    }
    return (

        <div className={open ? styles.sidenav : styles.sidenavClosed} style={{ backgroundColor: 'transparent', marginRight:2 }} >
            <div className='d-flex'>
                <div >
                    <div style={{ height: '50px', backgroundColor: '#D92A28', width: 250, display: 'flex', justifyContent: 'center', alignContent: 'center', flexDirection: 'column', fontWeight: 'bold', color: 'white' }}>
                        <span></span>
                        <div style={{ display: 'flex',zIndex:1 }}>
                            <div className="col-10 text-center">Menu Items
                            </div>
                            <div className="col-2 right" >
                                <img src={CancelIcon} style={{ width: '25px', height: '25px', flexDirection: 'flex-end',cursor: "pointer" }} className="right" onClick={closeClicked} />
                            </div>
                        </div>
                    </div>
                    <Paper elevation={2} style={{ height: '100vh', width: 250 }}>


                        {showData.map(item => {
                            return <div style={{ marginTop: 5 }} onClick={closeClicked}>
                                <Paper elevation={1} >
                                    <NavLink key={item.id} to={item.link} id={item.id} onClick={() => handleClick(item.id)}
                                        className={styles.sideitem}>

                                        <img src={item.icon} style={{ width: '25px', height: '25px' }}></img>
                                        <span className={styles.linkText}>{item.text}</span>
                                    </NavLink>
                                </Paper>
                            </div>
                            // return <NavLink key={item.id} to={item.link} onClick={() => handleClick(item.id)}
                            //     className={
                            //         styles.sideitem
                            //     } >
                            //     {item.icon}
                            //     <span className={styles.linkText}>{item.text}</span>
                            // </NavLink>
                        })}

                    </Paper>
                </div>

                <div onClick={closeClicked} style={{ height: '100vh', backgroundColor: '#959595', opacity: 0.5, width: '100%' }} />

            </div>
        </div>

    )
}