import React, { useRef, useState, useEffect } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { TextField, InputAdornment } from "@mui/material";
import recording from "../Images/recording_gif.gif";
import Mic from "../Images/newmic.png";
import SendButton from "../Images/send_button.png";
import toast, { Toaster } from "react-hot-toast";
import { useSpeechSynthesis } from "react-speech-kit";

function NewSpeechRecognition(props) {
  const [value, setValue] = useState("");
  const [listen, setListen] = useState(true);
  const [sendButton, setSendButton] = useState(false);
  const [showRecord, setShowRecord] = useState(true);
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const { speak, voices, supported } = useSpeechSynthesis();

  useEffect(() => {
    console.log(
      SpeechRecognition.browserSupportsSpeechRecognition(),
      "speech working"
    );

    console.log(props.transtext,"trans text at speech kit");


    if (props.transtext !== "") {
      toast(props.transtext);
        } else {
    
    }

    speak({ text: props.transtext});
   

    
  }, [props.transtext]);

  const handleListing = () => {

    SpeechRecognition.startListening({
      language: props.SMCode,
    });
    setValue(transcript);

    console.log(props.SMCode,"SM code at handleListening")
    if (listen) {
      setListen(false)
      console.log("transcript " ,transcript);
    } else if (!listen&&value.length > 0) {
        setSendButton(true);
        setShowRecord(false);
      }
  };

  const sendText = () => {
    var url =
      "https://translation.googleapis.com/language/translate/v2?key=AIzaSyAyHbGQIB8fxyJdp865kntv23GgYzrJSPs";
    url += "&target=" + props.providerCode; //"en";
    url += "&q=" + value;

    fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);

        props.data.session.signal(
          {
            data: responseJson.data.translations[0].translatedText,
            type: "TRANSLATE",
          },
          function(error) {
            if (error) {
              console.log(
                "signal error (" + error.name + "): " + error.message
              );
            } else {
              console.log("signal sent.");
            }
          }
        );
      })
      .catch((error) => {
        console.error(error);
      });
    setValue("");
    setShowRecord(true);
    setListen(true);
    setSendButton(false);
  };


  const onChange = (event) => {
    setValue(event.target.value);
    console.log("event.target.value", event.target.value);
    if (event.target.value.length > 0) {
      setSendButton(true);
      setShowRecord(false);
    } else {
      setSendButton(false);
      setShowRecord(true);
    }
  };
  function textDidChange(e) {
    setValue(e.target.value);

    if (value == "") {
      setShowRecord(true);
      setSendButton(false);
    } else {
      setShowRecord(false);
      setSendButton(true);
    }
  }
  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <div className="microphone-wrapper">
      <div className="mircophone-container">
        <div style={{ margin: 10 }}>
          <TextField
            autoComplete="off"
            variant="standard"
            value={value}
            onChange={onChange}
            placeholder={props.placeholder}
            className="col-12 MuiInputBase-input-search"
            color="white"
            style={{
              fontSize: 9,
              backgroundColor: "#00A0EB",
              paddingTop: 10,
              paddingBottom: 10,
              borderRadius: 25,
              cursor: "default",
              paddingLeft: 10,
            }}
            InputProps={{
              disableUnderline: true,
              color: "white",
              sx: {
                color: "white",
              },
              endAdornment: (
                <InputAdornment position="end">
                  {sendButton && (
                    <img
                      src={SendButton}
                      onClick={sendText}
                      style={{ width: "30px", height: "30px", marginRight: 15 }}
                    ></img>
                  )}
                  {showRecord && (
                    <img
                      src={listen ? Mic : recording}
                      style={{ width: "25px", height: "30px", marginRight: 15 }}
                      onClick={handleListing}
                    ></img>
                  )}
                </InputAdornment>
              ),
              color: "white",
            }}
          />
        </div>
      </div>
      <Toaster position="bottom-center" />
    </div>
  );
}
export default NewSpeechRecognition;
