

import { useEffect, useState, useContext, useRef } from "react";
import ReactLoading from 'react-loading';
import React from "react";
import informationIcon from '../Images/information-icon.png'
import CustomDivComponent from "../components/divComponent";
import formData from '../lib/formsData.json'
import { useNavigate } from "react-router-dom";
import { postRequest, getUser, getMandatoryData, setCompanyId, setCompanyName, setBranchName, setBranchId } from "../Services/DataMode";
import context from "react-bootstrap/esm/AccordionContext";
import UserContext from '../components/context';
import Parser from 'html-react-parser';
import SearchIcon from "../Images/search_icon.png";
import SearchList from "../components/SearchList";
import {
    TextField,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Checkbox,
    TextareaAutosize,
    Box,
    OutlinedInput,
    InputAdornment,
} from "@mui/material";

function FutureVisitSearchCompany(props) {

    const timeout = React.useRef(null);
    const [visits, setVisits] = useState([]);
    const [loading, setloading] = useState(false);
    let navigate = useNavigate();
    const [companies, setCompanies] = useState([{ name: 'Select', id: '0' }]);
    const [branches, setbranches] = useState([{ name: 'Select', id: '0' }]);
    const context = useContext(UserContext);
    const [searchedText, setsearchedText] = useState("");
    const [selectedCompany, setSelectedCompany] = useState(0);
    const [selectedBranch, setSelectedBranch] = useState(0);
    const [isShow, setShow] = useState(false);
    const [noCompaniesFound, setNoCompaniesFound] = useState(false);
    useEffect(() => {
        props.updatePage();

    }, [])

    const onOKClick = (e) => {
        console.log("onOKClick")
        if (selectedCompany == 0) {
            context.setUser(0, 'Error', getMandatoryData('global_user', 'company_mandatory'), 'OK')
        } else if (branches.length > 1 && selectedBranch == 0) {
            context.setUser(0, 'Error', getMandatoryData('global_user', 'branch_mandatory'), 'OK')
        } else {
            setCompanyId(selectedCompany)
            setBranchId(selectedBranch)
            navigate('/App/FutureDashboard');
        }
    }
    function getNameByID(array, ID) {
        let obj = array.find(o => { return o.id === ID });
        return obj.name
    }
    function handleFieldChangeBranch(event) {
        var name = event.target.name;
        var value = event.target.value;
        console.log(event.target.name + " --> " + event.target.value)
        console.log(getNameByID(branches,value))
        setBranchName(getNameByID(branches,value))
        setSelectedBranch(event.target.value)
    }
    function onSearchFieldChange(event) {
        setNoCompaniesFound(false)
        var value = event.target.value;
        console.log(event.target.value)
        setsearchedText(event.target.value)
        clearTimeout(timeout.current);
        if (value.length > 2) {
            console.log(value)
            timeout.current = setTimeout(() => {
                setVisits([]);
                setShow(false);
                setloading(true);
                postRequest("ws_webrtc/telemed.asmx/GetEMRTelemedCompaniesForFutureVisits",
                    {
                        sKey: getUser(),
                        sCompanyName: event.target.value
                    },
                    (response) => {

                        setloading(false);
                        setShow(true);
                        if (response.d.length == 0) {
                            setNoCompaniesFound(true)
                        } else {
                            setVisits(response.d);
                        }

                    }, context)
            }, 2000);

        } else {
            setloading(false);
            setVisits([]);
            setShow(false);
        }

    }
    const onCompanySelect = (item) => {
        setVisits([]);
        setShow(false);
        setloading(false);
        console.log(item)

        setsearchedText(item.CompanyName)
        setCompanyName(item.CompanyName)
        setSelectedCompany(item.CompanyId)
        setSelectedBranch(0)
        const branchesArray = []
        branchesArray.push({ id: 0, name: "Select" })
        setbranches(branchesArray);

        if (item.CompanyId != 0) {
            postRequest('ws_webrtc/Telemed.asmx/getTelemedCompanyBranchesPerCompany',
                { sKey: getUser(), iCompanyId: item.CompanyId },
                (response) => {
                    console.log(response.d);
                    const branchesArray = []
                    // branchesArray.push({ id: 0, name: "Select" })
                    for (var i = 0; i < response.d.length; i++) {
                        branchesArray.push({ id: response.d[i].CompanyId, name: response.d[i].CompanyName })
                    }
                    setbranches(branchesArray);
                }, [context])
        }


    }
    return (

        <>
            <div style={{ padding: 30, height: "100%" }}
                className='d-flex align-items-center justify-content-center'>

                <div style={{
                    width: "100%", backgroundColor: "white", borderRadius: 5, alignItems: "center", margin: 10,
                    marginLeft: -2, paddingTop: 20, paddingBottom: 30, borderStyle: "solid", borderWidth: 1, borderColor: "grey", paddingBottom: 14,
                    paddingLeft: 30, paddingRight: 30, paddingTop: 10, paddingLeft: 10, paddingRight: 19,
                }} >
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: -30, }}>
                        <img src={informationIcon} width="35" height="35" />
                    </div>

                    <div style={{ marginTop: 15, textAlign: "center", }} className="col">
                        <label style={{ fontSize: "23px", fontWeight: 'bold', color: 'Grey' }} > Select Company </label>
                    </div>


                    <div className='row'>
                        <div className='col-md-12 col-lg-12 col-sx-12 col-sm-12'>
                            <div style={{ padding: 10 }}>
                                <label for="exampleSelect1" style={{ paddingLeft: 5, fontSize: '16px', fontWeight: 'bold', color: 'grey' }}>{Parser(getMandatoryData('global_user', 'searchLblComany'))}</label>
                                <div style={{ height: 5 }}></div>
                                <div>
                                    {/* <select onChange={handleFieldChangeCompany} className="col-12 required" class="form-select" style={{ background: '#E2E2E2', height: 40 }} >
                                    {

                                        companies != undefined && companies.map((item, i) => (
                                            <option value={item.id}> {item.name}</option>

                                        ))
                                    }
                                     </select> */}
                                    <>
                                        <TextField
                                            variant="standard"
                                            placeholder="Search Company"
                                            id="outlined"
                                            size="small"
                                            value={searchedText}
                                            //   disabled={props.isDisable}
                                            //   defaultValue={props.tag_id}
                                            style={{
                                                fontSize: 9,
                                                backgroundColor: "#E9E9EC",
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                                paddingTop: 10,
                                                paddingBottom: 10,
                                                borderStyle: "none",
                                                borderRadius: 10,
                                                color: "black",
                                            }}
                                            className={"col-12 required"}
                                            onChange={onSearchFieldChange}
                                            //   name={props.tagid}
                                            InputProps={{
                                                disableUnderline: true,
                                                color: "black",
                                                sx: {
                                                    color: "black",
                                                },
                                                startAdornment: (
                                                    <InputAdornment position="end">
                                                        <img
                                                            src={SearchIcon}
                                                            style={{ width: "30px", height: "30px" }}
                                                        ></img>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />

                                    </>
                                    {isShow && <div style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: 500 }} >
                                        <SearchList data={visits} isCompany={true} handleClick={onCompanySelect} />
                                    </div>
                                    }
                                    {noCompaniesFound &&
                                        <text style={{ padding: 10, justifyContent: 'center', alignItems: 'center', alignContent: 'center', display: 'flex' }}>No Companies found</text>
                                    }
                                </div>

                            </div>

                            <div style={{ padding: 10 }}>
                                <label for="exampleSelect1" style={{ paddingLeft: 5, fontSize: '16px', fontWeight: 'bold', color: 'grey' }}>{Parser(getMandatoryData('global_user', 'drpLblbranch'))}</label>
                                <div style={{ height: 5 }}></div>
                                <div>
                                    <select onChange={handleFieldChangeBranch} disabled={branches.length == 1 ? true : false} className="col-12 required" class="form-select" style={{ background: '#E2E2E2', height: 40 }} >
                                        {branches != undefined && branches.map((item, i) => (
                                            <option value={item.id}> {item.name}</option>

                                        ))
                                        }
                                    </select>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12 col-lg-12 col-xs-12 col-sm-12' style={{ padding: 10, justifyContent: 'center', alignItems: 'center', alignContent: 'center', display: 'flex' }}>

                            <button className={"btnNoRadius"} onClick={onOKClick} style={{ paddingTop: 5, paddingBottom: 5, }} >OK</button> &nbsp;&nbsp;

                        </div>

                    </div>


                </div>
            </div>

            {loading && (
                <div style={{
                    position: 'absolute', left: '50%', top: '50%',
                    transform: 'translate(-50%, -50%)', height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                }}>
                    <ReactLoading type={'spin'} color={'#ff0000'} height={'5%'} width={'5%'} />
                </div>
            )}
        </>


    );
}

export default FutureVisitSearchCompany;