import React, { useState ,useEffect,Fragment} from 'react';
import { Paper, TextField } from '@mui/material'
import { Modal } from 'react-bootstrap';
import informationIcon from '../Images/information-icon.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactLoading from 'react-loading';

import 'date-fns';
import Form from 'react-bootstrap/Form'

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import Parser from 'html-react-parser';
import {  getMandatoryData } from "../Services/DataMode";




function ReasonForCancel(props) {
    // const [reasons] = useState([
    //     { name: 'Select', id: '0' },
    //     { name: 'The visit is no longer needed', id: '1' },
    //     { name: 'The visit time does not work', id: '2' },
    //     { name: 'Other', id: '3' },
    // ]);


    const data =props.reasons;
    const [loading, setloading] = useState(true);
    const [startDate, setStartDate] = useState();
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState('');
    const [selectedDate, handleDateChange] = useState(new Date());

    console.log("cancel reasons",data);

    const [selectedReason, setSelectedReason] = useState('0');
    const [comments, setComments] = useState('');
    const [rescheduleDate, setRescheduleDate] = useState(null);

    const handleReasonChange = (event) => {
        setSelectedReason(event.target.value);
    };

    const handleCommentsChange = (event) => {
        setComments(event.target.value);
    };

    const handleRescheduleDateChange = (date) => {
        setRescheduleDate(date);
    };

    useEffect(() => {

        if(props.reasons !==""){
            setloading(false)
        }
      },[props.patientreason])

      const [selectedOption1, setSelectedOption1] = useState(null);
      const [selectedOption2, setSelectedOption2] = useState(null);
      const [selectedBranch, setSelectedBranch] = useState(0);

      function handleFieldChangeBranch(event) {

        var name = event.target.name;
        var value = event.target.value;
        console.log(event.target.name + " --> " + event.target.value)
        setSelectedBranch(event.target.value)
    }
   

    function handleChange(){
        props.onFieldChange();
    }


    function onDateChange(date){
        var value = date;
        console.log("date" + value);
        props.handleDateChange(value);
    }
    
    

    

      const options1 = 
         [
          { label: 'Option A', value: 'A' },
          { label: 'Option B', value: 'B' },
        ];

    return (
        <Modal
            {...props}
            backdrop="static"
            keyboard={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className="container" style={{ paddingTop: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={informationIcon} width="35" height="35" alt="Information Icon" />
                </div>
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-xs-12 col-sm-12">
                        <header className="pageHeader" style={{ paddingLeft: 10, paddingTop: 20, color: '#333', fontWeight: 'bold', fontSize: '23px', justifyContent: 'center', alignItems: 'center', alignContent: 'center', display: 'flex' }}>{Parser(getMandatoryData('sm_dashboard', 'reasonForCancelMainHeading'))}</header>
                    </div>
                </div>

                <div className='row' style={{ border: '1px solid #ccc',margin:"10px"}}>
                        <div className='col-md-12 col-lg-12 col-sx-12 col-sm-12'>
                              <div style={{ padding: 10 }}>
                                
                              
                                <div style={{ height: 5 }}></div>
                                   <div>
                                   {props.showReasonMandatory&&(
                                   <Form.Label className="col-12 required"style={{ fontSize:'16px', color:'red', fontWeight:'normal'}}> {Parser(getMandatoryData('sm_dashboard', 'reasonForCancelMandatory'))}</Form.Label>
                                   )} 
                                   <Form.Label className="col-12 required" style={{ fontSize:'16px', color:'black', fontWeight:'bold'}}> {Parser(getMandatoryData('sm_dashboard', 'reasonForCancelHeading'))}</Form.Label>
                                      <select onChange={props.handleFieldReason} className="col-12 required" class="form-select" style={{ background: '#E2E2E2', height: 40,paddingTop:10 }} value={props.value} >
                                        {

                                            props.reasons != undefined && props.reasons.map((item, i) => (
                                                <option value={item.id}> {item.name}</option>

                                            ))
                                        }
                                         </select>
                                   </div>
 
                                  {props.setpatientreason&&(
                                             
                                          <div>
                                              <div style={{ height: 5 }}></div>
                                              {props.showPatientsReasonMandatory&&(
                                              <Form.Label className="col-12 required" style={{ fontSize:'16px', color:'red', fontWeight:'normal'}}> {Parser(getMandatoryData('sm_dashboard', 'reasonForCancelSubMandatory'))}</Form.Label>
                                              )}
                                             <Form.Label  className="col-12 required" style={{ fontSize: "16px", color: 'black', fontWeight: 'bold' }}>
                                             {Parser(getMandatoryData('sm_dashboard', 'reasonForCancelSubHeading'))}
                                             </Form.Label>
                                              <select onChange={props.handleFieldChangesubreasons} className="col-12 required" class="form-select" style={{ background: '#E2E2E2', height: 40 }} value={props.subreasonvalue} >
                                                  { props.patientreason.map((item, i) => (
                                                            <option value={item.id}> {item.name}</option>

                                                                    ))
                                                    }
                                                   </select>
                                             </div>

                                                   )}
                              

                        
                            {props.comments && (

                                <div >
                                     <div style={{ height: 5 }}></div>
                                     {props.showCommentsMandatory&&(
                                     <Form.Label className="col-12 required"style={{ fontSize:'16px', color:'red', fontWeight:'normal'}}> {Parser(getMandatoryData('sm_dashboard', 'reasonForCancelCommentsMandatory'))}</Form.Label>
                                     )}
                                    <Form.Label  className="col-12 required" style={{ fontSize: '16px', color: 'black', fontWeight: 'bold' }}>{Parser(getMandatoryData('sm_dashboard', 'reasonForCancelCommentsHeading'))}</Form.Label>
                                    <Form.Control as="textarea" placeholder={props.placeholder} onChange={props.onFieldChange} style={{ height: '100px' }} />
                                </div>
                                )}



                            {props.rescheduleDate && (

                                <td className='col-7' >
                                    <div >
                                    <div style={{ height: 5 }}></div>
                                        <Form.Label  className="col-12 required" style={{ fontSize: '16px', color: 'black', fontWeight: 'bold' }}>{Parser(getMandatoryData('sm_dashboard', 'reasonForCancelRescheduleHeading'))}</Form.Label>
                                        <Fragment>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>

                                                <KeyboardDatePicker
                                                    disablePast
                                                    autoOk
                                                    value={props.changedDate == '' ? new Date() : props.changedDate}
                                                    onChange={(date) => onDateChange(date)}
                                                    format="MM/dd/yyyy"
                                                    open={open}
                                                    fullWidth
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    placeholder='Reschedule to'
                                                    InputAdornmentProps={{ position: "end" }}
                                                    InputProps={{ readOnly: true }}
                                                    onOpen={() => setOpen(true)}
                                                    onClose={() => setOpen(false)}
                                                    PopoverProps={{ style: { ...{ left: '30%', top: '15%', justifyContent: 'center', alignItems: 'center' } } }}
                                                    TextFieldComponent={(props) => (
                                                        <TextField {...props} onClick={(e) => setOpen(true)} />
                                                      )} 
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Fragment>
                                    </div>
                                </td>
                            )}
                             </div>

                            
                          </div>
                    </div>

                <div style={{ height: 10 }}></div>
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-xs-12 col-sm-12" style={{ padding: 10, justifyContent: 'center', alignItems: 'center', alignContent: 'center', display: 'flex' }}>
                        <button className="btnred col-3 text-center" onClick={props.onHideMain} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', alignContent: 'center', marginRight: 10 }} variant="primary" type="submit">{Parser(getMandatoryData('sm_dashboard', 'reasonForCancelPopupOkBtn'))}</button>
                        <button className="btnred col-3 text-center" onClick={props.onHide} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', alignContent: 'center' }} variant="primary" type="submit">{Parser(getMandatoryData('sm_dashboard', 'reasonForCancelPopupCancelBtn'))}</button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ReasonForCancel;
