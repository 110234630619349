import React, { Component }  from 'react';

import DashboardIcon from '../Images/dash_board.png'
import CompletedVisitsDashboardIcon from '../Images/completed_visits.png'
import SignOutIcon from '../Images/sign_out.png'
import incompIcon from'../Images/IncompleteVisit.png'
import addDocumentsListIcon from'../Images/addDocumentsList.png'
import CompanyProtocolListIcon from'../Images/CompanyProtocolList.png'


export const navData = [
    {
        id: 0,
        icon: DashboardIcon,
        text: "Dashboard",
        link: "Dashboard"
    },
    {
        id: 1,
        icon: CompletedVisitsDashboardIcon ,
        text: "Completed Visits",
        link: "CompletedVisitsDashboard"
    },
    {
        id: 2,
        icon: DashboardIcon ,
        text: "Scheduled SR",
        link: "SRDashboard"
    },
    {
        id: 3,
        icon: DashboardIcon ,
        text: "Future Visits",
        link: "FutureDashboard"
    },
    {
        id: 4,
        icon: SignOutIcon,
        text: "Sign Out",
        link: "/"
    },

    
]
export const navInitialGSMData = [
    {
        id: 0,
        icon: DashboardIcon,
        text: "Back",
        link: "GODuser"
    },
    {
        id: 1,
        icon: incompIcon ,
        text: "Incomplete Visits",
        link: "IncompleteVisitsDashboard"
    },
    {
        id: 2,
        icon: CompletedVisitsDashboardIcon ,
        text: "Completed Visits",
        link: "CompletedVisitsDashboard"
    },
    {
        id: 3,
        icon: addDocumentsListIcon ,
        text: "Add Documents",
        link: "AddDocumentSearchCompany"
    },
    {
        id: 4,
        icon: DashboardIcon,
        text: "Future Visits",
        link: "FutureVisitSearchCompany"
    },
    {
        id: 5,
        icon: CompanyProtocolListIcon,
        text: "Company Protocol",
        link: "CompanyProtocolDashboard"
    },
    {
        id: 6,
        icon: SignOutIcon,
        text: "Sign Out",
        link: "/"
    },

    
]
export const navGSMData = [
    {
        id: 0,
        icon: DashboardIcon,
        text: "Dashboard",
        link: "Dashboard"
    },
    {
        id: 1,
        icon: incompIcon ,
        text: "Incomplete Visits",
        link: "IncompleteVisitsDashboard"
    },
    {
        id: 2,
        icon: CompletedVisitsDashboardIcon ,
        text: "Completed Visits",
        link: "CompletedVisitsDashboard"
    },
    {
        id: 3,
        icon: DashboardIcon,
        text: "Scheduled SR",
        link: "SRDashboard"
    },
    {
        id: 4,
        icon: addDocumentsListIcon ,
        text: "Add Documents",
        link: "AddDocumentSearchCompany"
    },
    {
        id: 5,
        icon: DashboardIcon,
        text: "Future Visits",
        link: "FutureVisitSearchCompany"
    },
    {
        id: 6,
        icon: CompanyProtocolListIcon,
        text: "Company Protocol",
        link: "CompanyProtocolDashboard"
    },
    {
        id: 7,
        icon: SignOutIcon,
        text: "Sign Out",
        link: "/"
    },

    
]