import React from "react";
import { useNavigate } from "react-router-dom";
import { useMemo, useState, useEffect, useRef } from "react";
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import LogoutIcon from '@mui/icons-material/Logout'
import AccountCircleOutlinedIcon from '@mui/icons-material/Person';
import logo from "../Images/CaduceusLogoRed.png"
import { TextField, FormControl, MenuItem, Select, InputLabel, Checkbox, TextareaAutosize, Box, OutlinedInput, InputAdornment, Paper, Image } from '@mui/material';
// import TopBar from "./Navbar";
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import MenuIcon from '../Images/menu.png';
import AddIcon from '../Images/add_user.png';
import { setVisitId, setVisitType, setFutureVisitFromSMDashboard, setSRData,setFollowupYN, getFollowupYN, postRequest, getUser, getVisitId ,setFromDashboard} from "../Services/DataMode";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import addNewDocuments from "../Images/addNewDocuments.png"

function RBMenu(props) {

    const ref = useRef(null);
    const [activePage, setActivepage] = useState('topmenu');
    useEffect(() => {
        setActivepage(props.activePage);
        handleResize();
         window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
        //console.log('activepage->'+activePage);
    }, [props.activePage]);
    let navigate = useNavigate();
    //Dashboard    
    //CreateVisit
    //SRDashboard
    //FutureDashboard
    //CompletedVisitsDashboard

    function addClicked(e) {
     //   setFromDashboard("0")
        e.preventDefault();
        setFollowupYN('')
        if (activePage == 'Dashboard') {
            setVisitType('Regular');
            navigate('CreateVisit');
        } else if (activePage == 'Scheduled Service Request') {
            setSRData(null);
            setVisitType('SR');
            navigate('SRPageOne');
        } else if (activePage == 'Future Visits') {
            setFutureVisitFromSMDashboard('False');
            setVisitType('Future');
            navigate('CreateVisit');
        } else if (activePage == 'Add Documents ') {
            navigate('AddDocumentsCreateUpdate');
        } else if (activePage == 'Company Protocol') {
            navigate('CompanyProtocolCreateUpdate');
        }
        setVisitId(0)
    }
const handleResize = () => {
    const top = ref.current;
   
    if (window.innerWidth <= 960) {
      // alert(side)
      top.className = "fontsize15"
    
    } else {
      top.className = "fontsize20"
    }
  }
    function menuClicked(e) {
        e.preventDefault();
        props.menuClicked(true);
    }

    function cancelClicked(e) {
        if(activePage=='Patient Information'){
            if(getFollowupYN() == 'true'){
                postRequest('ws_webrtc/WebRTC.asmx/cancelRTCSession',
                { sKey: getUser(), iVisitId: getVisitId() },
                (response) => {
                    console.log("Abort Visit ", JSON.stringify(response));
                  //  navigate('/App/Dashboard')
                  e.preventDefault();
                  navigate(-1);
                }, null)
            } else{
                e.preventDefault();
                navigate(-1);
            }
        } else{
            e.preventDefault();
            navigate(-1);
        }

        setVisitId("0")

        
    }

    //console.log(props.mode)
    const [show, setShow] = useState(false);
    const toggleOffCanvas = () => {
        setShow((show) => !show);
    };
    const clickOffCanvasClose = () => {
        setShow(false);
    }

    return (
        <>
            <div className="text-center paddingmargin0" >
                
                    <div expand="lg md sm xs" className=" justify-content-center topNavBarStyle col-12 navbar-fixed sticky-nav" style={{ backgroundColor: '#D92A28', height: 55 }}>
                        <div className="row h-100  align-items-center paddingmargin0" >

                            <div className="col-md-2 col-lg-2 col-sm-2 col-xs-2" style={{ flex: ' 0 0 75px' }}>
                                {(activePage == 'Dashboard'
                                    || activePage == 'Completed Visits'
                                    || activePage == 'Select Company - Global user'
                                    || activePage == 'Scheduled Service Request'
                                    || activePage == 'Future Visits'
                                    || activePage == 'Incomplete Visits'
                                    || activePage == 'Select Company '
                                    || activePage == 'Add Documents '
                                    || activePage == ' Select Company '
                                    || activePage == 'Company Protocol') && <img src={MenuIcon} style={{ width: '25px', height: '25px', cursor: "pointer" }} onClick={menuClicked}></img>}
                                {(activePage == 'Select a Provider' || activePage == 'Select a MA Provider'
                                ) && <span onClick={cancelClicked} style={{ width: '25px', height: '25px', color: '#ffffff', marginLeft: 12, cursor: "pointer" }}><b>{props.showBackButton ? "Back" : "Cancel"}</b></span>}

                                {(activePage == 'Patient Information'
                                    || activePage == 'Service Request'
                                    || activePage == 'Add Documents'
                                    || activePage == 'Add Company Protocol'
                                ) && <span onClick={cancelClicked} style={{ width: '25px', height: '25px', color: '#ffffff', marginLeft: 12, cursor: "pointer" }}><b>{props.showBackButton ? "Back" : "Cancel"}</b></span>}

                            </div>

                            <div className="col">
                                <label style={{ fontSize: "20px", fontWeight: 'bold', color: '#fff' }} ref={ref}>{activePage}</label>
                            </div>

                            <div className="col-2" style={{ flex: ' 0 0 75px' }}>
                                {(activePage == 'Dashboard'
                                    || activePage == 'Scheduled Service Request'
                                    || activePage == 'Future Visits'
                                    || activePage == 'Add Documents '
                                    || activePage == ' Select Company '
                                    || activePage == 'Company Protocol') && <img src={activePage == 'Add Documents ' ? addNewDocuments : AddIcon} style={{ width: '25px', height: '25px', cursor: "pointer" }} onClick={addClicked}></img>}
                            </div>
                        </div>
                    </div >
             
            </div>
        </>
    );
}

export default RBMenu;