import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from "react";
import $ from 'jquery';
import { getMandatoryData } from '../Services/DataMode';


function ShowSelectPopup(props) {
    return (
        <Modal
            {...props}
            backdrop="static"
            keyboard={false}
            size="md"   
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="modal-width"
        >
            <div className='p-2'>

                <div style={{ padding: 10,  justifyContent: 'center', alignItems: 'center', alignContent:'center', display:'flex' }} className='pb-2 d-flex align-items-center border-bottom border-danger ' >
                    <img
                        src={props.icon}
                        style={{ width: '40px', height: '40px' }}
                    />
                    <label style={{ display: 'flex', fontSize: "20px", paddingLeft: 5, color: 'black', fontWeight: 'Bold' }}>{props.headerName}</label>
                </div>

                <div className='m-3'>
                   
                {props.data.map(choice => (
                <label style={{ padding: 10,  marginLeft: '60px', alignItems: 'center', alignContent:'center', display: 'flex', fontSize: "20px", color: 'grey', fontWeight: 'normal' }}>
                    <input type="radio"
                        id={props.tagid}
                        name={props.tagid}
                        value={choice.value}
                        defaultChecked={choice.default}
                        // selected={props.remotePatientYN}
                        checked={(choice.label == getMandatoryData('sm_dashboard', 'edit_option_text') && props.selectedYN) || (choice.label == getMandatoryData('sm_dashboard', 'email_option_text') && !props.selectedYN)}
                        onChange={props.handleFieldChange}
                        style={{ padding: 5 }}
                    />
                    {choice.label}
                </label>

            ))
            }
                    {/* <label style={{ display: 'flex', fontSize: "20px", color: 'grey', fontWeight: 'normal' }}> {props.data} </label> */}

                    </div>
 
                 <div className='row'>
                    <div className='col-md-12 col-lg-12 col-xs-12 col-sm-12' style={{ padding: 10,  justifyContent: 'center', alignItems: 'center', alignContent:'center', display:'flex' }}>
                                      <button className={getMandatoryData('sm_room', 'abort_popup_message') == props.data ? "btnpop" : "btn"} onClick={props.onHide} style={{ borderRadius: 25, paddingTop: 5, paddingBottom: 5, }} >{props.okText}</button> &nbsp;&nbsp;
                     {props.cancel &&
                            <button className={getMandatoryData('sm_room', 'abort_popup_message') == props.data ? "btnpop" : "btn"} onClick={props.onCancel} style={{ borderRadius: 25, paddingTop: 5, paddingBottom: 5,  justifyContent: 'center', alignItems: 'center', alignContent: 'center' }} variant="primary" type="submit">{props.cancelText}</button>
                       
                    }   
                                </div>

                </div> 
            </div>

        </Modal>
    );
}

export default ShowSelectPopup