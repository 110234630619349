import React, { Component, Fragment, useState } from 'react';
import { Paper, TextField } from '@mui/material'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

function Reschedule(props) {
    const [open, setOpen] = useState(false);

    function onDateChange(date){
        var value = date;
        console.log("date" + value);
        props.handleDateChange(value);
    }

    console.log(props)
    return (
        <Modal
             {...props}
            backdrop="static"
            keyboard={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
             <div className="row">
                <div className='col-md-12 col-lg-12 col-xs-12 col-sm-12'>
                    <header className='pageHeader' style={{ paddingLeft: 10,paddingTop:10,color: '#333', fontWeight: 'bold', justifyContent: 'center', alignItems: 'center', alignContent:'center', display:'flex' }}>Reschedule Telemed Visit</header>
                </div>
            </div>
            <div className="container" style={{ paddingTop: 10 }}>
                <table className="contentbody">
                    <tr>
                        <td className='col-5'>   
                            <div className="schedulePopupLbl">
                                <Form.Label>Patient Name</Form.Label>
                            </div>
                        </td>
                        <td className='col-7'> 
                            <div className="schedulePopupLblvalue">
                                <Form.Label>{props.data.first_name + props.data.last_name}</Form.Label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className='col-5'>   
                            <div className="schedulePopupLbl">
                                <Form.Label>Company Name</Form.Label>
                            </div>
                        </td>
                        <td className='col-7'>
                            <div className="schedulePopupLblvalue">
                                <Form.Label>{props.data.company_name}</Form.Label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className='col-5'>  
                            <div className="schedulePopupLbl">
                                <Form.Label>Branch Name</Form.Label>
                            </div>
                        </td>
                        <td className='col-7'>   
                            <div className="schedulePopupLblvalue">
                                <Form.Label>{props.data.branch_name == '' ? 'None' : props.data.branch_name }</Form.Label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className='col-5'>    
                            <div className="schedulePopupLbl">
                                <Form.Label>Current Follow Up date</Form.Label>
                            </div>
                        </td>
                        <td className='col-7'>  
                            <div className="schedulePopupLblvalue">
                                    <Form.Label>{props.data.date_of_service}</Form.Label>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className='col-5'>  
                            <div className="schedulePopupLbl">
                                <Form.Label>Reschedule To</Form.Label>
                            </div>
                        </td>
                        <td className='col-7' style={{paddingBottom:10,paddingRight:5}}>  
                            <div className="schedulePopupLblvalue">
                                <Fragment>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker disablePast
                                            autoOk
                                            open={open}
                                            fullWidth
                                            variant="inline"
                                            inputVariant="outlined"
                                            // format="yyyy-dd-MM"
                                            value={props.changedDate == '' ? new Date() : props.changedDate}
                                            format="MM/dd/yyyy"
                                            InputAdornmentProps={{ position: "end" }}
                                            onChange={(date) => onDateChange(date)}
                                            InputProps={{ readOnly: true }}
                                            onOpen={() => setOpen(true)}
                                            onClose={() => setOpen(false)}
                                            TextFieldComponent={(props) => (
                                                <TextField {...props} onClick={(e) => setOpen(true)} />
                                              )} 
                                              PopoverProps={{style: {...{left: '30%',top:'15%',justifyContent:'center',alignItems:'center'}}}}                     

                                        />
                                    </MuiPickersUtilsProvider>
                                </Fragment>
                            </div>
                        </td>
                    </tr>
      
                </table>
            </div>
            <div style={{height:10}}></div>
            <div className='row'>         
                <div className='col-md-12 col-lg-12 col-xs-12 col-sm-12' style={{ padding: 10,  justifyContent: 'center', alignItems: 'center', alignContent:'center', display:'flex' }}>
                    <button className="btnblue col-3 text-center" onClick={props.onHideMain} style={{  justifyContent: 'center', alignItems: 'center',display:'flex', alignContent: 'center',marginRight:10 }} variant="primary" type="submit">Save</button>
                    <button className="btnred col-3 text-center" onClick={props.onHide}  style={{  justifyContent: 'center', alignItems: 'center',display:'flex', alignContent: 'center'}} variant="primary" type="submit">Cancel</button>
                </div>
                           
            </div>


        {/* <div className="container" style={{ paddingTop: 10 }}>

            <div className="row">
                <div className='col-md-12 col-lg-12 col-sx-12 col-sm-12'>



                    <div className="contentbody" style={{margin:10}}>
                        <div className="row">
                            <div className='col-md-12 col-lg-12 col-xs-12 col-sm-12'>
                                <header className='pageHeader' style={{ paddingLeft: 10, padding: 10, color: '#333', fontWeight: 'bold', justifyContent: 'center', alignItems: 'center', alignContent:'center', display:'flex' }}>Reschedule Telemed Visit</header>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-4 col-lg-4 col-xs-4 col-sm-4'>
                                <div  style={{  fontWeight: 'bold', color: '#0C489E', justifyContent: 'left', alignItems: 'left', alignContent:'left', display:'flex', marginLeft:10 }}>
                                    <Form.Label>Patient Name</Form.Label>
                                </div>
                            </div>
                            <div className='col-md-8 col-lg-8 col-xs-8 col-sm-8'>
                                <div style={{  fontWeight: 'bold', justifyContent: 'left', alignItems: 'left', alignContent:'left', display:'flex' }}>
                                    <Form.Label>{props.data.first_name + props.data.last_name}</Form.Label>
                                </div>
                            </div>

                        </div>
                        <div className='row'>
                            <div className='col-md-4 col-lg-4 col-sx-4 col-sm-4'>
                                <div style={{  fontWeight: 'bold', color: '#0C489E', justifyContent: 'left', alignItems: 'left', alignContent:'left', display:'flex', marginLeft:10 }}>
                                    <Form.Label>Company Name</Form.Label>
                                </div>
                            </div>
                            <div className='col-md-8 col-lg-8 col-sx-8 col-sm-8'>
                                <div style={{  fontWeight: 'bold', justifyContent: 'left', alignItems: 'left', alignContent:'left', display:'flex',  }}>
                                    <Form.Label>{props.data.company_name}</Form.Label>
                                </div>
                            </div>
                        </div>
                        { props.data.branch_name &&
                        <div className='row'>
                            <div className='col-md-4 col-lg-4 col-xs-4 col-sm-4'>
                                <div style={{  fontWeight: 'bold', color: '#0C489E', justifyContent: 'left', alignItems: 'left', alignContent:'left', display:'flex', marginLeft:10}}>
                                    <Form.Label>Branch Name</Form.Label>
                                </div>
                            </div>
                            <div className='col-md-8 col-lg-8 col-xs-8 col-sm-8'>
                                <div style={{  fontWeight: 'bold', justifyContent: 'left', alignItems: 'left', alignContent:'left', display:'flex', }}>
                                    <Form.Label>{props.data.branch_name}</Form.Label>
                                </div>
                            </div>
                        </div>
}
                        <div className='row'>
                            <div className='col-md-4 col-lg-4 col-sx-4 col-sm-4'>
                                <div style={{  fontWeight: 'bold', color: '#0C489E', justifyContent: 'left', alignItems: 'left', alignContent:'left', display:'flex', marginLeft:10 }}>
                                    <Form.Label>Current Follow Up date</Form.Label>
                                </div>
                            </div>
                            <div className='col-md-8 col-lg-8 col-sx-8 col-sm-8'>
                                <div style={{  fontWeight: 'bold', justifyContent: 'left', alignItems: 'left', alignContent:'left', display:'flex',  }}>
                                    <Form.Label>11/30/2022</Form.Label>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-4 col-lg-4 col-xs-4 col-sm-4'>
                                <div style={{  fontWeight: 'bold', color: '#0C489E', justifyContent: 'left', alignItems: 'left', alignContent:'left', display:'flex', marginLeft:10 }}>
                                    <Form.Label>Reschedule To</Form.Label>
                                </div>
                            </div>
                            <div className='col-md-8 col-lg-8 col-xs-8 col-sm-8'>
                                <div style={{ width:'60%',  fontWeight: 'bold', color: '#0C489E', justifyContent: 'left', alignItems: 'left', alignContent:'left', display:'flex', }}>
                                <Fragment>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker disableFuture
                                            autoOk
                                            fullWidth
                                            variant="inline"
                                            inputVariant="outlined"
                                            format="yyyy-dd-MM"
                                            InputAdornmentProps={{ position: "end" }}
                                            onChange={(date) => onDateChange(date)}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Fragment>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-6 col-lg-6 col-xs-6 col-sm-6 text-center'>
                                <div className='col-md-12 col-lg-12 col-xs-12 col-sm-12' style={{ padding: 10,  justifyContent: 'right', alignItems: 'right', alignContent:'right', display:'flex' }}>
                                    <button className={"btnblue"} onClick={props.onHideMain} style={{ width:'50%', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }} variant="primary" type="submit">Save</button>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-6 col-xs-6 col-sm-6 text-center'>
                                <div className='col-md-12 col-lg-12 col-xs-12 col-sm-12' style={{ padding: 10,  justifyContent: 'left', alignItems: 'left', alignContent:'left', display:'flex' }}>
                                    <button className={"btn"} onClick={props.onHide} style={{ width:'50%', justifyContent: 'center', alignItems: 'center', alignContent: 'center'}} variant="primary" type="submit">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>





            </div>
         </div>  */}
        </Modal>
    );
}

export default Reschedule;