import React, { useContext, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../styles/styles.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Paper } from "@mui/material";
import formData from "../lib/formsData.json";
import CustomDivComponent from "../components/divComponent";
import RowComponent from "../components/rowComponent";
import {
  validateSSNArray,
  postRequest,
  getUser,
  setVisitId,
  setSRData,
  getMandatoryData,
  validateEmail,
  getCompanyId,
  getSRData,
  getVisitType,
} from "../Services/DataMode";
import { postRequestSR } from "../Services/DataMode";
import UserContext from "../components/context";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import { set } from "date-fns";

function ServiceRequestPageOne(props) {
  const [reason, setReason] = useState([{ name: "Select", id: "0" }]);
  const [services, setServices] = useState([
    { ExamName: "Select", ExamID: "0" },
  ]);
  const [branches, setBranches] = useState([{ name: "Select", id: "0" }]);
  const [states, setStates] = useState([{ name: "Select", id: "0" }]);
  const [gender, setGender] = useState([{ name: "", id: "" }]);
  const [dlnStates, setDlnStates] = useState([
    { StateName: "", StateAbbr: "" },
  ]);
  const [isRemotePatient, setRemotePatient] = useState(false);
  const [countries, setCountries] = useState([
    { name: "Select", id: "0", abbr: "", code: "" },
  ]);
  const [isbranchEnable, setbranchEnable] = useState(true);
  const [code, setCode] = useState("+1");
  const [abbr, setAbbr] = useState("US");
  const [loadData, setLoadData] = useState(JSON.parse(getSRData()));
  const [loading, setloading] = useState(false);
  const [SSNValidation, setSSNValidation] = useState(false);
  const [patientFieldWalsh, setPatientFieldWalsh] = useState(true);

  const [emailValidation, setEmailValidation] = useState(false);


  const userInfo = useContext(UserContext).user;
  const context = useContext(UserContext);
  const user = useContext(UserContext);
  let navigate = useNavigate();

  const [values, setValues] = useState({});
  // const handleFieldChange = (fieldId, value) => {
  //     setValues({ ...values, [fieldId]: value });
  //     //console.log("SRPageOneValues " + JSON.stringify(values));
  //     setSRData(JSON.stringify(values));
  // };

  const handleDateChange = (date, name) => {
    setValues({ ...values, [name]: date });
  };
  function subtractYears(date, years) {
    date.setFullYear(date.getFullYear() - years);
    return date;
  }
  function handleFieldChange(event) {
    var name = event.target.name;
    var value = event.target.value;
    console.log(event.target.name + " --> " + event.target.value);
    console.log(event.target.value, "value");

    if (name == "RemotePatient_YN") {
      if (value == true) {
        context.setUser(
          1,
          "Info",
          "Are you sure that patient is 'Remote'. Do you wish to continue?",
          "Yes",
          true,
          "No",
          () => {
            confirmRemotePatient(false);
          },
          () => {
            confirmRemotePatient(true);
          }
        );
      } else {
        confirmRemotePatient(false);
      }
    }

    if (name == "NR_PhoneNumber") {
      setValues({ ...values, Phone: value });
      values.Phone = value;
    }

    if (name == "country_id") {
      countries.map((item) => {
        if (item.id == value) {
          setAbbr(item.abbr);
          // setCode(item.code);
        }
      });
    }
    if (name == "SSNumber" && value.length == 11) {
      getSSNValidation(value.split("-").join(""));
    }

    setValues({ ...values, [name]: value });
    console.log(name, value);
  }

  useEffect(() => {
    props.updatePage();

    postRequest(
      "TelemedWebAPI2/ServiceRequest/GetReason",
      { sKey: getUser() },
      (response) => {
        // console.log(response.d);
        const reasonArray = [];
        for (var i = 0; i < response.d.length; i++) {
          reasonArray.push({
            id: response.d[i].Reason,
            name: response.d[i].reasonName,
          });
        }
        setReason(reasonArray);
      },
      context
    );

    postRequest(
      "TelemedWebAPI2/ServiceRequest/GetServiceRequestCompanyExams",
      { sKey: getUser() },
      (response) => {
        //console.log(response.d);
        setServices(response.d);
      }
    );

    postRequest(
      "ws_webrtc/Telemed.asmx/getTelemedCompanyBranchesPerCompany",
      { sKey: getUser(), iCompanyId: getCompanyId() },
      (response) => {
        //console.log(response.d);
        const branchArray = [];
        for (var i = 0; i < response.d.length; i++) {
          branchArray.push({
            id: response.d[i].CompanyId,
            name: response.d[i].CompanyName,
          });
        }
        setBranches(branchArray);
        if (branchArray.length == 1) {
          setbranchEnable(false);
        } else {
          setbranchEnable(true);
        }
      },
      context
    );

    postRequest(
      "ws_webrtc/Util.asmx/populateDDL",
      { sKey: getUser(), sTableName: "tblStates" },
      (response) => {
        //console.log(response.d);
        const stateArray = [];
        stateArray.push({ id: 0, name: "Select" });
        for (var i = 0; i < response.d.length; i++) {
          stateArray.push({ id: response.d[i].id, name: response.d[i].name });
        }
        setStates(stateArray);
      },
      context
    );

    postRequest(
      "TelemedWebAPI2/CommonDRP/GetGenders",
      { sKey: getUser() },
      (response) => {
        //console.log(response.d);
        const genderArray = [];
        for (var i = 0; i < response.d.length; i++) {
          genderArray.push({
            id: response.d[i].SexID,
            name: response.d[i].SexName,
          });
        }
        setGender(genderArray);
      },
      context
    );

    postRequest(
      "ws_webrtc/Telemed.asmx/GetCountries",
      { sKey: getUser() },
      (response) => {
        console.log(response.d);
        const countryArray = [];
        for (var i = 0; i < response.d.length; i++) {
          countryArray.push({
            id: response.d[i].Country_Id,
            name: response.d[i].Country_Name,
            abbr: response.d[i].Country_Abbr,
            code: response.d[i].Country_Code,
          });
        }
        setCountries(countryArray);
      },
      context
    );

    postRequest(
      "TelemedWebAPI2/CommonDRP/GetDLNStates",
      { sKey: getUser() },
      (response) => {
        //console.log(response.d);
        const stateArray = [];
        for (var i = 0; i < response.d.length; i++) {
          stateArray.push({
            id: response.d[i].StateAbbr,
            name: response.d[i].StateName,
          });
        }
        setDlnStates(stateArray);
      },
      context
    );

    postRequest(
      "ws_webrtc/Util.asmx/GetCompanySpecificFields_YN",
      { sKey: getUser(), icompanyId: getCompanyId() },
      (response) => {
        // debugger;
        console.log(
          "hello 1111111111 GetCompanySpecificFields_YN",
          response.d.status
        );

        if (response.d.status) {
          setPatientFieldWalsh(false);
          setloading(false);
          setEmailValidation(true)
        } else {
          setPatientFieldWalsh(true);
          setEmailValidation(false)

          setloading(false);
        }
      },
      context
    );
    // setShowPatientField(true);

    if (JSON.parse(getSRData()) !== null) {
      console.log("get data");
      loadPreviousData();
    } else {
      setValues({
        CreatedBy: "802855",
        Reason: "Corporate",
        CorporateExams: 0,
        BranchID: 0,
        StateID: 0,
        SSNumber: "",
        FirstName: "",
        LastName: "",
        SexID: 0,
        SR_Country_Code: "+1",
        NR_PhoneNumber: "",
        Email: "",
        DateOfBirth: subtractYears(new Date(), 13),
        DLNState: "",
        DLNumber: "",
        EmployeeID: "",
        RemotePatient_YN: false,
        Phone: "",
        country_id: 1,
        JobTitleID: 1,
        Address1: "",
        Address2: "",
        City: "",
        zip: "",
        Comments: "",
        CompanyID: getCompanyId(),
        VisitID: 0,
        sKey: getUser(),
      });
    }
  }, []);

  function getSSNValidation(value) {
    setloading(true);
    postRequest(
      "ws_webrtc/Util.asmx/GetSSNValidation_YN",
      { sKey: getUser(), sSSN: value },
      (response) => {
        console.log("Purpsoe", response.d.status);
        setSSNValidation(response.d.status);
        if (response.d.status) {
          setloading(false);
          context.setUser(0, "Error", response.d.message, "OK");
        } else {
          setloading(false);
        }
      },
      context
    );
  }

  function loadPreviousData() {
    console.log("Get Data " + JSON.parse(getSRData()));
    console.log("Data " + JSON.stringify(getSRData()));
    if (loadData.RemotePatient_YN) {
      setRemotePatient(true);
    } else {
      setRemotePatient(false);
    }
    setValues({
      CreatedBy: "802855",
      Reason: "Corporate",
      CorporateExams: loadData.CorporateExams, //.split(",")[0],
      BranchID: loadData.BranchID,
      StateID: loadData.StateID,
      SSNumber: loadData.SSNumber,
      FirstName: loadData.FirstName,
      LastName: loadData.LastName,
      SexID: loadData.SexID,
      SR_Country_Code: "+1",
      NR_PhoneNumber: loadData.NR_PhoneNumber,
      Email: loadData.Email,
      DateOfBirth: loadData.DateOfBirth,
      DLNState: loadData.DLNState,
      DLNumber: loadData.DLNumber,
      EmployeeID: loadData.EmployeeID,
      RemotePatient_YN: loadData.RemotePatient_YN ? true : false,
      Phone: loadData.Phone,
      country_id: 1,
      JobTitleID: 1,
      Address1: "",
      Address2: "",
      City: "",
      zip: "",
      Comments: "",
      CompanyID: getCompanyId(),
      VisitID: 0,
      sKey: getUser(),
    });
  }

  function confirmRemotePatient(remotePatientyesno) {
    setRemotePatient(false);
    remotePatientEnable(false);
    if (remotePatientyesno) {
      context.setUser(
        1,
        "Info",
        "Is remote patient phone number available with you?",
        "Yes",
        true,
        "No",
        () => {
          remotePatientEnable(false);
        },
        () => {
          remotePatientEnable(true);
        }
      );
    } else {
      setValues({ ...values, RemotePatient_YN: 0 });
    }
  }

  function remotePatientEnable(isRemote) {
    setRemotePatient(isRemote);
  }

  const navigateToPageTwo = () => {
    if (values.CorporateExams == 0) {
      user.setUser(0, "Error", getMandatoryData("create_sr", "exams"), "OK");
    } else if (values.BranchID == 0 && isbranchEnable) {
      user.setUser(0, "Error", getMandatoryData("create_sr", "branch"), "OK");
    } else if (values.StateID == 0) {
      user.setUser(0, "Error", getMandatoryData("create_sr", "state"), "OK");
    } else if (values.SSNumber.trim() == "") {
      user.setUser(0, "Error", getMandatoryData("create_sr", "ssn"), "OK");
    } else if (values.SSNumber.length !== 11) {
      user.setUser(
        0,
        "Error",
        getMandatoryData("create_sr", "ssn_valid"),
        "OK"
      );
    } else if (values.SSNumber.length == 11 && SSNValidation) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("create_sr", "ssn_invalid_message"),
        "OK"
      );
    } else if (
      getMandatoryData("create_sr", "ssn_invalid_message") != "" &&
      values.SSNumber.length == 11 &&
      !validateSSNArray(
        values.SSNumber.split("-").join(""),
        getMandatoryData("create_sr", "ssn_invalid")
      )
    ) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("create_sr", "ssn_invalid_message"),
        "OK"
      );
    } else if (values.FirstName.trim() == "") {
      user.setUser(
        0,
        "Error",
        getMandatoryData("create_sr", "firstname"),
        "OK"
      );
    } else if (values.LastName.trim() == "") {
      user.setUser(0, "Error", getMandatoryData("create_sr", "lastname"), "OK");
    } else if (values.SexID == 0) {
      user.setUser(0, "Error", getMandatoryData("create_sr", "gender"), "OK");
    } else if (values.NR_PhoneNumber.trim() == "") {
      user.setUser(
        0,
        "Error",
        getMandatoryData("create_sr", "phonenumber"),
        "OK"
      );
    } else if (values.Email == "" &&!emailValidation) {
      user.setUser(0, "Error", getMandatoryData("create_sr", "email"), "OK");
    } else if (!validateEmail(values.Email)&&!emailValidation) {
      user.setUser(
        0,
        "Error",
        getMandatoryData("create_sr", "email_invalid"),
        "OK"
      );
    } else if (values.DateOfBirth == "") {
      user.setUser(0, "Error", getMandatoryData("create_sr", "dob"), "OK");
    } else if (values.DateOfBirth > subtractYears(new Date(), 13)) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("create_sr", "dob_validation"),
        "OK"
      );
    } else if (values.DLNState == 0) {
      user.setUser(
        0,
        "Error",
        getMandatoryData("create_sr", "dln_state"),
        "OK"
      );
    } else if (values.DLNumber.trim() == "" && values.EmployeeID.trim() == "") {
      user.setUser(
        0,
        "Error",
        getMandatoryData("create_sr", "dln_empId"),
        "OK"
      );
    } else {
      console.log("Data " + JSON.stringify(values));
      setSRData(JSON.stringify(values));
      navigate("/App/SRPageTwo");
    }
  };

  return (
    <div className="h-100 col-12 p-2">
      <div className="row">
        <div className="col-md-12 col-lg-12 col-sx-12 col-sm-12">
          <div>
            <div className="row">
              <div className="col-md-12 col-lg-12 col-sx-12 col-sm-12">
                <div style={{ marginBottom: 80 }}>
                  <CustomDivComponent
                    data={formData.ServiceRequestPageOne}
                    reasonValue={reason}
                    serviceValue={services}
                    branchValue={branches}
                    stateValue={states}
                    genderValue={gender}
                    dlnValue={dlnStates}
                    countryValue={countries}
                    onFieldChange={handleFieldChange}
                    datePickerChange={handleDateChange}
                    remotePatientYN={isRemotePatient}
                    remotePhoneNo={values.Phone}
                    countryAbbr={abbr}
                    loadValues={values}
                    isShowPatientEmail={patientFieldWalsh}
                  />
                </div>
              </div>
            </div>
            {loading && (
              <div
                style={{
                  position: "fixed",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ReactLoading
                  type={"spin"}
                  color={"#ff0000"}
                  height={"5%"}
                  width={"5%"}
                />
              </div>
            )}
          </div>

          <div className="fixed-bottom">
            <div className="col-md-12 col-lg-12 col-sx-12 col-sm-12 text-center bg-white">
              <div style={{ padding: 10 }}>
                <button
                  className={"btn"}
                  onClick={navigateToPageTwo}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                  variant="primary"
                  type="submit"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ServiceRequestPageOne;
