import React, { useState, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
import Icon from '@mui/material/Icon';
import AttachFile from '../Images/img_attach.png'
import CancelIcon from '@mui/icons-material/Cancel';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import MyVerticallyCenteredModal from "./MyVerticallyCenteredModal";
import InfoIcon from '../Images/information-icon.png';
import {validateSSNArray, setTestResultFileIds, setMQRIds, setUploadIds, postRequest, getUser, getBaseUrl, getVisitId, getMandatoryData, getCompanyId, getBranchId, getSsnNumber, getFirstName, getLastName, setADIds, setiPrevDocumentId, getTempCompanyId } from "../Services/DataMode";
import { PropaneSharp } from "@mui/icons-material";
import UserContext from './context';
import { pdfjs, Document, Page } from 'react-pdf';
import '../styles/styles.css';
import Parser from 'html-react-parser';


function UploadPreview(props) {

  const options = {
    cMapUrl: 'cmaps/',
    standardFontDataUrl: 'standard_fonts/',
  };


  const context = useContext(UserContext);

  const [fileObj, setfileObj] = useState([]);
  const [fileArray, setfileArray] = useState([]);
  const [fileIDArray, setfileIDArray] = useState([]);

  const [fileShow, setfileShow] = useState('');
  const [deleteEvent, setdeleteEvent] = useState('');
  const [ssnTF, setSsnTF] = useState([]);

  const [showLbl, setShowLbl] = useState(true);

  const [isOpen, setisOpen] = useState('');
  const [showPop, setshowPop] = useState('');
  const [pdfisOpen, setpdfisOpen] = useState('');
  const [numPages, setNumPages] = useState(0);
  const [isUploadID_Mandatory_Field, setisUploadID_Mandatory_Field] = useState(false);

  const [iPrevDocumentIds, setiPrevDocumentIds] = useState(0);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

    if (props.ssn !== undefined) {
      getDocumentsPerSSN(props.ssn)
    }
    // if (getVisitId() != 0 &&  getVisitId() != undefined){
    // getPreVisitTestResults(getVisitId())
    // }
    
    if (props.documentsVisitID != 0 &&  props.documentsVisitID != undefined && props.ssn == ''){
      getPreVisitTestResults(props.documentsVisitID)
      }
      if (props.documentsVisitID == 0 && props.id == 'CP'){
        setSsnTF([])
        setfileIDArray([])
       // props.onUpload('')
        setADIds('')
        setfileObj([])
        setfileArray([])
      }
     if(props.id == 'ID'){
      postRequest('ws_webrtc/Util.asmx/GetClientSpecific_MandatoryFields',
      { sKey: getUser(), iCompanyId: getCompanyId() },
      (response) => {
          setisUploadID_Mandatory_Field(response.d.isUploadID_Mandatory_Field)
      }, [])
    }
  }, [props.ssn,props.documentsVisitID])

  const getBase64 = (file) => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        var string = file.name.split('.');

        var string2 = baseURL.split(',');
        setADIds('')
        if (props.id == 'CP') {

          postRequest('ws_webrtc/Telemed.asmx/CreateCompanyProtocolUploadDocument',
            {
              sKey: getUser(),
              sFileStream: string2[1],
              sFileName: string[0],
              sFileExt: '.' + string.pop(),

              iCompanyProtocolId:iPrevDocumentIds,
              iCompanyId:parseInt(getTempCompanyId())

            },
            (response) => {
              console.log(response.d);
              setiPrevDocumentIds(response.d.CompanyProtocol_Document_Detail_Id)
              setiPrevDocumentId(response.d.CompanyProtocol_Document_Detail_Id)
              fileIDArray.push(response.d.CompanyProtocol_Document_Detail_Id)
              setfileIDArray(fileIDArray)
              var csv = fileIDArray.join()
              //props.onUpload(csv)
              setADIds(csv)
            }, context)
          
        }
        else if (props.id == 'AD') {

          postRequest(props.urlPath,
            {
              sKey: getUser(),
              sFileStream: string2[1],
              sFileName: string[0],
              sFileExt: '.' + string.pop(),

              iPrevDocumentId:iPrevDocumentIds,
              sFirstName:getFirstName(),
              sLastName:getLastName(),
              sSSN:getSsnNumber().split("-").join(""),
              iCompanyId:getCompanyId(),
              iCompanybranchId:getBranchId()

            },
            (response) => {
              console.log(response.d);
              setiPrevDocumentIds(response.d.PrevDocumentDetail_Id)
              setiPrevDocumentId(response.d.PrevDocumentDetail_Id)
              fileIDArray.push(response.d.PrevDocumentDetail_Id)
              setfileIDArray(fileIDArray)
              var csv = fileIDArray.join()
              props.onUpload(csv)
              setADIds(csv)
            }, context)
        }else{
        postRequest(props.urlPath,
          {
            sKey: getUser(),
            sFileStream: string2[1],
            sPrevVisitId: 0,
            sFileName: string[0],
            sFileExt: '.' + string.pop()
          },
          (response) => {
            //console.log(response.d);
            fileIDArray.push(response.d)
            setfileIDArray(fileIDArray)
            var csv = fileIDArray.join()
            props.onUpload(csv)
            if(props.id == 'VS'){
            setTestResultFileIds(csv)
            }else if (props.id == 'MQ') {
            setMQRIds(csv)
            }else if (props.id == 'ID') {
            setUploadIds(csv)
            }
          }, context)
        }
        resolve(baseURL);

      };
    });
  };

  const onChange = (event) => {
    console.log('onchange calling')
    if (props.id == 'CP'){  
    if (getTempCompanyId() == ''){
      context.setUser(0,'Error',getMandatoryData('add_documents_protocol','company_mandatory'),'OK')
    }else{
      event.preventDefault();
      var numFiles = event.target.files.length;
      for (var i = 0, numFiles = event.target.files.length; i < numFiles; i++) {
        console.log('loop calling')
        fileArray.push(event.target.files[i].name)
        fileObj.push(URL.createObjectURL(event.target.files[i]))
        ssnTF.push(true)
        setSsnTF(ssnTF)
        setfileArray(fileArray)
        setfileObj(fileObj)
        getBase64(event.target.files[i])
    }
  }
  }else if (props.id == 'AD'){  
      if(getSsnNumber()=='' || getSsnNumber()==undefined ){
        context.setUser(0,'Error',getMandatoryData('add_documents_protocol','ssn'),'OK')
    } else if(getSsnNumber().length !== 11){
      context.setUser(0,'Error',getMandatoryData('add_documents_protocol','ssn_valid'),'OK')
    } else if(getFirstName()=='' || getFirstName()==undefined ){
      context.setUser(0,'Error',getMandatoryData('add_documents_protocol','first_name'),'OK')
    } else if(getLastName()=='' || getLastName()==undefined){
      context.setUser(0,'Error',getMandatoryData('add_documents_protocol','last_name'),'OK')
    }  else{
      event.preventDefault();
      var numFiles = event.target.files.length;
      for (var i = 0, numFiles = event.target.files.length; i < numFiles; i++) {
        console.log('loop calling')
        fileArray.push(event.target.files[i].name)
        fileObj.push(URL.createObjectURL(event.target.files[i]))
        ssnTF.push(true)
        setSsnTF(ssnTF)
        setfileArray(fileArray)
        setfileObj(fileObj)
        getBase64(event.target.files[i])
      }
    }

    }else{
    event.preventDefault();
    var numFiles = event.target.files.length;
    for (var i = 0, numFiles = event.target.files.length; i < numFiles; i++) {
      console.log('loop calling')
      fileArray.push(event.target.files[i].name)
      fileObj.push(URL.createObjectURL(event.target.files[i]))
      ssnTF.push(true)
      setSsnTF(ssnTF)
      setfileArray(fileArray)
      setfileObj(fileObj)
      getBase64(event.target.files[i])
    }
  }

  }
  const onClickChange = (event) => {
    event.target.value = null
  }

  const resetFile = (event) => {
    setshowPop(true)
    setdeleteEvent(event)
  }
  const deleteDoc = () => {
    setshowPop(false)

    let index = fileArray.indexOf(deleteEvent)
    if (index > -1) {
      fileArray.splice(index, 1)
      fileObj.splice(index, 1)
      setfileArray(fileArray)
      setfileObj(fileObj)
      ssnTF.splice(index, 1)
      setSsnTF(ssnTF)

    if (props.id == 'ID') { 
      postRequest(props.urlDeletePath,
        {
          sKey: getUser(),
          iIDcardFileId: fileIDArray[index]
        },
        (response) => {
          //console.log(response.d);
          fileIDArray.splice(index, 1)
          setfileIDArray(fileIDArray)
          var csv = fileIDArray.join()
          props.onUpload(csv)
          setUploadIds(csv)
        }, context)

    }else if (props.id == 'VS'){
      postRequest(props.urlDeletePath,
        {
          sKey: getUser(),
          iTestResultId: fileIDArray[index]
        },
        (response) => {
          //console.log(response.d);
          fileIDArray.splice(index, 1)
          setfileIDArray(fileIDArray)
          var csv = fileIDArray.join()
          props.onUpload(csv)
          setTestResultFileIds(csv)
        }, context)
    }else if (props.id == 'MQ'){
      postRequest(props.urlDeletePath,
        {
          sKey: getUser(),
          iTestResultId: fileIDArray[index]
        },
        (response) => {
          //console.log(response.d);
          fileIDArray.splice(index, 1)
          setfileIDArray(fileIDArray)
          var csv = fileIDArray.join()
          props.onUpload(csv)
          setMQRIds(csv)
        }, context)
    }else if (props.id == 'AD'){  
      fileIDArray.splice(index, 1)
      setfileIDArray(fileIDArray)
      var csv = fileIDArray.join()
      props.onUpload(csv)
      setADIds(csv)
    }else if (props.id == 'CP'){  
      fileIDArray.splice(index, 1)
      setfileIDArray(fileIDArray)
      var csv = fileIDArray.join()
     // props.onUpload(csv)
      setADIds(csv)
    }
     
      // postRequest(props.urlDeletePath,
      //   {
      //     sKey: getUser(),
      //     iTestResultId: fileIDArray[index]
      //   },
      //   (response) => {
      //     //console.log(response.d);
      //     fileIDArray.splice(index, 1)
      //     setfileIDArray(fileIDArray)
      //     var csv = fileIDArray.join()
      //     props.onUpload(csv)
      //     setTestResultFileIds(csv)
      //   }, context)

    }

  }

  const handleShowDialog = (event) => {
    setfileShow(fileArray.indexOf(event))
    setisOpen(!isOpen);
  };

  const openInNewTab = (url) => {
    setfileShow(fileArray.indexOf(url))
    setpdfisOpen(!pdfisOpen);
    // var index = fileArray.indexOf(url)
    // window.open(fileObj[index], '_blank', 'noreferrer');
  };


  function onDocumentLoadSuccess(props) {
    setNumPages(props.numPages);
  }

  return (

    <div>
      <div >
      {showLbl && (
        <> 
        <label style={ (props.id == 'ID' || props.id == 'IDs') ? { paddingLeft: 5, color: 'black', fontSize: '16px', fontWeight: 'bold', color: 'grey' } : { paddingLeft: 5, color: 'black' }}>{props.id == 'ID' ? (isUploadID_Mandatory_Field ? Parser(getMandatoryData('visitPage', 'takePictureIDLbl')) : Parser(getMandatoryData('visitPage', 'id_card_heading'))) : props.id == 'MQ' ? Parser(getMandatoryData('visitPage', 'mqr_sub_heading')) : props.data}</label>
        </>
       )}
       {props.id != 'IDs' && (
        <>
        <label htmlFor={props.id} style={{ paddingLeft: 5, color: 'blue' }}>
          <img src={AttachFile} style={{ width: '30px', height: '30px', cursor: "pointer" }}></img>

        </label>
        <input onClick={onClickChange} onChange={onChange} id={props.id} style={{ visibility: "hidden" }} type={"file"}></input>
        </>
       )}

      </div>


      <div>

        {fileArray != undefined && fileArray.map((Name, i) => {
          return (
            <div >
              <label onClick={() => { if (Name.includes('.pdf') || Name.includes('.PDF')){openInNewTab(Name)}else{ handleShowDialog(Name)} }} style={{ paddingLeft: 5, color: 'blue', textDecorationLine: 'underline', }}>{Name}</label>
              {ssnTF[i] && props.id != 'IDs' && ( <CancelIcon onClick={() => { resetFile(Name) }} style={{ color: '#D92A28', cursor: "pointer" }} />
              )}
            </div>
          )
        })}
        {isOpen && (
          <Modal
            show={true}
            onHide={() => { handleShowDialog() }}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              <img
                src={fileObj[fileShow]}
                style={{ width: "100%", height: "100%", }}
                alt="no image"
              />
              {/* {fileObj[fileShow].includes(".pdf") && (
                    //  <iframe alt="no PDF" src={fileObj[fileShow]} type="application/pdf" style={{ width: "100%", height: "100%", }} />
                     <object alt="no PDF" data={fileObj[fileShow]} type="application/pdf" style={{ width: "100%", height: "200%", }} />
            )} */}
          
            {/* {!fileObj[fileShow].includes(".pdf") && (
              <img
                src={fileObj[fileShow]}
                style={{ width: "100%", height: "100%", }}
                alt="no image"
              /> */}
            </Modal.Body>
          </Modal>

        )}

       {pdfisOpen && (
          <Modal
            show={true}
            onHide={() => { openInNewTab() }}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ height: "100%", }}
          >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
             
               {/* <Document file={fileObj[fileShow]}  options={options}>
               {Array.from(new Array(numPages), (el, index) => (
               <Page key={`page_${index + 1}`} pageNumber={index + 1} />
               ))}
                </Document> */}

<div className="PDF">
      <div className="PDF__container">
        <div className="PDF__container__document">
          <Document file={fileObj[fileShow]} onLoadSuccess={onDocumentLoadSuccess}  options={options}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </div>
      </div>
    </div>
             
            </Modal.Body>
          </Modal>
        )}

      </div>
      <MyVerticallyCenteredModal
        okText={'Yes'}
        cancelText={'No'}
        headerName={'Info'}
        icon={InfoIcon}
        data={props.id == 'ID' ? getMandatoryData('visitPage', 'delete_id_card') : props.id == 'MQ' ? getMandatoryData('visitPage', 'mqr_delete_message') : props.etext}
        show={showPop}
        cancel={true}
        onHide={() => { deleteDoc() }}
        onCancel={() => setshowPop(false)}
      />
    </div>

  );

  function getPreVisitTestResults(VisitId) {
    if (props.id == 'VS') {
      setSsnTF([])
      setfileIDArray([])
      props.onUpload('')
      setTestResultFileIds('')
      setfileObj([])
      setfileArray([])
      if (VisitId != ''){
    postRequest('ws_webrtc/Telemed.asmx/GetTestFilesUploadedAtProviderEnd',
      { sKey: getUser(), iVisitId: VisitId },
      (response) => {
      // console.log(response)
          if (response.d.length !== 0) {
            response.d.map((item) => {

              ssnTF.push(true)
              setSsnTF(ssnTF)

              fileIDArray.push(item.Test_Result_File_Id)
              setfileIDArray(fileIDArray)
              var csv = fileIDArray.join()
              props.onUpload(csv)
              setTestResultFileIds(csv)
              var imagefile = getBaseUrl() + item.message
              fileObj.push(imagefile)
              setfileObj(fileObj)

              fileArray.push(item.Test_Result_File_Name)
              setfileArray(fileArray)

            })
          }
    
      }, context)
    }
    }else if (props.id == 'ID' || props.id == 'IDs') {
      setSsnTF([])
      setfileIDArray([])
      if (props.id == 'ID'){
      props.onUpload('')
      }
      setUploadIds('')
      setfileObj([])
      setfileArray([])
      if (VisitId != ''){
    postRequest('ws_webrtc/Telemed.asmx/GetIDCardsUploaded',
      { sKey: getUser(), iVisitId: VisitId },
      (response) => {
      // console.log(response)
          if (response.d.length !== 0) {
            response.d.map((item) => {

              ssnTF.push(true)
              setSsnTF(ssnTF)

              fileIDArray.push(item.ID_Card_File_Id)
              setfileIDArray(fileIDArray)
              var csv = fileIDArray.join()

            //  props.onUpload(csv)

              if (props.id == 'ID'){
                props.onUpload(csv)
              }

              setUploadIds(csv)
              var imagefile = getBaseUrl() + item.message
              fileObj.push(imagefile)
              setfileObj(fileObj)

              fileArray.push(item.ID_Card_File_Name)
              setfileArray(fileArray)

            })
          }else{
            if (props.id == 'IDs'){
              setShowLbl(false)
            }
          }
    
      }, context)
    }
    }else if (props.id == 'MQ') {
      setSsnTF([])
      setfileIDArray([])
      props.onUpload('')
      setMQRIds('')
      setfileObj([])
      setfileArray([])
      if (VisitId != ''){
    postRequest('ws_webrtc/Telemed.asmx/GetMQRFilesUploaded',
      { sKey: getUser(), iVisitId: VisitId },
      (response) => {
      // console.log(response)
          if (response.d.length !== 0) {
            response.d.map((item) => {

              ssnTF.push(true)
              setSsnTF(ssnTF)

              fileIDArray.push(item.Test_Result_File_Id)
              setfileIDArray(fileIDArray)
              var csv = fileIDArray.join()
              props.onUpload(csv)
              setMQRIds(csv)
              var imagefile = getBaseUrl() + item.message
              fileObj.push(imagefile)
              setfileObj(fileObj)

              fileArray.push(item.Test_Result_File_Name)
              setfileArray(fileArray)

            })
          }
    
      }, context)
    }
    }else if (props.id == 'AD') {
      setSsnTF([])
      setfileIDArray([])
      props.onUpload('')
      setADIds('')
      setfileObj([])
      setfileArray([])
      if (VisitId != ''){
    postRequest('ws_webrtc/Telemed.asmx/GetPatientDocumentsUploaded',
      { sKey: getUser(), iPrevDocumentId: VisitId },
      (response) => {
      // console.log(response)
          if (response.d.length !== 0) {
            response.d.map((item) => {

              ssnTF.push(true)
              setSsnTF(ssnTF)

              fileIDArray.push(item.Test_Result_File_Id)
              setfileIDArray(fileIDArray)
              var csv = fileIDArray.join()
              props.onUpload(csv)
              setADIds(csv)
              var imagefile = getBaseUrl() + item.message
              fileObj.push(imagefile)
              setfileObj(fileObj)

              fileArray.push(item.Test_Result_File_Name)
              setfileArray(fileArray)

            })
          }
    
      }, context)
    }
    }else if (props.id == 'CP') {
      setSsnTF([])
      setfileIDArray([])
     // props.onUpload('')
      setADIds('')
      setfileObj([])
      setfileArray([])
      if (VisitId != ''){
    postRequest('ws_webrtc/Telemed.asmx/GetCompanyProtocolDocumentsUploaded',
      { sKey: getUser(), iCompanyProtocolDocumentId: VisitId },
      (response) => {
      // console.log(response)
          if (response.d.length !== 0) {
            response.d.map((item) => {

              ssnTF.push(true)
              setSsnTF(ssnTF)

              fileIDArray.push(item.CompanyProtocol_Document_Detail_Id)
              setfileIDArray(fileIDArray)
              var csv = fileIDArray.join()
              props.onUpload(csv)
              setADIds(csv)
              var imagefile = getBaseUrl() + item.message
              fileObj.push(imagefile)
              setfileObj(fileObj)

              fileArray.push(item.CompanyProtocol_Document_File_Name)
              setfileArray(fileArray)

            })
          }
    
      }, context)
    }
    }
  }

  function getDocumentsPerSSN(ssn) {
    if (ssn == ''){
      if (props.id == 'VS' || props.id == 'MQ' || props.id == 'ID') {
              setSsnTF([])

              setfileIDArray([])
              props.onUpload('')
              setTestResultFileIds('')
              setMQRIds('')
              setUploadIds('')
              setfileObj([])

              setfileArray([])
      }
    }else{
    postRequest('ws_webrtc/Telemed.asmx/GetPatientDocumentsUploadedPerSSN',
      { sKey: getUser(), sSSN: ssn },
      (response) => {
        //console.log(response.d);
        if (props.id == 'VS') {
          if (response.d.length !== 0) {
            response.d.map((item) => {

              //console.log(item.message);
              //console.log(item.Test_Result_File_Id);
              //console.log(item.Test_Result_File_Name);

              ssnTF.push(false)
              setSsnTF(ssnTF)

              fileIDArray.push(item.Test_Result_File_Id)
              setfileIDArray(fileIDArray)
              var csv = fileIDArray.join()
              props.onUpload(csv)
              setTestResultFileIds(csv)
              var imagefile = getBaseUrl() + item.message
              //console.log(imagefile)

              fileObj.push(imagefile)
              setfileObj(fileObj)

              fileArray.push(item.Test_Result_File_Name)
              setfileArray(fileArray)

            })
          }
        }
      }, context)
  }
}
}
export default UploadPreview;