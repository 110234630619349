import React, { useState } from "react";

import { useRef, useEffect } from "react";
import FormControls from "../components/formControls";
import {
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Checkbox,
  TextareaAutosize,
  Box,
  OutlinedInput,
  InputAdornment,
  Paper,
} from "@mui/material";
import SubHeader from "./subHeader";
import RowComponent from "./rowComponent";
export default function CustomDivComponent(props) {
  // console.log("hello upload document", props.isShowPatientEmail);
  const [events, setEvents] = React.useState(props);
  const [visitTypes, setVisitTypes] = React.useState(
    props.visitCategoryTypeData
  );
  const [purposeTypes, setPurposeTypes] = React.useState(props.purposeValue);
  const [drugTypes, setDrugTypes] = React.useState(props.drugValue);
  const [countries, setCountries] = React.useState(props.countryValue);
  const [jobCategoryArray, setJobCategoryArray] = React.useState(
    props.jobCategoryValue
  );
  const [branches, setBranches] = React.useState(props.branchValue);
  const [states, setStates] = React.useState(props.stateValue);
  const [languages, setLanguages] = React.useState(props.languageValue);
  const [visitReason, setVisitReason] = React.useState(props.reasonValue);
  const [services, setServices] = React.useState(props.serviceValue);
  const [gender, setGender] = React.useState(props.genderValue);
  const [dlnValue, setDlnState] = React.useState(props.dlnValue);
  const [timeSlots, setTimeSlots] = React.useState(props.timeValue);
  const [loadSSNDocuemnts, setSSNForDocuments] = useState("");
  const [countryAbbr, setCountryAbbr] = React.useState(props.countryAbbr);
  const [value, setValue] = React.useState(props.loadValues);
  const [remotePhoenNo, setremotePhoenNo] = React.useState("");
  //for patient mail
  const [patientEmail, setPatientEmail] = React.useState(
    props.isShowPatientEmail
  );
  const [loadSSN, setLoadSSN] = useState(props.loadValues.ssn);
  const [additionalEmail, setAdditionalEmail] = React.useState(
    props.isShowAdditionalEmail
  );

  const [SRMailValue, setSRMailValue] = useState(props.SREmail);
  //for job and region
  const [jobTitle, setJobTitle] = React.useState(props.jobTitleValue);
  const [region, setRegion] = React.useState(props.regionValue);

  const datePickerChange = (date, name) => {
    props.datePickerChange(date, name);
  };
  const { onFieldChange } = props;
  // const handleFieldChange = (fieldId, value) => {
  //     if (fieldId == "Visit_Category_Type_Id") {
  //         console.log("category visit types", visitTypes);
  //         var selectedItem = visitTypes.filter((item) => item.name === value);
  //         value = selectedItem[0] == undefined ? 0 : selectedItem[0].id;
  //         alert(fieldId)
  //     } else if (fieldId == "PurposeOfVisit_Id") {
  //         var selectedItem = purposeTypes.filter((item) => item.name === value);
  //         value = selectedItem[0] == undefined ? 0 : selectedItem[0].id;
  //     } else if (fieldId == "DrugType_Id") {
  //         var selectedItem = drugTypes.filter((item) => item.DrugType === value);
  //         value = selectedItem[0] == undefined ? 0 : selectedItem[0].DrugType_Id;
  //     } else if (fieldId == "Country_Id") {
  //         var selectedItem = countries.filter((item) => item.Country_Name === value);
  //         value = selectedItem[0] == undefined ? 0 : selectedItem[0].Country_Id;
  //     } else if (fieldId == "branch_id" || fieldId == "BranchID") {
  //         var selectedItem = branches.filter((item) => item.name === value);
  //         value = selectedItem[0] == undefined ? 0 : selectedItem[0].id;
  //     } else if (fieldId == "state_id" || fieldId == "StateID") {
  //         var selectedItem = states.filter((item) => item.name === value);
  //         value = selectedItem[0] == undefined ? 0 : selectedItem[0].id;
  //     } else if (fieldId == "language_id") {
  //         var selectedItem = languages.filter((item) => item.name === value);
  //         value = selectedItem[0] == undefined ? 0 : selectedItem[0].id;
  //     } else if (fieldId == "SexID") {
  //         var selectedItem = gender.filter((item) => item.SexName === value);
  //         value = selectedItem[0] == undefined ? 0 : selectedItem[0].SexID;
  //     } else if (fieldId == "DLNState") {
  //         var selectedItem = dlnValue.filter((item) => item.StateName === value);
  //         value = selectedItem[0] == undefined ? 0 : selectedItem[0].StateAbbr;
  //     } else if (fieldId == "CorporateExams") {
  //         var selectedItem = services.filter((item) => item.ExamName === value);
  //         value = selectedItem[0] == undefined ? 0 : selectedItem[0].ExamID;
  //     } else if (fieldId == "date_of_birth") {
  //         //const temp_array = value.split('T');
  //         //value = temp_array[0];
  //     } else if (fieldId == "date_of_injury") {
  //         //const temp_array = value.split('T');
  //         //value = temp_array[0];
  //     }
  //     props.onFieldChange(fieldId, value);
  // };

  useEffect(() => {
    // console.log("Country111", props.visitCategoryTypeData)
    setVisitTypes(props.visitCategoryTypeData);
    setPurposeTypes(props.purposeValue);
    setDrugTypes(props.drugValue);
    setCountries(props.countryValue);
    setJobCategoryArray(props.jobCategoryValue);
    setBranches(props.branchValue);
    setStates(props.stateValue);
    setLanguages(props.languageValue);
    setVisitReason(props.reasonValue);
    setServices(props.serviceValue);
    setGender(props.genderValue);
    setDlnState(props.dlnValue);
    setSSNForDocuments(props.loadSSNDocuemnts);
    setValue(props.loadValues);
    setCountryAbbr(props.countryAbbr);
    setTimeSlots(props.timeValue);
    // for patient EMail
    setPatientEmail(props.isShowPatientEmail);
    setAdditionalEmail(props.isShowAdditionalEmail);
    //job title and region
    // if (props.jobTitleValue !== undefined && props.jobTitleValue === "") {
    setJobTitle(props.jobTitleValue);
    setRegion(props.regionValue);
    setLoadSSN(props.loadValues.ssn);
    setSRMailValue(props.SREmail);

    // }
  }, [
    props.visitCategoryTypeData,
    props.jobCategoryValue,
    props.purposeValue,
    props.drugValue,
    props.countryValue,
    props.branchValue,
    props.languageValue,
    props.reasonValue,
    props.serviceValue,
    props.genderValue,
    props.dlnValue,
    props.documentsVisitID,
    props.sherwinText,
    props.loadSSNDocuemnts,
    props.loadValues,
    props.countryAbbr,
    props.timeValue,
    //patient Email
    props.isShowPatientEmail,
    props.isShowAdditionalEmail,
    //job title and region
    props.jobTitleValue,
    props.regionValue,
    props.loadValues.ssn,
    props.SREmail,
  ]);

  function getDropDownData(id) {
    if (id == "visit_type") {
      return visitTypes;
    } else if (id == "purpose_type") {
      return purposeTypes;
    } else if (id == "drug_type") {
      return drugTypes;
    } else if (id == "country") {
      return countries;
    } else if (id == "Job_Category") {
      return jobCategoryArray;
    } else if (id == "branch") {
      return branches;
    } else if (id == "state") {
      return states;
    } else if (id == "language") {
      return languages;
    } else if (id == "visit_reason") {
      return visitReason;
    } else if (id == "services") {
      return services;
    } else if (id == "gender") {
      return gender;
    } else if (id == "dlnstate") {
      return dlnValue;
    } else if (id == "time") {
      return timeSlots;
    } else if (id == "job_title") {
      return jobTitle;
    } else if (id == "region") {
      return region;
    } else {
      return purposeTypes;
    }
  }

  const buildRow = (child_props) => {
    return (
      <>
        <div style={{ height: 10 }}></div>
        {(child_props.headerName != "Medical Questionnaire" ||
          !props.hideMQR) && (
          <div className="contentbody">
            {child_props.headerName != "" && (
              <SubHeader headerName={child_props.headerName} />
            )}

            {child_props.rowData.map((rowdata) => {
              return (
                <div className="row px-3">
                  {rowdata.columnData.map((item) => {
                    {
                      return (
                        <>
                          <div className={item.colClassName}>
                            {props.hideControl !== item.tag_id &&
                              (item.controlId !== "Job_Category" ||
                                !props.hideJC) &&
                              props.hideClaim !== item.tag_id &&
                              (item.controlId !== "MQ" || !props.hideMQR) &&
                              (item.tag_id != "Job_title_Id" ||
                                props.unitedRentals) &&
                              (item.tag_id != "Job_region_Id" ||
                                props.hideRegionValue) && (
                                <FormControls
                                  type={item.controlType}
                                  placeholder={item.placeholder}
                                  label={item.label}
                                  name={item.label}
                                  default={item.defaultValue}
                                  className={item.className}
                                  controlId={item.controlId}
                                  urlPath={item.urlPath}
                                  urlDeletePath={item.urlDeletePath}
                                  urlUploadKey={item.urlUploadKey}
                                  visitTypes={visitTypes}
                                  purposeTypes={purposeTypes}
                                  dropdowndata={getDropDownData(item.controlId)}
                                  tagid={item.tag_id}
                                  tagvalue={item.tag_value}
                                  options={item.options}
                                  loadSSNDocuemnts={loadSSNDocuemnts}
                                  onFieldChange={onFieldChange}
                                  value={value[item.tag_id]}
                                  datePickerChange={props.datePickerChange}
                                  remotePhoneNo={props.remotePhoneNo}
                                  remotePatientYN={props.remotePatientYN}
                                  isRadioBtnChecked={props.isRadioBtnChecked}
                                  countryAbbr={props.countryAbbr}
                                  sherwinText={props.sherwinText}
                                  patientName={props.patientName}
                                  documentsVisitID={props.documentsVisitID}
                                  isClaimEnabled={props.isClaimEnabled}
                                  isDisable={child_props.isDisable}
                                  // dropdownSelectedValue={props.visittype}
                                  //for patient EMail
                                  patientEmailStatus={patientEmail}
                                  additionalEmailStatus={additionalEmail}
                                  // for test
                                  unitedRentalsValue={props.unitedRentals}
                                  ssn={loadSSN}
                                  SRPatientEmail={SRMailValue}
                                />
                              )}
                          </div>

                          <div style={{ height: 10 }}></div>
                        </>
                      );
                    }
                  })}
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  };

  return props.data.map((item) => {
    return buildRow(item);
  });
}
