import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from "react";


function AnswerPopup(props) {
    return (
        <Modal
            {...props}
            backdrop="static"
            keyboard={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className='p-3'>
            <div className='m-3 border-bottom border-secondary'>
            <div className='col d-flex justify-content-center'>
            <label style={{ display: 'flex', fontSize: "20px", color: 'black', fontWeight: 'normal' }}>{props.data}</label>
            </div>
            </div>

            <div className='row'>
            <div className='col d-flex justify-content-center' >
            <label onClick={props.onHide} style={{ backgroundColor:'white', color:'#05baf5' }} >{props.okText}</label>
            </div>
            </div>
            </div>
        </Modal>
    );
}

export default AnswerPopup