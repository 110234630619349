import { Paper } from "@mui/material";
import { useEffect, useState, useContext, useRef } from "react";
import UserContext from '../components/context';
import { setADIds, postRequest, getUser, getCompanyId, getMandatoryData, setVisitId, setVisitType, setFutureVisitFromSMDashboard, getBranchId, setSsnNumber, setFirstName, setLastName, setiPrevDocumentId } from "../Services/DataMode";
import CancelIcon from '@mui/icons-material/Cancel';
import UpdateIcon from '@mui/icons-material/Update';
import Icon from '@mui/material/Icon';
import React from "react";
import VisitsList from "../components/VisitsList";
import ReactLoading from 'react-loading';
import { useNavigate } from "react-router-dom";
import SearchView from "../components/SearchView";


function AddDocumentsDashboard(props) {
  const [loading, setloading] = useState(true);
  const [visits, setVisits] = useState([]);
  const userInfo = useContext(UserContext).user;
  const context = useContext(UserContext);
  const [reloading, setreloading] = useState(false);
  const ref = useRef();
  let navigate = useNavigate();
  const [getData, setGetData] = useState(false);

  const searchRef = useRef();
  const data = [
    { id: '1', title: 'First item' },
    { id: '2', title: 'Second item' },
    { id: '3', title: 'Third item' },
    { id: '4', title: 'Fourth item' }
  ];

  useEffect(() => {
    props.updatePage();
    loadDashboard();

    setSsnNumber('')
    setFirstName('')
    setLastName('')
    setADIds('')
    setiPrevDocumentId('')

  }, [reloading])

  function loadDashboard() {
    setloading(true);
    postRequest('ws_webrtc/telemed.asmx/GetPatientDocumentsDashboardList',
      { sKey: getUser(), sPatientName: "", iCompanyId: getCompanyId() , iCompanyBranchId: getBranchId() },
      (response) => {
        console.log(response);
        setloading(false);
        setVisits(response.d);
      }, context)
  }

  function visitDeleteClicked(item) {

    var str = 'Do you want to cancel the visit for patient <XXXXXXXXX>  ?'
    var new_str = str.replace(/<XXXXXXXXX>/g, item.first_name + " " + item.last_name);
    var array = ['Do you want to delete the document for ', item.First_Name + " " + item.Last_Name, ' ?']

    context.setUser(1, "Info", array, 'Yes', true, 'No', () => { console.log(false) }, () => { deleteVisit(item.PrevDocument_Id, item.first_name, item.last_name) });
  }

  function deleteVisit(visit_id, first_name, last_name) {
    // setloading(true);
    postRequest('ws_webrtc/Telemed.asmx/DeletePatientDocument',
      { sKey: getUser(), iPrevDocumentsId: visit_id },
      (response) => {
        // setloading(false);
        var str = 'Visit for patient <XXXXXXXXX> cancelled successfully.'
        var new_str = str.replace(/<XXXXXXXXX>/g, first_name + " " + last_name);
        var array = ['Visit for patient ', first_name + " " + last_name, ' cancelled successfully.']

        context.setUser(2, "Success", array, 'OK')
        setreloading(!reloading)
      }, context)
  }

  function patientSelected(item) {
    setVisits([]);
    setloading(true);
    postRequest('ws_webrtc/telemed.asmx/GetPatientDocumentsDashboardList',
      { sKey: getUser(), sPatientName: item.Patient_Name, iCompanyId: getCompanyId() , iCompanyBranchId: getBranchId()  },
      (response) => {
        //console.log(response);
        setloading(false);
        setVisits(response.d);
      }, context)
  }

  function clearVisitsWhileLoading(item) {
    console.log(item.clear_visit)
    if (item.clear_visit >= 1) {
      setGetData(true);
      setVisits([]);
    } else {
      if (getData) {
        setGetData(false);
        loadDashboard();
      }
    }
  }

  function navigateToCreateVisit(item) {
    // setVisitId(item.visit_id);
    // setVisitType('Future');
    // setFutureVisitFromSMDashboard('False');
    // navigate('/App/CreateVisit');
    console.log(item)
//PrevDocument_Id
    var array = ['Do you want to edit the document for ', item.First_Name + " " + item.Last_Name, ' ?']

    context.setUser(1, "Info", array, 'OK', true, 'Cancel',
     () => { console.log(false) },
      () => { navigate('/App/AddDocumentsCreateUpdate');
              console.log(item.PrevDocument_Id);
               setiPrevDocumentId(item.PrevDocument_Id);
              setFirstName(item.First_Name);
            setLastName(item.Last_Name);
          setSsnNumber(item.SSN) });

  }
  // useEffect(() => {


  //   handleResize();
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   }

  // }, []);
  const handleResize = () => {

    const top = ref.current;
    const search = searchRef.current;
    if (window.innerWidth <= 960) {
      top.className = "col-lg-12 col-md-12 col-xs-12 col-sm-12 paddingLR"
      search.className = "marginRight10"
    } else {
      top.className = "col-lg-7 col-md-7 col-sm-7 col-sm-7 container"
      search.className = "marginRight35"
    }
  }
  return (
    <div className="h-100 col-12 p-2">
      {loading && (
        <div style={{
          position: 'absolute', left: '50%', top: '50%',
          transform: 'translate(-50%, -50%)', height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
        }}>
          <ReactLoading type={'spin'} color={'#ff0000'} height={'5%'} width={'5%'} />
        </div>
      )}
      <div className="sticky-top bg-white" style={{ zIndex: 99 }}>
        <SearchView isDisable={loading} heading="Patient Search:" placeholder={"Patient Search"} url={"ws_webrtc/telemed.asmx/GetPatientDocumentsDashboardSearch"} handleClick={patientSelected} clearVisits={clearVisitsWhileLoading}></SearchView>
      </div>
      <div className="px-1">
        <VisitsList deleteDocumentIcon={true} data={visits} handleClick={navigateToCreateVisit} handleDeleteClick={visitDeleteClicked} />
      </div>
    </div >
  );
}

export default AddDocumentsDashboard;