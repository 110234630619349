import { Paper } from "@mui/material";
import { useEffect, useState, useContext, useRef } from "react";
import UserContext from "../components/context";
import {
  postRequest,
  getUser,
  getCompanyId,
  getVisitId,
  setVisitId,
  getMandatoryData,
  getisGlobal,
} from "../Services/DataMode";
import CancelIcon from "@mui/icons-material/Cancel";
import UpdateIcon from "@mui/icons-material/Update";
import Icon from "@mui/material/Icon";
import React from "react";
import VisitsList from "../components/VisitsList";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import SearchView from "../components/SearchView";
import ShowSelectPopup from "../components/ShowSelectPopup";
import InfoIcon from "../Images/information-icon.png";

function CompletedVisitsDashboard(props) {
  const [loading, setloading] = useState(true);
  const [showPopUP, setShowPopUP] = useState(false);
  const [selectedID, setSelectedID] = useState(0);
  const [selectedYN, setSelectedYN] = useState(true);
  const [selectedItem, setSelectedItem] = useState();
  const [visits, setVisits] = useState([]);
  const userInfo = useContext(UserContext).user;
  const context = useContext(UserContext);
  let navigate = useNavigate();
  const ref = useRef();
  const [getData, setGetData] = useState(false);

  const searchRef = useRef();

  useEffect(() => {
    props.updatePage();
    loadDashboard();
  }, []);

  function loadDashboard() {
    setloading(true);
    if (getisGlobal() !== "SM") {
      setloading(false);
    } else {
      postRequest(
        "ws_webrtc/util.asmx/getSMCompletedVisitsPerPatientName",
        {
          iPurposeOfVisit: "0",
          iCompanyId: getCompanyId(),
          bFollowup: false,
          sPatientName: "",
          sKey: getUser(),
          sSSN: "",
          iVisitCatergory: "0",
        },
        (response) => {
          //console.log(response);
          setloading(false);
          setVisits(response.d);
        },
        context
      );
    }
  }

  function patientSelected(selectedValue) {
    setVisits([]);
    setloading(true);
    postRequest(
      getisGlobal() !== "SM"
        ? "ws_webrtc/util.asmx/getGlobalSMCompletedVisitsPerPatientName"
        : "ws_webrtc/util.asmx/getSMCompletedVisitsPerPatientName",
      { sKey: getUser(), sSSN: selectedValue.ssn },
      (response) => {
        //console.log(response);
        setloading(false);
        setVisits(response.d);
      },
      context
    );
  }

  function clearVisitsWhileLoading(item) {
    console.log(item.clear_visit);
    if (item.clear_visit >= 1) {
      setGetData(true);
      setVisits([]);
    } else {
      if (getData) {
        setGetData(false);
        loadDashboard();
      }
    }
  }

  function showEmailPopup(item) {
    context.setUser(
      1,
      "Info",
      getMandatoryData("sm_dashboard", "completed_visit_email_popup_message"),
      getMandatoryData("sm_dashboard", "completed_visit_email_popup_ok_btn"),
      true,
      getMandatoryData(
        "sm_dashboard",
        "completed_visit_email_popup_cancel_btn"
      ),
      null,
      () => {
        sendEmail(item.visit_id);
      }
    );
  }
  function showSelectPopup(item) {
    setSelectedYN(true);
    setSelectedID(0);
    setSelectedItem(item);
    setShowPopUP(true);
  }
  function sendEmail(visit_id) {
    console.log(selectedItem);
    setloading(true);
    postRequest(
      "ws_webrtc/util.asmx/RequestEmail",
      { sKey: getUser(), iVisitId: visit_id },
      (response) => {
        setloading(false);
        var array = [
          "Email sent successfully for ",
          selectedItem.first_name + " " + selectedItem.last_name,
          " ",
        ];
        context.setUser(2, "Success", array, "OK");
      },
      context
    );
  }

  function popOkClick() {
    // setShowPopUP(false)
    if (selectedID == 0) {
      console.log("Redirect to edit page");
      setVisitId(selectedItem.visit_id);
      navigate("/App/EditVisitPage");
    } else {
      console.log("Redirect to email popup");
      setShowPopUP(false);
      showEmailPopup(selectedItem);
    }
  }
  function popCancelClick() {
    setShowPopUP(false);
  }
  function handleFieldChange(event) {
    var value = event.target.value;
    console.log(value);
    setSelectedYN(!selectedYN);
    setSelectedID(value);
  }
  const handleResize = () => {
    const top = ref.current;
    const search = searchRef.current;
    if (window.innerWidth <= 960) {
      top.className = "col-lg-12 col-md-12 col-xs-12 col-sm-12  paddingLR";
      search.className = "marginRight10";
    } else {
      top.className = "col-lg-7 col-md-7 col-sm-7 col-sm-7 container";
      search.className = "marginRight35";
    }
  };
  return (
    <div className="h-100 col-12 p-2">
      {loading && (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactLoading
            type={"spin"}
            color={"#ff0000"}
            height={"5%"}
            width={"5%"}
          />
        </div>
      )}
      <div className="sticky-top bg-white" style={{ zIndex: 99 }}>
        <SearchView
          isDisable={loading}
          heading="Patient Search:"
          placeholder={"Search Patient"}
          url={
            getisGlobal() !== "SM"
              ? "ws_webrtc/util.asmx/GetGlobalSMCompletedVisitsPatients"
              : "ws_webrtc/util.asmx/GetSMCompletedVisitsPatients"
          }
          handleClick={patientSelected}
          showssn={true}
          clearVisits={clearVisitsWhileLoading}
        ></SearchView>
      </div>
      <div className="px-1">
        <VisitsList
          deleteIcon={false}
          data={visits}
          handleClick={
            getMandatoryData("sm_dashboard", "show_edit_vist")
              ? showSelectPopup
              : showEmailPopup
          }
        />
        <ShowSelectPopup
          show={showPopUP}
          okText={getMandatoryData("sm_dashboard", "edit_visit_pop_up_ok_btn")}
          cancelText={getMandatoryData(
            "sm_dashboard",
            "edit_visit_pop_up_cancel_btn"
          )}
          headerName={getMandatoryData(
            "sm_dashboard",
            "edit_visit_pop_up_message"
          )}
          icon={InfoIcon}
          handleFieldChange={handleFieldChange}
          data={[
            {
              label: getMandatoryData("sm_dashboard", "edit_option_text"),
              value: 0,
              default: getMandatoryData("sm_dashboard", "edit_option_text"),
            },
            {
              label: getMandatoryData("sm_dashboard", "email_option_text"),
              value: 1,
              default: "",
            },
          ]}
          selectedYN={selectedYN}
          cancel={true}
          onHide={popOkClick}
          onCancel={popCancelClick}
        />
      </div>
    </div>
  );
}

export default CompletedVisitsDashboard;
