import React, {
  useEffect,
  useContext,
  useState,
  Fragment,
  useRef,
} from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import {
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Checkbox,
  TextareaAutosize,
  Box,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import UserContext from "../components/context";
import IconButton from "@mui/material/IconButton";
import AttachFile from "@mui/icons-material/AttachFile";
import Button from "react-bootstrap/Button";
import "../styles/styles.css";
import { FormCheck } from "react-bootstrap";
import Icon from "@mui/material/Icon";
import { postRequest, getUser, getMandatoryData } from "../Services/DataMode";
import UploadPreview from "../components/UploadPreview";
import SearchIcon from "../Images/search_icon.png";
//import DatePicker from "react-datepicker";
import Dropdown from "react-bootstrap/Dropdown";
// import "react-datepicker/dist/react-datepicker.css";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";
import { green } from "@mui/material/colors";

import chevron_down from "../Images/chevron_down.png";
import chevron_up from "../Images/chevron_up.png";
import { relative } from "path";
import Parser from "html-react-parser";

function FormControls(props) {
  // console.log("hello form control world", props.ssn);
  const [open, setOpen] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [exams, setExams] = useState("");
  const [isRemotePatient, setRemotePatient] = useState();
  const [date, setDate] = useState();
  const [ssn, setSSN] = useState();
  const phoneNoRef = useRef();
  const ssnNoRef = useRef();
  const [response, setResponse] = useState([]);
  const userInfo = useContext(UserContext).user;
  const context = useContext(UserContext);
  const [remotePhoneNo, setRemotePhoneNo] = useState();
  const [selectedValue, setSelectedValue] = useState({});
  const [dropdowndata, setDropDownData] = React.useState([props.dropdowndata]);

  // const [selectedDate, setSelectedDate] = useState(new Date());
  const [examIDs, setExamIDs] = useState([]);
  const handleCheckChange = (event) => {
    if (event.target.checked) {
      examIDs.push(event.target.value);
      setExamIDs(examIDs);
    } else {
      var index = examIDs.indexOf(event.target.value);
      if (index > -1) {
        examIDs.splice(index, 1);
        setExamIDs(examIDs);
      }
    }
    var csv = examIDs.join();
    onFieldChange({ target: { name: event.target.name, value: csv } });

    setExams(csv);
  };
  //const { datePickerChange } = props
  const { onFieldChange } = props;

  var tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const datePickerChange = (date, name) => {
    props.datePickerChange(date, name);
  };

  function subtractYears(date, years) {
    date.setFullYear(date.getFullYear() - years);
    return date;
  }

  function onPaste(e) {
    const str = e.clipboardData.getData("Text");
    //alert(str);
    const newStr = str.replace(/[+-.A-Za-z]/g, "");
    //alert(newStr);
    if (str !== newStr) {
      e.preventDefault();
    }
  }
  //function handleChange(event) {

  // var name = event.target.name;
  // var value = event.target.value;

  // if (name == "RemotePatient_YN") {
  //     (value == 0) ? setRemotePatient(false) : setRemotePatient(true);
  //     (value == 0) ? value = false : value = true;

  // }
  // if (name == "date_of_birth") {
  //     setDate(value)
  // }
  // if (name == "date_of_injury") {
  //     setDate(value)
  // }
  // props.onFieldChange(name, value);
  //}

  function handleClick() {
    if (dropdowndata.length !== examIDs.length) {
      setIsClicked(!isClicked);
    }
  }

  useEffect(() => {
    if (props.type == "dropdownExams") {
      if (props.value != 0) {
        if (props.value !== undefined) {
          setExams(props.value);
          console.log(props.value);
          setExamIDs(props.value.split(","));
          setTimeout(() => {
            setIsClicked(true);
          }, 100);
        }
      }
    }

    setDropDownData(props.dropdowndata);

    setSSN(props.loadSSNDocuemnts);
  }, [props.dropdowndata, props.loadSSNDocuemnts]);

  // eslint-disable-next-line no-lone-blocks
  //{}

  if (props.type == "text" || props.type == "email") {
    return (
      <>
        {(props.patientEmailStatus ||
          (props.label !== "Patient's Email:" &&
            props.label !==
              "Patient's Email<span style='color: red'> * </span>:")) && (
          <div>
            <Form.Label
              style={{
                paddingLeft: 5,
                color: "black",
                fontSize: "16px",
                fontWeight: "bold",
                color: "grey",
              }}
            >
              {Parser(props.label)}
            </Form.Label>

            <Form.Control
              disabled={
                props.isDisable
                  ? true
                  : !props.isClaimEnabled && props.tagid == "Claim_Number"
                  ? true
                  : false
              }
              maxlength={
                props.tagid === "ssn"
                  ? "9"
                  : props.tagid === "SSNumber"
                  ? "11"
                  : ""
              }
              onKeyPress={(event) => {
                if (
                  props.tagid === "ssn" ||
                  props.tagid === "NR_PhoneNumber" ||
                  props.tagid === "Zip_Code"
                ) {
                  if (!/[0-9]/.test(event.key)) {
                    if (props.tagid == "NR_PhoneNumber") {
                      phoneNoRef.current.value = event.key;
                      //setRemotePhoneNo(event.key);
                    }
                    event.preventDefault();
                  }
                } else if (props.tagid === "SSNumber") {
                  if (/[0-9]/.test(event.key) || event.key == "-") {
                    console.log("key pressed");
                    if (
                      ssnNoRef.current.value.length == 11 ||
                      ssnNoRef.current.value.length > 11
                    ) {
                    } else if (ssnNoRef.current.value.length == 3) {
                      ssnNoRef.current.value = ssnNoRef.current.value + "-";
                    } else if (ssnNoRef.current.value.length == 6) {
                      ssnNoRef.current.value = ssnNoRef.current.value + "-";
                    } else {
                      // ssnNoRef.current.value = ssnNoRef.current.value + event.key
                    }
                  } else {
                    event.preventDefault();
                  }
                }
              }}
              onPaste={onPaste}
              type={props.type}
              style={{ color: "#000000" }}
              ref={props.tagid == "SSNumber" ? ssnNoRef : undefined}
              placeholder={props.placeholder}
              defaultValue={
                props.tagid == "patient_name" ? props.patientName : props.value
              }
              onChange={onFieldChange}
              name={props.tagid}
            />
          </div>
        )}
      </>
    );
  } else if (props.type == "textPhone") {
    return (
      <>
        <Form.Label
          style={{
            paddingLeft: 5,
            color: "black",
            fontSize: "16px",
            fontWeight: "bold",
            color: "grey",
          }}
        >
          {Parser(props.label)}
        </Form.Label>
        <div className="row">
          <div className="col-md-1 col-lg-1 col-xs-1 col-sm-1">
            <select
              disabled
              value={props.countryAbbr}
              style={{
                background: "#E2E2E2",
                marginTop: 1,
                height: 36,
                width: "100%",
              }}
            >
              {" "}
              <option>{props.countryAbbr}</option>{" "}
            </select>
          </div>
          <div className="col-md-11 col-lg-11 col-xs-11 col-sm-11">
            <Form.Control
              maxlength={props.tagid === "ssn" ? "9" : ""}
              onKeyPress={(event) => {
                if (
                  props.tagid === "ssn" ||
                  props.tagid === "NR_PhoneNumber" ||
                  props.tagid === "Zip_Code"
                ) {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }
              }}
              onPaste={onPaste}
              type={props.type}
              style={{ color: "#000000" }}
              placeholder={props.placeholder}
              defaultValue={props.value}
              onChange={onFieldChange}
              name={props.tagid}
            />
          </div>
        </div>
      </>
    );
  } else if (props.type == "SR_TextPhone") {
    return (
      <>
        <Form.Label
          style={{
            paddingLeft: 5,
            color: "black",
            fontSize: "16px",
            fontWeight: "bold",
            color: "grey",
          }}
        >
          {Parser(props.label)}
        </Form.Label>
        <div className="row">
          <div className="col-md-1 col-lg-1 col-xs-1 col-sm-1">
            {/* <select disabled value={props.countryAbbr} style={{ background: '#E2E2E2', height: 30, width: '100%'}}>  <option >{props.countryAbbr}</option> </select> */}
            <select
              class="form-select"
              id="country_id"
              className={props.className}
              onChange={onFieldChange}
              name="country_id"
              value={props.countryAbbr}
              style={{
                background: "#E2E2E2",
                height: 36,
                marginTop: 1,
                width: "100%",
              }}
            >
              {dropdowndata != undefined &&
                dropdowndata.map((item, i) => (
                  <option class="active" value={item.id}>
                    {" "}
                    {item.abbr}{" "}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-md-11 col-lg-11 col-xs-11 col-sm-11">
            <Form.Control
              maxlength={props.tagid === "ssn" ? "9" : ""}
              onKeyPress={(event) => {
                if (
                  props.tagid === "ssn" ||
                  props.tagid === "NR_PhoneNumber" ||
                  props.tagid === "Zip_Code"
                ) {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }
              }}
              onPaste={onPaste}
              type={props.type}
              style={{ color: "#000000" }}
              placeholder={props.placeholder}
              defaultValue={props.value}
              onChange={onFieldChange}
              name={props.tagid}
            />
          </div>
        </div>
      </>
    );
  } else if (props.type == "dropdown") {
    return (
      <>
        {/* <Form.Label style={{ paddingLeft: 5, color: 'black', fontSize: '16px', fontWeight: 'bold', color: 'grey' }}>{Parser(props.label)}</Form.Label>
            <Form.Select id={props.tagid} className={props.className} onChange={onFieldChange} name={props.tagid} defaultValue={props.value}>
                {
                    dropdowndata != undefined && dropdowndata.map((item, i) => (
                        <option class="active" value={item.id}> {item[props.tagvalue]}</option>
                    ))
                }
            </Form.Select> */}

        <label
          for="exampleSelect1"
          style={{
            paddingLeft: 5,
            color: "black",
            fontSize: "16px",
            fontWeight: "bold",
            color: "grey",
          }}
        >
          {Parser(props.label)}
        </label>
        <select
          disabled={
            (props.tagid == "branch_id" ||
              props.tagid == "state_id" ||
              props.tagid == "Reason") &&
            dropdowndata.length == 1
              ? true
              : false
          }
          class="form-select"
          id={props.tagid}
          className={props.className}
          onChange={onFieldChange}
          name={props.tagid}
          value={props.value}
          style={{ background: "#E2E2E2", height: 30 }}
        >
          {dropdowndata != undefined &&
            dropdowndata.map((item, i) => (
              <option class="active" value={item.id}>
                {" "}
                {item[props.tagvalue]}
              </option>

              // onChange = {(event) => handleChange(props.tagid, event.target.value)}
              // <option class="active" selected={item[props.tagvalue] === 'English (United States)' || item[props.tagvalue] === 'United States' || "Select"}>{item[props.tagvalue]}</option>

              // < option class="active" id={item.id} > {item[props.tagvalue]}
              // </option>
            ))}
        </select>
      </>
    );
  } else if (props.type == "dropdownExams") {
    return (
      <>
        {/* <label for="exampleSelect1" style={{ paddingLeft: 5, color: 'black', fontSize: '16px', fontWeight: 'bold', color: 'grey' }}>{Parser(props.label)}</label>
            <select disabled class="form-select" id={props.tagid} className={props.className} name={props.tagid} value={props.value}
                style={{ background: '#E2E2E2', height: 30 }} >
                {

                    <option> Corporate Exams </option>
                }
            </select>

            {exams !== '' &&  dropdowndata.map(choice => (

                <div className="toppings-list-item">

                    <input type="checkbox"
                        name={props.tagid}
                        value={choice.ExamID}
                        onChange={handleCheckChange}
                        style={{ padding: 5,cursor:'pointer' }}
                        id={`custom-checkbox-${choice.ExamID}`}
                        defaultChecked={exams.includes(choice.ExamID)}
                    />

                    <label htmlFor={`custom-checkbox-${choice.ExamID}`} style={{ padding: 10,cursor:'pointer' }}>
                        {choice[props.tagvalue]}

                    </label>

                </div>

            ))
            }

            {exams === '' && dropdowndata.map(choice => (

                <div className="toppings-list-item">

                    <input type="checkbox"
                        name={props.tagid}
                        id={`custom-checkbox-${choice.ExamID}`}
                        value={choice.ExamID}
                        onChange={handleCheckChange}
                        style={{ padding: 5,cursor:'pointer' }}
                    />

                    <label htmlFor={`custom-checkbox-${choice.ExamID}`} style={{ padding: 10,cursor:'pointer' }}>
                        {choice[props.tagvalue]}

                    </label>

                </div>

            ))
            } */}

        <label
          for="exampleSelect1"
          style={{
            paddingLeft: 5,
            color: "black",
            fontSize: "16px",
            fontWeight: "bold",
            color: "grey",
          }}
        >
          {Parser(props.label)}
        </label>

        <div
          onClick={() => {
            handleClick();
          }}
          style={{
            background: "#E2E2E2",
            height: 30,
            borderColor: "gray",
            borderWidth: 10,
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <label
            style={{
              color: "black",
              fontSize: "16px",
              marginLeft: 5,
              width: "100%",
            }}
          >
            {" "}
            Corporate Exams{" "}
          </label>
          <img
            src={isClicked ? chevron_down : chevron_up}
            style={{
              width: "13px",
              height: "13px",
              position: "relative",
              marginRight: 5,
            }}
          ></img>
        </div>

        {!isClicked &&
          dropdowndata.map((choice) => (
            <div className="toppings-list-item">
              <input
                type="checkbox"
                name={props.tagid}
                id={`custom-checkbox-${choice.ExamID}`}
                value={choice.ExamID}
                onChange={handleCheckChange}
                style={{ padding: 5, cursor: "pointer" }}
                defaultChecked={exams.includes(choice.ExamID)}
              />

              <label
                htmlFor={`custom-checkbox-${choice.ExamID}`}
                style={{ padding: 10, cursor: "pointer" }}
              >
                {choice[props.tagvalue]}
              </label>
            </div>
          ))}

        {isClicked &&
          dropdowndata.map(
            (choice) =>
              exams.includes(choice.ExamID) && (
                <div className="toppings-list-item">
                  <input
                    type="checkbox"
                    name={props.tagid}
                    id={`custom-checkbox-${choice.ExamID}`}
                    value={choice.ExamID}
                    onChange={handleCheckChange}
                    style={{ padding: 5, cursor: "pointer" }}
                    defaultChecked={exams.includes(choice.ExamID)}
                  />

                  <label
                    htmlFor={`custom-checkbox-${choice.ExamID}`}
                    style={{ padding: 10, cursor: "pointer" }}
                  >
                    {choice[props.tagvalue]}
                  </label>
                </div>
              )
          )}
      </>
    );
  } else if (props.type == "disabled_dropdown") {
    return (
      <>
        {props.label != "" && (
          <label
            for="exampleSelect1"
            style={{
              paddingLeft: 5,
              color: "black",
              fontSize: "16px",
              fontWeight: "bold",
              color: "grey",
            }}
          >
            {Parser(props.label)}
          </label>
        )}
        <select
          disabled
          class="form-select"
          id={props.tagid}
          className={props.className}
          onChange={onFieldChange}
          name={props.tagid}
          value={props.value}
          style={{ background: "#E2E2E2", height: 30 }}
        >
          {<option class="active"> {props.value}</option>}
        </select>
      </>
    );
  } else if (props.type == "textarea") {
    return (
      <>
        <Form.Label
          style={{
            paddingLeft: 5,
            color: "black",
            fontSize: "16px",
            fontWeight: "bold",
            color: "grey",
          }}
        >
          {Parser(props.label)}
        </Form.Label>
        <Form.Control
          as="textarea"
          disabled={props.isDisable}
          placeholder={props.placeholder}
          defaultValue={props.value}
          onChange={onFieldChange}
          style={{ height: "100px" }}
          name={props.tagid}
        />
      </>
    );

    // <TextField
    //     id="outlined-multiline-flexible"
    //     label={Parser(props.label)}
    //     multiline
    //     maxRows={2}
    //     className={props.className}

    // />
  } else if (props.type == "textareaSherwin" && props.additionalEmailStatus) {
    return (
      <>
        <Form.Label
          style={{
            paddingLeft: 5,
            color: "black",
            fontSize: "16px",
            fontWeight: "bold",
            color: "grey",
          }}
        >
          {props.sherwinText == ""
            ? props.label
            : Parser("HR Manager's E-Mail<span style='color: red'> * </span>:")}
        </Form.Label>
        {props.sherwinText == "" ? (
          <Form.Control
            //as="textarea"
            placeholder={props.placeholder}
            defaultValue={props.value}
            onChange={onFieldChange}
            // style={{ height: "100px" }}
            name={props.tagid}
          />
        ) : (
          <div className="row">
            <div className="col-md-10 col-lg-10 col-xs-10 col-sm-10">
              <Form.Control
                onPaste={onPaste}
                type={props.type}
                style={{ color: "#000000" }}
                placeholder="HR E-mail..."
                defaultValue={props.value}
                onChange={onFieldChange}
                name={props.tagid}
              />
            </div>
            <div
              className="col-md-2 col-lg-2 col-xs-2 col-sm-2"
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <text style={{ color: "#000000" }}>{props.sherwinText}</text>
            </div>
          </div>
        )}
      </>
    );
  } else if (props.type == "datepicker") {
    return (
      <>
        <Form.Label
          style={{
            color: "black",
            fontSize: "16px",
            fontWeight: "bold",
            color: "grey",
          }}
        >
          {Parser(props.label)}
        </Form.Label>
        {/* <DatePicker id="date"
                type="date"
                size="small"
                format={'YYYY/MM/DD'}
                className={props.className}
                value={props.value}
                onChange={(event) => handleChange(props.tagid, event.target.value)}
                name={props.controlId}
            /> */}
        {/* <TextField id="date"
                type="date"
                defaultValue={props.value}
                size="small"
                className={props.className}

                onChange={onFieldChange}
                name={props.tagid}
                inputFormat="yyyy-MM-dd"
                format="yyyy-MM-dd"
                views={["year", "month", "date"]}
            /> */}
        {/* <DatePicker
                selected={date}
                //   onChange={ this.handleChange }  
                maxDate={props.tagid === 'date_of_injury' ? new Date() : new Date() - 7}
                placeholderText={props.placeholder}
                name={props.tagid}
                onChange={onFieldChange}
                dateFormat="yyyy-dd-MM"
                className={props.className}
                value={props.value}

                
            /> */}
        <br />
        <Fragment>
          <MuiPickersUtilsProvider utils={DateFnsUtils} id="datepicker">
            <KeyboardDatePicker
              disableFuture={props.tagid !== "Future_Date" ? true : false}
              disablePast={props.tagid == "Future_Date" ? true : false}
              minDate={
                props.tagid == "Future_Date"
                  ? getMandatoryData(
                      "visitPage",
                      "create_future_visit_for_today"
                    )
                    ? new Date()
                    : tomorrow
                  : undefined
              }
              autoOk
              open={open}
              fullWidth
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              value={props.value == "" ? date : props.value}
              InputAdornmentProps={{ position: "end" }}
              onChange={(date) => datePickerChange(date, props.tagid)}
              InputProps={{ readOnly: true }}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              TextFieldComponent={(props) => (
                <TextField {...props} onClick={(e) => setOpen(true)} />
              )}
              position="center"
              PopoverProps={{
                style: {
                  ...{
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    cursor: "pointer",
                  },
                },
              }}
            />
            {/* <DatePicker
        style={{ width: "100%" }}
        autoOk
        open={open}
        fullWidth
        variant="inline"
        inputVariant="outlined"
        format={props.tagid == "DateOfBirth" ? "MM-dd-yyyy" : "yyyy-dd-MM"}
        value={props.value == "" ? date : props.value}
        InputAdornmentProps={{ position: "end" }}
        onChange={(date) => datePickerChange(date, props.tagid)}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)} 
        renderInput={(params) => {
            return <TextField {...params} />;
          }}
        PopoverProps={{
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
          transformOrigin: { horizontal: "center", vertical: "bottom" }
        }}
      /> */}
          </MuiPickersUtilsProvider>
          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          TextFieldComponent={(props) => (
            <TextField {...props} onClick={(e) => setOpen(true)} />
          )}
        />
      </MuiPickersUtilsProvider> */}
        </Fragment>
      </>
    );
  } else if (props.type == "search") {
    return (
      <div style={{}}>
        <Form.Label
          style={{
            paddingLeft: 5,
            color: "black",
            fontSize: "16px",
            fontWeight: "bold",
            color: "grey",
          }}
        >
          {Parser(props.label)}
        </Form.Label>
        <TextField
          variant="standard"
          placeholder={props.placeholder}
          id="outlined"
          size="small"
          value={props.value}
          disabled={props.isDisable}
          defaultValue={props.tag_id}
          style={{
            fontSize: 9,
            backgroundColor: "#E9E9EC",
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 10,
            paddingBottom: 10,
            borderStyle: "none",
            borderRadius: 10,
            color: "black",
          }}
          className={props.className}
          onChange={onFieldChange}
          name={props.tagid}
          InputProps={{
            disableUnderline: true,
            color: "black",
            sx: {
              color: "black",
            },
            startAdornment: (
              <InputAdornment position="end">
                <img
                  src={SearchIcon}
                  style={{ width: "30px", height: "30px" }}
                ></img>
              </InputAdornment>
            ),
          }}
        />
      </div>
    );
  } else if (props.type == "label") {
    return <label className={props.className}>{Parser(props.label)}</label>;
  } else if (props.type == "checkbox") {
    return <Checkbox />;
  } else if (props.type == "radiobutton") {
    return (
      <div>
        {
          //console.log(props.options)
        }
        <Form.Label style={{ paddingLeft: 5, color: "black" }}>
          {Parser(props.label)}
        </Form.Label>
        {props.options.map((choice) => (
          <label style={{ padding: 10 }}>
            <input
              type="radio"
              id={props.tagid}
              name={props.tagid}
              value={choice.value}
              defaultChecked={choice.default}
              // selected={props.remotePatientYN}
              checked={
                (choice.label == "Yes" && props.remotePatientYN) ||
                (choice.label == "No" && !props.remotePatientYN)
              }
              onChange={onFieldChange}
              style={{ padding: 5, accentColor: "green" }}
            />
            {choice.label}
          </label>
        ))}
        {props.tagid == "RemotePatient_YN" && (
          <div>
            <Form.Label
              style={{
                paddingLeft: 5,
                color: "black",
                fontFamily: "calibre",
                fontStyle: "italic",
              }}
            >
              (Please select "Yes" only if patient is not in room and his/her
              mobile number is known)
            </Form.Label>
          </div>
        )}
        {props.remotePatientYN && (
          <div>
            <Form.Label
              style={{
                paddingLeft: 5,
                color: "black",
                fontSize: "16px",
                fontWeight: "bold",
                color: "grey",
              }}
            >
              {Parser("Phone Number<span style='color: red'> * </span>:")}
            </Form.Label>
            <div className="row">
              <div className="col-md-1 col-lg-1 col-xs-1 col-sm-1">
                <select
                  disabled
                  value={props.countryAbbr}
                  style={{ background: "#E2E2E2", height: 30, width: "100%" }}
                >
                  {" "}
                  <option>{props.countryAbbr}</option>{" "}
                </select>
              </div>
              <div className="col-md-11 col-lg-11 col-xs-11 col-sm-11">
                <Form.Control
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  type="text"
                  style={{ color: "#000000" }}
                  placeholder={props.placeholder}
                  onChange={onFieldChange}
                  name={"PhoneNumber"}
                  ref={phoneNoRef}
                  defaultValue={props.remotePhoneNo}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else if (props.type == "radiobuttonVisitHistory") {
    return (
      <div>
        <Form.Label style={{ paddingLeft: 5, color: "black" }}>
          {Parser(props.label)}
        </Form.Label>
        {props.options.map((choice) => (
          <label style={{ padding: 10 }}>
            <input
              type="radio"
              id={props.tagid}
              name={props.tagid}
              value={choice.value}
              defaultChecked={choice.default}
              checked={
                (choice.label == "Yes" && props.PrescriptionRequired_YN) ||
                (choice.label == "No" && !props.PrescriptionRequired_YN)
              }
              onChange={onFieldChange}
              style={{ padding: 5 }}
            />
            {choice.label}
          </label>
        ))}
      </div>
    );
  } else if (props.type == "textareaVisitHistory") {
    return (
      <>
        {props.isDisable && props.PrescriptionRequired_YN && (
          <Form.Label
            style={{
              paddingLeft: 5,
              color: "black",
              fontSize: "16px",
              fontWeight: "bold",
              color: "grey",
            }}
          >
            {Parser(props.label)}
          </Form.Label>
        )}
        {props.isDisable && props.PrescriptionRequired_YN && (
          <Form.Control
            as="textarea"
            disabled={props.isDisable}
            placeholder={props.placeholder}
            defaultValue={props.value}
            onChange={onFieldChange}
            style={{ height: "100px" }}
            name={props.tagid}
          />
        )}
      </>
    );
  } else if (props.type == "heading") {
    // return <>
    //     <Form.Label style={{ paddingLeft: 5, color: 'black' }}>{Parser(props.label)}</Form.Label>
    // </>
  } else if (props.type == "choosefile") {
    return (
      <>
        {/* <Form.Label style={{ paddingLeft: 5, color: 'black' }}>{Parser(props.label)}</Form.Label>
            <Icon inline style={{ paddingLeft: 5, color: 'blue' }}><AttachFile /></Icon> 
            onUpload={(event) => onFieldChange(props.urlUploadKey, event)}
            */}
        <UploadPreview
          documentsVisitID={props.documentsVisitID}
          ssn={ssn}
          data={Parser(props.label)}
          etext={props.placeholder}
          id={props.controlId}
          urlPath={props.urlPath}
          urlDeletePath={props.urlDeletePath}
          onUpload={(event) =>
            onFieldChange({
              target: { name: props.urlUploadKey, value: event, props: props },
            })
          }
        />
      </>
    );
  } else if (props.type == "button") {
    return (
      <>
        <Button className={"btn"} variant="primary" type="submit">
          {Parser(props.label)}
        </Button>
      </>
    );
  } else if (props.type == "labelValue") {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <label
            style={{
              paddingLeft: 5,
              color: "black",
              fontSize: "16px",
              fontWeight: "bold",
              color: "grey",
            }}
          >
            {Parser(props.label)}
          </label>{" "}
          &nbsp;{" "}
          <label
            style={{
              color: "rgb(27 119 226)",
              paddingLeft: 5,

              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            {props.value}
          </label>
        </div>
      </>
    );
  }
}

export default FormControls;
