import React, { Component, useState } from 'react';
import { Paper, TextField } from '@mui/material'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import Rating1 from '../Images/rating_1.png';
import Rating2 from '../Images/rating_2.png';
import Rating3 from '../Images/rating_3.png';
import Rating4 from '../Images/rating_4.png';
import Rating5 from '../Images/rating_5.png';
import ActiveRating1 from '../Images/rating_active_1.png';
import ActiveRating2 from '../Images/rating_active_2.png';
import ActiveRating3 from '../Images/rating_active_3.png';
import ActiveRating4 from '../Images/rating_active_4.png';
import ActiveRating5 from '../Images/rating_active_5.png';

function Rating(props) {
    
    var ratingImages = [Rating1,Rating2,Rating3,Rating4,Rating5]
    var ratingActiveImages = [ActiveRating1,ActiveRating2,ActiveRating3,ActiveRating4,ActiveRating5]

    const [ratingCopyImages, setRatingCopyImages]  = useState([Rating1,Rating2,Rating3,Rating4,Rating5]);

    const [selectedRating, setSelectedRating]  = useState(0);

    function ratingClicked(e) {
        if(e===selectedRating){
            setRatingCopyImages(ratingImages)
            setSelectedRating(0);
            props.ratingValue(selectedRating)
        }else{
        ratingImages[e-1] = ratingActiveImages[e-1]
        setRatingCopyImages(ratingImages)
        setSelectedRating(e);
        props.ratingValue(selectedRating)
    }
    }
    function handleChange(fieldId, value){
        props.onFieldChange(fieldId, value);
    }

    function reset(){
        setRatingCopyImages(ratingImages);
        setSelectedRating(0);
        props.onHide();
    }
    return (
        <Modal
             {...props}
            backdrop="static"
            keyboard={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
        <div className="container" style={{ paddingTop: 10 }}>
            <div className="row">
                {/* <div className='col-md-10 col-lg-10 col-sx-10 col-sm-10'> */}
                    <div className='p-4'>
                    <Form.Label style={{  color: 'black',fontSize:'17px'}}>Help Caduceus Telemed improve. How was the experience of this telemedicine visit?</Form.Label>

         <Form.Label style={{ fontSize:'16px', color:'black', fontWeight:'bold'}}>Comments:</Form.Label>
            <Form.Control as="textarea" placeholder={props.placeholder} onChange={(event)=> handleChange(props.tagid, event.target.value )}  style={{ height: '100px' }} />

            <div className='row p-1'>
                {
                    ratingCopyImages != undefined && ratingCopyImages.map((Name, i) => {
        return (
            
            <div className='col p-2' style={{}}>
            <div className='col d-flex justify-content-center'>
            <img onClick= {() => {ratingClicked(i+1)}}  src={Name} style={{width:'40px',height:'40px'}}></img>
            </div> 
             {/* {i == 0 && (
                 <div>
                    <text style={{ color: 'grey', backgroundColor:'#000000' ,justifyContent: 'center', alignItems: 'center', alignContent:'center', display:'flex' }}>Very dissatisfied</text>
                 </div>
                
            )
            }
            {i == 4 && (
            <div>
                <text style={{ color: 'grey', backgroundColor:'#000000' ,justifyContent: 'center', alignItems: 'center', alignContent:'center', display:'flex' }}>Very Satisfied</text>
            </div>
            
            )
            } */}
            
            </div>
            

          )
             })}

            </div>
            <div className='row'> 
                <div className='col-md-6 col-lg-6 col-sx-6 col-sm-6'>
                    <div  style={{ color: 'grey', justifyContent: 'left', alignItems: 'left', alignContent:'left', display:'flex' }}>
                        <Form.Label>Very dissatisfied</Form.Label>
                    </div>
                </div>
                <div className='col-md-6 col-lg-6 col-sx-6 col-sm-6'>
                    <div  style={{ color: 'grey', justifyContent: 'right', alignItems: 'right', alignContent:'right', display:'flex' }}>
                        <Form.Label>Very Satisfied</Form.Label>
                    </div>
                </div>
            </div>
                         <div className='row'>
            <div className='d-flex justify-content-center' >
            <button className={"btn"} onClick={reset} style={{marginTop:20, paddingTop: 5 ,paddingBottom: 5, paddingRight: 50, paddingLeft: 50}} >Submit</button>
            </div>
            </div>
                    {/* </div> */}

                </div>
            </div>
         </div> 
        </Modal>
    );
}

export default Rating;