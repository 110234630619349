import logo from "./logo.svg"; //
import "./App.css";
import Sidenav from "./components/Sidenav";
import AlertDailog from "./components/AlertDailog";
import {
  postRequest,
  getUser,
  getVisitId,
  getVisitType,
  getMandatoryData,
  getExamId,
  getDoctorId,
  getDoctorName,
  setDoctorId,
  setDoctorName,
} from "./Services/DataMode";
import Rating from "./pages/Rating";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { browserName, browserVersion, osName } from "react-device-detect";

import Container from "react-bootstrap/Container";
import React, {
  Component,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";

import { Routes, Route } from "react-router-dom";
import CreateVisit from "./pages/createVisit";
import Login from "./pages/Login";
import TopMenu from "./components/TopMenu";
import SMDashboard from "./pages/smDashboard";
import ParentCompanySelect from "./pages/ParentCompanySelect";
import ServiceRequestPageOne from "./pages/ServiceRequestPageOne";
import ServiceRequestPageTwo from "./pages/ServiceRequestPageTwo";
import SRDashboard from "./pages/ScheduledServiceRequest";
import FutureDashboard from "./pages/FutureVisits";
import CompletedVisitsDashboard from "./pages/CompletedVisitsDashboard";
import Reschedule from "./pages/Reschedule";
import SelectProvider from "./pages/selectProvider";
import SMCallRoom from "./pages/SMCallRoom";
import VisitHistory from "./components/VisitHistory";
import { useNavigate } from "react-router-dom";
import UserContext from "./components/context";
import EditVisitPage from "./pages/EditVisitPage";
import GODuser from "./pages/GODuser";
import IncompleteVisitsDashboard from "./pages/IncompleteVisitsDashbaord";
import AddDocumentSearchCompany from "./pages/AddDocumentSearchCompany";
import AddDocumentsDashboard from "./pages/AddDocumentsDashboard";
import AddDocumentsCreateUpdate from "./pages/AddDocumentsCreateUpdate";
import FutureVisitSearchCompany from "./pages/FutureVisitSearchCompany";
import CompanyProtocolDashboard from "./pages/CompanyProtocolDashboard";
import CompanyProtocolCreateUpdate from "./pages/CompanyProtocolCreateUpdate";
import EmailSummaryEdit from "./pages/EmailSummaryEdit";
function App() {
  const [modalShow, setModalShow] = React.useState(false);
  const [activePage, setActivepage] = useState();
  const [showBackButton, setshowBackButton] = useState(false);
  const [menuOpen, setmenuOpen] = useState(false);
  const [ratingValue, setRatingValue] = useState(0);
  const interval = useRef();
  const interval2 = useRef();
  const ref = useRef(null);
  const contentRef = useRef(null);
  const divRef = useRef(null);

  const rowRef = useRef(null);
  let navigate = useNavigate();
  const [isMac, setisMac] = useState(false);
  const context = useContext(UserContext);
  useEffect(() => {
    //console.log('app__activepage->'+activePage);
    // if(!(activePage == 'Select a Provider' || activePage == 'Patient Information' || activePage == 'Service Request')){
    //   handleBackButton();
    // }

    //handleResize();
    //window.addEventListener("resize", handleResize);
    return () => {
      //window.removeEventListener("resize", handleResize);
      // window.removeEventListener("popstate", handleBackButton);
    };
  }, [activePage]);

  function handleBackButton() {
    console.log("calling");
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function() {
      window.history.go(1);
      context.setUser(
        1,
        "Info",
        "Telemed: Back button is disabled",
        "Ok",
        false,
        "",
        () => {},
        () => {}
      );
    };
  }

  const handleResize = () => {
    if (osName == "Mac OS" && window.innerWidth == 1440) {
      setisMac(true);
    } else {
      setisMac(false);
    }
    const top = ref.current;
    const content = contentRef.current;
    const divbody = divRef.current;
    const row = rowRef.current;
    if (window.innerWidth <= 960) {
      // alert(side)
      top.className = "col-lg-12 col-md-12 col-xs-12 col-sm-12 fixed-top";
      content.className =
        activePage === "SMCallRoom"
          ? "col-sm-7 col-xs-7 col-md-7 col-lg-7 container scroll divBodySM paddingmargin0"
          : "col-lg-12 col-md-12 col-xs-12 col-sm-12 container divBody paddingmargin0";
      divbody.className = "marginleft row vh-100 h-100";
      row.className = "paddingmargin0";
    } else {
      top.className = "col-lg-7 col-md-7 col-sm-7 col-sm-7 fixed-top container";
      content.className =
        activePage === "SMCallRoom"
          ? "col-sm-7 col-xs-7 col-md-7 col-lg-7 container scroll divBodySM "
          : "col-lg-7 col-md-7 col-xs-7 col-sm-7  container divBody";
    }
  };

  function dismissRatingPopup() {
    postRequest(
      "ws_webrtc/telemed.asmx/InsertProviderRating",
      {
        sKey: getUser(),
        iVisitId: getVisitId(),
        iRating: ratingValue,
        sComments: textFinal,
      },
      (response) => {
        //console.log(response);
      },
      context
    );
    setModalShow(false);
    navigate(getVisitType() == "SR" ? "/App/SRDashboard" : "/App/Dashboard");
  }

  var textFinal = "";
  const handleFieldChange = (fieldId, value) => {
    textFinal = value;
  };
  function deleteVisit(visit_id) {
    postRequest(
      "ws_webrtc/WebRTC.asmx/DeleteVisit",
      { sKey: getUser(), iVisitId: visit_id },
      (response) => {
        console.log(response);
        navigate(
          getVisitType() == "SR" ? "/App/SRDashboard" : "/App/Dashboard"
        );
        classTimeStop();
      },
      context
    );
  }
  function classTimeStart(action) {
    postRequest(
      "ws_webrtc/Telemed.asmx/GetResentNotificationTimeOut",
      { sKey: getUser() },
      (response) => {
        interval.current = setInterval(() => {
          console.log("isVisitEngaged");
          console.log(new Date());
          isVisitEngaged(action);
        }, response.d.sessionId * 1000);

        return () => clearInterval(interval.current);
      },
      context
    );

    postRequest(
      "ws_webrtc/Telemed.asmx/telemed_OutOfRoom_Time_Get",
      { sKey: getUser() },
      (response) => {
        interval2.current = setInterval(() => {
          console.log("checkisVisitTaken");
          console.log(new Date());
          clearInterval(interval.current);
          console.log("hello get visit Id", getVisitId());
          if (getVisitId() > 0) {
            checkisVisitTaken(action);
          } else {
            classTimeStop();
          }
        }, response.d * 60 * 1000);

        return () => clearInterval(interval2.current);
      },
      context
    );
  }
  function checkisVisitTaken(action) {
    if (activePage === "SMCallRoom") {
      if (getDoctorId() == 0) {
        context.setUser(
          0,
          "Info",
          getMandatoryData("sm_room", "high_callvolume_alert"),
          "Ok",
          false,
          null,
          () => {
            // navigate(getVisitType() == 'SR' ? '/App/SRDashboard' : '/App/Dashboard')
            // action.endCall()
            // classTimeStop()
          },
          () => {
            outOfRoom(action);
          }
        );
      } else {
        context.setUser(
          0,
          "Info",
          [
            "Provider ",
            getDoctorName(),
            " is not available. Please select OK for the next available provider",
          ],
          "Call again",
          true,
          "OK",
          () => {
            postRequest(
              "ws_webrtc/Telemed.asmx/InsertProviderForVisitLog",
              {
                sKey: getUser(),
                iVisitId: getVisitId(),
                iDocId: getDoctorId(),
              },
              (response) => {
                setDoctorId(0);
                setDoctorName("Next Available");
                classTimeStart(action);
              },
              context
            );
          },
          () => {
            postRequest(
              "ws_webrtc/Telemed.asmx/InsertProviderForVisitLog",
              {
                sKey: getUser(),
                iVisitId: getVisitId(),
                iDocId: getDoctorId(),
              },
              (response) => {
                classTimeStart(action);
              },
              context
            );
          }
        );
      }
    }
  }
  function outOfRoom(action) {
    console.log("hello visitId", getVisitId());
    notifyEmail();
    postRequest(
      "ws_webrtc/Telemed.asmx/telemed_OutofRoom_YN_Update",
      { sKey: getUser(), iVisitId: getVisitId() },
      (response) => {
        console.log("out of room " + response.d);
        postRequest(
          "ws_webrtc/WebRTC.asmx/cancelRTCSession",
          { sKey: getUser(), iVisitId: getVisitId(), bAbort: true },
          (response) => {
            console.log("Cancel rtc " + response.d);

            navigate(
              getVisitType() == "SR" ? "/App/SRDashboard" : "/App/Dashboard"
            );
            action.endCall();
            classTimeStop();
          },
          context
        );
      },
      context
    );
  }
  function isVisitEngaged(action) {
    if( getVisitId() > 0){
      postRequest(
        "ws_webrtc/Util.asmx/isVisitEngaged",
        { sKey: getUser(), iVisitId: getVisitId() },
        async (response) => {
          console.log(response.d);
          if (response.d.status) {
            postRequest(
            
                 "ws_webrtc/Util.asmx/notifyANewVisit",
              { firebasetoken: "", sKey: getUser(), iVisitId: getVisitId() },
              (response) => {
                console.log(response.d);
              },
              context
            );
          } else {
            classTimeStop();
          }
        },
        context
      );

    }else{
      classTimeStop();
    }

  }
  function classTimeStop() {
    clearInterval(interval.current);
    clearInterval(interval2.current);
  }
  function classNavigation(action) {
    if (action == "end_initial_call") {
      context.setUser(
        1,
        "Info",
        getMandatoryData("sm_room", "abort_popup_message"),
        getMandatoryData("sm_room", "abort_popup_delete"),
        true,
        getMandatoryData("sm_room", "abort_popup_cancel"),
        () => {
          loadEndSession();
        },
        () => {
          deleteVisit(getVisitId());
        }
      );
    } else if (action == "end_followup_call") {
      context.setUser(
        1,
        "Info",
        "As you clicked on End Call, you will be redirected to dashboard",
        "Ok",
        false,
        null,
        null,
        () => {
          loadEndSession();
        }
      );
    } else if (action == "provider_abort") {
      if (activePage === "SMCallRoom") {
        context.setUser(
          0,
          "Info",
          "Call Ended",
          "Ok",
          false,
          null,
          () => {
            navigate(
              getVisitType() == "SR" ? "/App/SRDashboard" : "/App/Dashboard"
            );
            classTimeStop();
          },
          () => {
            navigate(
              getVisitType() == "SR" ? "/App/SRDashboard" : "/App/Dashboard"
            );
            classTimeStop();
          }
        );
      }
    } else if (action == "close") {
      console.log("action12345",action)
      setModalShow(true);
    } else if (action == "stream_destroyed") {
      if (activePage === "SMCallRoom") {
        if (getVisitId() === "0") {
        } else {
          context.setUser(
            0,
            "Error",
            "Call Ended",
            "Ok",
            false,
            null,
            null,
            () => {
              postRequest(
                "ws_webrtc/util.asmx/LowBandWidth_Disconnect_SendEmail",
                { sKey: getUser(), iVisistId: getVisitId() },
                (response) => {
                  console.log(response);
                  navigate(
                    getVisitType() == "SR"
                      ? "/App/SRDashboard"
                      : "/App/Dashboard"
                  );
                },
                context
              );
              classTimeStop();
            }
          );
        }
      }
    } else if (action == "video_off") {
      context.setUser(1, "Info", "Audio Only", "Ok", false, null, null, () => {
        console.log("audio only");
      });
    } else if (action == "video_on") {
      context.setUser(1, "Info", "Video ON", "Ok", false, null, null, () => {
        console.log("audio and video");
      });
    } else if (action == "session_reconnecting") {
      if (activePage === "SMCallRoom") {
        context.setUser(
          0,
          "Error",
          "Session is reconnecting",
          "Ok",
          false,
          null,
          null,
          () => {
            console.log("session");
          }
        );
      }
    } else if (action == "session_disconnect_video") {
      context.setUser(
        0,
        "Error",
        "Video session could not be established because of inadequate network speed, you will be redirected to Dashboard.",
        "Ok",
        false,
        null,
        null,
        () => {
          navigate(
            getVisitType() == "SR" ? "/App/SRDashboard" : "/App/Dashboard"
          );
        }
      );
      classTimeStop();
    } else if (action == "session_disconnect_bandwidth") {
      context.setUser(
        0,
        "Error",
        "Alert! Please move to an area with a stronger connection to make a TeleMed call.",
        "Ok",
        false,
        null,
        null,
        () => {
          loadEndSession();
        }
      );
    } else {
      navigate(action);
    }
  }
  function loadEndSession() {
    postRequest(
      "ws_webrtc/WebRTC.asmx/cancelRTCSession",
      { sKey: getUser(), iVisitId: getVisitId() },
      (response) => {
        console.log("End session ", JSON.stringify(response));
        navigate(
          getVisitType() == "SR" ? "/App/SRDashboard" : "/App/Dashboard"
        );
        classTimeStop();
      },
      context
    );
  }
  function disconnectFromVisit(action) {
    context.setUser(
      1,
      "Info",
      "Do you want to Disconnect from Visit?",
      "Yes",
      true,
      "Cancel",
      () => {
        console.log("disconnect from visit clicked Cancel");
      },
      () => {
        action.session.signal(
          {
            data: "hello",
            type: "SMCLOSED",
          },
          function(error) {
            if (error) {
              console.log(
                "signal error (" + error.name + "): " + error.message
              );
            } else {
              console.log("signal sent.");
            }
          }
        );
        action.endCall();
        // action.disconnect()
        setModalShow(true);
      }
    );
  }
  function inValidRemotePatient(action) {
    context.setUser(0, "Error", action, "OK", false, null, null, () => {
      classTimeStop();
      navigate(
        getVisitType() == "SR" ? "/App/SRDashboard" : "/App/CreateVisit"
      );
    });
  }

  function notifyEmail(action) {
    postRequest(
      "ws_webrtc/Telemed.asmx/SendEmailToTelemedProviders",
      {
        sKey: getUser(),
        iVisitId: getVisitId(),
      },
      (response) => {
        console.log(response);
        // navigate(
        //   getVisitType() == "SR" ? "/App/SRDashboard" : "/App/Dashboard"
        // );
      //  action.endCall();
        classTimeStop();
      },
      context
    );
  }

  return (
    // <div className="h-100 flex-grow-1 paddingmargin1">
    //       <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 flex-grow-1  h-100"></div>
    <div class="row paddingmargin0">
      <div class="col-lg-3 vh-100 d-none d-lg-block d-xl-block"></div>
      <div class="col-lg-6 vh-100 p-0">
        <div className="flex-column d-flex align-items-stretch base">
          <div style={{ position: "absolute", zIndex: 100, width: "100%" }}>
            {menuOpen && (
              <Sidenav menuClicked={(close) => setmenuOpen(close)} />
            )}
          </div>

          {activePage !== "SMCallRoom" && (
            <TopMenu
              activePage={activePage}
              showBackButton={showBackButton}
              menuClicked={(open) => setmenuOpen(open)}
            />
          )}
          <div
            className={
              activePage === "SMCallRoom"
                ? "flex-grow-1 col-12  divBodySM"
                : "flex-grow-1 col-12 divBody"
            }
            ref={contentRef}
          >
            <Routes>
              <Route
                path="/EmailSummaryEdit"
                element={
                  <EmailSummaryEdit
                    updatePage={() => (
                      setActivepage("Visit Summary"), setshowBackButton(true)
                    )}
                  />
                }
              />
              <Route
                path="/CompanyProtocolCreateUpdate"
                element={
                  <CompanyProtocolCreateUpdate
                    updatePage={() => (
                      setActivepage("Add Company Protocol"),
                      setshowBackButton(true)
                    )}
                  />
                }
              />
              <Route
                path="/CompanyProtocolDashboard"
                element={
                  <CompanyProtocolDashboard
                    updatePage={() => (
                      setActivepage("Company Protocol"),
                      setshowBackButton(false)
                    )}
                  />
                }
              />
              <Route
                path="/FutureVisitSearchCompany"
                element={
                  <FutureVisitSearchCompany
                    updatePage={() => (
                      setActivepage(" Select Company "),
                      setshowBackButton(false)
                    )}
                  />
                }
              />
              <Route
                path="/AddDocumentsDashboard"
                element={
                  <AddDocumentsDashboard
                    updatePage={() => (
                      setActivepage("Add Documents "), setshowBackButton(false)
                    )}
                  />
                }
              />
              <Route
                path="/AddDocumentsCreateUpdate"
                element={
                  <AddDocumentsCreateUpdate
                    updatePage={() => (
                      setActivepage("Add Documents"), setshowBackButton(true)
                    )}
                  />
                }
              />
              <Route
                path="/AddDocumentSearchCompany"
                element={
                  <AddDocumentSearchCompany
                    updatePage={() => (
                      setActivepage("Select Company "), setshowBackButton(false)
                    )}
                  />
                }
              />
              <Route
                path="/IncompleteVisitsDashboard"
                element={
                  <IncompleteVisitsDashboard
                    updatePage={() => (
                      setActivepage("Incomplete Visits"),
                      setshowBackButton(false)
                    )}
                  />
                }
              />
              <Route
                path="/GODuser"
                element={
                  <GODuser
                    updatePage={() => (
                      setActivepage("Select Company - Global user"),
                      setshowBackButton(false)
                    )}
                  />
                }
              />
              <Route
                path="/SelectCompany"
                element={
                  <ParentCompanySelect
                    updatePage={() => (
                      setActivepage("Select Company"), setshowBackButton(false)
                    )}
                  />
                }
              />
              <Route
                path="/Dashboard"
                element={
                  <SMDashboard
                    updatePage={() => (
                      setActivepage("Dashboard"), setshowBackButton(false)
                    )}
                  />
                }
              />
              <Route
                path="/CreateVisit"
                element={
                  <CreateVisit
                    updatePage={() => (
                      setActivepage("Patient Information"),
                      setshowBackButton(false)
                    )}
                  />
                }
              />
              <Route
                path="/SRPageOne"
                element={
                  <ServiceRequestPageOne
                    updatePage={() => (
                      setActivepage("Service Request"), setshowBackButton(false)
                    )}
                  />
                }
              />
              <Route
                path="/SRPageTwo"
                element={
                  <ServiceRequestPageTwo
                    updatePage={() => (
                      setActivepage("Service Request"), setshowBackButton(true)
                    )}
                  />
                }
              />
              <Route
                path="/SRDashboard"
                element={
                  <SRDashboard
                    updatePage={() => (
                      setActivepage("Scheduled Service Request"),
                      setshowBackButton(false)
                    )}
                  />
                }
              />
              <Route
                path="/EditVisitPage"
                element={
                  <EditVisitPage
                    updatePage={() => (
                      setActivepage("Edit Visit"), setshowBackButton(false)
                    )}
                  />
                }
              />
              <Route
                path="/FutureDashboard"
                element={
                  <FutureDashboard
                    updatePage={() => (
                      setActivepage("Future Visits"), setshowBackButton(false)
                    )}
                  />
                }
              />
              <Route
                path="/CompletedVisitsDashboard"
                element={
                  <CompletedVisitsDashboard
                    updatePage={() => (
                      setActivepage("Completed Visits"),
                      setshowBackButton(false)
                    )}
                  />
                }
              />
              <Route
                path="/Reschedule"
                element={
                  <Reschedule
                    updatePage={() => (
                      setActivepage("CompletedVisitsDashboard"),
                      setshowBackButton(false)
                    )}
                  />
                }
              />
              {/* <Route
                path="/SelectProvider"
                element={
                  <SelectProvider
                    updatePage={() => (
                      setActivepage(
                        getExamId() == ""
                          ? "Select a Provider"
                          : getMandatoryData("select_provider", getExamId())
                      ),
                      setshowBackButton(true)
                    )}
                  />
                }
              /> */}
              <Route
                path="/SMCallRoom"
                element={
                  <SMCallRoom
                    disconnectFromVisit={(action) =>
                      disconnectFromVisit(action)
                    }
                    timeStartHelper={(action) => classTimeStart(action)}
                    timeStopHelper={(action) => classTimeStop(action)}
                    navigationHelper={(action) => classNavigation(action)}
                    inValidRemoteNumber={(action) =>
                      inValidRemotePatient(action)
                    }
                    updatePage={() => (
                      setActivepage("SMCallRoom"), setshowBackButton(false)
                    )}
                  />
                }
              />
              <Route
                path="/VisitHistory"
                element={
                  <VisitHistory
                    updatePage={() => (
                      setActivepage("Patient Information"),
                      setshowBackButton(false)
                    )}
                  />
                }
              />
            </Routes>
          </div>
          <Rating
            show={modalShow}
            onHide={dismissRatingPopup}
            onFieldChange={handleFieldChange}
            ratingValue={(item) => {
              setRatingValue(item);
            }}
          />
        </div>
      </div>
      <div class="col-lg-3 vh-100 d-none d-lg-block d-xl-block"></div>
    </div>
    //</div>
  );
}

export default App;
