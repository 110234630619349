import { useNavigate } from "react-router-dom";
import { Paper } from "@mui/material";
import { useEffect, useState, useContext, useRef } from "react";
import UserContext from "../components/context";
import {
  setDoctorId,
  setDoctorName,
  getVisitTypeName,
  getisFollowupYN,
  getFollowupYN,
  getVisitTypeID,
  getPurposeOfVistID,
  getisGlobal,
  postRequest,
  getUser,
  setVisitId,
  getVisitId,
  getCompanyId,
  getMandatoryData,
  setVisitType,
  setExamId,
  setFutureVisitFromSMDashboard,
  setLanguageId,
  getCompanyName,
  getVisitType,
  setFollowupYN,
  setFromDashboard,
} from "../Services/DataMode";
import CancelIcon from "@mui/icons-material/Cancel";
import UpdateIcon from "@mui/icons-material/Update";
import Icon from "@mui/material/Icon";
import ReactLoading from "react-loading";
import React from "react";
import VisitsList from "../components/VisitsList";
import { PropaneSharp } from "@mui/icons-material";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "../Images/search_icon.png";
import SearchView from "../components/SearchView";
import Reschedule from "./Reschedule";
import PreviousVisitDetails from "./PreviousVisitDetails";
import { format } from "date-fns";
import changeicon from "../Images/changeicon.png";
import CompanyChangeView from "../components/ChangeCompanyView";
import ReasonForCancel from "../components/ReasonForCancel";

// import Rating from "./Rating";
function SMDashboard(props) {
  const [modalShowPopUP, setModalShowPopUP] = React.useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  const [modalShow3, setModalShow3] = React.useState(false);
  const [visits, setVisits] = useState([]);
  const [loading, setloading] = useState(true);
  const userInfo = useContext(UserContext).user;
  const context = useContext(UserContext);
  let navigate = useNavigate();
  const [useVisit, setUseVisit] = useState([]);
  const [reloading, setreloading] = useState(false);
  const [date, setDate] = useState(new Date());
  const ref = useRef();
  const [reasons, setReasons] = React.useState([]);
  const [selectedReason, setSelectedReason] = useState(0);
  const [subReasonList, setSubReasonsList] = useState([]);
  const [getData, setGetData] = useState(false);
  const [subreason, setSubreason] = useState(0);
  const [enteredComments, setEnteredComments] = useState("");
  const [comments, setComments] = useState(false);
  const [rescheduleDate, setRescheduleDate] = useState(false);

  const [visible, setVisibility] = useState(false);

  const [showReasonMandatory, setShowReasonMandatory] = useState(false);
  const [
    showPatientsReasonMandatory,
    setShowPatientsReasonMandatory,
  ] = useState(false);
  const [showCommentsMandatory, setShowCommentsMandatory] = useState(false);

  const searchRef = useRef();
  const data = [
    { id: "1", title: "First item" },
    { id: "2", title: "Second item" },
    { id: "3", title: "Third item" },
    { id: "4", title: "Fourth item" },
  ];

  useEffect(() => {
    props.updatePage();
    loadDashboard();
    setVisitId(0);
    setVisitType("");
    setExamId("");
  }, [reloading]);

  // function closeClicked(e) {
  //   postRequest('telemed.asmx/InsertProviderRating',
  //     { sKey: getUser(), iVisitId: getVisitId(), iRating: 3, sComments: textFinal },
  //     (response) => {
  //       console.log(response);
  //       setModalShow2(false)
  //     },context)
  // }
  // var textFinal = null
  // const handleFieldChange = (fieldId, value) => {
  //    textFinal = value
  // }

  function loadDashboard() {
    setloading(true);
    console.log("getisGlobal");
    console.log(getisGlobal());
    if (getisGlobal() !== "SM") {
      postRequest(
        "ws_webrtc/Telemed.asmx/GetGlobalSMDashboardVisits",
        {
          sKey: getUser(),
          sPatientName: "",
          iPurposeOfVisit: getPurposeOfVistID(),
          bFollowup: getisFollowupYN(),
          iVisitCatergory: getVisitTypeID(),
        },
        (response) => {
          console.log(response);
          setloading(false);
          setVisits(response.d);
        },
        context
      );
    } else {
      postRequest(
        "ws_webrtc/Util.asmx/getSMDashboardCompanyVisitsPatientNameList",
        { sKey: getUser(), iCompanyId: getCompanyId(), sPatientName: "" },
        (response) => {
          console.log(response);
          setloading(false);
          setVisits(response.d);
        },
        context
      );
    }
  }

  function visitClicked(item) {
    if (item.followup_YN == true) {
      setModalShow2(true);
      setUseVisit(item);
    } else {
      // setVisitId(item.visit_id); navigate('/App/SelectProvider'); setVisitType('Regular');
      setVisitId(item.visit_id);
      navigate("/App/SMCallRoom");
      setVisitType("Regular");
    }
  }

  function resetReasonAlertData() {
    setSelectedReason(0);
    setVisibility(false);
    setSubreason("0");
    setRescheduleDate(false);
    setComments(false);
    setModalShow3(false);
  }

  function visitDeleteClicked(item) {
    if (item.followup_YN) {
      console.log("visit clicked item", item.followup_YN);
      reasonsforCancel();
      setModalShow3(true);
      setUseVisit(item);
    } else {
      var str = "Do you want to cancel the visit for patient <XXXXXXXXX>  ?";
      var new_str = str.replace(
        /<XXXXXXXXX>/g,
        item.first_name + " " + item.last_name
      );

      var array = [
        "Do you want to cancel the visit for patient ",
        item.first_name + " " + item.last_name,
        " ?",
      ];
      context.setUser(
        1,
        "Info",
        array,
        "Yes",
        true,
        "No",
        () => {
          console.log(false);
        },
        () => {
          deleteVisit(
            item.visit_id,
            item.first_name,
            item.last_name,
            "0",
            "0",
            " "
          );
        }
      );
    }
  }
  // function resetCanceldeatails(){

  // }

  function deletewithReason(item) {
    if (selectedReason == 0) {
      context.setUser(
        0,
        "Error",
        getMandatoryData("sm_dashboard", "reasonForCancelMandatory"),
        "OK"
      );
    } else if (selectedReason == 1) {
      if (subreason == 0) {
        context.setUser(
          0,
          "Error",
          getMandatoryData("sm_dashboard", "reasonForCancelSubMandatory"),
          "OK"
        );
      } else if (subreason == 4) {
        if (
          enteredComments == "" &&
          getMandatoryData(
            "sm_dashboard",
            "reasonForCancelCommentsMandatory"
          ) !== ""
        ) {
          context.setUser(
            0,
            "Error",
            getMandatoryData(
              "sm_dashboard",
              "reasonForCancelCommentsMandatory"
            ),
            "OK"
          );
        } else {
          // submit delete
          deleteVisit(
            useVisit.visit_id,
            useVisit.first_name,
            useVisit.last_name,
            selectedReason,
            subreason,
            enteredComments
          );
          setModalShow3(false);
          setSelectedReason(0);
          setSubReasonsList("");
          setRescheduleDate(false);
          setComments(false);
        }
      } else {
        // submit delete
        deleteVisit(
          useVisit.visit_id,
          useVisit.first_name,
          useVisit.last_name,
          selectedReason,
          subreason,
          ""
        );
        setModalShow3(false);
        setSelectedReason(0);
        setSubReasonsList("");
        setRescheduleDate(false);
        setVisibility(false);
        setSubreason("0");
      }
    } else if (selectedReason == 2) {
      console.log(subReasonList, "subreasonlist");
      // date
      console.log(date);
      var finalDate = getFormattedDate(date);
      console.log(finalDate);
      // submit resedhule
      RescheduleVisit(useVisit, selectedReason, "0", "");
      setModalShow3(false);
      setSelectedReason(0);
      setSubReasonsList("");
      setRescheduleDate(false);
    } else if (selectedReason == 3) {
      if (
        enteredComments == "" &&
        getMandatoryData("sm_dashboard", "reasonForCancelCommentsMandatory") !==
          ""
      ) {
        context.setUser(
          0,
          "Error",
          getMandatoryData("sm_dashboard", "reasonForCancelCommentsMandatory"),
          "OK"
        );
      } else {
        // submit delete
        deleteVisit(
          useVisit.visit_id,
          useVisit.first_name,
          useVisit.last_name,
          selectedReason,
          "0",
          enteredComments
        );
        setModalShow3(false);
        setSelectedReason(0);
        setSubReasonsList("");
        setRescheduleDate(false);
        setComments(false);
      }
    }
  }

  function getFormattedDate(date) {
    console.log("hello formateed date ++>", date);
    var year = date;

    // var month = (1 + date.getMonth()).toString();
    // month = month.length > 1 ? month : "0" + month;

    // var day = date.getDate().toString();
    // day = day.length > 1 ? day : "0" + day;

    return year;
  }

  function reasonsforCancel() {
    postRequest(
      "ws_webrtc/Telemed.asmx/VisitCancelReasonType",
      { sKey: getUser() },
      (response) => {
        console.log("reasons response type", response.d);
        console.log("reasons response type", response.d);

        var newArray = [];

        newArray.push({ id: 0, name: "Select" });
        for (var i = 0; i < response.d.length; i++) {
          newArray.push({
            id: response.d[i].reason_type_id,
            name: response.d[i].reason_type,
          });
        }
        console.log("setReasons", newArray);

        setReasons(newArray);
        /// console.log("setReasons",reasons);
      },
      context
    );
  }
  function handleFieldReasonChange(event) {
    var Array = [];
    var name = event.target.name;
    var value = event.target.value;
    setSelectedReason(event.target.value);
    console.log(event.target.name + " --> " + event.target.value);
    console.log(event.target + " -----> ");
    console.log(selectedReason + "selectedReason -----> ");
    if (value == 1) {
      // setVisibility(true)
      setComments(false);
      subreasonsforCancel(value);
      setRescheduleDate(false);
    } else if (value == 3) {
      setVisibility(false);
      setSubreason("0");
      setComments(true);
      setRescheduleDate(false);
    } else if (value == 2) {
      setComments(false);
      setVisibility(false);
      setSubreason("0");
      setRescheduleDate(true);
      console.log("set DAte picker");
    } else {
      setRescheduleDate(false);
      setVisibility(false);
      setSubreason("0");
    }
  }

  function onFieldChange(event) {
    var name = event.target.name;
    var value = event.target.value;
    console.log(event.target.name + " --> " + event.target.value);
    setEnteredComments(event.target.value);
  }

  function subreasonsforCancel(item) {
    postRequest(
      "ws_webrtc/Telemed.asmx/PatientReasonTypeForVisitCancel",
      { sKey: getUser(), iReasonTypeId: item },
      (response) => {
        console.log("sub reasons response type", response.d);

        if (response.d !== "") {
          var newArray = [];
          newArray.push({ id: 0, name: "Select" });
          for (var i = 0; i < response.d.length; i++) {
            newArray.push({
              id: response.d[i].patient_reason_type_id,
              name: response.d[i].patient_reason_type,
            });
          }
          setSubReasonsList(newArray);
          setVisibility(true);
          console.log("setsubReasons", newArray);
        } else {
          var array = [];
          setSubReasonsList(array);
        }
      },
      context
    );
  }

  function handleFieldChangesubreasons(event) {
    var name = event.target.name;
    var value = event.target.value;
    console.log(event.target.name + " --> " + event.target.value);
    setSubreason(event.target.value);
    if (value == 4) {
      setComments(true);
    }
  }

  function deleteVisit(
    visit_id,
    first_name,
    last_name,
    iReasonTypeId,
    iPatientReasonSubTypeId,
    comments
  ) {
    setloading(true);
    postRequest(
      "ws_webrtc/Telemed.asmx/DeleteFollowUpVisit_New",
      {
        sKey: getUser(),
        iVisitId: visit_id,
        iReasonTypeId: iReasonTypeId,
        iPatientReasonSubTypeId: iPatientReasonSubTypeId,
        comments: comments,
      },
      (response) => {
        setloading(false);
        var str = "Visit for patient <XXXXXXXXX> cancelled successfully.";
        var new_str = str.replace(/<XXXXXXXXX>/g, first_name + " " + last_name);
        var array = [
          "Visit for patient ",
          first_name + " " + last_name,
          " cancelled successfully.",
        ];
        context.setUser(2, "Success", array, "OK");
        setreloading(!reloading);
      },
      context
    );
  }
  function changeCompany(selectedValue) {
    console.log("changeCompany");
    setModalShowPopUP(true);
  }
  function popupClose() {
    setModalShowPopUP(false);
  }
  function popupSubmitClose() {
    setModalShowPopUP(false);
    loadDashboard();
  }
  function patientSelected(selectedValue) {
    setVisits([]);
    setloading(true);
    if (getisGlobal() !== "SM") {
      postRequest(
        "ws_webrtc/Telemed.asmx/GetGlobalSMDashboardVisits",
        {
          sKey: getUser(),
          sPatientName: selectedValue.first_name,
          iPurposeOfVisit: getPurposeOfVistID(),
          bFollowup: getisFollowupYN(),
          iVisitCatergory: getVisitTypeID(),
        },
        (response) => {
          console.log(response);
          setloading(false);
          setVisits(response.d);
        },
        context
      );
    } else {
      postRequest(
        "ws_webrtc/Util.asmx/getSMDashboardCompanyVisitsPatientNameList",
        {
          sKey: getUser(),
          iCompanyId: getCompanyId(),
          sPatientName: selectedValue.first_name,
        },
        (response) => {
          //console.log(response);
          setloading(false);
          setVisits(response.d);
        },
        context
      );
    }
  }

  function clearVisitsWhileLoading(item) {
    console.log(item.clear_visit);
    if (item.clear_visit >= 1) {
      setGetData(true);
      setVisits([]);
    } else {
      if (getData) {
        setGetData(false);
        loadDashboard();
      }
    }
  }

  function onDateChanged(value) {
    console.log("Dashboard date  ++++> " + value);
    const date = new Date(value);
    if (!isNaN(date)) {
      setDate(date.toLocaleDateString());
    } else {
      console.error("Invalid date value:", value);
    }
  }

  // function RescheduleVisit(item) {
  //   postRequest('ws_webrtc/telemed.asmx/AlterFollowUpDate',
  //     { sKey: getUser(), iVisitId: item.visit_id, sFollowUp_Date: date, },
  //     (response) => {
  //       console.log(response);
  //       loadDashboard();
  //     }, context)
  // }
  function RescheduleVisit(
    item,
    iReasonTypeId,
    iPatientReasonSubTypeId,
    comments
  ) {
    postRequest(
      "ws_webrtc/telemed.asmx/AlterFollowUpDate_New",
      {
        sKey: getUser(),
        iVisitId: item.visit_id,
        sFollowUp_Date: date,
        iReasonTypeId: iReasonTypeId,
        iPatientReasonSubTypeId: iPatientReasonSubTypeId,
        comments: comments,
      },
      (response) => {
        console.log(response);
        loadDashboard();
      },
      context
    );
  }

  function CreateInitialLevel(item) {
    if (item.visit_id == 0) {
      setVisitType("Regular");
      navigate("/App/CreateVisit");
     
    } else {
      

      setloading(true);
      postRequest(
        "ws_webrtc/Util.asmx/FollowUpVisitCreateInitialLevel",
        { sKey: getUser(), iVisitId: item.visit_id },
        (response) => {
          console.log(response);
          setloading(false);
          if (item.IsFutureVisit && !item.Abort_YN) {
            setVisitId(item.visit_id);
            setVisitType("Future");
            setFutureVisitFromSMDashboard("True");
            navigate("/App/CreateVisit");
          } else {
            if (item.followup_YN == true) {
              if (!response.d.FollowUp_Visit_Create_YN) {
                context.setUser(
                  1,
                  "Info",
                  response.d.message,
                  "OK",
                  false,
                  "No",
                  () => {
                    console.log(false);
                  },
                  () => {
                    console.log(false);
                  }
                );
              } else if (response.d.Patient_Level_YN) {
                context.setUser(
                  1,
                  "Info",
                  response.d.message,
                  "OK",
                  false,
                  "No",
                  () => {
                    console.log(false);
                  },
                  () => {
                    console.log(false);
                  }
                );
              } else {
                setModalShow2(true);
                setUseVisit(item);
              }
            } else {
              console.log("Language_Id:", item.Language_Id);
              setLanguageId(item.Language_Id);
              setVisitId(item.visit_id);
              setVisitType("Regular");

              //  navigate('/App/SelectProvider');
              console.log(item.PurposeOfVisit_Id);
              if (item.PurposeOfVisit_Id == 2) {
                //setLoading(true)
                postRequest(
                  "ws_webrtc/Telemed.asmx/InsertProviderForVisitLog",
                  { sKey: getUser(), iVisitId: item.visit_id, iDocId: 0 },
                  (response) => {
                    setDoctorId(0);
                    setDoctorName("Next Available");
                    //      setLoading(false)
                    navigate("/App/SMCallRoom");
                  },
                  context
                );
              } else {
                //     navigate('/App/SelectProvider');
                if (
                  getMandatoryData(
                    "visitPage",
                    "isSelectaProviderScreenVisible"
                  )
                ) {
                  isproviderEngaged(item);
                } else {
                  navigate("/App/SelectProvider");
                }
              }
            }
          }
        },
        context
      );
    }
  }

  function getdocCheckoutStatus(item) {
    postRequest(
      "ws_webrtc/Telemed.asmx/IsProviderCheckoutState",
      { sKey: getUser(), iVisitId: getVisitId() },
      (response) => {
        console.log(
          "get Doc status",
          response.d.IsInCheckout + "" + response.d.IsInConference
        );

        if (response.d.IsInCheckout || response.d.IsInConference) {
          context.setUser(
            1,
            "Info",
            getMandatoryData("select_provider", "all_engaged_message"),
            "Ok",
            false,
            "",
            "",
            () => {
              // navigate("/App/Dashboard");
              notifyEmail();
            }
          );

          //      context.setUser(1, "Info", getMandatoryData("select_provider","all_engaged_message"), getMandatoryData("select_provider","all_engaged_btn"),navigate('/App/Dashboard'));
        } else if (!response.d.IsInCheckout && !response.d.IsInConference) {
          //    context.setUser(1, "Info", getMandatoryData("select_provider","all_engaged_message"), getMandatoryData("select_provider","all_engaged_btn"), false, "", "", () => { navigate('/App/Dashboard') });
          getStateSpecific(item);
        }
      },
      context
    );
  }
  function isproviderEngaged(item) {
    postRequest(
      "ws_webrtc/telemed.asmx/IsAllTelemedProvidersEngaged",
      { sKey: getUser(), iVisitId: item.visit_id },
      (response) => {
        console.log(response.d, "get Doc status");

        if (response.d) {
          context.setUser(
            1,
            "Info",
            getMandatoryData("select_provider", "all_engaged_message"),
            "Ok",
            notifyEmail()
          );
        } else {
          getdocCheckoutStatus(item);
        }
      },
      context
    );
  }
  function getStateSpecific(item) {
    postRequest(
      "ws_webrtc/Telemed.asmx/getStateSpecificProvider",
      { sKey: getUser(), iVisitId: item.visit_id },
      (response) => {
        console.log(response.d, "get Doc ID");
        insertDoc(response.d);
        setDoctorId(response.d.Doc_ID)
      },
      context
    );
  }
  function insertDoc(item) {
    //setLoading(true)
    console.log(item);
    postRequest(
      "ws_webrtc/Telemed.asmx/InsertProviderForVisitLog",
      { sKey: getUser(), iVisitId: getVisitId(), iDocId: item.Doc_ID },
      (response) => {
        setDoctorId(item.Doc_ID);

        //     setDoctorId(selectedOption.value == undefined ? 0 : selectedOption.value)
        //    setDoctorName(selectedOption.label == undefined ? 'Next Available' : selectedOption.label)
        // setLoading(false)
        navigate("/App/SMCallRoom");
      },
      context
    );
  }

  function PreviousVisitDetailsOKClicked(item) {
    setModalShow2(false);
    if (useVisit.date_of_service === format(new Date(), "MM/dd/yyyy")) {
      setVisitId(useVisit.visit_id);
      navigate("/App/CreateVisit");
    } else {
      context.setUser(
        1,
        "Info",
        "Follow Up visit not due as yet - Wish to edit date, click 'Yes'.",
        "Yes",
        true,
        "No",
        () => {
          console.log(false);
        },
        () => {
          setVisitId(useVisit.visit_id);
          navigate("/App/CreateVisit");
        }
      );
    }
  }
  const handleResize = () => {
    const top = ref.current;
    const search = searchRef.current;
    if (window.innerWidth <= 960) {
      top.className = "col-lg-12 col-md-12 col-xs-12 col-sm-12  paddingLR";
      search.className = "marginRight10";
    } else {
      top.className = "col-lg-7 col-md-7 col-sm-7 col-sm-7 container";
      search.className = "marginRight35";
    }
  };

  function notifyEmail() {
    postRequest(
      "ws_webrtc/Telemed.asmx/SendEmailToTelemedProviders",
      {
        sKey: getUser(),
        iVisitId: getVisitId(),
      },
      (response) => {
        console.log(response);
      },
      context
    );
  }

  return (
    <div className="h-100 col-12 px-2">
      {/* {loading && <div> <ReactLoading type={'bubbles'} color={'#ff0000'} height={'20%'} width={'20%'}  /> </div>} */}
      {loading && (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactLoading
            type={"spin"}
            color={"#ff0000"}
            height={"5%"}
            width={"5%"}
          />
        </div>
      )}
      {getisGlobal() !== "SM" && (
        <>
          <label
            for="exampleSelect1"
            style={{
              paddingLeft: 5,
              fontSize: "16px",
              fontWeight: "bold",
              color: "black",
            }}
          >
            Visit Type:
          </label>
          <label
            for="exampleSelect1"
            style={{
              paddingLeft: 5,
              fontSize: "16px",
              fontWeight: "bold",
              color: "lightBlue",
            }}
          >
            {getVisitTypeName()}
          </label>
        </>
      )}

      <div className="sticky-top bg-white" style={{ zIndex: 99 }}>
        <SearchView
          isDisable={loading}
          heading={getMandatoryData(
            "sm_dashboard",
            "pateint_search_sub_heading"
          )}
          placeholder={getMandatoryData(
            "sm_dashboard",
            "pateint_search_place_holder"
          )}
          url={
            getisGlobal() !== "SM"
              ? "ws_webrtc/telemed.asmx/GetGlobalSMTelemedPatients"
              : "ws_webrtc/Util.asmx/getSMDashboardCompanyVisitsPatientNameGet"
          }
          handleClick={patientSelected}
          clearVisits={clearVisitsWhileLoading}
        ></SearchView>
      </div>
      {getisGlobal() !== "SM" && (
        <>
          <label
            for="exampleSelect1"
            style={{
              padding: 5,
              fontSize: "16px",
              fontWeight: "bold",
              color: "lightBlue",
            }}
          >
            {getCompanyName()}
          </label>
          <img
            src={changeicon}
            onClick={changeCompany}
            style={{
              color: "#D92A28",
              width: 30,
              height: 30,
              cursor: "pointer",
            }}
          />
        </>
      )}

      <div className="px-1">
        <VisitsList
          deleteIcon={true}
          data={visits}
          handleRescheduleClick={(item) => {
            setModalShow(true);
            setUseVisit(item);
          }}
          handleClick={CreateInitialLevel}
          handleDeleteClick={visitDeleteClicked}
        />
        {modalShow && (
          <Reschedule
            show={modalShow}
            onHideMain={() => {
              RescheduleVisit(useVisit, "0", "0", " ");
              setModalShow(false);
            }}
            handleDateChange={onDateChanged}
            onHide={() => {
              setDate(new Date());
              setModalShow(false);
            }}
            data={useVisit}
            changedDate={date}
          />
        )}
        {modalShow2 && (
          <PreviousVisitDetails
            show={modalShow2}
            onHideMain={() => setModalShow2(false)}
            onHide={PreviousVisitDetailsOKClicked}
            data={useVisit}
          />
        )}
        {modalShow3 && (
          <ReasonForCancel
            showReasonMandatory={showReasonMandatory}
            showPatientsReasonMandatory={showPatientsReasonMandatory}
            showCommentsMandatory={showCommentsMandatory}
            show={modalShow3}
            onHideMain={(item) => {
              deletewithReason(item);
            }}
            onHide={() => resetReasonAlertData()}
            data={useVisit}
            reasons={reasons}
            value={selectedReason}
            handleFieldReason={(event) => handleFieldReasonChange(event)}
            handleFieldChangesubreasons={handleFieldChangesubreasons}
            onFieldChange={onFieldChange}
            patientreason={subReasonList}
            subreasonvalue={subreason}
            comments={comments}
            handleDateChange={onDateChanged}
            changedDate={date}
            rescheduleDate={rescheduleDate}
            setpatientreason={visible}
          />
        )}
      </div>

      {modalShowPopUP && (
        <>
          <CompanyChangeView
            onHide={popupClose}
            onSubmitHide={popupSubmitClose}
          />
        </>
      )}
      {/* <Rating show={modalShow2} onHide={closeClicked} onFieldChange={handleFieldChange} />      */}
    </div>
  );
}

export default SMDashboard;
