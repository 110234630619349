import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import MainPage from "./NewMainPage";
import SMCallRoom from "./pages/SMCallRoom";

import { HashRouter } from "react-router-dom";
import { MemoryRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <div class="row">
  // <div class="col-lg-3 vh-100 d-none d-lg-block d-xl-block">
  // </div>
  // <div class="col-lg-6 vh-100 p-0">
  // <MemoryRouter>
  <MemoryRouter>
    <MainPage />
  </MemoryRouter>
  // </MemoryRouter>
  //   </div>
  //   <div class="col-lg-3 vh-100 d-none d-lg-block d-xl-block">
  //   </div>
  // </div>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
