import React, { useRef, useState, useEffect } from "react";
import { useSpeechSynthesis } from "react-speech-kit";
import { TextField, InputAdornment } from "@mui/material";

import SendButton from "../Images/send_button.png";
import video_1 from "../videos/video_1.mp4";
import video_2 from "../videos/video_2.mp4";
import video_3 from "../videos/video_3.mp4";

import { setVideoIndexId, getVideoIndexId } from "../Services/DataMode";

function videoLoop(props) {
  const [vidIndex, setVidIndex] = useState(0);

  var videos = [video_1, video_2, video_3];


  useEffect(() => {
    let index = getVideoIndexId();
    if (index >= 0 && index < videos.length) {
      setVidIndex(index);
      setVideoIndexId((index + 1) % videos.length);
    } else {
      setVidIndex(0);
      setVideoIndexId(1);
    }
  }, []);
  const handleVideoEnd = () => {
    if (vidIndex + 1 < videos.length) {
      setVidIndex(vidIndex + 1);
    } else {
      setVidIndex(0);
    }
  };


  return (
    <video
      controls
      autoPlay
      muted
      width="100%"
      height="100%"
      src={videos[vidIndex]}
      onEnded={handleVideoEnd}
    /> //idx + 1)} />
  );
}
export default videoLoop;
